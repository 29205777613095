import React, { useState, useEffect } from 'react';

import Helmet from 'react-helmet';

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import nbikesSimpleLogo from 'assets/nbikes_Simbolo-removebg-preview.png';

import { toastError } from 'utils/defaultToasts';

import api from 'services/api';
import * as S from './styles';
import Product from './components/Product';

export default function MyNegotiations() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [filter, setFilter] = useState('&IsSuccessful=true');
  const [pointOfViewParam, setPointOfViewParam] = useState(false);
  const [search, setSearch] = useState('');

  const [negotiationsData, setNegotiationsData] = useState([]);

  const handlePreviousPage = () => {
    if (currentPage === 1) return;

    setCurrentPage((prevPage) => (prevPage -= 1));
  };

  const handleNextPage = () => {
    if (currentPage === totalPages) return;

    setCurrentPage((prevPage) => (prevPage += 1));
  };

  const getMyNegotiations = async () => {
    const myNegotiationsUrl = '/api/v1/Negotiations/';

    const parameters = `?SearchText=${search}&AsSeller=${pointOfViewParam}&CurrentPage=${currentPage}&PageSize=5&IsPaged=true${filter}`;

    try {
      setNegotiationsData([]);

      const response = await api.get(`${myNegotiationsUrl}${parameters}`);
      const { data } = response;

      const paginationFromHeaders = response.headers.pagination;
      const totalPagesFromHeaders = JSON.parse(paginationFromHeaders)
        .TotalPages;

      setTotalPages(totalPagesFromHeaders);
      setNegotiationsData(data);
    } catch (error) {
      toastError(
        'Erro ao buscar suas negociações ou não há nenhuma negociação bem-sucedida.'
      );
    }
  };

  useEffect(() => {
    getMyNegotiations();
  }, [pointOfViewParam, currentPage, filter]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pointOfViewParam, filter]);

  return (
    <>
      <S.MainContainer>
        <Helmet>
          <meta
            property="og:title"
            content="n bikes | Uma mão na roda! | Brasil"
          />
          <meta
            property="og:image"
            content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
          />
          <meta
            property="og:description"
            content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
          />
          <title>Minhas Negociações | n Bikes | Brasil</title>
        </Helmet>

        <S.PageTitle>Minhas Negociações</S.PageTitle>

        <S.SearchBarContainer>
          <S.SearchBar
            type="text"
            placeholder="Procurar por negociação"
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
          <S.SearchButton onClick={getMyNegotiations}>Pesquisar</S.SearchButton>
        </S.SearchBarContainer>

        <S.ProductsContainer>
          {negotiationsData.map((negotiation) => (
            <Product
              setCurrentPage={setCurrentPage}
              getMyNegotiations={getMyNegotiations}
              data={negotiation}
              negotiationId={negotiation.Id}
              asSeller={pointOfViewParam}
            />
          ))}

          {!negotiationsData.length && (
            <S.NegotiationsNotFound>
              <img width={250} src={nbikesSimpleLogo} alt="" />

              <S.NegotiationsNotFoundText>
                Nenhuma negociação encontrada.
              </S.NegotiationsNotFoundText>
            </S.NegotiationsNotFound>
          )}
        </S.ProductsContainer>

        {negotiationsData.length > 0 && (
          <S.PagePreviousNext>
            <div className="pageCounter">
              {currentPage === 1 ? null : (
                <button type="button" onClick={handlePreviousPage}>
                  <FaAngleLeft /> Anterior
                </button>
              )}

              {currentPage === totalPages ? null : (
                <button type="button" onClick={handleNextPage}>
                  Próxima <FaAngleRight />
                </button>
              )}
            </div>
          </S.PagePreviousNext>
        )}
      </S.MainContainer>
    </>
  );
}
