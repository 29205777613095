import React, { useCallback, useEffect, useState, useRef } from 'react';

import { toastError, toastSuccess, toastInfo } from 'utils/defaultToasts';

import { FaMoneyCheckAlt } from 'react-icons/fa';
import * as S from './styles';

import api from '~/services/api';

export default function Payment({ setPage, announcement, setAnnouncement }) {
  const animationContainerRef = useRef(null);

  const insertInto = useCallback((string, index, inserted) => {
    return (
      string.slice(0, index) + inserted + string.slice(index + Math.abs(0))
    );
  }, []);

  const MoneyMask = (amount) => {
    amount = amount.replace(/\D/g, '');

    while (amount.length > 3 && amount.charAt(0) === '0') {
      amount = amount.substring(1);
    }

    while (amount.length < 3) {
      amount = `0${amount}`;
    }

    let ponctuationIndex = amount.length - 2;
    amount = insertInto(amount, ponctuationIndex, ',');

    if (amount.length > 6) {
      while (ponctuationIndex > 3) {
        ponctuationIndex -= 3;
        amount = insertInto(amount, ponctuationIndex, '.');
        ponctuationIndex -= 1;
      }
    }

    return amount;
  };

  const [value, setValue] = useState('0,00');

  const toCurrency = useCallback((amount) => {
    return parseFloat(String(amount).replaceAll('.', '').replace(',', '.'));
  }, []);

  const showContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '1';
    animationContainerRef.current.style.left = '0';
  };

  const hideContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '0';
    animationContainerRef.current.style.left = '100%';
  };

  const patchPrice = useCallback(async () => {
    toastInfo('Adicionando o preço do produto...');

    const price = toCurrency(value);

    try {
      const patchPriceUrl = `api/v1/Announcements/${announcement.Id}/Payment`;
      const response = await api.patch(patchPriceUrl, { price });

      hideContainerAnimation();

      setAnnouncement(response.data);

      toastSuccess('Preço adicionado com sucesso!');

      setTimeout(() => {
        setPage('Review');
      }, 600);
    } catch (error) {
      if (error?.response?.status === 400) {
        toastError(
          'Preencha os campos anteriores antes de adicionar o preço do produto.'
        );
        return;
      }

      toastError('Erro ao adicionar o preço do produto.');
    }
  }, [announcement, setPage, setAnnouncement, toCurrency, value]);

  useEffect(() => {
    if (announcement.Price) {
      setValue(announcement.Price);
    }
  }, []);

  useEffect(() => {
    showContainerAnimation();
  }, []);

  return (
    <div>
      <S.AnimationContainer ref={animationContainerRef}>
        <S.Container>
          <h1>Vamos agora definir detalhes do pagamento</h1>

          <S.Field>
            <h2>Preço:</h2>
            <div>
              <div id="dollar-sign-and-input">
                <FaMoneyCheckAlt
                  style={toCurrency(value) >= 1 && { fill: '#00c569' }}
                />

                <h3>R$</h3>
                <input
                  placeholder="Valor do Produto"
                  value={value}
                  onChange={(e) => setValue(MoneyMask(e.target.value))}
                />
              </div>
              <p id="example-div">Ex.: R$ 1.000,00</p>
            </div>
          </S.Field>
          <section className="buttons-section">
            <button
              type="button"
              className="nextStep"
              onClick={() => setPage('Contact')}
            >
              Voltar
            </button>
            <button
              type="button"
              onClick={async () => {
                await patchPrice();
              }}
            >
              Avançar
            </button>
          </section>
        </S.Container>
      </S.AnimationContainer>
    </div>
  );
}
