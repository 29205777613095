/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';

import SVG from 'react-inlinesvg';

import { Container } from './styles';

export default function SellerSatisfaction({ score, title, clickable = false, callback=null }) {
  const [satisfaction, setSatisfaction] = useState('Good');
  const [noRate, setNoRate] = useState(false);

  const handleChangeSatisfaction = useCallback((sat = 'Regular') => {
    if (clickable) {
      callback(sat);
      return setSatisfaction(sat);
    }

    if (score < 0) {
      setSatisfaction('Regular');
      setNoRate(true);

      return;
    }

    if (score === 100) return setSatisfaction('Awesome');
    if (score >= 80) return setSatisfaction('Good');
    if (score >= 60) return setSatisfaction('Regular');
    if (score >= 40) return setSatisfaction('Bad');
    if (score >= 20 || score >= 0) return setSatisfaction('Terrible');
  }, [setSatisfaction, satisfaction, score]);

  useEffect(() => {
    handleChangeSatisfaction();
  }, [score]);

  return (
    <Container satisfaction={satisfaction} clickable={clickable}>
      <div
        className="LeadTrust"
        style={{ filter: noRate ? 'grayscale(1)' : 'grayscale(0)' }}
      >
        <div className="satisfactionRate-1">
          <SVG src={require('~/assets/Expressions Lead Trust.svg')} />
          <span onClick={() => handleChangeSatisfaction('Terrible')} />
          <span onClick={() => handleChangeSatisfaction('Bad')} />
          <span onClick={() => handleChangeSatisfaction('Regular')} />
          <span onClick={() => handleChangeSatisfaction('Good')} />
          <span onClick={() => handleChangeSatisfaction('Awesome')} />
        </div>

        <p style={{ display: noRate ? 'unset' : 'none', opacity: '0.5' }}>
          Sem avaliações.
        </p>
        <p>{title}</p>
      </div>
    </Container>
  );
}
