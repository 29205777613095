import React from 'react';

import Satisfaction from './components/Satisfaction';

import { Container } from './styles';

export default function LeadTrust({ score }) {
  return (
    <Container>
      <div className="LeadTrust">
        <Satisfaction score={score} />
      </div>
    </Container>
  );
}
