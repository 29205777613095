/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-unresolved */
import React from 'react';

import { HashRouter, Switch } from 'react-router-dom';

import Home from 'pages/Home';
import Accordion from 'context/Accordion';
import Announcement from 'pages/Anuncio';
import Category from 'pages/Category';
import NewAnnouncement from 'pages/NewAnnouncement';
import LogIn from 'pages/Login';
import ForgotPassword from 'pages/PasswordForgot';
import Register from 'pages/Register';
import Confirmation from 'pages/Confirmation';
import EditProfile from 'pages/EditProfile';
import Favorites from 'pages/MyFavorites';
import MyNegotiations from 'pages/MyNegotiations';
import MyAnnouncements from 'pages/MyAnnouncements';
import InterestedClients from 'pages/InterestedClients';
import EditMyAnnouncement from '~/pages/EditMyAnnouncement/index';
import Help from 'pages/Help';
import RateNegotiation from 'pages/RateNegotation';
import Announcements from '~/pages/Announcements';
import RegisterPartnerAndCoupon from '~/pages/RegisterPartnerAndCoupon';
import Route from './Route';
import { PartnerCoupon } from '~/pages/PartnerCoupon';
import EditPartnerAndCoupon from '~/pages/EditPartnerAndCoupon';

export default function Routes() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/Home" component={Home} />
        <Route path="/Login" component={LogIn} IsAuthPage />
        <Route path="/ForgotPassword" component={ForgotPassword} />
        <Route path="/Anuncios" component={Announcements} />
        <Route path="/Anuncio/:announcementId" component={Announcement} />
        <Route path="/Category/:title" component={Category} />
        <Route path="/NewAnnouncement" component={NewAnnouncement} isPrivate />
        <Route path="/Register" component={Register} IsAuthPage />
        <Route path="/Confirmation" component={Confirmation} IsAuthPage />
        <Route path="/EditProfile" component={EditProfile} isPrivate />
        <Route path="/Favorites" component={Favorites} isPrivate />
        <Route path="/MyNegotiations" component={MyNegotiations} isPrivate />
        <Route path="/MyAnnouncements" component={MyAnnouncements} isPrivate />
        <Route
          path="/RateNegotiation/:announcementId/:profileType"
          component={RateNegotiation}
          isPrivate
        />
        <Route
          path="/InterestedClients"
          component={InterestedClients}
          isPrivate
        />
        <Route
          path="/EditMyAnnouncement"
          component={EditMyAnnouncement}
          isPrivate
        />
        <Route
          path="/RegisterPartnerAndCoupon"
          component={RegisterPartnerAndCoupon}
          isPrivate
        />
        <Route
          path="/PartnerCoupon/:partnerVoucherId"
          component={PartnerCoupon}
        />
        <Route
          path="/EditPartnerAndCoupon/:partnerId/:partnerVoucherId"
          component={EditPartnerAndCoupon}
          isPrivate
        />

        <Route path="/Help" component={Help} />

        <Route path="*" component={() => <h1>Not Found</h1>} />
      </Switch>
    </HashRouter>
  );
}
