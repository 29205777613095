import styled, { css } from 'styled-components';

export const Main = styled.div`
  width: 250px;
  height: auto;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99999;

  @media screen and (max-width: 960px) {
    width: 100% !important;
    position: relative;
    right: unset !important;
    top: unset !important;
    padding: 0 20px;
    transform: translateY(0) !important;
  }
`;

export const TopField = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 960px) {
    height: auto;

    svg {
      display: none;
    }
  }

  ${(props) => {
    if (props.propmore) {
      return css`
        svg {
          transform: rotate(0deg) !important;
        }
      `;
    }
  }}

  .profilePic {
    min-width: 50px;
    height: 50px;
    border-radius: 99px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      height: auto;
    }

    svg {
      min-width: 50px;
      height: 50px;
      fill: #fce76d;
    }
  }

  .myAccount {
    width: calc(100% - 30px);

    padding-left: 20px;

    h6 {
      font-weight: 400;
      font-size: 23px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .my-account-button {
      font-size: 20px;
      background: none;
      border: 0;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.2s;
      color: rgb(69, 189, 194);

      &:hover {
        cursor: pointer;
        color: #ebe878;

        svg {
          fill: #ebe878;
        }
      }
    }

    svg {
      transform: rotate(-180deg);
      fill: rgb(33, 52, 85);
      transition: 0.2s;
    }
  }
`;

export const BottomField = styled.div`
  width: 250px;
  height: auto;
  position: absolute;
  background: white;
  border-radius: 22px;
  bottom: 450px;
  opacity: 0;
  z-index: -10;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.3);
  transition: 0.3s;

  @media screen and (max-width: 1450px) {
    ul {
      padding: 0 !important;
    }
  }

  @media screen and (max-width: 960px) {
    width: 100% !important;
    position: relative;
    bottom: unset !important;
    background: none !important;
    box-shadow: none;

    z-index: 10 !important;
    opacity: 1;

    ul {
      padding: 10px 0 !important;
    }

    ul:nth-child(n + 2):nth-child(-n + 3) {
      border-top: none !important;
    }

    li {
      height: auto !important;
      padding: 2px 10px !important;
    }

    a {
      padding: 0px !important;
      display: flex;
    }

    .icon-bg {
      background: rgba(241, 250, 251, 0) !important;
    }
  }
  ${(props) => {
    if (props.propmore) {
      return css`
        opacity: 1;
        z-index: 1;
        bottom: -300px;
      `;
    }
  }}

  button {
    width: 100%;
    height: 100%;
    background: none;
    border: 0;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }
  ul {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    span {
      font-size: 1.1em;

      @media screen and (max-width: 960px) {
        color: rgb(69, 189, 194);
      }
    }
  }

  ul:nth-child(n + 2):nth-child(-n + 3) {
    border-top: 1px solid gray;
  }

  li {
    width: 100%;
    height: 50px;

    color: rgb(33, 52, 85);
    transition: 0.3s;

    &:hover {
      border: solid rgb(33, 52, 85, 0.5);
      border-width: 0.5px 0;
    }

    &:nth-child(1) {
      border-top: 0;
    }

    &:nth-last-child(1) {
      border-bottom: 0px;
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: rgb(33, 52, 85);
    }
  }

  .icon-bg {
    width: 25px;
    height: 30px;
    background: #f1fafb;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  svg {
    width: 15px;
    height: 15px;
    fill: rgb(33, 52, 85);
  }
`;
