import styled from 'styled-components';

export const Categories = styled.div`
  width: 100%;

  @media screen and (max-width: 960px) {
    background: none;
    padding: 0px !important;
  }

  .react-sanfona {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative !important;

    ul {
      width: 100%;
      height: 100%;
    }

    li {
      width: 100%;
      transition: 0.2s;
      padding-bottom: 35px;
    }
  }

  .limit {
    width: 12px;
    height: 12px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;

    margin: auto;

    svg {
      width: 12px;
      height: 12px;
      fill: #213355;
      transition: 0.3s;
    }
  }

  .react-sanfona-item-title {
    font-size: 14px;
    color: #213355;
    padding: 20px 0 20px 5px;
    font-weight: 400;
    text-align: left;
    border-bottom: 0.05px solid rgb(33, 52, 85, 0.3);

    transition: 0.3s;

    &:hover {
      color: #36bcc1;

      svg {
        fill: #36bcc1;
      }
      .limit {
        border-color: #36bcc1 !important;
      }
    }
  }

  .react-sanfona-item-expanded .react-sanfona-item-title {
    color: #213355;
    border-bottom: none;
    transition: 0.3s;

    &:hover {
      color: #36bcc1;
    }

    svg {
      transform: rotate(90deg);
    }

    .title-done-btn {
      float: right;
      color: #000;
    }
  }

  .react-sanfona-item-body-wrapper {
    color: #213355;
    position: relative;
    transition: 0.3s;
    border-bottom: 0.5px solid rgb(33, 52, 85, 0.3);
  }

  .react-sanfona-item-body-wrapper:hover .tooltip {
    opacity: 1;
  }

  .bike-year {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    input {
      width: 120px;
      padding: 5px 3px;
      border: solid rgb(33, 52, 85, 0.3);
      border-width: 0 0 2px 0;
    }

    button {
      width: 30px;
      height: 3 0px;
      background: var(--blueNbikes);
      font-weight: 300;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: none;
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`;

// export const Accordion = styled.section`
//   width: 100%;
//   height: auto;
// `;

// export const AccordionItem = styled.div`
//   width: 100%;
//   height: auto;

//   .select-section {
//     width: 100%;
//     height: 50px;
//     padding: 10px;
//     border: solid rgb(33, 52, 85, 0.3);
//     border-width: 0 0 0.5px 0;
//     cursor: pointer;
//     position: relative;

//     select {
//       width: 100%;
//       height: 100%;
//       border: 0;
//       cursor: pointer;
//       -moz-appearance: none;
//       -webkit-appearance: none;
//       appearance: none;

//       &:focus {
//         + .select-anim {
//           .arrow-1 {
//             transform: rotate(0deg) !important;
//           }
//           .arrow-2 {
//             transform: rotate(180deg) !important;
//           }
//         }
//       }
//     }

//     option {
//       font-size: 18px;
//       background: white;
//     }

//     .select-anim {
//       width: 10px;
//       height: 10px;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       position: absolute;
//       right: 0px;
//       top: 50%;
//       transform: translateY(-50%);

//       span {
//         width: 100%;
//         background: black;
//         height: 2px;
//         position: absolute;
//         transition: 0.4s;
//       }

//       span:nth-child(1) {
//         transform: rotate(90deg);
//       }
//     }
//   }
// `;
