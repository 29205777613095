import { enumsTypes } from "./types";

export function loadAnnouncementCategoryEnum(enums) {
  return {
    type: enumsTypes.LOAD_ANNOUNCEMENT_CATEGORY_ENUM,
    payload: enums,
  };
}

export function loadAnnouncementEnum(enums) {
  return {
    type: enumsTypes.LOAD_ANNOUNCEMENT_ENUM,
    payload: enums,
  };
}

export function loadUFEnum(enums) {
  return {
    type: enumsTypes.LOAD_UF_ENUM,
    payload: enums,
  };
}
export function loadCitiesEnum(enums) {
  return {
    type: enumsTypes.LOAD_CITY_ENUM,
    payload: enums,
  };
}

export function clearCities() {
  return {
    type: enumsTypes.CLEAR_CITY_ENUM,
  };
}
