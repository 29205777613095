import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { toastError } from 'utils/defaultToasts';

import { FaAngleLeft, FaAngleRight, FaHeart } from 'react-icons/fa';
import api from '~/services/api';

import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';

import {
  MainContainer,
  BaseContainer,
  Products,
  PagePreviousNext,
  ProductSquare,
  Used,
} from './styles';

export default function Favorites() {
  const history = useHistory();

  const [announcements, setAnnouncements] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isFavoritesEmpty, setIsFavoritesEmpty] = useState(false);

  const userId = useSelector((state) => state.authReducer.auth.profile.Id);

  const getFavorites = async (userId, currentPage) => {
    const getFavoritesUrl = `api/v1/Profiles/${userId}/Starreds?CurrentPage=${currentPage}&PageSize=4&IsPaged=true`;

    try {
      const response = await api.get(getFavoritesUrl);
      const data = response.data;

      if (!data.length && currentPage !== 1) {
        setCurrentPage((prevPage) => prevPage - 1);
        return;
      }

      if (!data.length && currentPage === 1) setIsFavoritesEmpty(true);

      const pagination = JSON.parse(response.headers.pagination);

      setTotalPages(pagination.TotalPages);
      setAnnouncements(data);
    } catch (error) {
      toastError('Ocorreu um erro ao buscar seus anúncios favoritos.');
    }
  };

  const removeFavorite = async (target, userId, announcementId) => {
    const deleteFavoriteUrl = `api/v1/Profiles/${userId}/Starreds/${announcementId}`;

    try {
      await api.delete(deleteFavoriteUrl);

      target.style.fill = '#36bcc1';
    } catch (error) {
      toastError('Ocorreu um erro ao remover favorito.');
    }
  };

  useEffect(() => {
    getFavorites(userId, currentPage);
  }, [currentPage]);

  return (
    <MainContainer>
      <Helmet>
        <meta
          property="og:title"
          content="n bikes | Uma mão na roda! | Brasil"
        ></meta>
        <meta
          property="og:image"
          content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
        ></meta>
        <meta
          property="og:description"
          content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
        ></meta>
        <title>Favoritos | n Bikes | Brasil</title>
      </Helmet>
      <BaseContainer>
        <h2>Favoritos</h2>
        <div
          style={
            isFavoritesEmpty ? { display: 'inherit' } : { display: 'none' }
          }
          id="no-favorites-div"
        >
          Você não possui anúncios favoritados.
          <p onClick={() => history.push('/home')}>Buscar anúncios</p>
        </div>
        <Products>
          <div className="products">
            <ul>
              {announcements.map((announcement) => (
                <li key={announcement.AnnouncementId}>
                  <ProductSquare
                    productId={announcement.AnnouncementId}
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(`/Anuncio/${announcement.AnnouncementId}`);
                    }}
                  >
                    <div className="icons">
                      <FaHeart
                        style={{ fill: '#fce76d' }}
                        onClick={async (event) => {
                          event.stopPropagation();

                          const id = announcement.AnnouncementId;

                          await removeFavorite(event.target, userId, id);
                          getFavorites(userId, currentPage);
                        }}
                      />
                    </div>
                    <div className="image">
                      <img src={announcement.DtoPicture?.ThumbnailUrl} alt="" />
                    </div>
                    <Used
                      isUsed={announcement.UseCondition.Name === 'New'}
                      className="bikeState"
                    >
                      {announcement.UseCondition.Description}
                    </Used>
                    <h1 id="announcement-title" title={announcement.Title}>{announcement.Title}</h1>
                    <p>
                      <h4>R$ {announcement.Price}</h4>
                    </p>
                  </ProductSquare>
                </li>
              ))}
            </ul>
          </div>

          <PagePreviousNext>
            <div className="pageCounter">
              <button
                style={
                  currentPage === 1 || isFavoritesEmpty
                    ? { display: 'none' }
                    : { display: 'inherit' }
                }
                onClick={() => {
                  if (currentPage === 1) return;

                  setCurrentPage((prevPage) => prevPage - 1);
                }}
                type="button"
              >
                <FaAngleLeft /> Anterior
              </button>
              <button
                style={
                  currentPage === totalPages || isFavoritesEmpty
                    ? { display: 'none' }
                    : { display: 'inherit' }
                }
                type="button"
                onClick={() => {
                  if (currentPage === totalPages) return;

                  setCurrentPage((prevPage) => prevPage + 1);
                }}
              >
                Próxima <FaAngleRight />
              </button>
            </div>
          </PagePreviousNext>
        </Products>
      </BaseContainer>
    </MainContainer>
  );
}
