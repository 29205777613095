import React, { useState } from 'react';

import { Container } from './styles';

export default function Charge() {
  const [CPF, setCPF] = useState();
  const [CNPJ, setCNPJ] = useState();

  const cpfMask = (value) => {
    if (CNPJ <= 14) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2');
    } else if (CNPJ > 12) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    }
  };

  return (
    <Container>
      <h5>Dados de Cobrança</h5>

<div className="mainInfo">



      <ul style={{ marginRight: '5%' }}>
        <li>
          <p>Nome do Pagador* </p>

          <input type="text" style={{ width: '100%' }} />
        </li>

        <li>
          <div>
            <p>CPF/CNPJ * </p>

            <input
              type="text"
              value={CPF}
              maxLength="18"
              onChange={(e) => {
                setCPF(cpfMask(e.target.value));
                setCNPJ(e.target.value.length);
              }}
            />
          </div>
        </li>
      </ul>

      <ul>
        <li>
          <p>CEP * </p>

          <input type="email" />
        </li>

        <li>
          <p>Endereço * </p>

          <input type="email" />
        </li>

        <li className="fullField">
          <div>
            <p>Complemento </p>

            <input type="email" />
          </div>

          <div>
            <p>Bairro * </p>

            <input type="email" />
          </div>
        </li>

        <li className="fullField">
          <div>
            <p>Cidade* </p>

            <input type="email" />
          </div>

          <div>
            <p>Estado * </p>

            <input type="email" />
          </div>
        </li>
      </ul>
      </div>
    </Container>
  );
}
