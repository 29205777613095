import Axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import api from '~/services/api';
import { setPrivilegeType, signOut } from '~/store/ducks/auth/actions';

export default function StartUp({children}) {
  const dispatch = useDispatch();
  const accessToken = useSelector(state => state.authReducer.auth.accessToken);
  // const privilegeType = useSelector(state => state.authReducer.auth.privilegeType);

  useEffect(() => {
    if (accessToken)

      api.get('/api/v1/Auth/PrivilegeType')
        .then(response => {
          dispatch(setPrivilegeType({privilegeType: response.data.PrivilegeType}));
        })
        .catch(err => {
          api.post('/api/v1/Auth/ReLogIn', {
            ipAddress: '192.58.56.181'
          })
            .then(response => {
              api.defaults.headers.Authorization = `Bearer ${response.data.Authorization}`
            })
            .catch(err => {
              dispatch(signOut());
            });
        });

  }, [accessToken]);

  return (
    <>
      {children}
    </>
  );
}
