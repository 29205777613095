import styled, { css } from 'styled-components';

export const Field = styled.form`
  width: clamp(250px, 100%, 600px);
  /* height: 80vh; */
  margin: 3em auto;

  border-radius: 12px;

  transition: 0.3s;
  overflow: hidden;
  background: white !important;

  &:hover {
    box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);
  }
  padding: 20px !important;

  @media screen and (max-width: 960px) {
    padding: 20px !important;

    ul {
      li {
        div {
          width: 100% !important;
        }
      }
    }
  }

  ${(props) => {
    if (props.propChange[0] === '') {
      return css``;
    }
    if (props.propChange[0]) {
      return css`
        .spanMail {
          bottom: 50px !important;
          font-size: 14px !important;
        }
      `;
    }
  }}

  ${(props) => {
    if (props.propChange[1] === '') {
      return css``;
    }

    if (props.propChange[1]) {
      return css`
        .spanPassword {
          bottom: 50px !important;
          font-size: 14px !important;
        }
      `;
    }
  }}

  ${(props) => {
    if (props.propChange[2] === '') {
      return css``;
    }

    if (props.propChange[2]) {
      return css`
        .spanCode {
          bottom: 28px !important;
          font-size: 14px !important;
        }
      `;
    }
  }}

  h2 {
    font-size: 30px;
    margin: 0.5em 0 0.1em 0;
    font-weight: 600;
  }

  ul {
    width: 100%;
    height: 60%;

    #forgot-password {
      a {
        text-decoration: underline 1px;

        font-size: 18px;
        font-weight: 500;

        color: var(--blueNbikes);
      }

      width: 100%;

      display: flex;
      justify-content: flex-end;
    }
  }

  .title {
    h1 {
      font-weight: 700;
    }

    p {
      font-size: 20px;
      color: #6b6b6b;
    }

    width: 100%;

    margin-bottom: 50px;
  }

  ${(props) => {
    if (props.propCode) {
      return css`
        .verifCode,
        .verifCodeRequest {
          z-index: 1 !important;
          opacity: 1 !important;
        }

        li {
          margin-bottom: 5em;
        }
      `;
    }
  }}

  .verifCode {
    width: 40%;
    margin-top: 3em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0;
    transition: 0.4s;

    span {
      position: absolute;
      bottom: 8px;
      left: 10px;

      pointer-events: none;

      font-size: 22px;
      font-weight: 400;

      transition: 0.2s;
      color: gray;
    }
  }

  .verifCodeRequest {
    width: 80%;
    margin-top: 5em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0;
    transition: 0.4s;

    #textHover {
      text-align: right;
    }
    #textHover:hover {
      color: #74b81f !important;
      cursor: pointer;
    }

    button {
      background: none;
      color: gray;
      border: none;
      font-size: 20px;
    }
    button:hover {
      color: #7b1a8b;
      cursor: pointer;
    }
  }

  li {
    width: 100%;

    background: white;
    z-index: 1;
    transition: 0.2s;

    p {
      font-size: 20px;
      text-align: right;
      width: 50%;
      button {
        color: gray;
        background: none;
        font-size: 20px;
        border: none;
      }
      button:hover {
        color: #7b1a8b;
        cursor: pointer;
      }
    }

    div {
      margin-bottom: 35px;
      position: relative;

      &:nth-child(2) {
        margin-bottom: 8px !important;
      }
    }

    span {
      position: absolute;
      bottom: 12px;

      pointer-events: none;

      font-size: 22px;
      font-weight: 400;

      transition: 0.2s;
      color: gray;
    }
  }

  input {
    max-width: 550px;
    width: 100%;

    margin: 5px 10px 5px 0;
    padding-bottom: 10px;

    border: 0;
    border-bottom: 2px solid #c3c3c3;

    overflow: hidden;

    box-sizing: border-box;

    font-size: 22px;

    transition: 0.3s;

    &:focus {
      border-bottom: solid 2px #00c569;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    &[data-autocompleted] {
      background-color: transparent !important;
    }
  }

  .logged {
    width: 100%;
    height: 30px;
    text-align: center;

    span {
      font-size: 18px;
      display: inline-block;

      a {
        color: var(--blueNbikes);

        text-decoration: underline 1px;
      }
    }
  }

  .log-in {
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    transition: 0.4s;

    margin: 10px auto;

    button {
      width: 100%;

      height: 40px;

      background: #37bcc1;

      font-size: 20px;

      color: white;

      border: none;
      border-radius: 5px;

      transition: 0.3s;

      :hover {
        transition: 0.3s;

        background-color: var(--blueNbikes);
      }
    }
  }

  #hiddenCode {
    visibility: visible;
  }
`;

export const BaseContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  min-height: 600px;
  height: 100%;
  margin: 0 auto;
  padding: 1em 20px 3em 20px;
  position: relative;

  h2 {
    font-size: 22px;
    font-weight: 400;
  }
  h1 {
    font-size: 42px;
  }

  .Product {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.4);
    margin: 1em 0;
    text-align: center;
    transition: 0.2s;
    background: white;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.6);
    }
  }

  svg {
    width: 100px;
    height: 100px;
    margin: 0.5em auto;
    fill: rgb(32, 52, 83);
  }

  span {
    font-size: 12px;
    vertical-align: bottom;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 20px 0;
  overflow: hidden;
  background: rgb(125, 125, 125, 0.1);
`;
