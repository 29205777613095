import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  background-color: white;

  margin-top: 2rem;
  padding: 3rem;

  border-radius: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Optional = styled.span`
  opacity: 0.5;
`;

export const Mandatory = styled.span`
  opacity: 0.5;

  color: #de4b4b;
`;

export const Input = styled.input`
  width: 500px;
  height: 40px;

  border: 0;
  border-bottom: 2px solid rgb(125, 125, 125, 0.3);

  box-sizing: border-box;

  padding-left: 0.25rem;

  font-size: 20px;

  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const ReasonTitle = styled.span`
  position: absolute;
  top: 0.5rem;
  left: 1rem;

  z-index: 4;
`;

export const Select = styled.select`
  width: 500px;
  height: 60px;

  padding-top: 2rem;
  padding-left: 0.25rem;

  border: 2px solid #b3b3b3;

  box-sizing: border-box;

  font-size: 20px;

  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;

export const Option = styled.option``;

export const Topic = styled.div`
  font-size: 18px;
`;

export const TextArea = styled.textarea`
  width: 650px;
  height: 180px;

  resize: none;

  font-size: 20px;

  border: 1px solid #808080;
  border-radius: 4px;

  margin-top: 10px;
  padding: 1rem;

  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  width: 300px;
  height: 60px;

  font-size: 20px;

  border-radius: 10px;
  border: none;

  color: white;

  background-color: var(--blueNbikes);

  :hover {
    transform: scale(1.025);
  }
`;
