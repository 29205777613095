import styled from 'styled-components';

export const Field = styled.div`
  width: 90%;
  margin: 3em auto;
  border-radius: 12px;
  text-align: center;
  padding: 12px 50px 60px 50px;
  transition: 0.3s;
  background: white !important;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);
  }

  h4 {
    font-size: 24px;
    margin: 0.5em 1em 0;
    font-weight: 400;
  }

  svg {
    width: 170px;
    height: 170px;
    fill: #ebe878;
    margin: 0 auto;
  }

  input {
    width: 300px;
    font-size: 20px;
    border-width: 0 0 1px 0;
    margin: 60px auto 5px auto;
    text-align: center;
    border-bottom: 1px solid gray;
  }

  span {
    width: 150px;
    margin: 0 auto;
  }

  .resendButton {
    border-bottom: 1px solid gray;
    color: gray;
    background: none;
    border: none;
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
  .resendButton:hover {
    color: #7b1a8b;
    cursor: pointer;
  }
`;

export const BaseContainer = styled.form`
  width: 100%;
  max-width: 1280px;
  min-height: 600px;
  height: 100%;
  margin: 0 auto;
  padding: 1em 20px 3em 20px;
  position: relative;

  h2 {
    font-size: 22px;
    font-weight: 400;
  }
  h1 {
    margin: 50px 0 0 50px;
  }

  .nextStep {
    width: 150px;
    height: 60px;
    background: rgb(32, 52, 83);
    font-size: 20;
    color: white;
    border: none;
    border-radius: 10px;
    float: right;

    margin-right: 65px;
  }

  .Product {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.4);
    margin: 1em 0;
    text-align: center;
    transition: 0.2s;
    background: white;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.6);
    }
  }

  svg {
    width: 100px;
    height: 100px;
    margin: 0.5em auto;
    fill: rgb(32, 52, 83);
  }

  p {
    font-size: 20px;
  }

  span {
    font-size: 12px;
    vertical-align: bottom;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 20px 0;
  overflow: hidden;
  background: rgb(125, 125, 125, 0.1);
`;

export const GoBackButton = styled.button`
  z-index: 10;

  position: absolute;

  top: 30px;
  left: 90px;

  display: flex;

  justify-content: center;
  align-items: center;

  width: fit-content;

  background-color: transparent;
  border: 0;

  svg {
    color: var(--blueNbikes);
    width: 20px;
  }
`;
