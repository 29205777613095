import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;



  h5 {
    font-size: 23px;
    font-weight: 400;
    margin-bottom: 1em;
  }

  ul {
    width: 100%;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  li {
    width: 100%;
    height: 70px;
    padding-top: 20px;

    &:nth-child(2) {
      display: flex;
      justify-content: space-between;

      div {
        width: 50%;

        input {
          width: 100% ;}}



          div:nth-child(2){

            input {
          width: 95% ;
          float:right;
          }
          }

    }
  }

  span {
    font-size: 16px;
  }

  input {
    width: 50%;
    border-width: 0 0 2px 0;
    border-color: rgb(125, 125, 125, 0.3);
    border-style: solid;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: gray;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
    color: #00c569;
    font-family: Nunito;
    margin: 1em 0;
  }
`;
