import React from 'react';
import { FaStoreAlt } from 'react-icons/fa';
import { RxIdCard } from 'react-icons/rx';
import { SlGlobe } from 'react-icons/sl';
import * as S from './styles';
import UploadLogo from '~/pages/RegisterPartnerAndCoupon/components/UploadLogo';
import InputComponent from '~/components/InputComponent';
import { PatternFormat } from 'react-number-format';
import { useFormContext } from 'react-hook-form';

export default function PartnerInputs() {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const formatCnpj = (cnpj) => {
    return cnpj
      .replace('.', '')
      .replace('.', '')
      .replace('/', '')
      .replace('-', '');
  };

  return (
    <S.Stack
      style={{
        width: '100%',

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '3rem',

        fontSize: '16px',

        marginBottom: '3rem',
      }}
    >
      <S.FormHeader>
        <S.FormTitle
          style={{
            minWidth: '28%',
          }}
        >
          Cadastro da Loja
        </S.FormTitle>
        <S.Line />
      </S.FormHeader>

      <S.StoreFormDiv>
        <S.StoreInputs>
          <UploadLogo
            type={'image/*'}
            filename="Logotipo da Loja"
            {...register('StoreInputs.Background')}
            onChange={(file) => setValue('StoreInputs.Background', file)}
            isError={errors.StoreInputs?.Background ? true : false}
            errorMessage={errors.StoreInputs?.Background?.message}
          />
          <S.InputsDiv>
            <InputComponent
              placeholder="Nome da Loja"
              leftAddornment={<FaStoreAlt size={20} />}
              isMaxWidth
              {...register('StoreInputs.Title')}
              onChange={(e) => setValue('StoreInputs.Title', e.target.value)}
              isError={errors.StoreInputs?.Title ? true : false}
              errorMessage={errors.StoreInputs?.Title?.message}
            />

            <PatternFormat
              customInput={InputComponent}
              placeholder="CNPJ"
              leftAddornment={<RxIdCard size={20} />}
              mask="_"
              format="##.###.###/####-##"
              isMaxWidth
              {...register('StoreInputs.Cnpj')}
              onChange={(e) =>
                setValue('StoreInputs.Cnpj', formatCnpj(e.target.value))
              }
              isError={errors.StoreInputs?.Cnpj ? true : false}
              errorMessage={errors.StoreInputs?.Cnpj?.message}
            />

            <InputComponent
              placeholder="Site"
              leftAddornment={<SlGlobe size={20} />}
              isMaxWidth
              {...register('StoreInputs.Website')}
              onChange={(e) => setValue('StoreInputs.Website', e.target.value)}
              isError={errors.StoreInputs?.Website ? true : false}
              errorMessage={errors.StoreInputs?.Website?.message}
            />
          </S.InputsDiv>
        </S.StoreInputs>
      </S.StoreFormDiv>
    </S.Stack>
  );
}
