import styled from 'styled-components';

export const Container = styled.div`
  height: 9rem;
  width: 100%;
  max-width: 25rem;

  padding: 1rem;

  display: flex;
  align-items: center;
  gap: 1rem;

  border-radius: 0.5rem;
  border: 0.125rem solid rgba(0, 0, 0, 0.25);
`;

export const Image = styled.img`
  height: 7rem;
  width: 7rem;
`;

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 10rem;
`;

export const Flex = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Button = styled.button`
  border: none;
  background: transparent;
`;

export const Star = styled(Button)`
  svg {
    fill: ${({ isFavorite }) => (isFavorite ? '#dae65e' : '#918f8e')};
  }
`;

export const Delete = styled(Button)``;
