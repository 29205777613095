import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    AccessKey: 'aPhhK2$tBT;c_ZW>',
    accept: 'application/problem+json',
  },
});

export default api;
