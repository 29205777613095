import { takeLatest, call, put, all } from 'redux-saga/effects';
import md5 from 'md5';
import { toast } from 'react-toastify';
import { AuthTypes } from './types';
import api from '~/services/api';
import {
  signInSuccess,
  stopLoading,
  signUpSuccess,
  updateProfile,
} from './actions';
import { toastError, toastInfo, toastSuccess } from '~/utils/defaultToasts';

export function setaccessToken({ payload }) {
  if (!payload) return;

  const { accessToken } = payload.authReducer.auth;

  if (accessToken) {
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }
}

export function* onRehydrate({ payload }) {
  setaccessToken({ payload });
  yield put(stopLoading());
}

export function* signIn({ payload }) {
  try {
    const { username, password, reCaptcha } = payload;
    const passwordHash = md5(password);
    const response = yield call(api.post, '/api/v1/Auth/LogIn', {
      username,
      passwordHash,
      reCaptcha,
    });

    if (response.data.Status >= 400) throw response.data.Status;

    const accessToken = response.data.Authorization;
    const profile = response.data.DTOProfileInfo;
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
    window.location.href = '/#/home';
    yield put(signInSuccess(accessToken, profile, username));
  } catch (err) {
    if (
      err.response.status === 400 &&
      err.response.data.Messages[0] === 'Credenciais incorretas.'
    ) {
      toastError('Usuário ou senha estão incorretos!');
    } else if (err.response.status === 401) {
      toastError('Email ou senha incorretos!');
    } else if (err.response.status === 404) {
      toastError('Usuário não encontrado! Verifique seu email e senha.');
    } else if (err.response.status === 500) {
      toastError('Houve um erro ao fazer login. Tente novamente.');
    } else if (err.response.status === 422) {
      toastError('Campos inválidos!');
    } else if (err.response.status === 302) {
      toastError('Redefina sua senha.');
      window.location.href = `/#/forgotpassword?email=${payload.username}`;
    } else if (
      err.response.status === 400 &&
      err.response.data.Messages[0] === 'Usuário não habilitado.'
    ) {
      toastError('É necessário confirmar seu cadastro!');
      yield call(api.post, `/api/v1/Auth/ResendConfirmationCode`, {
        username: payload.username,
      });
      window.location.href = `/#/Confirmation?email=${payload.username}`;
    }
    yield put(stopLoading());
  }
}

export function* signUp(payload) {
  const [
    name,
    lastName,
    documentType,
    documentNumber,
    birthDate,
    email,
    telephone,
    cellphone,
    passwordNotHashed,
    confirmPasswordNotHashed,
    reCaptcha,
  ] = payload.payload;

  try {
    let password = '';
    let confirmPassword = '';

    if (passwordNotHashed !== '' && confirmPasswordNotHashed !== '') {
      password = md5(passwordNotHashed);
      confirmPassword = md5(confirmPasswordNotHashed);
    }

    const response = yield call(api.post, '/api/v1/Auth/Register', {
      name,
      lastName,
      documentType,
      documentNumber,
      birthDate,
      telephone,
      cellphone,
      email,
      password,
      confirmPassword,
    });

    yield put(signUpSuccess({ id: response.data.ProfileId, email }));

    window.location.href = `/#/Confirmation?email=${email}`;
  } catch (err) {
    yield put(stopLoading());

    const errorsMessages = err.response.data.Messages;

    if (
      err.response.status === 400 &&
      errorsMessages.includes(`Username '${email}' is already taken.`)
    ) {
      toastError('Usuário já registrado, por favor redefina sua senha!');
      window.location.href = '/#/forgotpassword?email=' + email;
    } else if (err.response.status === 500) {
      toastError('Entre em contato com o suporte.');
    } else {
      errorsMessages.forEach((message) => {
        toastError(message);
      });
    }

    yield put(stopLoading());
  }
}

export function* signUpConfirm(payload) {
  try {
    const [code, username, id] = payload.payload.data;
    yield call(api.post, `/api/v1/Auth/ConfirmAccount`, {
      secretCode: code,
      username,
      profileId: id,
    });

    toastSuccess('Cadastro confirmado com sucesso!');

    window.location.href = '/#/Login';
    yield put(stopLoading());
  } catch (err) {
    toastError('Código de verificação inválido, tente novamente.');
  }

  yield put(stopLoading());
}

export function* emailConfirm(payload) {
  try {
    const [username, , code, setCode] = payload.payload;
    yield call(api.patch, `/api/v1/Auth/ConfirmAccount`, {
      code,
      username,
    });
    setCode(false);

    yield call(signIn, payload);
  } catch (err) {
    if (err.response.status === 400 || err.response.status === 500) {
      if (err?.response?.data?.Messages)
        toastError(err?.response?.data?.Messages[0]);
    }
    yield put(stopLoading());
  }
}

export function* resendCode(payload) {
  try {
    const [username, profileId] = payload.payload.data;
    const response = yield call(
      api.post,
      `/api/v1/Auth/ResendConfirmationCode`,
      {
        username,
        profileId,
      }
    );
    if (response.status === 200) {
      toast.success('Código de confirmação reenviado! Confira seu email.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      yield put(stopLoading());
    }
  } catch (err) {
    if (err.response.status === 106) {
      toast.error('Conta não encontrada.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (err.response.status === 107) {
      toast.error('Usuário bloqueado.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (err.response.status === 109) {
      toast.error('Conta já confirmada.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    yield put(stopLoading());
  }
}

export function* reloadProfile(id) {
  try {
    const response = yield call(api.get, `/api/v1/Profiles/${id}`);
    const profile = response.data;
    yield put(updateProfile(profile));
  } catch (err) {
    toast.error('Não foi possível atualizar seu perfil.', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    yield put(stopLoading());
  }
}

export function* updateProfilePic(payload) {
  try {
    const [id, imageBase64String] = payload.payload;
    yield call(api.patch, `/api/v1/Profiles/${id}/Avatar`, {
      image: imageBase64String,
      clearOnly: false,
    });
    yield call(reloadProfile, id);
    yield put(stopLoading());
  } catch (err) {
    toast.error('Não foi possível enviar a imagem.', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    yield put(stopLoading());
  }
}

export function signOut() {
  api.defaults.headers.Authorization = '';
  window.location.href = '/#/';

  toastInfo('Você se desconectou da sua conta.');
}

export function* forgotEmailPassword({ payload }) {
  try {
    const { email } = payload;
    yield call(api.patch, `/api/v1/Auth/ChangePassword`, {
      username: email,
      ipAddress: '46.5.21.123',
    });

    payload.setResetPassword('secretCodeAndResetPass');

    toast.success(
      'Verifique o código de alteração que foi enviado ao seu e-mail.',
      {
        position: toast.POSITION.BOTTOM_CENTER,
      }
    );
  } catch (e) {
    const errorsMessages = e.response?.data?.Messages;

    errorsMessages.forEach((message) => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
  }
}

export function* confirmNewPassword({ payload }) {
  try {
    const { password, confirmPassword, email, code } = payload;
    yield call(api.patch, `/api/v1/Auth/NewPassword`, {
      username: email,
      passwordHash: md5(password),
      confirmPasswordHash: md5(confirmPassword),
      secretCode: code,
      withToken: true,
      ipAddress: '46.5.21.123',
    });

    payload.setResetPassword('passwordChanged');
  } catch (err) {
    toast.error('Verifique se o código e as senhas estão de acordo.', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    payload.setResetPassword('secretCodeAndResetPass');
  }
}

export default all([
  takeLatest('persist/REHYDRATE', onRehydrate),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.SIGN_UP_REQUEST, signUp),
  takeLatest(AuthTypes.SIGN_UP_CONFIRMATION, signUpConfirm),
  takeLatest(AuthTypes.EMAIL_CONFIRMATION, emailConfirm),
  takeLatest(AuthTypes.RESEND_CODE, resendCode),
  takeLatest(AuthTypes.UPLOAD_PROFILE_PIC, updateProfilePic),
  takeLatest(AuthTypes.SIGN_OUT, signOut),
  takeLatest(AuthTypes.FORGOT_EMAIL_PASSWORD, forgotEmailPassword),
  takeLatest(AuthTypes.CONFIRM_NEW_PASSWORD, confirmNewPassword),
]);
