import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 1200px;
  height: 100%;
  margin: 20px auto;
  padding: 20px 0 50px 0;
  display: flex;

  @media screen and (max-width: 960px) {
    width: 90%;
    flex-direction: column;
  }

  h1 {
    margin: 50px 0 0 50px;
  }

  .prevNext {
    width: 90%;
    display: flex;
    justify-content: space-between;
    float: right;
    margin-right: 60px;
  }

  .nextStep {
    width: 150px;
    height: 60px;
    background: rgb(32, 52, 83);
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 10px;
  }
  .slide {
    margin: auto;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainField = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-left: 60px;
  border-radius: 12px;
  padding: 12px 50px;
  transition: 0.3s;
  background: white;
  position: relative;

  @media screen and (max-width: 960px) {
    padding: 12px 10px;
    width: 100%;
    margin: 2em 0;
  }

  h2 {
    font-size: 30px;
    margin: 0.9em 0 0.1em 0;
    font-weight: 600;

    svg {
      width: 25px;
      height: 25px;
      margin-left: 10px;
      fill: #37bcc1;
    }
  }

  .heart-icon {
    cursor: pointer;
  }

  .title {
    width: 90%;

    @media screen and (max-width: 960px) {
      margin-top: 3em;
    }

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }

  h3 {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px rgb(125, 125, 125, 0.4);
    color: #37bcc1;
  }
`;

export const TopField = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 10px 0;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 4em;
    justify-content: unset !important;
    align-content: center;

    .photos,
    .right {
      width: 100% !important;
    }
  }

  .photos,
  .selfInfos {
    margin: 15px 0;
    width: 50%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);
    padding: 12px 15px;
    transition: 0.3s;
    background: white;
  }

  .selfInfos {
    width: 100% !important;
    padding: 12px 10px !important;

    h3 {
      color: #213455;
    }
  }

  .showSellerInfo {
    width: 80%;
    height: 50px;
    color: white;
    background: #213454;
    border-radius: 4px;
    font-size: 18px;
    margin: 2em 10%;
    border: none;
    transition: 0.2s;

    &:hover {
      background: #36bcc1;
    }
  }
  background: white;

  .Carousel {
    width: 100%;
    background: white;
    cursor: pointer;

    .slide.selected {
      background: white;
      div {
        background: white;
      }
    }
    .CarouselItem {
      display: flex;

      justify-content: center;
      align-items: center;

      margin: 0 auto;

      max-width: 400px;
      max-height: 400px;
      width: auto;
      height: auto;

      @media screen and (max-width: 520px) {
        max-width: 300px;
        max-height: 300px;
      }

      @media screen and (max-width: 420px) {
        max-width: 200px;
        max-height: 200px;
      }
    }

    .thumb {
      .CarouselItem {
        max-height: 60px;
      }
    }
  }

  .right {
    margin-top: 15px;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-content: center;

    span {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-top: 20px;
    }

    p {
      display: flex;
      margin: 0 13px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      border-bottom: solid 1px rgb(125, 125, 125, 0.4);
    }
  }

  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #00c569;
  }

  .myAccount {
    width: 80%;
    display: flex;
    align-items: center;
    color: rgb(33, 52, 85);
    padding-left: 20px;
    margin-top: 20px;

    h6 {
      font-weight: 600;
      font-size: 23px;
      text-transform: uppercase;
      margin-left: 20px;
    }
  }
  .profilePic {
    width: 20%;
    height: 20% !important;
    border: solid rgb(33, 52, 85) 1px;
    border-radius: 99px;
    overflow: hidden;
    text-align: center;

    img {
      width: 135%;
      height: auto;
    }
  }

  .ratingBar {
    width: 100%;
    margin-top: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 25px;
      height: 25px;
      fill: #fce76d;

      &:nth-child(9) {
        fill: rgb(33, 52, 85) !important;
      }
    }
  }

  .trust {
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightgray;
    color: white;
    right: 30px;
    top: -50px;
    font-size: 14px !important;
    position: absolute;

    div {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30px;
      background: lightgray;
      border-style: solid;
      border-width: 15px 15px 0 15px;
      border-color: lightgray white white white;
    }
  }

  .contactButton {
    width: 120px;
    color: rgb(32, 52, 83);
    border: none;
    font-size: 23px;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const MiddleField = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);
  padding: 12px 10px;
  transition: 0.3s;
  background: white;

  h3 {
    font-size: 25px;
  }

  ul {
    width: 100%;
    display: inline-block;
    vertical-align: top;

    &:nth-child(2) {
      // margin-right: 200px;
    }
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;

    span {
      font-size: 16px;
      width: inherit;
      padding-left: 10px;
    }

    span:nth-child(1) {
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

export const BottomField = styled.div`
  width: clamp(250px, 100%, 900px);
  height: auto;

  margin: 1em auto;
  padding: 12px 10px;

  border-radius: 12px;

  box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);

  transition: 0.3s;

  background: white;

  h3 {
    color: #213455;
  }

  pre {
    word-break: break-word;
    white-space: pre-wrap;
    hyphens: auto;

    text-align: justify;

    margin-top: 10px;
    width: clamp(250px, 100%, 900px);

    font-size: 15px;
  }
`;

export const CreatedData = styled.div`
  width: clamp(250px, 100%, 900px);
  height: auto;

  margin: 1em auto;
  padding: 12px 10px;

  border-radius: 12px;

  box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);

  transition: 0.3s;

  background: white;

  h3 {
    color: #213455;
    border-bottom: 0;
    padding-bottom: 0;
  }

  span {
    color: #000000;
    font-weight: lighter;

    word-break: break-word;
    white-space: pre-wrap;
    hyphens: auto;

    text-align: justify;

    width: clamp(250px, 100%, 900px);

    font-size: 15px;
  }
`;

export const Categories = styled.div``;

export const CustomButton = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 25px;
  font-size: 14px;
  color: white;
  padding: 8px 0;
  border-radius: 10px;
  text-align: center;
  position: absolute;
  background-color: #a7a7a7;

  transition: background-color 0.3s;

  &:hover {
    background-color: #707070;
  }
`;

export const ProductState = styled.div`
  ${(props) => {
    if (props.useCondition === 'Novo') {
      return css`
        background: var(--blueNbikes);
        }
      `;
    }
    if (props.useCondition === 'Usado') {
      return css`
        background: #37bcc1;
        }
      `;
    }
  }}

  display:flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 25px;
  font-size: 14px;
  color: white;
  padding: 8px 0;
  border-radius: 10px;
  text-align: center;
  position: absolute;

  top: 20px;

  ${({ privilegeType }) =>
    privilegeType === 'Administrator'
      ? css`
          right: 115px;
        `
      : css`
          right: 30px;
        `}
`;

export const ActionsContainer = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 25px;
  font-size: 14px;
  color: white;
  padding: 8px 0;
  border-radius: 10px;
  text-align: center;
  position: absolute;

  right: 25px;
  top: 20px;

  .edit-button,
  .delete-button {
    cursor: pointer;

    color: white;
    width: 100%;
    padding: 10px 5px;

    transition: all 0.2s ease;
  }

  .edit-button {
    background-color: #1c1cff;

    border-radius: 10px 0 0 10px;

    &:hover {
      width: 140%;
      background-color: #1515c6;
    }
  }

  .delete-button {
    background-color: #ff1c1c;

    border-radius: 0 10px 10px 0;

    &:hover {
      width: 140%;
      background-color: #c61515;
    }
  }
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #1c1cff;

  position: absolute;
  top: -5px;
  right: 15px;

  transition: border-bottom 0.3s;

  &:hover {
    border-bottom: 10px solid #1515c6;
  }
`;

export const ShareButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;

  background-color: var(--blueNbikes);

  color: white;

  height: 30px;
  width: 120px;

  border-radius: 10px;
  border: none;

  transition: 0.3s ease;

  :hover {
    background-color: rgb(54 188 193);

    transition: 0.3s ease;
  }

  svg {
  }
`;

export const ModalStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const ZoomContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  div:first-child {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
    img {
      margin: 0 auto;

      max-width: clamp(100px, 100%, 800px);
      max-height: clamp(100px, 100%, 600px);
      width: auto;
      height: auto;

      border-radius: 1.5rem;

      padding: 0 1rem;
    }
  }

  div:last-child {
    position: absolute;

    left: 50%;
    transform: translateX(-50%);

    display: flex;

    justify-content: space-between;

    max-width: 80vw;
    width: inherit;

    svg {
      color: white;
      cursor: pointer;
    }
  }
`;
