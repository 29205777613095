import produce from 'immer';
import { enumsTypes } from './types';

const INITIAL_STATE = {
  announcementsCategoryEnums: [],
  UFEnums: [],
  citiesEnums: [],
  announcementsEnums: [],

};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case enumsTypes.LOAD_ANNOUNCEMENT_CATEGORY_ENUM: {
        draft.announcementsCategoryEnums = action.payload;
        break;
      }
      case enumsTypes.LOAD_ANNOUNCEMENT_ENUM: {
        draft.announcementsEnums = action.payload;
        break;
      }
      case enumsTypes.LOAD_CITY_ENUM: {
        draft.citiesEnums = action.payload;
        break;
      }
      case enumsTypes.LOAD_UF_ENUM: {
        draft.UFEnums = action.payload;
        break;
      }
      case enumsTypes.CLEAR_CITY_ENUM: {
        draft.citiesEnums = [];
        break;
      }
      default:
      //draft.announcementData = {};
    }
  });
}
