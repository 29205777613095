import { Button } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';

import React, { useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FiUpload, FiDownload, FiTrash2 } from 'react-icons/fi';
import { partnerBackgroundAtom } from '~/jotai/atoms/registerPartnerAtoms';

import { styled } from '../../../../stiches.config';

const Container = styled('div', {
  height: '100%',
});

const AttachmentContainer = styled('div', {
  position: 'relative',

  width: 230,
  height: 230,

  aspectRatio: 1,
  borderRadius: 18,
  overflow: 'hidden',

  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

  '&:hover::after': {
    content: '',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: 'white',
    opacity: '50%',
  },

  '@media (max-width: 350px)': {
    width: 200,
    height: 200,
  },
});

const FileInput = styled('input', {
  position: 'absolute',
  inset: 0,
  opacity: 0,

  zIndex: 1,
});

const DownloadButton = styled('a', {
  zIndex: 3,

  aspectRatio: 1,

  border: 12,
  borderRadius: 4,

  cursor: 'pointer',

  padding: '6px 8px',

  svg: {
    transition: '0.1s ease',
    size: '100%',
  },

  '&:hover': {
    backgroundColor: 'rgba(48, 68, 112, 0.04)',
  },
});

const Text = styled('h3', {
  position: 'relative',

  fontSize: 18,
  fontWeight: 400,
  color: '#304470',
  textAlign: 'center',

  width: '50%',

  '@media (max-width: 350px)': {
    fontSize: 16,
  },
});

const Stack = styled('div', {
  display: 'flex',
});

const ErrorText = styled('p', {
  fontSize: 14,
  fontWeight: 400,
  color: '#ee5d5d',

  textAlign: 'start',

  marginLeft: 8,
  marginTop: 8,
});

export default function UploadLogo({
  file,
  type,
  filename,
  onChange,
  isError,
  errorMessage,
  ...rest
}) {
  const fileInputRef = useRef(null);

  const [blob, setBlob] = useState(null);
  const [base64, setBase64] = useState(null);
  const hasFile = blob || base64 || file;
  const fileFromBlob = blob && URL.createObjectURL(blob);

  const [backgroundFile, setBackgroundFile] = useAtom(partnerBackgroundAtom);

  const onUploadBlob = useCallback((e) => {
    if (!e.target?.files) return;

    const blob = e.target.files[0];

    setBlob(blob);

    const file = new File([blob], blob.name, {
      type: blob.type,
    });

    setBackgroundFile(file);

    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      setBase64(reader.result);

      onChange && onChange(blob);
    };

    e.target.value = '';
  }, []);

  const onRemove = useCallback(() => {
    setBase64(null);
    setBlob(null);
    onChange && onChange(null);
    fileInputRef.current.value = '';
  }, []);

  return (
    <Container>
      <AttachmentContainer>
        {hasFile && (
          <Stack
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: 8,

              position: 'absolute',
              top: 9,
              right: 9,
            }}
          >
            <Button
              onClick={onRemove}
              type="button"
              sx={{
                zIndex: 6,
                fontSize: 15,
                fontWeight: 600,
                minWidth: 'unset',
              }}
              variant="text"
            >
              <FiTrash2 size={24} />
            </Button>

            <DownloadButton href={fileFromBlob || file} download type="button">
              <FiDownload size={24} color="#304470" />
            </DownloadButton>
          </Stack>
        )}

        <FileInput
          ref={fileInputRef}
          type="file"
          accept={type}
          onChange={onUploadBlob}
          {...rest}
          style={{
            pointerEvents: hasFile ? 'none' : 'all',

            '&:hover': {
              cursor: 'pointer',
            },
          }}
        />

        {type === 'image/*' && (
          <Stack
            style={{
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: 8,

              border: isError ? '3px dashed #ee5d5d' : '3px dashed #304470',
              borderRadius: 18,

              backgroundImage: hasFile
                ? `url(${fileFromBlob || file})`
                : 'unset',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',

              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {hasFile ? (
              <></>
            ) : (
              <>
                {' '}
                <FiUpload
                  size={'clamp(60px, 1vw, 80px)'}
                  color={isError ? '#ee5d5d' : '#304470'}
                  style={{
                    transition: 'none',
                  }}
                />
                <Text
                  style={{
                    color: isError ? '#ee5d5d' : '#304470',
                  }}
                >
                  Fazer upload do Logotipo da Loja (230x230)
                </Text>
              </>
            )}
          </Stack>
        )}
      </AttachmentContainer>

      {isError && <ErrorText>{errorMessage}</ErrorText>}
    </Container>
  );
}
