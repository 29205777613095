/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { store } from '~/store';

import DefaultLayout from '~/pages/_layouts/DefaultLayout';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  IsAuthPage,
  isAdm,
  ...rest
}) {
  const { accessToken } = store.getState().authReducer.auth;

  if (!accessToken && isPrivate) {
    return <Redirect to="/" />;
  }
  if (accessToken && IsAuthPage) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  IsAuthPage: PropTypes.bool,
  isAdm: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  IsAuthPage: false,
  isAdm: false,
};
