import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { signOut } from 'store/ducks/auth/actions';

import {
  FaAngleUp,
  FaPenAlt,
  FaHeart,
  FaUserCircle,
  FaHandshake,
  FaBox,
  FaSignOutAlt,
} from 'react-icons/fa';

import { Main, TopField, BottomField } from './styles';

const visible = {
  visibility: 'visible',
};
const invisible = {
  visibility: 'hidden',
  display: 'none',
};

export default function LoggedLogin({ setHamburger }) {
  const dispatch = useDispatch();

  const [more, setMore] = useState(false);

  const user = useSelector((state) => state.authReducer.auth.profile.NickName);
  const profilePic = useSelector(
    (state) => state.authReducer.auth.profile.DtoAvatar.Key
  );

  const wrapperRef = useRef(null);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setMore(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);

  const handleLogout = () => {
    dispatch(signOut());

    window.location.reload();
  };

  return (
    <Main ref={wrapperRef}>
      <TopField propmore={more}>
        <div className="profilePic">
          {profilePic ? (
            <img
              src={process.env.REACT_APP_S3URL + profilePic}
              alt="ProfilePic"
              style={profilePic !== null ? visible : invisible}
            />
          ) : (
            <FaUserCircle />
          )}
        </div>

        <div className="myAccount">
          <h6>Olá, {user?.split(' ')[0]}</h6>

          <button
            type="button"
            className="my-account-button"
            onClick={() => {
              setMore(!more);
              setHamburger(false);
            }}
          >
            Minha conta <FaAngleUp propmore={more} />
          </button>
        </div>
      </TopField>

      <Router>
        <BottomField propmore={more}>
          <ul>
            <li>
              <a href="/#/EditProfile">
                <button
                  type="button"
                  onClick={() => {
                    setMore(!more);
                    setHamburger(false);
                  }}
                >
                  <div className="icon-bg">
                    <FaPenAlt />
                  </div>{' '}
                  <span> Dados Pessoais</span>
                </button>
              </a>
            </li>
            {/* <li>
              <a href="/#/EditProfile" onClick={() => setMore(!more)}>
                <div className="icon-bg">
                  <FaPenAlt />
                </div>{' '}
                <span> Alterar Senha</span>
              </a>
            </li> */}
          </ul>
          <ul>
            <li>
              <a href="/#/Favorites">
                <button
                  type="button"
                  onClick={() => {
                    setMore(!more);
                    setHamburger(false);
                  }}
                >
                  <div className="icon-bg">
                    <FaHeart />
                  </div>
                  <span>Favoritos</span>
                </button>
              </a>
            </li>
            <li>
              <a href="/#/MyNegotiations">
                <button
                  type="button"
                  onClick={() => {
                    setMore(!more);
                    setHamburger(false);
                  }}
                >
                  <div className="icon-bg">
                    <FaHandshake />
                  </div>
                  <span>Minhas Negociações</span>
                </button>
              </a>
            </li>
            <li>
              <a href="/#/MyAnnouncements">
                <button
                  type="button"
                  onClick={() => {
                    setMore(!more);
                    setHamburger(false);
                  }}
                >
                  <div className="icon-bg">
                    {' '}
                    <FaBox />{' '}
                  </div>{' '}
                  <span>Meus Anúncios</span>
                </button>
              </a>
            </li>
          </ul>
          <ul>
            <li className="exit">
              <button type="button" onClick={handleLogout}>
                <div className="icon-bg">
                  <FaSignOutAlt />{' '}
                </div>{' '}
                <span> Sair</span>
              </button>
            </li>
          </ul>
        </BottomField>
      </Router>
    </Main>
  );
}
