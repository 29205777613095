import React, { forwardRef } from 'react';
import { useCallback } from 'react';
import { styled } from '../../stiches.config';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

const Container = styled('div', {
  maxWidth: 280,
  minWidth: 60,

  variants: {
    isMaxWidth: {
      true: {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },
});

const Group = styled('div', {
  position: 'relative',
  width: '100%',
  height: 48,

  transition: '$Default',

  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    fieldset: {
      background: '$PrimaryLightBlue',
    },
  },

  '&:focus-within': {
    fieldset: {
      borderColor: '$PrimaryBlue',
    },

    input: {
      color: '$PrimaryBlue',
    },

    '&:hover': {
      fieldset: {
        background: 'unset',
      },
    },

    legend: {
      color: '$PrimaryBlue',
      transform: 'translate(0, 0)',
      width: 'fit-content',
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,
    },

    label: {
      color: '$PrimaryBlue',
      transform: 'translate(0, -20px)',
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,

      marginLeft: '10px !important',
    },

    svg: {
      color: '$PrimaryBlue',
    },
  },

  '&:not(:focus-within) > input:not(:placeholder-shown) + fieldset': {
    legend: {
      transform: 'translate(0, 0)',
      width: 'fit-content',
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,
    },

    background: 'unset',
  },

  '&:not(:focus-within) > input:not(:placeholder-shown) + fieldset + label': {
    transform: 'translate(0, -20px)',
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,

    marginLeft: '10px !important',
  },

  variants: {
    isActive: {
      true: {
        fieldset: {
          borderColor: '$PrimaryBlue',
        },

        input: {
          color: '$PrimaryBlue',
        },

        '&:hover': {
          fieldset: {
            background: 'unset',
          },
        },

        legend: {
          color: '$PrimaryBlue',
          transform: 'translate(0, 0)',
          width: 'fit-content',
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 12,
        },

        label: {
          color: '$PrimaryBlue',
          transform: 'translate(0, -20px)',
          paddingLeft: 8,
          paddingRight: 8,
          fontSize: 12,
        },

        svg: {
          color: '$PrimaryBlue',
        },
      },
    },

    isDisabled: {
      true: {
        color: '$DarkGray',
        background: 'transparent',

        'label, input, svg': {
          color: '$DarkGray',
        },

        fieldset: {
          borderColor: '$DarkGray',
        },
      },
    },

    isError: {
      true: {
        fieldset: {
          borderColor: '$ErrorRed',
        },

        input: {
          color: '$ErrorRed',
        },

        color: '$ErrorRed',
        borderColor: '$ErrorRed',
      },
    },
  },
});

const Fieldset = styled('fieldset', {
  position: 'absolute',
  inset: 0,

  height: '100%',
  width: '100%',

  pointerEvents: 'none',

  border: '2px solid $PrimaryBlue',
  borderRadius: 12,

  transition: '$Default',

  variants: {
    isDisabled: {
      true: {
        opacity: 0.55,
        borderColor: '$DarkGray',
        cursor: 'not-allowed',
      },
    },
  },
});

const Label = styled('label', {
  position: 'absolute',

  marginLeft: 10,

  cursor: 'text',

  transition: '$Default',
  transform: 'translate(0, 1px)',

  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: 'calc(100% - 24px)',

  color: '$PrimaryBlue',

  '@media (max-width: 800px)': {
    fontSize: 14,
  },

  variants: {
    isDisabled: {
      true: {
        opacity: 0.55,
        cursor: 'not-allowed',
      },
    },
    isError: {
      true: {
        color: '$ErrorRed',
      },
    },
    leftAddornment: {
      true: {
        marginLeft: 40,
      },
    },
  },
});

const Legend = styled('legend', {
  width: 0,

  lineHeight: 0,

  paddingBottom: 8,
  marginLeft: 10,

  fontSize: 16,

  transition: '$Default',

  transform: 'translate(0, 26px)',

  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  visibility: 'hidden',
});

const CustomInput = styled('input', {
  width: '100%',
  height: '100%',

  border: 'none',
  outline: 'none',

  fontSize: 16,
  fontWeight: 400,

  color: '$PrimaryBlue',

  background: 'transparent',

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.55,
  },

  variants: {
    leftAddornment: {
      false: {
        paddingLeft: 12,
      },
    },
  },

  '&::-webkit-inner-spin-button': {
    marginRight: 12,
    marginTop: 2,
    borderRadius: 4,
  },

  '&::-webkit-calendar-picker-indicator': {
    opacity: '0 !important',
    position: 'absolute',
    top: 14,
    left: 14,

    width: '100%',
    zIndex: 199,
  },
});

const Addornment = styled('span', {
  position: 'relative',

  zIndex: 99,

  height: '100%',
  aspectRatio: 1,

  paddingTop: 4,

  display: 'grid',
  placeItems: 'center',

  color: '$PrimaryBlue',

  variants: {
    isError: {
      true: {
        color: '$ErrorRed',
      },
    },
  },
});

const ErrorMessage = styled('span', {
  color: '$ErrorRed',

  fontSize: 13,

  height: 16,
  width: 1,

  marginLeft: 8,
});

const InfoText = styled('span', {
  color: '$DarkGray',

  fontSize: 12,

  height: 16,
  width: 1,

  marginLeft: 6,
  marginTop: 8,
});
function InputComponent(props, ref) {
  const {
    placeholder = 'Pesquisar',
    type = 'text',
    errorMessage = 'O campo não pode estar vazio.',
    name,
    defaultValue,
    min,
    max,

    onChange,
    value,

    onSubmit,

    isDisabled,
    isReadOnly,
    isError,
    isActive,

    rightAdornment,
    leftAddornment,

    isMaxWidth,

    infoText,

    ...rest
  } = props;

  const isInputDisabled = isDisabled;

  const id = v4();

  const onPressEnter = useCallback(
    (e) => {
      if (e.key === 'Enter' && onSubmit) onSubmit();
    },
    [onSubmit]
  );

  return (
    <Container isMaxWidth={isMaxWidth}>
      <Group isDisabled={isInputDisabled} isError={isError} isActive={isActive}>
        {leftAddornment && (
          <Addornment onClick={(e) => e.stopPropagation()} isError={isError}>
            {leftAddornment}
          </Addornment>
        )}

        <CustomInput
          ref={ref}
          id={id}
          type={type}
          name={name}
          placeholder=" "
          onChange={onChange}
          onKeyDown={onPressEnter}
          value={value}
          readOnly={isReadOnly}
          disabled={isInputDisabled}
          defaultValue={defaultValue}
          min={min}
          max={max}
          leftAddornment={leftAddornment ? true : false}
          {...rest}
        />

        <Fieldset isDisabled={isDisabled}>
          <Legend>{placeholder}</Legend>
        </Fieldset>

        <Label
          htmlFor={id}
          isDisabled={isDisabled}
          leftAddornment={leftAddornment ? true : false}
          isError={isError}
        >
          {placeholder}
        </Label>

        {rightAdornment && (
          <Addornment onClick={(e) => e.stopPropagation()} isError={isError}>
            {rightAdornment}
          </Addornment>
        )}
      </Group>

      {isError ? (
        <ErrorMessage>{isError && errorMessage}</ErrorMessage>
      ) : (
        <InfoText>{infoText && infoText}</InfoText>
      )}
    </Container>
  );
}

InputComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'tel', 'url', 'email', 'number']),
  name: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,

  value: PropTypes.string,

  onSubmit: PropTypes.func,

  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isError: PropTypes.bool,
  isActive: PropTypes.bool,

  errorMessage: PropTypes.string,

  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),

  rightAdornment: PropTypes.element,
  leftAddornment: PropTypes.element,

  isMaxWidth: PropTypes.bool,

  infoText: PropTypes.string,
};

InputComponent.defaultProps = {
  type: 'text',
  errorMessage: 'O campo não pode estar vazio.',
};

export default forwardRef(InputComponent);
