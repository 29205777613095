import styled, { css } from 'styled-components';

export const Container = styled.div`
  .LeadTrust {
    height: auto;
    transition: 0.3s;

    section {
      display: flex;
    }

    .satisfactionRate {
      width: 250px;
      height: 70px;
      position: relative;
      display: flex;
      align-items: flex-end;

      svg {
        width: 250px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      label {
        font-size: 17px;
        position: absolute;
        bottom: -30px;
        left: 0;
      }

      span {
        width: 50px;
        height: 3px;
      }

      span:nth-child(2) {
        background: #ff2327;
        border-radius: 4px 0px 0px 4px;
      }
      span:nth-child(3) {
        background: #ff8523;
      }
      span:nth-child(4) {
        background: #ffdd33;
      }
      span:nth-child(5) {
        background: #a7ec66;
        height: 60px;
        border-radius: 5px 5px 0 0;
      }
      span:nth-child(6) {
        background: #36e266;
        border-radius: 0 4px 4px 0;
      }

      .expression-1 {
        display: none;
      }
      .expression-2 {
        display: none;
      }
      .expression-3 {
        display: none;
      }
      .expression-4 {
        display: unset;
      }
      .expression-5 {
        display: none;
      }
    }

    .logo-resume {
      display: flex;
      margin-top: 20px;
    }

    .logoLeadTrust {
      width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      svg {
        width: 50px;
        height: auto;
      }
    }

    .LeadTrustResume {
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      p {
        width: 220px;
        padding: 0 20px;
        font-size: 8px;
        text-align: center;
      }

      div {
        width: 150px;
        height: 4px;
        display: flex;

        span {
          width: 30px;
          height: 2px;
        }
        span:nth-child(1) {
          background: #ff2327;
          border-radius: 4px 0px 0px 4px;
        }
        span:nth-child(2) {
          background: #ff8523;
        }
        span:nth-child(3) {
          background: #ffdd33;
        }
        span:nth-child(4) {
          background: #a7ec66;
        }
        span:nth-child(5) {
          background: #36e266;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
`;
