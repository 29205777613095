import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useSelector } from 'react-redux';

import { toastError } from 'utils/defaultToasts';

import api from '~/services/api';

import { FaInfoCircle } from 'react-icons/fa';
import { ImWhatsapp } from 'react-icons/im';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import SellerInfo from '~/components/SellerInfo';

import { Carousel } from 'react-responsive-carousel';

import * as S from './styles';

export default function Review({ setPage, announcement, setAnnouncement }) {
  const animationContainerRef = useRef(null);

  const [personalData, setPersonalData] = useState(false);

  const [callAnim, setCallAnim] = useState(personalData);

  const [errors, setErrors] = useState([]);
  const [alerts, setAlerts] = useState([]);

  const [numberOfNotifications, setNumberOfNotifications] = useState(0);

  const publishButtonRef = useRef(null);

  const user = useSelector((state) => state.authReducer.auth.profile.NickName);

  const profilePic = useSelector(
    (state) => state.authReducer.auth.profile.DtoAvatar.Key
  );

  const patchReview = useCallback(async () => {
    if (!announcement.Id) {
      setPage('Information');

      return;
    }

    const patchReviewUrl = `/api/v1/Announcements/${announcement.Id}/Review`;

    try {
      const response = await api.patch(patchReviewUrl);
      const { data } = response;

      setAlerts(data.Alerts);
      setNumberOfNotifications(data.Alerts.length);

      const canPublish = data.IsEligibleToPublish;

      if (canPublish)
        publishButtonRef.current.disabled = false;
      else
        toastError('Seu anúncio não está elegível para ser publicado. Confira os alertas e corrija os campos inválidos.')

    } catch (error) {
      if (error?.response?.status === 400) {
        toastError('Preencha os campos anteriores antes de concluir o seu anúncio.');
        return;
      }

      toastError('Ocorreu um erro ao revisar seu anúncio.');
    }
  }, [announcement.Id]);

  useEffect(() => {
    publishButtonRef.current.disabled = true;

    patchReview();
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');

    if (newWindow) newWindow.opener = null;
  }

  const mobilePhone =
    announcement?.DtoContact?.DtoMobilePhone
      ?.replace(/^(\d{2})(\d)/g, '($1) $2')
      ?.replace(/(\d)(\d{4})$/, '$1-$2');

  const commercialPhone =
    announcement?.DtoContact?.DtoCommercialPhone
      ?.replace(/^(\d{2})(\d)/g, '($1) $2')
      ?.replace(/(\d)(\d{4})$/, '$1-$2');

  const mobilePhoneWhastappLink =
    'https://api.whatsapp.com/send?phone=' + mobilePhone;

  const topFunction = useCallback(() => {
    document.body.scrollTop = 1200;
    document.documentElement.scrollTop = 1200;
  }, []);

  const showNewFieldWithDelay = useCallback(() => {
    if (!personalData) {
      setTimeout(() => {
        setCallAnim(!personalData);
      }, 300);
      setPersonalData(!personalData);
    } else {
      setTimeout(() => {
        setPersonalData(!personalData);
      }, 300);
      setCallAnim(!personalData);
    }
  }, []);

  const publish = useCallback(async () => {
    const publishAnnouncementUrl = `api/v1/Announcements/${announcement.Id}/Publish`;

    try {
      await api.patch(publishAnnouncementUrl);

      hideContainerAnimation();

      setTimeout(() => {
        setPage('Aproved');
      }, 600);
    } catch (error) {
      setErrors(error.response.data);
      setNumberOfNotifications(error.response.data.length);

      toastError(error.response);
    }
  }, []);

  const showContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '1';
    animationContainerRef.current.style.transform = 'translateX(0)';
  };

  const hideContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '0';
    animationContainerRef.current.style.transform = 'translateX(100%)';
  };

  useEffect(() => {
    showContainerAnimation();
  }, []);

  return (
    <>
      <div>
        <S.AnimationContainer ref={animationContainerRef}>
          <S.Container propPersonalData={callAnim}>
            <h1>Confira seu anúncio</h1>

            {personalData ? (
              <SellerInfo showNewFieldWithDelay={showNewFieldWithDelay} />
            ) : null}
            <S.MainField>
              <div className="notify-button" onClick={() => topFunction()}>
                <FaInfoCircle />

                <div className="notify-counter">{numberOfNotifications}</div>
              </div>

              <div className="title">
                <h2>{announcement?.Title}</h2>{' '}
                <span>{announcement?.UseCondition?.Description}</span>
              </div>

              <S.TopField>
                <div className="photos">
                  <Carousel
                    showArrows
                    showStatus={false}
                    infiniteLoop
                    emulateTouch
                    className="Carousel"
                  >
                    {announcement?.DtoPictures?.map((picture) => (
                      <div key={picture.key}>
                        <img
                          src={
                            process.env.REACT_APP_S3URL + picture.Key
                          }
                          alt={picture.Description}
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>

                <div className="right">
                  <p className="price-tag">
                    <h2>R$ {announcement?.Price}</h2>
                  </p>

                  <div className="selfInfos">
                    <h3>Dados do Vendedor</h3>

                    <div className="myAccount">
                      <div className="profilePic">
                        <img src={process.env.REACT_APP_S3URL + profilePic} alt="" />
                      </div>

                      <div>
                        <h6>{user}</h6>
                      </div>
                    </div>

                    <div className="contact-info">
                      <S.MobilePhone
                        onClick={() => {
                          openInNewTab(mobilePhoneWhastappLink);
                        }}
                      >
                        <h2>Celular</h2>
                        <div className="number-and-icon">
                          <ImWhatsapp />
                          <span>{mobilePhone}</span>
                        </div>
                      </S.MobilePhone>

                      <br />

                      <S.GetInTouchDescription>
                        Entre em contato com vendedor preferencialmente por
                        Whatsapp.
                      </S.GetInTouchDescription>
                    </div>
                  </div>
                </div>
              </S.TopField>

              <S.MiddleField>
                <h3>Ficha Técnica:</h3>

                <ul>
                  <li>
                    <span>Categoria:</span>
                    <span>{announcement.MajorCategory?.Description || '-'}</span>
                  </li>
                  <li>
                    <span>Sub:</span>
                    <span>{announcement.Category?.Description || '-'}</span>
                  </li>
                  <li>
                    <span>Tamanho:</span>
                    <span>{announcement.Size?.Description || '-'}</span>
                  </li>
                </ul>

                <ul>
                  <li>
                    <span>Marca:</span> <span>{announcement.Brand || '-'}</span>
                  </li>
                  <li>
                    <span>Ano:</span> <span>{announcement.Year || '-'}</span>
                  </li>
                  <li>
                    <span>Condição:</span> <span>{announcement.UseConditionDescription || '-'}</span>
                  </li>
                </ul>
              </S.MiddleField>

              <S.BottomField>
                <h3>Detalhes:</h3>
                <pre>{announcement?.Description}</pre>
              </S.BottomField>
            </S.MainField>

            <S.NotifySection id="notify-section">
              {errors.map((error) => (
                <ul key={error.Detail}>
                  <li>{error.Detail}</li>
                </ul>
              ))}
              {alerts.map((error) => (
                <ul key={error}>
                  <li>{error}</li>
                </ul>
              ))}
            </S.NotifySection>
            <section className="buttons-section">
              <button
                type="button"
                className="nextStep"
                onClick={() => setPage('Payment')}
              >
                Voltar
              </button>
              <div>
                <button
                  ref={publishButtonRef}
                  type="button"
                  id="publish-button"
                  onClick={() => { publish() }}
                  style={{ marginLeft: '30px' }}
                >
                  Publicar
                </button>
              </div>
            </section>
          </S.Container>
        </S.AnimationContainer>
      </div>
    </>
  );
}
