import { toast } from 'react-toastify';

export const toastError = (string) => {
  toast.error(string, { position: toast.POSITION.BOTTOM_CENTER });
};

export const toastInfo = (string) => {
  toast.info(string, { position: toast.POSITION.BOTTOM_CENTER });
};

export const toastSuccess = (string) => {
  toast.success(string, { position: toast.POSITION.BOTTOM_CENTER });
};
