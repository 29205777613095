import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Pagination } from '@material-ui/lab';
import { IoMdArrowRoundBack } from 'react-icons/io';

import api from '~/services/api';

import {
  Container,
  Products,
  ProductSquare,
  Title,
  BackContainer,
} from './styles';
import { toastError } from '~/utils/defaultToasts';
import AccordionFilter from '~/components/AccordionStruct/Accordion';
import FilterOptions from '~/components/FilterOptions';
import { AccordionFilterItemsContext } from '~/context/Accordion';

export default function Category() {
  const history = useHistory();
  const location = useLocation();

  const { accordionFilterItems } = useContext(AccordionFilterItemsContext);

  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state) => state.authReducer.auth.accessToken
  );
  const userId = useSelector((state) => state.authReducer.auth.profile.Id);

  const { title } = useParams();

  const [categoryDescription, setCategoryDescription] = useState('');

  const [announcements, setAnnouncements] = useState([]);

  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function countProperties(obj) {
    var count = 0;

    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) ++count;
    }

    return count;
  }

  const getPage = useCallback(
    async (page) => {
      const parameters = {
        CurrentPage: page,
        PageSize: 9,
        IsPaged: true,
        State: accordionFilterItems.initialsUF?.Name,
        CityId: accordionFilterItems.city?.Name,
        UseCondition: accordionFilterItems.useCondition?.Name,
        Size: accordionFilterItems.size?.Name,
        RimSize: accordionFilterItems.rimSize?.Name,
        ForkTravel: accordionFilterItems.forkTravel?.Name,
        BasePrice: accordionFilterItems.basePrice?.Name,
        LimitPrice: accordionFilterItems.limitPrice?.Name,
        BaseYear: accordionFilterItems.baseYear?.Name,
        LimitYear: accordionFilterItems.limitYear?.Name,
      };

      const categoryQueryParams = accordionFilterItems.categories
        .map((category) => `&Categories=${category.Name}`)
        .toString()
        .replaceAll(',', '');

      Object.keys(parameters).forEach(
        (key) => parameters[key] === undefined && delete parameters[key]
      );

      const getBasicInfoSearchUrl =
        '/api/v1/Announcements/BasicInfoSearch?' + categoryQueryParams;

      try {
        const response = await api.get(getBasicInfoSearchUrl, {
          params: parameters,
        });
        const data = response.data;

        const pagination = JSON.parse(response.headers.pagination);

        setAnnouncements(data);
        setNumberOfPages(pagination.TotalPages);
      } catch (error) {
        toastError('Erro ao carregar anúncios.');
      }
    },
    [accordionFilterItems]
  );

  const addFavorite = useCallback(
    async (id, event) => {
      const heart = event.target;

      const postFavoriteUrl = `api/v1/Profiles/${userId}/Starreds`;

      try {
        await api.post(postFavoriteUrl, { AnnouncementId: id });

        heart.style.fill = '#fce76d';

        setAnnouncements(
          announcements.map((announcement) => {
            return announcement.Id === id
              ? { ...announcement, IsStarred: true }
              : announcement;
          })
        );
      } catch (error) {
        toast.error('Erro ao favoritar anúncio.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    },
    [announcements]
  );

  const removeFavorite = useCallback(
    async (id, event) => {
      const heart = event.target;

      const deleteFavoriteUrl = `api/v1/Profiles/${userId}/Starreds/${id}`;

      try {
        await api.delete(deleteFavoriteUrl);

        heart.style.fill = '#36bcc1';

        setAnnouncements(
          announcements.map((announcement) => {
            return announcement.Id === id
              ? { ...announcement, IsStarred: false }
              : announcement;
          })
        );
      } catch (error) {
        toast.error('Erro ao remover anúncio dos favoritos.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    },
    [announcements]
  );

  const changePage = useCallback(
    (event, page) => {
      setCurrentPage(page);
      getPage(page);
    },
    [getPage]
  );

  useEffect(() => {
    if (accordionFilterItems.categories.length > 0) {
      getPage(1);
    }
  }, [accordionFilterItems]);

  useEffect(() => {
    setCategoryDescription(location?.state?.CategoryDescription);
  }, [location]);

  return (
    <>
      <BackContainer>
        <button onClick={() => history.push('/Anuncios')}>
          <IoMdArrowRoundBack color="#213454" />
        </button>
      </BackContainer>
      <Container>
        <div>
          <Title>Filtros</Title>
          <AccordionFilter />
        </div>

        <div className="right-side">
          <div>
            <Title>Categoria: {categoryDescription}</Title>
            <FilterOptions />
          </div>
          <Products>
            <div className="ListOfProducts">
              <div className="products">
                <ul>
                  {announcements.map((announcement) => (
                    <li key={announcement.Id}>
                      <ProductSquare
                        isStarred={announcement.IsStarred}
                        productId={announcement.Id}
                        onClick={() => {
                          history.push(`/Anuncio/${announcement.Id}`);
                        }}
                      >
                        <div className="icons">
                          <FaHeart
                            className="heart-icon"
                            onClick={(event) => {
                              event.stopPropagation();
                              if (accessToken && !announcement.IsStarred) {
                                addFavorite(announcement.Id, event);
                              } else if (
                                accessToken &&
                                announcement.IsStarred
                              ) {
                                removeFavorite(announcement.Id, event);
                              } else {
                                history.push(`/Login`);
                              }
                            }}
                          />
                        </div>
                        <div className="image">
                          <img
                            src={
                              process.env.REACT_APP_S3URL +
                              announcement.DtoPicture?.Key
                            }
                            alt=""
                          />
                        </div>
                        <div
                          style={
                            announcement.UseCondition.Description === 'Novo'
                              ? { backgroundColor: '#223357' }
                              : { backgroundColor: '#37bcc1' }
                          }
                          className="use-condition"
                        >
                          {announcement.UseCondition.Description}
                        </div>

                        <div className="announcement-title-container">
                          <h1
                            title={announcement?.Title}
                            className="announcement-title"
                          >
                            {announcement?.Title}
                          </h1>
                        </div>

                        <p className="announcement-price">
                          {'R$ ' +
                            announcement?.Price?.toLocaleString('pt-BR', {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                        </p>
                      </ProductSquare>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Products>
          <Pagination
            count={numberOfPages}
            page={currentPage}
            shape="rounded"
            onChange={changePage}
          />
        </div>
      </Container>
    </>
  );
}
