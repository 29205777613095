import React, { useCallback, useState, useEffect, useRef } from 'react';

import Loading from '../../components/Loading';

import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import cropImage from './utils/cropImage';

import * as S from './styles';

import { useHistory } from 'react-router-dom';

import { Container, Advise, CropOptionsContainer } from './styles';

import api from '../../services/api';

import { toastError, toastSuccess, toastInfo } from 'utils/defaultToasts';

import { v4 as uuidv4 } from 'uuid';

import resizeBase64 from '~/utils/resizeBase64.js';

import { phoneMask } from 'utils/phoneMask';

const EditMyAnnouncement = () => {
  const history = useHistory();

  const formRef = useRef(null);

  const myAnnouncementId = window.location.href.split('EditMyAnnouncement/')[1];

  const apiMyAnnouncementUrl = '/api/v1/Announcements/' + myAnnouncementId;
  const apiCategoriesUrl = '/api/v1/Announcements/Enumerators/Categories';
  const apiEnumeratorsUrl = '/api/v1/Announcements/Enumerators';
  const apiStatesUrl = '/api/UFs/';

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [enumerators, setEnumerators] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [displayCropper, setDisplayCropper] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1);
  const [horizontalSize, setHorizontalSize] = useState(1000);
  const [verticalSize, setverticalSize] = useState(1000);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(1);

  const [newImagesAdded, setNewImagesAdded] = useState([]);
  const [imagesToRemoveByKey, setImagesToRemoveByKey] = useState([]);

  const [currentImageToCrop, setCurrentImageToCrop] = useState('');
  const [croppedImagesArray, setCroppedImagesArray] = useState([]);

  const [myAnnouncement, setMyAnnouncement] = useState({});
  const [myAnnouncementPictures, setMyAnnouncementPictures] = useState({});

  const [isPageLoading, setIsPageLoading] = useState(true);

  const currentYear = new Date().getFullYear();

  const [mobilePhone, setMobilePhone] = useState('');
  const [commercialPhone, setCommercialPhone] = useState('');

  const getCategories = async () => {
    try {
      const response = await api.get(apiCategoriesUrl);

      setCategories(response.data);
    } catch (error) {
      toastError('Houve um erro ao buscar as categorias do anúncio.');
    }
  };

  const getSubCategories = async (selectedCategory) => {
    try {
      const subCategory = '?aParentCategory=' + selectedCategory;
      const response = await api.get(apiCategoriesUrl + subCategory);

      setSubCategories(response.data);
    } catch (error) {
      toastError('Houve um erro ao buscar as subcategorias do anúncio.');
    }
  };

  const getEnumerators = async () => {
    try {
      const response = await api.get(apiEnumeratorsUrl);
      setEnumerators(response.data);
    } catch (error) {
      toastError('Houve um erro ao buscar dados do anúncio.');
    }
  };

  const getStates = async () => {
    try {
      const response = await api.get(apiStatesUrl);

      setStates(response.data);
    } catch (error) {
      toastError('Houve um erro ao buscar o Estado.');
    }
  };

  const getCities = async (state) => {
    try {
      const response = await api.get(apiStatesUrl + state + '/Cities');

      setCities(response.data);
    } catch (error) {
      toastError('Houve um erro ao buscar a Cidade.');
    }
  };

  const getMyAnnouncementeData = async () => {
    try {
      const response = await api.get(apiMyAnnouncementUrl);

      getSubCategories(response.data.MajorCategory.Name);

      setMyAnnouncement(response.data);
      setMyAnnouncementPictures(response.data.DtoPictures);

      setIsPageLoading(false);

      await getCities(response?.data?.State?.Name);
    } catch (error) {
      if (error?.response?.status === 403) {
        toastError('O anúncio que você buscou não pertence a sua conta.');

        history.push('/MyAnnouncements');
        return;
      }

      toastError('Houve um erro ao buscar o anúncio.');
      history.push('/MyAnnouncements');
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await cropImage(
        currentImageToCrop.blob,
        croppedAreaPixels
      );

      const fullImage = await resizeBase64(croppedImage, 512, 512);
      const miniature = await resizeBase64(croppedImage, 300, 250);

      setCroppedImagesArray([
        ...croppedImagesArray,
        {
          id: uuidv4(),
          dataUrl_full: fullImage,
          dataUrl_miniature: miniature,
          size: currentImageToCrop.size,
        },
      ]);

      setNewImagesAdded((curFiles) => [...curFiles, croppedImage]);

      setDisplayCropper(false);
      setZoom(1);
      setCrop({ x: 0, y: 0 });
      setCroppedAreaPixels(1);
    } catch (error) {
      toastError('Erro ao recortar imagem, faça o envio novamente.');
    }
  }, [croppedAreaPixels]);

  const removePictureFromCroppedImagesArray = (imageId) => {
    setCroppedImagesArray([...croppedImagesArray.filter(imageToBeRemoved => imageToBeRemoved.id !== imageId)]);
  };

  const removeAlreadyUploadedPictures = (image) => {
    const imageToBeRemoved = myAnnouncementPictures.find(imageToBeRemoved => imageToBeRemoved.Key === image);

    const imagesToRemoveUpdate = imagesToRemoveByKey;
    imagesToRemoveUpdate.push(imageToBeRemoved.Key);

    setImagesToRemoveByKey(imagesToRemoveUpdate);

    setMyAnnouncementPictures([...myAnnouncementPictures.filter(imageToBeRemoved => imageToBeRemoved.Key !== image)]);
  };

  const getAllInputReferences = (formRef) => {
    const form = formRef.current;
    return [
      form.title,
      form.description,
      form.category,
      form.brand,
      form.serialNumber,
      form.useCondition,
      form.year,
      form.sizeNumber,
      form.size,
      form.rimSize,
      form.forkTravel,
      form.mobilePhone,
      form.commercialPhone,
      form.email,
      form.state,
      form.city,
      form.cep,
      form.price,
      form.useConditionDescription,
    ];
  };

  const validateFields = () => {
    const allInputsReferences = getAllInputReferences(formRef);

    const invalidInputsArray = [];

    allInputsReferences.forEach((inputReference) => {
      const isInputValid =
        inputReference.checkValidity() && inputReference.value.length > 0;
      const isNotSelectField = inputReference.tagName !== 'SELECT';

      const isNotYear = inputReference.name !== 'year';
      const isNotSizeNumber = inputReference.name !== 'sizeNumber';
      const isNotSerialNumber = inputReference.name !== 'serialNumber';
      const isNotCommercialPhone = inputReference.name !== 'commercialPhone';
      const isNotUseConditionDescription =
        inputReference.name !== 'useConditionDescription';

      if (isNotYear && isNotSizeNumber) {
        inputReference.style.backgroundColor = '#fff';
        inputReference.style.borderColor = '#7d7d7d4d';

        if (
          isNotSelectField &&
          isNotSerialNumber &&
          isNotCommercialPhone &&
          isNotUseConditionDescription &&
          !isInputValid
        )
          invalidInputsArray.push(inputReference);
      }
    });

    if (invalidInputsArray.length > 0) {
      invalidInputsArray.forEach((invalidInput) => {
        invalidInput.style.backgroundColor = '#ecb9b9';
        invalidInput.style.borderColor = '#b41212';
      });

      toastError('Corrija os campos inválidos e salve novamente.');

      return false;
    }

    return true;
  };

  const getInputsObject = (inputReferences) => {
    const inputsObject = {
      title: inputReferences[0].value,
      description: inputReferences[1].value,
      category: inputReferences[2].value,
      brand: inputReferences[3].value,
      serialNumber: inputReferences[4].value,
      useCondition: inputReferences[5].value,
      year: inputReferences[6].value,
      sizeNumber: Number(inputReferences[7].value),
      size: inputReferences[8].value,
      rimSize: inputReferences[9].value,
      forkTravel: inputReferences[10].value,
      dtoEmail: { address: inputReferences[13].value },
      state: inputReferences[14].value,
      cityId: Number(inputReferences[15].value),
      cep: inputReferences[16].value,
      price: Number(inputReferences[17].value.replace(',', '.')),
      useConditionDescription: inputReferences[18].value,
    };

    if (inputReferences[12].value.length > 0) {
      let inputsObjectWithCommercialPhone = {
        ...inputsObject,
        dtoPhones: [
          {
            type: 'Mobile',
            ddi: 55,
            ddd: inputReferences[11].value.slice(0, 2),
            number: inputReferences[11].value.slice(2)
          },
          {
            type: 'Commercial',
            ddi: 55,
            ddd: inputReferences[12].value.slice(0, 2),
            number: inputReferences[12].value.slice(2)
          }
        ],
      }

      return inputsObjectWithCommercialPhone;
    }

    return {
      title: inputReferences[0].value,
      description: inputReferences[1].value,
      category: inputReferences[2].value,
      brand: inputReferences[3].value,
      serialNumber: inputReferences[4].value,
      useCondition: inputReferences[5].value,
      year: inputReferences[6].value,
      sizeNumber: Number(inputReferences[7].value),
      size: inputReferences[8].value,
      rimSize: inputReferences[9].value,
      forkTravel: inputReferences[10].value,
      dtoPhones: [
        {
          type: 'Mobile',
          ddi: 55,
          ddd: inputReferences[11].value.slice(0, 2),
          number: inputReferences[11].value.slice(2)
        }
      ],
      dtoEmail: { address: inputReferences[13].value },
      state: inputReferences[14].value,
      cityId: Number(inputReferences[15].value),
      cep: inputReferences[16].value,
      price: Number(inputReferences[17].value.replace(',', '.')),
      useConditionDescription: inputReferences[18].value,
    }
  }

  const updateAnnouncement = async () => {
    const areFieldsValid = validateFields();

    if (!areFieldsValid) return;

    newImagesAdded.forEach((image) => {
      const buffer = Buffer.from(image.split(",")[1]);

      if (buffer.length > 5 * 1024 * 1024) {
        toastError('A foto inserida precisar ter, no máximo, 5MB!');
        return;
      }

      const imageExtension = image.split(":")[1].split(";")[0];

      if (!['image/jpg', 'image/jpeg', 'image/png', 'image/heic'].includes(imageExtension)) {
        toastError('A foto inserida deve ser JPG, JPEG, PNG ou HEIC!');
        return;
      }
    });

    toastInfo('Enviando informações...');

    try {
      const bodyContent = {
        ...getInputsObject(getAllInputReferences(formRef)),
        images: newImagesAdded,
        deleteFileKeys: imagesToRemoveByKey
      };

      await api.put('/api/v1/Announcements/' + myAnnouncementId, bodyContent);

      toastSuccess('Anúncio atualizado com sucesso!');

      history.push('/MyAnnouncements');
    } catch (error) {
      toastError('Erro ao atualizar anúncio, tente novamente.');
    }
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getStates();
    await getCategories();
    await getEnumerators();
    await getMyAnnouncementeData();
  };

  const bikeInvetion = useCallback(
    () => (
      <div>
        A bicicleta foi inventada em{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pt.wikipedia.org/wiki/Bicicleta#Hist%C3%B3ria"
        >
          1817
        </a>
        ...
      </div>
    ),
    []
  );

  useEffect(() => {
    setMobilePhone(myAnnouncement.DtoContact?.DtoMobilePhone);
    setCommercialPhone(myAnnouncement.DtoContact?.DtoCommercialPhone);
  }, [
    myAnnouncement.DtoContact?.DtoMobilePhone,
    myAnnouncement.DtoContact?.DtoCommercialPhone,
  ]);

  if (isPageLoading) {
    return (
      <Container>
        <div id="loading">
          <Loading loadingText="Carregando anúncio..." />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <form ref={formRef}>
        <div className="edit-announcement-page-title">
          <h1>Editar anúncio</h1>
          <p>Anúncio: {myAnnouncementId}</p>
          <p>
            {myAnnouncement.LastUpdateAt &&
              `Última atualização em: ${myAnnouncement.LastUpdateAt?.slice(
                8,
                10
              )}/${myAnnouncement.LastUpdateAt?.slice(
                5,
                7
              )}/${myAnnouncement.LastUpdateAt?.slice(0, 4)}`}
          </p>
        </div>

        <div className="title">
          <h2>Identifique seu produto</h2>
          <input
            id="title"
            name="title"
            type="text"
            defaultValue={myAnnouncement.Title}
            placeholder="Digite o título do anúncio"
            minLength="5"
            maxLength="200"
          />
        </div>

        <div className="description">
          <h2>Descreva uma ficha técnica do seu produto</h2>
          <textarea
            id="description"
            name="description"
            type="text"
            defaultValue={myAnnouncement.Description}
            minLength="10"
            maxLength="2000"
          />
        </div>

        <div className="category-section">
          <h2>Escolha a categoria e marca</h2>
          <span className="category-span">Categoria</span>
          <select
            id="major-category"
            name="major-category"
            defaultValue={myAnnouncement.MajorCategory.Name}
            onChange={({ target }) => getSubCategories(target.value)}
          >
            {categories.map((category) => (
              <option key={category.Id} value={category.Name}>
                {category.Description}
              </option>
            ))}
          </select>
          <br />
          <span className="category-span">Subcategoria</span>
          <select id="category" name="subcategory">
            {subCategories?.map((category) =>
              category?.DtoEnums?.length > 0 ? (
                <optgroup label={category?.Description}>
                  {category?.DtoEnums?.map((subCategory) => (
                    <option
                      selected={
                        myAnnouncement?.Category?.Name === subCategory?.Name
                      }
                      key={subCategory?.Id}
                      value={subCategory?.Name}
                    >
                      {subCategory?.Description}
                    </option>
                  ))}
                </optgroup>
              ) : (
                <option
                  selected={myAnnouncement?.Category?.Name === category?.Name}
                  key={category?.Id}
                  value={category?.Name}
                >
                  {category?.Description}
                </option>
              )
            )}
          </select>

          <div className="brand-container">
            <span>Marca</span>
            <input
              id="brand"
              name="brand"
              type="text"
              defaultValue={myAnnouncement.Brand}
              placeholder="Digite a marca"
            />
          </div>

          <div className="serial-number-container">
            <span>Número de série (opcional)</span>
            <input
              id="serialNumber"
              name="serialNumber"
              type="text"
              defaultValue={myAnnouncement.SerialNumber}
              placeholder="Número de série"
            />
          </div>
        </div>

        <div className="more-info">
          <h2>Mais informações</h2>
          <section className="selectField">
            <div className="select-section">
              <span>Condição</span>
              <select
                id="use-condition"
                name="useCondition"
                defaultValue={myAnnouncement.UseCondition.Name}
              >
                {enumerators[4]?.DtoEnumContents.map((option) => (
                  <option key={option.Id} value={option.Name}>
                    {option.Description}
                  </option>
                ))}
              </select>
            </div>
            <div className="select-section">
              <span>Descreva a condição</span>
              <input
                id="useConditionDescription"
                name="useConditionDescription"
                defaultValue={myAnnouncement.UseConditionDescription}
              />
            </div>
            <div className="select-section">
              <span>Ano</span>
              <input
                id="year"
                defaultValue={myAnnouncement.Year}
                onChange={({ target }) => {
                  if (target.value >= currentYear) target.value = currentYear;
                }}
                onBlur={({ target }) => {
                  const isBikeYearCorrect =
                    target.value < 1817 && target.value.length !== 0;
                  if (isBikeYearCorrect) {
                    target.value = 1817;
                    toastError(bikeInvetion);
                  }
                }}
                name="year"
                type="number"
                min="1939"
                max={currentYear}
              />
            </div>
            <div className="select-section">
              <span>Tamanho</span>
              <select
                id="size"
                name="size"
                defaultValue={myAnnouncement.Size.Name}
              >
                {enumerators[3]?.DtoEnumContents.map((option) => (
                  <option key={option.Id} value={option.Name}>
                    {option.Description}
                  </option>
                ))}
              </select>
            </div>
            <div className="select-section">
              <span>Número</span>
              <input
                id="size-number"
                defaultValue={myAnnouncement.SizeNumber}
                name="sizeNumber"
                type="number"
                min="1"
              />
            </div>
            <div className="select-section">
              <span>Curso de suspensão</span>
              <select
                id="fork-travel"
                name="forkTravel"
                defaultValue={myAnnouncement.ForkTravel.Name}
              >
                {enumerators[1]?.DtoEnumContents.map((option) => (
                  <option key={option.Id} value={option.Name}>
                    {option.Description}
                  </option>
                ))}
              </select>
            </div>
            <div className="select-section">
              <span>Tamanho do Aro</span>
              <select
                id="rim-size"
                name="rimSize"
                defaultValue={myAnnouncement.RimSize.Name}
              >
                {enumerators[2]?.DtoEnumContents.map((option) => (
                  <option key={option.Id} value={option.Name}>
                    {option.Description}
                  </option>
                ))}
              </select>
            </div>
          </section>
        </div>

        <div className="price">
          <h2>Preço:</h2>
          <p>R$: </p>
          <input
            id="price"
            name="price"
            type="number"
            defaultValue={myAnnouncement.Price}
            min="1"
            step=".01"
            placeholder="0,00"
          />
        </div>

        <div className="bike-photo">
          <h2>Foto</h2>
          <div className="current-pics">
            <ul>
              {myAnnouncementPictures.map((picture, index) => (
                <li className="current-pic" key={index}>
                  <img
                    src={process.env.REACT_APP_S3URL + picture?.Key}
                    alt={'Anúncio Pic ' + (index + 1)}
                  />
                  <button
                    data-img-url={picture.url}
                    className="remove-pic-button"
                    onClick={() => removeAlreadyUploadedPictures(picture.Key)}
                  >
                    Remover
                  </button>
                </li>
              ))}
              {croppedImagesArray.map((picture, index) => (
                <li className="current-pic" key={index}>
                  <img
                    src={picture.dataUrl_miniature}
                    alt={'Nova foto do anúncio ' + (index + 1)}
                  />
                  <button
                    data-img-id={picture.id}
                    className="remove-pic-button"
                    onClick={(event) => {
                      event.preventDefault();
                      const imageId = event.target.getAttribute('data-img-id');
                      removePictureFromCroppedImagesArray(imageId);
                    }}
                  >
                    Remover
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="drop-file">
            <Dropzone
              accept="image/png, image/jpg, image/jpeg, image/heic"
              multiple={false}
              onDropAccepted={(uploadedImage) => {
                const image = uploadedImage[0];
                const blob = URL.createObjectURL(image);
                console.log(image);
                setCurrentImageToCrop({ blob: blob, size: image.size });
                setDisplayCropper(true);
              }}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
              }) => (
                <section style={{ position: 'relative' }}>
                  <div {...getRootProps()} className="drop-box">
                    <p
                      style={{
                        border: isDragActive
                          ? isDragAccept
                            ? 'dashed 2px #76dd4d'
                            : 'dashed 2px #da2828'
                          : '',
                      }}
                    >
                      Arraste as imagens nesta área.
                      <button>Procure no computador</button>
                      <Advise>
                        Apenas arquivos do tipo PNG e JPG são permitidos.
                      </Advise>
                    </p>
                    <input {...getInputProps()} />
                  </div>
                  <div
                    className="cropper-and-slider"
                    style={{ display: displayCropper ? 'inherit' : 'none' }}
                  >
                    <Cropper
                      image={currentImageToCrop.blob}
                      aspect={horizontalSize / verticalSize}
                      showGrid={false}
                      onCropComplete={onCropComplete}
                      crop={crop}
                      onCropChange={setCrop}
                      zoom={zoom}
                      onZoomChange={setZoom}
                    />
                    <CropOptionsContainer>
                      <div>
                        <label for="zoom">Zoom</label>
                        <input
                          type="range"
                          min={1}
                          max={10}
                          step={1}
                          value={zoom}
                          onChange={(e) => setZoom(e.target.value)}
                          id="zoom"
                        />
                      </div>

                      <div>
                        <label for="horizontal-ratio">
                          Proporção horizontal
                        </label>
                        <input
                          type="range"
                          min={1}
                          max={1000}
                          step={1}
                          value={horizontalSize}
                          onChange={(e) => setHorizontalSize(e.target.value)}
                          id="horizontal-ratio"
                        />
                      </div>

                      <div>
                        <label for="vertical-ratio">Proporção vertical</label>
                        <input
                          type="range"
                          min={1}
                          max={1000}
                          step={0.1}
                          value={verticalSize}
                          onChange={(e) => setverticalSize(e.target.value)}
                          id="vertical-ratio"
                        />
                      </div>
                    </CropOptionsContainer>
                    <button
                      className="confirm-crop"
                      onClick={(event) => {
                        event.preventDefault();

                        getCroppedImage();
                      }}
                    >
                      Confirmar
                    </button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>

        <div className="contact">
          <h2>Quais formas de contato estarão disponíveis?</h2>

          <p className="contact-title">Celular/Whatsapp (com DDD)</p>
          <S.PhoneInput
            id="mobile-phone"
            className="phone-number"
            type="text"
            name="mobilePhone"
            value={phoneMask(mobilePhone)}
            onChange={(event) => {
              if (event.target.value.length > 15) return;
              setMobilePhone(phoneMask(event.target.value));
            }}
            required
          />

          <p className="contact-title">Telefone Fixo (com DDD)</p>
          <input
            id="comercial-phone"
            className="phone-number"
            type="text"
            name="commercialPhone"
            value={phoneMask(commercialPhone)}
            onChange={(event) => {
              if (event.target.value.length > 15) return;
              setCommercialPhone(phoneMask(event.target.value));
            }}
          />

          <p className="contact-title">E-mail</p>
          <input
            id="email"
            name="email"
            type="email"
            defaultValue={myAnnouncement.DtoContact.DtoEmail}
          />

          <h2>Onde está o produto?</h2>
          <input
            id="cep"
            className="cep-input"
            defaultValue={myAnnouncement.CEP}
            name="cep"
            type="number"
            placeholder="CEP"
          />

          <div className="state-city-container">
            <div className="state">
              <span>Estado</span>
              <select
                id="state"
                className="select-state"
                name="state"
                onChange={({ target }) => getCities(target.value)}
                defaultValue={myAnnouncement?.State?.Name}
              >
                {states.map((state) => (
                  <option key={state.id} value={state.sigla}>
                    {state.nome}
                  </option>
                ))}
              </select>
            </div>

            <div className="city">
              <span>Cidade</span>
              <select id="city" className="select-city" name="city">
                {cities.map((city) => (
                  <option
                    selected={myAnnouncement.DtoCity.id === city.id}
                    key={city.id}
                    value={city.id}
                  >
                    {city.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="save-all-changes">
          <h2>Finalizar alterações</h2>
          <button
            className="save-changes-button"
            onClick={async (event) => {
              event.preventDefault();

              await updateAnnouncement();
            }}
          >
            Salvar
          </button>
          <button
            className="discard-changes-button"
            onClick={(event) => {
              event.preventDefault();

              history.push('/MyAnnouncements');
            }}
          >
            Descartar
          </button>
        </div>
        <div id="resize-canvas" style={{ display: 'none' }}></div>
      </form>
    </Container>
  );
};

export default EditMyAnnouncement;
