import styled from 'styled-components';

export const Container = styled.div`
  width: clamp(220px, 100%, 425px);

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Input = styled.input`
  border: 0;
  border-bottom: ${({ hasError }) => hasError ? '1px solid #b41212' : '1px solid gray'};

  width: clamp(220px, 100%, 425px);
  height: 40px;

  background-color: transparent;

  font-size: clamp(18px, 6vw, 22px);

  color: ${({ hasError }) => hasError ? '#b41212' : 'black'};

  ::placeholder {
    color: ${({ hasError }) => hasError ? '#b41212' : 'gray'};
  }
`;

export const Span = styled.span`
  font-size: 14px;

  opacity: 0.5;
`;

export const Error = styled.div`
  opacity: ${({ hasError }) => hasError ? '1' : '0'};

  color: #b41212;

  text-align: justify;
  font-size: 14px;

  box-sizing: border-box;

  border-radius: 10px;
`;
