import styled, { css } from 'styled-components';

export const AnimationContainer = styled.div`
  transition: 1s ease;

  opacity: 0;

  width: 100%;

  transform: translateX(-50%);
`;

export const Field = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }

  width: 100%;
  height: auto;

  border-radius: 12px;

  margin: 3em auto;
  padding: 12px 50px 0px 50px;
  transition: 0.3s;

  background: white;

  box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);

  @media screen and (max-width: 960px) {
    padding: 20px !important;
  }

  ${(props) => {
    const propErrors = props?.propErrors;
    const phoneErrors = propErrors?.DtoPhones?.at(0);
    const emailErrors = propErrors?.DtoEmail;
    if (props?.propScroll) {
      if (propErrors?.cityId)
        document.getElementsByName("cidade")[0].scrollIntoView({ block: "center", behavior: "smooth" });
      else if (propErrors?.state)
        document.getElementsByName("estado")[0].scrollIntoView({ block: "center", behavior: "smooth" });
      else if (emailErrors?.Address)
        document.getElementsByName("email")[0].scrollIntoView({ block: "center", behavior: "smooth" });
      else if (phoneErrors?.Number)
        document.getElementsByName("celular")[0].scrollIntoView({ block: "center", behavior: "smooth" });
      else if (phoneErrors?.DDD)
        document.getElementsByName("celular")[0].scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }}

  ${(props) => {
    if (props.propErrors?.cityId) {
      return css`
        div[name='cidade']{
          color: #b41212;
            border-color: #b41212;
        }
        select[name='cidade'] {
          color: #b41212;
            &::placeholder {
              color: #b41212;
            }
        }
      `;
      }
    }
  }

  ${(props) => {
    if (props.propErrors?.state) {
      return css`
        div[name='estado']{
          color: #b41212;
            border-color: #b41212;
        }
          select[name='estado'] {
            color: #b41212;
            &::placeholder {
              color: #b41212;
            }
          }
        `;
      }
    }
  }

  ${(props) => {
    if (props?.propErrors?.DtoEmail?.Address) {
        return css`
          input[name='email'] {
            color: #b41212;
            border-color: #b41212;
            &::placeholder {
              color: #b41212;
            }
          }
        `;
      }
    }
  }

  ${(props) => {
    const phoneErrors = props?.propErrors?.DtoPhones?.at(0);
    if (phoneErrors?.DDD || phoneErrors?.Number) {
      return css`
          input[name='celular'] {
            color: #b41212;
            border-color: #b41212;
            &::placeholder {
              color: #b41212;
            }
          }
        `;
      }
    }
  }

  h2 {
    font-size: 30px;
    margin: 0.5em 0 0.7em 0;
    font-weight: 600;
  }

  input {
    width: 100%;

    border-style: solid;
    border-color: rgb(125, 125, 125, 0.3);
    border-width: 0 0 1px 0;

    overflow: hidden;

    box-sizing: border-box;

    margin: 0 0 20px 0;

    font-size: 22px;
  }

  .selectField {
    width: 100%;
    height: auto;
    margin: 1em 0 0 0;

    transition: 0.1s ease;

    .loading-cep {
      width: 50px;
      height: 50px;

      .spinner {
        &::after {
          width: 24px;
          height: 24px;
        }
      }

      h1 {
        font-size: 16px;
      }
    }


    select {
      padding: 20px 0 0 2px;

      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    select::-ms-expand {
        display: none;
    }
  }

  .inputTitle {
    width: 100%;
    max-width: 560px;

    p {
      height: 50px;
      display: flex;
      align-items: center;
      align-content: flex-start;

      span {
        width: 100%;

        font-size: 18px;
        font-weight: 600;

        color: #000000;

        margin-left: 10px;
      }

      svg {
        position: relative;

        transition: 0.7s ease;

        width: 24px;
        height: 24px;

        fill: #cacaca;
      }
    }
  }

  .selectSection {
    width: 100%;
    max-width: 420px;
    height: 60px;

    position: relative;

    display: block;

    cursor: pointer;

    span {
      position: absolute;

      font-size: 16px;
      font-weight: 600;

      top: 3px;
      left: 5px;

      pointer-events: none;
    }

    select {
      border: none;

      text-align: left;

      width: 100%;
      height: 100%;

      font-size: 23px;
      font-weight: 400;

      transition: 0.3s;

      cursor: pointer;
    }
  }

  #loading-state-city-message {
    margin-bottom: 15px;

    font-size: 18px;

    color: #0e1eff;

    opacity: 0.7;

    display: none;
  }

  .selectSection-one {
    width: 100% !important;
    max-width: 100px !important;
    margin: 0 20px 20px 0;
    display: inline-block !important;
  }

  .selectSection-two {
    width: 100% !important;
    max-width: 300px !important;
    display: inline-block !important;
  }
`;

export const FieldTwo = styled.div`
  p {
    width: 170px;
    height: 50px;
    display: flex;
    align-items: center;
    align-content: flex-start;
    span {
      width: 100%;

      font-size: 18px;
      color: gray;
      margin-left: 10px;
    }

    svg {
      transition: 0.3s ease;

      width: 25px;
      height: 25px;

      fill: #36bcc1;
    }
  }

  input {
    max-width: 50%;
    display: block;
  }
`;

export const Container = styled.div`
  position: relative;
  height: auto;
  padding: 20px 0 50px 0;
`;


export const NextButton = styled.button`
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.8;
  `}
`;
