import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom';

import * as S from './styles';

import api from 'services/api';

import { toastError, toastInfo, toastSuccess } from 'utils/defaultToasts';

import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { AiFillStar, AiFillDislike, AiOutlineFileDone } from 'react-icons/ai';
import { GrImage } from 'react-icons/gr';
import { TiCancel } from 'react-icons/ti';

import SellerInfoInNegotiations from '../SellerInfoInNegotiations';

const Product = ({ data, negotiationId, asSeller, getMyNegotiations, setCurrentPage }) => {
  const history = useHistory();

  const sellerInfoRef = useRef(null);

  const [showImage, setShowImage] = useState(true);

  const canceled = data.Status.Name === 'Cancelled';
  const successful = data.Status.Name === 'Successful';
  const onGoing = data.Status.Name === 'Ongoing';

  const noBuyerReview = data?.BuyerReview === null;
  const noSellerReview = data?.SellerReview === null;
  const noBuyerReviewAsBuyerOrNoSellerReviewAsSeller = (noBuyerReview && !asSeller) || (noSellerReview && asSeller);

  const [toggleRefuse, setToggleRefuse] = useState(false);
  const [reason, setReason] = useState('');

  const handleShowSellerInfo = () => {
    if (!sellerInfoRef.current) return;

    sellerInfoRef.current.style.opacity = '1';
    sellerInfoRef.current.style.pointerEvents = 'all';
  }

  const patchCloseNegotiation = async () => {
    const patchCloseNegotiationUrl = `/api/v1/Negotiations/${negotiationId}/Close`;

    try {
      await api.patch(patchCloseNegotiationUrl);

      setCurrentPage(1);

      getMyNegotiations();

      toastSuccess('Negociação concluída com sucesso.')
    } catch (error) {
      toastError('Erro ao concluir sua negociação.');
    }
  }

  const patchCancelNegotiation = async () => {
    const patchCancelNegotiationUrl = `/api/v1/Negotiations/${negotiationId}/Cancel`;

    try {
      await api.patch(patchCancelNegotiationUrl);

      setCurrentPage(1);

      getMyNegotiations();

      toastInfo('Negociação cancelada.');
    } catch (error) {
      toastError('Erro ao cancelar sua negociação.');
    }
  }

  const patchRefuseNegotiation = async (reason) => {
    const patchRefuseNegotiationUrl = `/api/v1/Negotiations/${negotiationId}/Refuse`;

    try {
      await api.patch(patchRefuseNegotiationUrl, { reason });

      toastInfo('Você recusou esta negociação.');
    } catch (error) {
      toastError('Erro ao recusar esta negociação.');
    }
  }

  const handleToggleRefuse = () => {
    setToggleRefuse(prevState => !prevState);
  }

  const handleChangeReason = (event) => {
    setReason(event.target.value)
  }

  const handleConfirmRefuse = async () => {
    await patchRefuseNegotiation(reason);
    setToggleRefuse(false);

    setCurrentPage(1);

    getMyNegotiations();

    toastInfo('Você recusou essa negociação.');
  }

  const handleCancelRefuse = async () => {
    setToggleRefuse(false);
    setReason('');
  }

  return (
    <S.Container>
      <SellerInfoInNegotiations data={data} negotiationRef={sellerInfoRef} id={negotiationId} asSeller={asSeller} />

      <S.PhotoContainer>
        {
          showImage ?
          <S.Photo src={process.env.REACT_APP_S3URL + data?.DtoAnnouncement?.DtoPicture?.Key} onError={() => setShowImage(false)}/>
          : <GrImage size={200} style={{opacity: 0.3}} />
        }
      </S.PhotoContainer>

      <S.Wrapper>
        <S.Title>{data?.DtoAnnouncement?.Title}</S.Title>
        <S.Details href={'#/Anuncio/' + data?.DtoAnnouncement?.Id}>
          Detalhes do anúncio
        </S.Details>

        <S.Price>R$ {data?.DtoAnnouncement?.Price}</S.Price>
        <S.Status>Status: {data?.Status?.Description}</S.Status>

        <S.GetInTouch>
          <S.GetInTouchWrapper>
            <BsFillExclamationCircleFill />
            <S.Text>
              Entre em contato com o {asSeller ? 'comprador' : 'vendedor'}.
            </S.Text>
          </S.GetInTouchWrapper>

          <S.ShowInfo onClick={handleShowSellerInfo}>
            Exibir Dados
          </S.ShowInfo>
        </S.GetInTouch>

        <S.Buttons>
          {successful && noBuyerReviewAsBuyerOrNoSellerReviewAsSeller && (
            <div style={{ position: 'relative' }}>
              <S.Button onClick={() => {
                history.push(`/RateNegotiation/${negotiationId}/AsBuyer`);
              }}>
                <AiFillStar />
                <S.Text>Avaliar {asSeller ? 'Comprador' : 'Vendedor'}</S.Text>
              </S.Button>
            </div>
          )}

          {onGoing && (
            <S.Button onClick={patchCancelNegotiation}>
              <TiCancel style={{ width: '24px', height: '24px' }} />
              <S.Text>Cancelar</S.Text>
            </S.Button>
          )}

          {asSeller && !canceled && !successful && (
            <>
              <div style={{ position: 'relative' }}>
                <S.Button onClick={handleToggleRefuse}>
                  <AiFillDislike />
                  <S.Text>Recusar proposta</S.Text>
                </S.Button>
                <S.RefuseReason isToggled={toggleRefuse}>
                  <textarea
                    placeholder="Caso deseje, digite o motivo de estar recusando esta proposta."
                    cols="30"
                    rows="10"
                    onChange={handleChangeReason}
                    value={reason}
                  >
                  </textarea>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around',
                      alignItems: 'center'
                    }}
                  >
                    <S.TextAreaButtons onClick={handleCancelRefuse}>Cancelar</S.TextAreaButtons>
                    <S.TextAreaButtons onClick={handleConfirmRefuse}>Confirmar</S.TextAreaButtons>
                  </div>
                </S.RefuseReason>
              </div>

              {!successful && (
                <S.Button onClick={patchCloseNegotiation}>
                  <AiOutlineFileDone />
                  <S.Text>Concluir</S.Text>
                </S.Button>
              )}
            </>
          )}
        </S.Buttons>
      </S.Wrapper>
    </S.Container>
  );
};

export default Product
