import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AiOutlineShoppingCart } from 'react-icons/ai';
import * as S from './styles';

const Start = ({ setPage }) => {
  const animationContainerRef = useRef(null);

  const userName = useSelector(
    (state) => state.authReducer.auth.profile.NickName
  );

  const showContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '1';
    animationContainerRef.current.style.left = '0';
  };

  const hideContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '0';
    animationContainerRef.current.style.left = '100%';
  };

  useEffect(() => {
    showContainerAnimation();
  }, []);

  return (
    <S.AnimationContainer ref={animationContainerRef}>
      <S.Container>
        <h1>
          Bem vindo, <strong>{userName?.split(' ')[0] + ' ' + userName?.split(' ')[1]}</strong>. <br />
          <span>Aqui você poderá anunciar seus produtos.</span>
        </h1>
        <AiOutlineShoppingCart id="announcement-icon" />
        <button
          type="button"
          onClick={() => {
            hideContainerAnimation();

            setTimeout(() => {
              setPage('Information');
            }, 500);
          }}
        >
          Iniciar
        </button>
      </S.Container>
    </S.AnimationContainer>
  );
};

export default Start;
