import styled from 'styled-components';

export const AnimationContainer = styled.div`
  position: absolute;

  transition: 1s ease;

  opacity: 0;

  width: 100%;

  left: -50%;
`;

export const Container = styled.div`
  position: relative;

  max-width: 800px;
  width: 100%;

  margin: 0 auto;
  padding: 20px 0 50px 0;
`;

export const Field = styled.div`
  width: auto;

  margin: 3em auto;
  padding: 50px;

  border-radius: 12px;

  transition: 0.3s;

  background: white;

  box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);

  h2 {
    font-size: 30px;
    font-weight: 600;
  }

  #dollar-sign-and-input {
    margin: 1em 0;

    display: flex;
    align-items: center;

    svg {
      width: 34px;
      height: 34px;

      fill: gray;

      margin-right: 10px;

      transition: 0.7s ease;
    }

    h3 {
      font-size: 22px;
      font-weight: 500;

      padding-right: 6px;

      border-style: solid;
      border-color: rgb(125, 125, 125, 0.3);
      border-width: 0 0 1px 0;
    }

    input {
      width: 120px;
      max-width: 50%;

      border-style: solid;
      border-color: rgb(125, 125, 125, 0.3);
      border-width: 0 0 1px 0;

      overflow: hidden;

      box-sizing: border-box;

      font-size: 22px;
    }

    @media screen and (max-width: 960px) {
      font-size: 22px !important;
    }
  }

  #example-div {
    font-size: 16px;
  }

  p {
    font-size: 12px;
    color: gray;
  }
`;
