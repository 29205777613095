import React from 'react';

import { Container } from './styles';

export default function Products() {
  return (
    <Container>
      <h5>Produtos</h5>

      <ul>
        <li>
          <span>Mountain Bike Sense One - 2020
</span>

          <span>R$ 3.799,00</span>

          <p>Qtd.:1 Valor Unit.: R$ 3.799,00</p>
        </li>

      </ul>



    </Container>
  );
}
