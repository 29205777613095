import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;

  padding: 5rem 10rem;
  margin: 5rem auto;

  border-radius: 10px;
  background: white;

  position: relative;

  @media (max-width: 900px) {
    padding: 5rem;
  }
`;

export const Stack = styled.div`
  display: flex;
`;

export const GeneralForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

export const ButtonsDiv = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 1.5rem;

    button {
      width: 100%;
    }
  }
`;

export const ModalText = styled.p`
  font-size: 25px;
  font-weight: 500;
  color: #000000;

  text-align: center;

  max-width: 240px;

  line-height: 95%;

`

export const ModalButtonsDiv = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  button {
    text-transform: initial;

    font-size: 20px;
    font-weight: 700;

    border-radius: 12px;

    width: 80%;
  }

  `

