import React, { useCallback, useEffect, useState, useRef } from 'react';

import { RiCloseCircleFill } from 'react-icons/ri';

import { AiOutlinePhone } from 'react-icons/ai';
import { ImWhatsapp } from 'react-icons/im';

import LeadTrust from './components/LeadTrustRanking';

import * as S from './styles';
import api from '~/services/api';
import { toastError } from '~/utils/defaultToasts';
import { FaUserCircle } from 'react-icons/fa';

export default function SellerInfoInNegotiations({ id, negotiationRef, asSeller, data = null }) {
  const [infoData, setInfoData] = useState([]);
  const [showImage, setShowImage] = useState(true);


  const getColor = (health) => {
    if (health === 100) return '#36e266';
    if (health >= 80) return '#a7ec66';
    if (health >= 60) return '#ffdd33';
    if (health >= 40) return '#ff8523';
    if (health >= 20) return '#ff2327';
  }

  const getExpression = (health) => {
    if (health === 100) return require('~assets/very-happy-emoji.png');
    if (health >= 80) return require('~assets/happy-emoji.png');
    if (health >= 60) return require('~assets/regular-emoji.png');
    if (health >= 40) return require('~assets/bad-emoji.png');
    if (health >= 20) return require('~assets/very-bad-emoji.png');
  }

  const handleDimissSelerInfo = () => {
    if (!negotiationRef.current) return;

    negotiationRef.current.style.opacity = '0';
    negotiationRef.current.style.pointerEvents = 'none';
  }

  const getInfo = useCallback(async () => {
    const getSellerInfoUrl = `/api/v1/Negotiations/${id}/Seller`;
    const getBuyerInfoUrl = `/api/v1/Negotiations/${id}/Buyer`;

    try {
      setInfoData([]);

      if (asSeller) {
        const response = await api.get(getSellerInfoUrl);
        const { data } = response;

        setInfoData(data);
      } else {
        const response = await api.get(getBuyerInfoUrl);
        const { data } = response;

        setInfoData(data);
      }
    } catch (error) {
      if (error.response.status === 400) return;

      toastError('Erro ao carregar dados.');
    }
  })

  const getRegexPhoneNumber = useCallback((phone) => {
    return phone?.replace(/^(\d{2})(\d)/g, '($1) - $2')
      ?.replace(/(\d)(\d{4})$/, '$1-$2');
  }, []);

  const getWhatsappLink = useCallback((phone) => {
    return 'https://web.whatsapp.com/send?phone=' + phone;
  }, []);

  useEffect(() => {
    getInfo();
  }, [asSeller]);

  return (
    <S.Container ref={negotiationRef}>
      <S.FirstHalf>
        <S.Title id="first-title">Dados do {asSeller ? 'Comprador' : 'Vendedor'}</S.Title>

        <S.FlexWrapper>
          {showImage ?
            <S.ProfilePhoto src={asSeller
              ? infoData?.DtoBuyer?.DtoProfile?.DtoAvatar?.ThumbnailUrl
              : infoData?.DtoSeller?.DtoProfile?.DtoAvatar?.ThumbnailUrl}
              onError={() => setShowImage(false)}
            /> :
            <FaUserCircle color={'#213455'} size={93} />
          }
          <S.ProfileName>
            {asSeller
              ? infoData?.DtoBuyer?.DtoProfile?.NickName
              : infoData?.DtoSeller?.DtoProfile?.NickName}
          </S.ProfileName>
        </S.FlexWrapper>

        <S.PhonesWrapper>
          <S.MobilePhone>
            {!asSeller && <ImWhatsapp />}
            <S.PhoneNumber
              href={getWhatsappLink(infoData?.MobileNumber) && !asSeller}
              target="_blank"
            >
              {getRegexPhoneNumber(asSeller
                ? infoData?.DtoBuyer?.EmailAddress
                : infoData?.DtoAnnouncement?.DtoContact?.DtoMobilePhone)}
            </S.PhoneNumber>
          </S.MobilePhone>
        </S.PhonesWrapper>

        <S.GetInTouch>
          Entre em contato com o {asSeller ? 'comprador' : 'vendedor'} preferencialmente por {asSeller ? 'e-mail' : 'Whatsapp'}
        </S.GetInTouch>
      </S.FirstHalf>

      <S.SecondHalf>
        <S.CloseButton onClick={handleDimissSelerInfo}>
          <RiCloseCircleFill />
        </S.CloseButton>

        <S.Title>Avaliação nbikes</S.Title>

        <LeadTrust score={asSeller ? infoData?.BuyerScore?.AsBuyer : infoData?.SellerScore?.AsSeller} />

        {!data.BuyerReview ? <S.WaitingReview>Esperando sua avaliação</S.WaitingReview> :
          <S.BuyersReviewContainer>

            <S.BuyersReviewTitle>Sua Avaliação:</S.BuyersReviewTitle>


            <S.ReviewContainer color={getColor(data.BuyerReview?.Health)}>
              <S.Face>
                <img src={getExpression(data.BuyerReview?.Health)} />
              </S.Face>

              <S.Comment>"{data.BuyerReview?.Notes}"</S.Comment>
            </S.ReviewContainer>

          </S.BuyersReviewContainer>
        }
      </S.SecondHalf>
    </S.Container>
  );
}
