import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FaCheckCircle } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';
import { signUpConfirmation, resendCode } from '~/store/ducks/auth/actions';
import { store } from '~/store';

import { Field, MainContainer, BaseContainer, GoBackButton } from './styles';


export default function Confirmation({ location }) {
  const dispatch = useDispatch();
  const ConfRef = useRef(null);

  const history = useHistory();

  const handleSubmit = useCallback(() => {
    const form = ConfRef.current;
    const params = new URLSearchParams(location.search);
    const username =
      store.getState().authReducer.auth.email || params.get('email');
    dispatch(
      signUpConfirmation([
        form.code.value,
        username,
        store.getState().authReducer.auth.id,
      ])
    );

  }, [dispatch]);

  const resendConfirmationCode = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const username =
      store.getState().authReducer.auth.email || params.get('email');
    dispatch(resendCode([username, store.getState().authReducer.auth.id]));
  }, [dispatch]);

  return (
    <MainContainer>
      <BaseContainer ref={ConfRef}>
        <GoBackButton onClick={() => history.push('/Register')}>
          <FiChevronLeft size={10} />
          <p>voltar</p>
        </GoBackButton>
        <Field>
          <FaCheckCircle />

          <h4> Acesse seu e-mail e confirme seu cadastro</h4>

          <input name="code" />
          <span>Digite o código recebido </span>

          <button
            type="button"
            className="resendButton"
            onClick={() => resendConfirmationCode()}
          >
            Reenviar código
          </button>
        </Field>

        <button
          className="nextStep"
          type="button"
          onClick={() => {
            handleSubmit();
          }}
        >
          {' '}
          Confirmar
        </button>
      </BaseContainer>
    </MainContainer>
  );
}
