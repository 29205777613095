import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const Select = styled.select`
  width: clamp(70px, 20vw, 135px);
  height: 40px;

  text-align-last: center;

  font-size: clamp(18px, 6vw, 22px);

  border-radius: 10px;
`;

export const Option = styled.option``;


export const Span = styled.span`
  font-size: 14px;

  opacity: 0.7;
`;

export const Error = styled.div`
  opacity: ${({ hasError }) => hasError ? '1' : '0'};

  color: #b41212;

  text-align: justify;
  font-size: 14px;

  box-sizing: border-box;

  border-radius: 10px;
`;
