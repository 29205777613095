import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  Fragment,
} from 'react';
import { useSelector } from 'react-redux';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import md5 from 'md5';
import Helmet from 'react-helmet';
import { v4 } from 'uuid';
import { toastError, toastSuccess, toastInfo } from 'utils/defaultToasts';
import api from '~/services/api';
import ProfilePicture from './components/PerfilPicture';
import LeadTrust from '~/components/LeadTrust';
import * as S from './styles';

import vela from '~/assets/vela.png';
import { Coupon } from '~/features/Coupon';
import { Divider, Paper } from '@mui/material';
import { RiCoupon3Line } from 'react-icons/ri';

const phoneMask = (v) => {
  if (!v) return '';

  v = v?.replace(/\D/g, ''); //Remove tudo o que não é dígito
  v = v?.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v?.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
};

export default function EditProfile() {
  const getHasNumberOrSpecial = useCallback((string) => {
    const number = /\d/g;
    const specialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    const hasNumberOrSpecial =
      specialCharacter.test(string) || number.test(string);

    return hasNumberOrSpecial;
  }, []);

  const profile = useSelector((state) => state.authReducer.auth.profile);

  const privilegeType = useSelector(
    (state) => state.authReducer.auth.privilegeType
  );

  const isAdmin = useMemo(() => {
    return privilegeType === 'Administrator';
  }, [privilegeType]);

  const profileContainerRef = useRef(null);
  const changePasswordButtonRef = useRef(null);
  const secretCodeContainerRef = useRef(null);
  const nameErrorRef = useRef(null);

  const [toggleProfile, setToggleProfile] = useState(true);
  const [toggleRegister, setToggleRegister] = useState(false);
  const [toggleCoupon, setToggleCoupon] = useState(false);

  const [toggleChangePassword, setToggleChangePassword] = useState(false);

  const [enableSaveProfileButton, setEnableSaveProfileButton] = useState(true);

  const [nickName, setNickName] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  //const [secretCode, setSecretCode] = useState('');

  const [scores, setScores] = useState({});
  const [mobilePhone, setMobilePhone] = useState('');
  const [commercialPhone, setCommercialPhone] = useState('');
  const [documentNumber, setDocumentNumber] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [isEnabled, setIsEnabled] = useState(null);

  const [coupons, setCoupons] = useState([]);

  const formatCpf = (cpf) => {
    return cpf.replaceAll('.', '').replaceAll('-', '');
  };

  const formatDateFromApi = (date) => {
    const dateSplited = date.split('T')[0].split('-');
    return `${dateSplited[0]}-${dateSplited[1]}-${dateSplited[2]}`;
  };

  const formatDateInput = (date) => {
    const formattedDate = new Date(date).toLocaleDateString('pt-BR');

    return formattedDate;
  };

  const getUserData = async () => {
    const getUserDataUrl = `/api/v1/Profiles/${profile.Id}`;
    try {
      const response = await api.get(getUserDataUrl);
      const { data } = response;

      setNickName(data.NickName);
      setMobilePhone(data.Cellphone);
      setScores(data.DtoScore);
      setName(data.FirstName);
      setLastName(data.LastName);
      setEmail(data.Username);
      setDocumentNumber(formatCpf(data.DocumentNumber));
      setBirthDate(formatDateFromApi(data.BirthDate));
      setCreatedAt(data.CreatedAt);
      setIsEnabled(data.IsEnabled);

      if (data.Telephone === '(99) 99999-9999') {
        setCommercialPhone('');
      } else {
        setCommercialPhone(phoneMask(data.Telephone));
      }
    } catch (error) {
      console.log(error.response);
      toastError('Ocorreu um erro ao buscar as informações do seu perfil.');
    }
  };

  const getUserCupons = async () => {
    const getUserCuponsUrl = `/api/v1/Vouchers/${profile.Id}`;

    try {
      const response = await api.get(getUserCuponsUrl);
      const { data } = response;

      setCoupons(data);
    } catch (error) {
      console.log(error.response);
      toastError('Ocorreu um erro ao buscar as informações dos seus cupons.');
    }
  };

  useEffect(() => {
    getUserData();
    getUserCupons();
  }, []);

  const putProfileInfo = async () => {
    const putProfileInfoUrl = `/api/v1/Profiles/${profile.Id}/ProfileInfo`;

    const body = {
      id: profile.Id,
      key: md5(profile.Id),
      createdAt,
      updateAt: new Date().toISOString(),
      isEnabled,
      nickName,
      email,
      cellphone: mobilePhone,
      telephone: commercialPhone,
    };

    try {
      await api.put(putProfileInfoUrl, body);

      toastSuccess('Informações do perfil alteradas com sucesso!');
    } catch (error) {
      console.log(error.response);
      toastError('Ocorreu um erro ao alterar as informações do seu perfil.');
    }
  };

  const showSecretCodeField = () => {
    changePasswordButtonRef.current.style.height = '0px';
    //secretCodeContainerRef.current.style.height = '30px';
  };

  const patchSecretCode = async () => {
    const patchSecretCodeUrl = '/api/v1/Auth/ChangePassword';

    const body = {
      username: profile.Username,
      ipAddress: '192.58.56.181',
    };

    try {
      await api.patch(patchSecretCodeUrl, body);

      toastInfo('Um código de verificação foi enviado ao seu e-mail.');

      showSecretCodeField();
    } catch (error) {
      toastError('Ocorreu um erro ao alterar sua senha.');
    }
  };

  const changePassword = async () => {
    const patchPasswordUrl = '/api/v1/Auth/NewPassword';

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      toastError('Senha inválida.');
      return;
    }

    const body = {
      username: profile.Username,
      currentPassword: md5(currentPassword),
      passwordHash: md5(newPassword),
      confirmPasswordHash: md5(confirmNewPassword),
      withToken: false,
      ipAddress: '192.58.56.181',
    };

    try {
      await api.patch(patchPasswordUrl, body);

      toastSuccess('Senha alterada com sucesso!');
    } catch (error) {
      toastError('Erro ao alterar a senha.');
    }

    setToggleChangePassword(false);

    changePasswordButtonRef.current.style.height = '30px';
  };

  const formatToCPF = (cpf) => {
    const formattedCpf = cpf.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      function (regex, group1, group2, group3, group4) {
        return group1 + '.' + group2 + '.' + group3 + '-' + group4;
      }
    );

    return formattedCpf;
  };

  const putRegistrationInfo = async () => {
    const putRegistrationInfoUrl = `/api/v1/Profiles/${profile.Id}/RegistrationInfo`;

    const body = {
      name,
      lastName,
      documentNumber: formatToCPF(documentNumber),
      birthDate: new Date(birthDate).toISOString(),
    };

    try {
      await api.put(putRegistrationInfoUrl, body);

      toastSuccess('Dados de cadastro alterados com sucesso!');
    } catch (error) {
      toastError('Erro ao alterar seus dados de cadastro.');
    }
  };

  const userEmail = useSelector((state) => state.authReducer.auth.email);

  return (
    <S.MainContainer>
      <Helmet>
        <meta
          property="og:title"
          content="n bikes | Uma mão na roda! | Brasil"
        />
        <meta
          property="og:image"
          content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
        />
        <meta
          property="og:description"
          content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
        />
        <title>Seu Perfil | n Bikes | Brasil</title>
      </Helmet>

      <S.PageTitle>Dados Pessoais</S.PageTitle>

      <S.ToggleProfile
        onClick={() => {
          setToggleProfile((prevToggle) => !prevToggle);
          setToggleRegister(false);
        }}
        style={
          toggleProfile
            ? { borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }
            : {
                borderBottomRightRadius: '10px',
                borderBottomLeftRadius: '10px',
              }
        }
      >
        Perfil
        {toggleProfile ? (
          <FaAngleDown className="arrow" />
        ) : (
          <FaAngleUp className="arrow" />
        )}
      </S.ToggleProfile>
      <S.ProfileContainer
        ref={profileContainerRef}
        style={
          !toggleProfile ? { height: '0px', padding: '0', border: '0px' } : null
        }
      >
        <ProfilePicture />
        <S.FieldContainer>
          <S.NameContainer>
            <div id="name">
              <S.NickName
                type="text"
                value={nickName}
                onChange={({ target }) => {
                  const lessThan30 = target.value.length > 30;
                  const isEmpty = !target.value.length;
                  const hasNumberOrSpecials = getHasNumberOrSpecial(
                    target.value
                  );

                  setNickName(target.value);

                  if (lessThan30 || isEmpty || hasNumberOrSpecials) {
                    nameErrorRef.current.style.display = 'inherit';
                    setEnableSaveProfileButton(false);
                    return;
                  }

                  nameErrorRef.current.style.display = 'none';
                  setEnableSaveProfileButton(true);
                }}
              />
              <S.FieldName>Nome de Exibição</S.FieldName>
            </div>
            <span id="span-tag">{profile.Tag}</span>
          </S.NameContainer>
          <div ref={nameErrorRef} id="name-error">
            O nome de exibição não pode conter números nem caracteres especiais
            e deve ser menor que 30 caracteres.
          </div>
        </S.FieldContainer>

        <S.FieldContainer>
          <S.Email>{email}</S.Email>
          <S.FieldName>E-mail</S.FieldName>
        </S.FieldContainer>

        <S.FlexWrapper>
          <S.FieldContainer style={{ marginTop: '10px' }}>
            <S.InputStyle
              placeholder="(99) 99999-9999"
              type="text"
              value={phoneMask(mobilePhone)}
              onChange={(event) => {
                if (event.target.value.length > 15) return;

                const saveProfileButton = document.querySelector(
                  '#save-profile-button'
                );
                const mobileError = document.querySelector('#mobile-error');

                if (event.target.value.length <= 14) {
                  saveProfileButton.disabled = true;
                  mobileError.style.opacity = '1';

                  setMobilePhone(event.target.value);

                  return;
                }

                setMobilePhone(event.target.value);

                mobileError.style.opacity = '0';
                saveProfileButton.disabled = false;
              }}
            />
            <S.FieldName>Celular</S.FieldName>
            <S.MobileError
              id="mobile-error"
              style={{ opacity: '0', transition: '0.3s ease' }}
            >
              Preencha corretamente o número de celular.
            </S.MobileError>
          </S.FieldContainer>
        </S.FlexWrapper>

        <S.FlexWrapper>
          <S.FieldContainer style={{ marginTop: '10px' }}>
            <S.InputStyle
              placeholder="(99) 99999-9999"
              type="text"
              value={phoneMask(commercialPhone)}
              onChange={(event) => {
                const length = event.target.value.length;

                if (length > 15) return;

                const saveProfileButton = document.querySelector(
                  '#save-profile-button'
                );
                const commercialError =
                  document.querySelector('#commercial-error');

                if (length > 0 && length < 14) {
                  saveProfileButton.disabled = true;
                  commercialError.style.opacity = '1';

                  setCommercialPhone(event.target.value);

                  return;
                }

                setCommercialPhone(event.target.value);
                commercialError.style.opacity = '0';
                saveProfileButton.disabled = false;
              }}
            />
            <S.FieldName>Tel. Fixo</S.FieldName>
            <S.CommercialError
              id="commercial-error"
              style={{ opacity: '0', transition: '0.3s ease' }}
            >
              Preencha corretamente o número de telefone fixo.
            </S.CommercialError>
          </S.FieldContainer>
        </S.FlexWrapper>

        <S.FieldContainer>
          <S.PasswordContainer>
            <span
              id="change-password-span"
              onClick={() => {
                setToggleChangePassword((prevTogle) => !prevTogle);

                if (!toggleChangePassword) {
                  changePasswordButtonRef.current.style.height = '30px';
                }

                const isInMediaQuery = window.matchMedia(
                  'screen and (max-width: 650px)'
                );

                if (isInMediaQuery && !toggleChangePassword) {
                  profileContainerRef.current.style.height = '1100px';
                } else if (!isInMediaQuery) {
                  profileContainerRef.current.style.height = '950px';
                } else {
                  profileContainerRef.current.style.height = '1000px';
                }

                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
              }}
            >
              {toggleChangePassword ? 'Cancelar' : 'Alterar senha'}
            </span>
          </S.PasswordContainer>

          <S.ChangePasswordContainer
            style={!toggleChangePassword ? { height: '0px' } : null}
          >
            <S.FieldContainer>
              <S.FieldName>Senha atual</S.FieldName>
              <S.NewPassword
                type="password"
                value={currentPassword}
                placeholder="••••••••"
                onChange={({ target }) => {
                  setCurrentPassword(target.value);
                }}
              />
            </S.FieldContainer>

            <S.FieldContainer>
              <S.FieldName>Nova senha</S.FieldName>
              <S.NewPassword
                type="password"
                value={newPassword}
                placeholder="••••••••"
                onChange={({ target }) => {
                  setNewPassword(target.value);
                }}
              />
            </S.FieldContainer>

            <S.FieldContainer>
              <S.FieldName>Confirmar nova senha</S.FieldName>
              <S.ConfirmNewPassword
                type="password"
                value={confirmNewPassword}
                placeholder="••••••••"
                onChange={({ target }) => {
                  setConfirmNewPassword(target.value);
                }}
              />
            </S.FieldContainer>

            <S.ChangePasswordButton
              type="button"
              ref={changePasswordButtonRef}
              onClick={async () => {
                changePassword();
              }}
            >
              Enviar alteração
            </S.ChangePasswordButton>

            {/* <S.SecretCodeContainer ref={secretCodeContainerRef}>
              <S.SecretCodeField
                onChange={({ target }) => {
                  setSecretCode(target.value);
                }}
                type="text"
                placeholder="Código de verificação"
              />
              <S.SecretCodeConfirmButton
                type="button"
                onClick={() => {
                  changePassword();
                }}
              >
                Confirmar
              </S.SecretCodeConfirmButton>
            </S.SecretCodeContainer> */}
          </S.ChangePasswordContainer>
        </S.FieldContainer>

        <S.LeadTrustContainer>
          <S.FieldName>Avaliação</S.FieldName>
          <LeadTrust
            buyerScore={scores.AsBuyer}
            sellerScore={scores.AsSeller}
          />
        </S.LeadTrustContainer>

        <S.SaveProfileButton
          id="save-profile-button"
          onClick={async () => {
            await putProfileInfo();
          }}
          type="button"
          disabled={!enableSaveProfileButton}
        >
          Salvar
        </S.SaveProfileButton>
      </S.ProfileContainer>

      <S.ToggleRegister
        onClick={() => {
          setToggleRegister((prevToggle) => !prevToggle);
          setToggleProfile(false);
        }}
        style={
          toggleRegister
            ? { borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }
            : {
                borderBottomRightRadius: '10px',
                borderBottomLeftRadius: '10px',
              }
        }
      >
        Cadastro
        {toggleRegister ? (
          <FaAngleDown className="arrow" />
        ) : (
          <FaAngleUp className="arrow" />
        )}
      </S.ToggleRegister>
      <S.RegisterContainer
        style={
          toggleRegister
            ? { height: '400px' }
            : { height: '0px', padding: '0', border: '0px' }
        }
      >
        <div id="register-section-1">
          <S.FieldContainer>
            <S.Name
              className="register register-name"
              type="text"
              defaultValue={name}
              onChange={({ target }) => {
                setName(target.value);
              }}
            />
            <S.FieldName className="field-name">Nome</S.FieldName>
          </S.FieldContainer>

          <S.FieldContainer>
            <S.SurName
              className="register register-name"
              type="text"
              defaultValue={lastName}
              onChange={({ target }) => {
                setLastName(target.value);
              }}
            />
            <S.FieldName className="field-name">Sobrenome</S.FieldName>
          </S.FieldContainer>
        </div>

        <div id="register-section-2">
          <S.FieldContainer>
            <S.CPF
              className="register"
              type="number"
              defaultValue={documentNumber}
              value={documentNumber}
              onChange={({ target }) => {
                const length = target.value.length;
                const saveRegisterButton = document.querySelector(
                  '#save-register-button'
                );
                const cpfError = document.querySelector('#cpf-error');

                if (length !== 11) {
                  saveRegisterButton.disabled = true;
                  cpfError.style.opacity = '1';

                  setDocumentNumber(formatCpf(target.value));

                  return;
                }

                setDocumentNumber(formatCpf(target.value));
                cpfError.style.opacity = '0';
                saveRegisterButton.disabled = false;
              }}
            />
            <S.FieldName className="field-name">
              CPF (Somente números)
            </S.FieldName>
            <S.CpfError
              id="cpf-error"
              style={{
                opacity: '0',
                transition: '0.3s ease',
              }}
            >
              CPF inválido. Por favor, verifique e tente novamente.
            </S.CpfError>
          </S.FieldContainer>

          <S.FieldContainer>
            <S.BirthDate
              className="register"
              type="date"
              defaultValue={birthDate}
              onChange={({ target }) => {
                setBirthDate(target.value);
              }}
            />
            <S.FieldName className="field-name">Data de Nascimento</S.FieldName>
          </S.FieldContainer>
        </div>

        <S.SaveRegisterButton
          id="save-register-button"
          onClick={async () => {
            await putRegistrationInfo();
          }}
          type="button"
        >
          Salvar
        </S.SaveRegisterButton>
      </S.RegisterContainer>

      {!isAdmin &&
        (process.env.REACT_APP_FLAG_USER_EMAIL == userEmail ||
          process.env.REACT_APP_FLAG_USER_EMAIL == '') && (
          <Fragment>
            <S.ToggleCoupon
              onClick={() => {
                setToggleCoupon((prevToggle) => !prevToggle);
                setToggleProfile(false);
                setToggleRegister(false);
              }}
              style={
                toggleCoupon
                  ? {
                      borderBottomRightRadius: '0px',
                      borderBottomLeftRadius: '0px',
                    }
                  : {
                      borderBottomRightRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    }
              }
            >
              Meus Cupons
              {toggleCoupon ? (
                <FaAngleDown className="arrow" />
              ) : (
                <FaAngleUp className="arrow" />
              )}
            </S.ToggleCoupon>
            <S.CouponContainer
              style={
                toggleCoupon
                  ? { height: 'auto' }
                  : { height: '0px', padding: '0', border: '0px' }
              }
            >
              <div id="coupons-section">
                {coupons.length > 0 ? (
                  coupons.map((coupon, i) => (
                    <S.CouponDiv key={i + coupon.Id}>
                      <Coupon
                        viewModeOnly
                        id={coupon.Id}
                        image={coupon.PartnerBackground?.Url}
                        discount={coupon.DiscountValue}
                        isPercentage={coupon.IsPercentage}
                      />

                      <Paper
                        sx={{
                          minWidth: '330px',
                          height: '100%',

                          position: 'relative',

                          backgroundColor: '#fce66257',

                          color: '#213455',

                          borderRadius: '12px',

                          '@media screen and (max-width: 750px)': {
                            width: '100% !important',
                            minWidth: 'unset !important',
                          },
                        }}
                      >
                        <S.CouponHeader>
                          <h3>Dados do Cupom</h3>
                          <Divider
                            sx={{
                              width: '100%',
                              height: '2px',

                              backgroundColor: '#213455',
                            }}
                          />
                        </S.CouponHeader>

                        <S.CouponBody>
                          <S.CouponInfo>
                            <h4>Parceiro:</h4>
                            <p>{coupon.PartnerTitle}</p>
                          </S.CouponInfo>

                          <S.CouponInfo>
                            <h4>Código:</h4>
                            <p>{coupon.Value}</p>
                          </S.CouponInfo>

                          <S.CouponInfo>
                            <h4>Data de expiração:</h4>
                            <p>
                              {new Date(coupon.ExpiresAt).toLocaleDateString(
                                'pt-BR'
                              )}
                            </p>
                          </S.CouponInfo>
                        </S.CouponBody>
                      </Paper>
                    </S.CouponDiv>
                  ))
                ) : (
                  <S.NoCoupons>
                    <RiCoupon3Line size={85} fill="#213455" />
                    <h4>Você não possui nenhum cupom ativo.</h4>
                  </S.NoCoupons>
                )}
              </div>
            </S.CouponContainer>
          </Fragment>
        )}
    </S.MainContainer>
  );
}
