/**
 * Action types
 */
export const AuthTypes = {
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  STOP_LOADING: '@auth/STOP_LOADING',
  SIGN_UP_REQUEST: '@auth/SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: '@auth/SIGN_UP_SUCCESS',
  SIGN_UP_CONFIRMATION: '@auth/SIGN_UP_CONFIRMATION',
  EMAIL_CONFIRMATION: '@auth/EMAIL_CONFIRMATION',
  RESEND_CODE: '@auth/RESEND_CODE',
  SIGN_OUT: '@auth/SIGN_OUT',
  UPLOAD_PROFILE_PIC: '@auth/UPLOAD_PROFILE_PIC',
  UPDATE_PROFILE: '@auth/UPDATE_PROFILE',
  FORGOT_EMAIL_PASSWORD: '@auth/FORGOT_EMAIL_PASSWORD',
  CONFIRM_NEW_PASSWORD: '@auth/CONFIRM_NEW_PASSWORD',
  RELOAD_PROFILE: '@auth/RELOAD_PROFILE',
  SET_PRIVILEGE_TYPE: "@auth/SET_PRIVILEGE_TYPE"
};
