/**
 * Action types
 */
export const enumsTypes = {
  LOAD_CITY_ENUM: "@enums/LOAD_CITY_ENUM",
  CLEAR_CITY_ENUM: "@enums/CLEAR_CITY_ENUM",
  LOAD_UF_ENUM: "@enums/LOAD_UF_ENUM",
  LOAD_ANNOUNCEMENT_ENUM: "@enums/LOAD_ANNOUNCEMENT_ENUM",
  LOAD_ANNOUNCEMENT_CATEGORY_ENUM: "@enums/LOAD_ANNOUNCEMENT_CATEGORY_ENUM",
};
