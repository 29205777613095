/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  Fragment,
} from 'react';
import { useHistory } from 'react-router-dom';

import { toastError } from 'utils/defaultToasts';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { debounce } from 'lodash';
import { IoMdAdd } from 'react-icons/io';
import { AiOutlineMinus } from 'react-icons/ai';
import { AccordionFilterItemsContext } from '~/context/Accordion';
import api from '../../../services/api';
import * as S from './styles';
import { useMediaQuery } from '@material-ui/core';

export default function AccordionFilter() {
  const history = useHistory();

  const {
    setAccordionFilterItems,
    accordionFilterItems,
    selectedSubcategories,
    setSelectedSubcategories,
  } = useContext(AccordionFilterItemsContext);

  const [expanded, setExpanded] = useState(false);

  const [expandedFilters, setExpandedFilters] = useState(false);
  const [cities, setCities] = useState([]);
  const [categoriesFilterAccordion, setCategoriesFilterAccordion] = useState([
    { Description: 'Cidade' },
  ]);
  const [subCategories, setSubCategories] = useState({});
  const [partSubCategories, setPartSubCategories] = useState([]);

  const handleSetBaseYear = debounce((value) => {
    setAccordionFilterItems((prev) => {
      return {
        ...prev,
        baseYear: { Name: value, Description: 'Ano mínimo: ' + value },
      };
    });
  }, 500);

  const handleSetLimitYear = debounce((value) => {
    setAccordionFilterItems((prev) => {
      return {
        ...prev,
        limitYear: { Name: value, Description: 'Ano máximo: ' + value },
      };
    });
  }, 500);

  const handleSetBasePrice = debounce((value) => {
    setAccordionFilterItems((prev) => {
      return {
        ...prev,      
        basePrice: {
          Name: value,
          Description:
            'Preço mínimo: ' +
            Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }),
        },
      };
    });
  }, 500);

  const handleSetLimitPrice = debounce((value) => {
    setAccordionFilterItems((prev) => {
      return {
        ...prev,
        limitPrice: {
          Name: value,
          Description:
            'Preço máximo: ' +
            Number(value).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            }),
        },
      };
    });
  }, 500);

  const handleToggleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const announcementsFilterEnumerators =
    '/api/v1/Announcements/Filter/Enumerators';

  const getAccordionFilterTitle = useCallback(async () => {
    try {
      const sortingPattern = {
        Categoria: 1,
        Estado: 2,
        Cidade: 3,
        Condição: 4,
        'Tamanho da bike': 5,
        'Tamanho das rodas': 6,
        'Cursor de suspensão': 7,
        Ano: 8,
        'Faixa de Preço': 9,
      };

      const response = await api.get(announcementsFilterEnumerators);

      const newFilters = [
        ...categoriesFilterAccordion,
        ...Object.values(response.data),
      ];
      setCategoriesFilterAccordion(
        newFilters.sort(
          (a, b) =>
            sortingPattern[a.Description] - sortingPattern[b.Description]
        )
      );
    } catch (error) {
      toastError('Houve um erro ao buscar os títulos do menu.');
    }
  }, [setCategoriesFilterAccordion]);

  const getSubCategoriesOfCategories = useCallback(async () => {
    setSubCategories(() => ({}));

    try {
      for await (const category of accordionFilterItems.categories) {
        const response = await api.get(
          'api/v1/Announcements/Enumerators/Categories',
          {
            params: { aParentCategory: category.Name },
          }
        );

        const { data } = response;

        setSubCategories((previous) => ({
          ...previous,
          [category.Name]: data,
        }));
      }
    } catch (error) {
      console.log(error.response);
    }
  }, [accordionFilterItems.categories]);

  useEffect(() => {
    getSubCategoriesOfCategories();
  }, [getSubCategoriesOfCategories]);

  function renderAnnouncementCategoryAccordionField(filterOption) {
    const filterCategoryItems = (category) => {
      if (!accordionFilterItems.categories) return;

      if (
        accordionFilterItems.categories.find(
          (item) => item.Name === category.Name
        )
      )
        return setAccordionFilterItems((prev) => ({
          ...prev,
          categories: prev?.categories.filter(
            (item) => item.Name !== category.Name
          ),
        }));

      setAccordionFilterItems((prev) => ({
        ...prev,
        categories: [
          ...prev?.categories,
          { Name: category.Name, Description: category.Description },
        ],
      }));
    };

    const filterSubcategoryItems = (subcategory) => {
      if (
        selectedSubcategories.find(
          (selectedSubcategory) => selectedSubcategory.Name === subcategory.Name
        )
      )
        return setSelectedSubcategories((prev) =>
          prev.filter(
            (selectedSubcategory) =>
              selectedSubcategory.Name !== subcategory.Name
          )
        );

      setSelectedSubcategories((prev) => [...prev, subcategory]);
    };

    const handleCheckCategory = (category) => {
      filterCategoryItems(category);
    };

    return filterOption.EnumContents?.map((category) => {
      const isChecked = !!accordionFilterItems?.categories?.find(
        (item) => item?.Name === category.Name
      );

      return (
        <Fragment>
          <S.CategoryCheckboxDiv>
            <input
              type="checkbox"
              id={`announcementcategory${category.Name}`}
              name="announcementcategory"
              checked={isChecked}
              onClick={() => handleCheckCategory(category)}
            />
            <label for={`announcementcategory${category.Name}`}>
              {category.Description}
            </label>
          </S.CategoryCheckboxDiv>

          <S.SubCategoryCheckboxList>
            {category?.Name &&
              subCategories[category?.Name]?.map((subcategory) => {
                const isChecked = !!selectedSubcategories?.find(
                  (item) => item?.Name === subcategory.Name
                );

                return (
                  <li>
                    <input
                      checked={isChecked}
                      type="checkbox"
                      id={`announcementSubcategory${subcategory.Description}`}
                      name="announcementSubcategory"
                      onClick={() => filterSubcategoryItems(subcategory)}
                    />

                    <label
                      htmlFor={`announcementSubcategory${subcategory.Description}`}
                    >
                      {subcategory.Description}
                    </label>
                  </li>
                );
              })}
          </S.SubCategoryCheckboxList>
        </Fragment>
      );
    });
  }

  function renderAnnouncementForkTravelAccordionField(filterOption) {
    return filterOption.DtoEnumContents.map((subCategory) => (
      <S.ForkTravelDiv
        onClick={() =>
          setAccordionFilterItems((prev) => {
            return {
              ...prev,
              forkTravel: {
                Name: subCategory.Name,
                Description: subCategory.Description,
              },
            };
          })
        }
      >
        <p>{subCategory.Description}</p>
      </S.ForkTravelDiv>
    ));
  }

  function renderAnnouncementRimSizeAccordionField(filterOption) {
    return filterOption.DtoEnumContents.map((subCategory) => (
      <S.RimSizeDiv
        onClick={() =>
          setAccordionFilterItems((prev) => {
            return {
              ...prev,
              rimSize: {
                Name: subCategory.Name,
                Description: subCategory.Description,
              },
            };
          })
        }
      >
        <p>{subCategory.Description}</p>
      </S.RimSizeDiv>
    ));
  }

  function renderAnnouncementSizeAccordionField(filterOption) {
    return filterOption.DtoEnumContents?.map((subCategory) => (
      <div
        onClick={() =>
          setAccordionFilterItems((prev) => {
            return {
              ...prev,
              size: {
                Name: subCategory.Name,
                Description: subCategory.Description,
              },
            };
          })
        }
      >
        <p>{subCategory.Description}</p>
      </div>
    ));
  }

  function renderAnnouncementUseConditionAccordionField(filterOption) {
    return filterOption.DtoEnumContents.map((subCategory) => (
      <S.UseConditionDiv
        onClick={() =>
          setAccordionFilterItems((prev) => {
            return {
              ...prev,
              useCondition: {
                Name: subCategory.Name,
                Description: subCategory.Description,
              },
            };
          })
        }
      >
        <p>{subCategory.Description}</p>
      </S.UseConditionDiv>
    ));
  }

  function renderAnnouncementUFAccordionField(filterOption) {
    return (
      <S.SelectDiv>
        <select
          onChange={(e) =>
            setAccordionFilterItems({
              ...accordionFilterItems,
              initialsUF: { Description: e.target.value, Name: e.target.value },
            })
          }
        >
          {filterOption.DtoEnumContents?.map((subCategory) => (
            <option>{subCategory.Name}</option>
          ))}
          <option selected>Selecione</option>
        </select>
      </S.SelectDiv>
    );
  }

  function renderAnnouncementCitiesAccordionField() {
    return (
      <S.SelectDiv>
        <select
          onChange={(e) => {
            const [id, description] = e.target.value.split('#');
            setAccordionFilterItems({
              ...accordionFilterItems,
              city: { Description: description, Name: id },
            });
          }}
        > 
          {cities.map((filterOption) => (
            <option value={filterOption.id + '#' + filterOption.nome}>
              {filterOption.nome}
            </option>
          ))}
        </select>
      </S.SelectDiv>
    );
  }

  function renderAnnouncementBikeYearRangeAccordionField() {
    return (
      <S.InputRangeDiv>
        <input
          type="number"
          placeholder="Ano Min: 1900"
          onChange={(e) => {
            handleSetBaseYear(e.currentTarget.value);
          }}
        />

        <input
          type="number"
          placeholder="Ano Max: 2021"
          onChange={(e) => {
            handleSetLimitYear(e.currentTarget.value);
          }}
        />
      </S.InputRangeDiv>
    );
  }

  function renderAnnouncementBikePriceRangeAccordionField() {
    return (
      <S.InputRangeDiv>
        <input
          type="number"
          placeholder="Preço Min: R$ 500"
          onChange={(e) => {
            handleSetBasePrice(e.currentTarget.value);
          }}
        />

        <input
          type="number"
          placeholder="Preço Máx: R$ 7000"
          onChange={(e) => {
            handleSetLimitPrice(e.currentTarget.value);
          }}
        />
      </S.InputRangeDiv>
    );
  }

  function renderAccordionFieldByName(filterOption) {
    switch (filterOption.Name || filterOption.Description) {
      case 'AnnouncementCategory':
        return renderAnnouncementCategoryAccordionField(filterOption);
      case 'AnnouncementForkTravel':
        return renderAnnouncementForkTravelAccordionField(filterOption);
      case 'AnnouncementRimSize':
        return renderAnnouncementRimSizeAccordionField(filterOption);
      case 'AnnouncementSize':
        return renderAnnouncementSizeAccordionField(filterOption);
      case 'AnnouncementUseCondition':
        return renderAnnouncementUseConditionAccordionField(filterOption);
      case 'Cidade':
        return renderAnnouncementCitiesAccordionField();
      case 'UF':
        return renderAnnouncementUFAccordionField(filterOption);
      case 'Ano':
        return renderAnnouncementBikeYearRangeAccordionField();
      case 'Faixa de preço':
        return renderAnnouncementBikePriceRangeAccordionField();
      default:
        return;
    }
  }

  const renderAccordion = (filterOption) => {
    return history.location.pathname.includes('anuncios') &&
      filterOption.Description === 'Categoria' ? null : (
      <Accordion>
        <AccordionSummary
          expanded={expanded === (filterOption.Name || filterOption.Description)}
          onClick={() => {handleToggleExpand((filterOption.Name || filterOption.Description))(null, expanded !== (filterOption.Name || filterOption.Description));}}
          expandIcon={expanded === (filterOption.Name || filterOption.Description) ? <AiOutlineMinus /> : <IoMdAdd />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <S.Title>{filterOption.Description}</S.Title>
        </AccordionSummary>
        <S.SubCategoriesList>
          {renderAccordionFieldByName(filterOption)}
        </S.SubCategoriesList>
      </Accordion>
    );
  };

  const renderFilterAccordion = () => {
    return (
      <Accordion>
        <AccordionSummary
          onClick={() => {setExpandedFilters(!expandedFilters)}}
          expanded={expandedFilters === true}
          expandIcon={expandedFilters ? <AiOutlineMinus /> : <IoMdAdd />}
          aria-controls="filter-content"
          id="filter-header"
        >
          <S.Title>Filtros</S.Title>
        </AccordionSummary>
        {categoriesFilterAccordion.length > 1 &&
        categoriesFilterAccordion.map((filterOption) => {
          return renderAccordion(filterOption);
        })}
      </Accordion>
    );  
  };

  const getCities = useCallback(async () => {
    const filterCities = `/api/UFs/${accordionFilterItems.initialsUF.Name}/Cities`;
    try {
      const response = await api.get(filterCities);

      setCities(response.data);
    } catch (error) {
      toastError('Houve um erro ao buscar as "Cidades".');
    }
  }, [accordionFilterItems?.initialsUF]);

  useEffect(() => {
    getAccordionFilterTitle();
  }, [getAccordionFilterTitle]);

  useEffect(() => {
    if (accordionFilterItems.initialsUF?.Name) getCities();
  }, [getCities]);

  const matches = useMediaQuery('(max-width:960px)');

  return (
    <S.Container>
      {matches ? renderFilterAccordion() : (categoriesFilterAccordion.length > 1 &&
        categoriesFilterAccordion.map((filterOption) => {
          return renderAccordion(filterOption);
        }))}
    </S.Container>
  );
}
