import React, { useState, useCallback, useEffect, useRef } from 'react';
import heic2any from 'heic2any';
import { FaRegTrashAlt, FaRegStar, FaStar } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { toastError, toastSuccess } from 'utils/defaultToasts';
import {
  Field,
  Container,
  AnimationContainer,
  Advise,
  CropOptionsContainer,
} from './styles';
import getCroppedImg from '~/pages/EditProfile/cropImage';
import api from '~/services/api';

import uploadIcon from '~/assets/uploadIcon.svg';

const img = {
  display: 'block',
  width: 100,
  height: 'auto',
};

export default function Images({ setPage, announcement, setAnnouncement }) {
  const animationContainerRef = useRef(null);

  const [loadedImage, setLoadedImage] = useState('');
  const [size, setSize] = useState(0);
  const [currentImage, setCurrentImage] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [base64Images, setBase64Images] = useState([]);
  const [show, setShow] = useState(false);

  const [horizontalSize, setHorizontalSize] = useState(1000);
  const [verticalSize, setverticalSize] = useState(1000);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, .heic, .heif',
    multiple: false,
    onDrop: async (acceptedFile) => {
      if (acceptedFile.length > 0) {
        let file = acceptedFile[0];
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          const heicBuffer = await file.arrayBuffer();
          console.log(heicBuffer);
          const pngBlob = await heic2any({
            blob: new Blob([heicBuffer], { type: 'image/heic' }),
          });

          Object.assign(file, {
            preview: URL.createObjectURL(pngBlob),
          });
        } else {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }

        setLoadedImage(file.preview);
        setSize(file.size);
        setShow(true);
      } else return;
    },
  });

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const croppedImage = await getCroppedImg(loadedImage, croppedAreaPixels);
      setCurrentImage(croppedImage);
    },
    [loadedImage]
  );

  const confirmCrop = useCallback(async () => {
    setBase64Images((curFiles) => [...curFiles, currentImage]);
    setLoadedImage('');
    setCurrentImage('');
  }, [currentImage, size]);

  const handleClick = useCallback(async () => {
    if (base64Images.length <= 0 && announcement?.DtoPictures?.length === 0) {
      toastError('Insira ao menos uma foto no anúncio.');
      return;
    }

    if (announcement?.DtoPictures?.length > 0 && base64Images.length <= 0) {
      setTimeout(() => setPage('Contact'), 500);
      return;
    }

    base64Images.forEach((image) => {
      const imageExtension = image.split(':')[1].split(';')[0];
      console.log(image);
      if (
        !['image/jpg', 'image/jpeg', 'image/png', 'image/heic'].includes(
          imageExtension
        )
      ) {
        toastError('A foto inserida deve ser JPG, JPEG, PNG ou HEIC!');
        return;
      }
      if (extractBytes(image) > 5 * 1024 * 1024) {
        toastError('A foto inserida precisar ter, no máximo, 5MB!');
        return;
      }
    });

    const bodyContent = {
      images: base64Images,
    };

    api
      .patch(`api/v1/Announcements/${announcement.Id}/Images`, bodyContent)
      .then((response) => {
        setAnnouncement(response.data);
        toastSuccess('Enviado com sucesso!');
        setTimeout(() => setPage('Contact'), 500);
      })
      .catch(() => toastError('Erro ao enviar uma ou mais imagens.'));
  }, [base64Images]);

  const moveFileToTop = useCallback(
    (index) => {
      const item = base64Images[index];
      const auxArray = base64Images.filter((image) => image !== item);

      auxArray.unshift(item);

      setBase64Images(auxArray);
    },
    [base64Images]
  );

  const removeBase64Image = useCallback(
    (index) => {
      setBase64Images(
        base64Images.filter((image) => image !== base64Images[index])
      );
    },
    [base64Images]
  );

  const showContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '1';
    animationContainerRef.current.style.transform = 'translateX(0)';
  };

  const confirm = () => {
    confirmCrop();
    setShow(!show);
  };

  const backPage = () => setPage('Information');

  useEffect(() => {
    showContainerAnimation();
  }, []);

  const extractBytes = (img) => {
    const buffer = Buffer.from(img.substring(img.indexOf(',') + 1));
    return buffer.length;
  };

  return (
    <div>
      <AnimationContainer ref={animationContainerRef}>
        <Container propShow={show}>
          <h1>Vamos agora enviar algumas fotos</h1>

          <Field>
            <div className="inputTitle">
              <section className="uploadContainer">
                <div className="image-cropper">
                  <Cropper
                    image={loadedImage}
                    crop={crop}
                    minZoom={1}
                    zoom={zoom}
                    aspect={horizontalSize / verticalSize}
                    showGrid={false}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />

                  <CropOptionsContainer>
                    <div>
                      <label for="zoom">Zoom</label>
                      <input
                        type="range"
                        min={1}
                        max={10}
                        step={1}
                        value={zoom}
                        onChange={(e) => setZoom(e.target.value)}
                        id="zoom"
                      />
                    </div>

                    <div>
                      <label for="horizontal-ratio">Proporção horizontal</label>
                      <input
                        type="range"
                        min={1}
                        max={1000}
                        step={1}
                        value={horizontalSize}
                        onChange={(e) => setHorizontalSize(e.target.value)}
                        id="horizontal-ratio"
                      />
                    </div>

                    <div>
                      <label for="vertical-ratio">Proporção vertical</label>
                      <input
                        type="range"
                        min={1}
                        max={1000}
                        step={0.1}
                        value={verticalSize}
                        onChange={(e) => setverticalSize(e.target.value)}
                        id="vertical-ratio"
                      />
                    </div>
                  </CropOptionsContainer>

                  <button onClick={confirm}>Confirmar</button>
                </div>

                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <img draggable={false} src={uploadIcon} alt="" />

                  <h2>Arraste e solte as imagens aqui</h2>
                  <p>ou</p>
                  <button type="button">Procure no computador</button>

                  <Advise>
                    Apenas arquivos do tipo PNG, JPG e HEIC são permitidos.
                  </Advise>
                </div>
              </section>
            </div>

            <div className="thumbsContainer">
              {base64Images.map((image, index) => (
                <div className="thumb" key={'image_' + index}>
                  <div className="thumbInner">
                    <img src={image} style={img} />
                  </div>

                  <div className="filesDetails">
                    <p> {extractBytes(image)} Bytes</p>
                  </div>

                  {index === 0 ? (
                    <FaStar className="changed-position" title="principal" />
                  ) : (
                    <button
                      className="change-position"
                      onClick={() => moveFileToTop(index)}
                    >
                      <FaRegStar />
                    </button>
                  )}

                  <button
                    className="delete-image"
                    onClick={() => removeBase64Image(index)}
                  >
                    <FaRegTrashAlt />
                  </button>
                </div>
              ))}

              {announcement?.DtoPictures?.map((imageData) => (
                <div className="thumb" key={imageData?.key}>
                  <div className="thumbInner">
                    <img
                      src={process.env.REACT_APP_S3URL + imageData?.Key}
                      alt={imageData?.Description}
                    />
                  </div>

                  <div className="filesDetails">
                    <p> {imageData?.Bytes} Bytes</p>

                    <span id="already-added-image">
                      Essa imagem já foi adicionada anteriormente, você poderá
                      removê-la depois na edição de anúncios;
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Field>

          <section className="buttons-section">
            <button type="button" className="nextStep" onClick={backPage}>
              Voltar
            </button>

            <button type="button" onClick={() => handleClick()}>
              Avançar
            </button>
          </section>
        </Container>
      </AnimationContainer>
    </div>
  );
}
