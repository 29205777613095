import styled, { css } from 'styled-components';

export const Container = styled.div`
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: 100%;
  max-width: 1280px;

  min-height: 600px;
  /* height: 85vh; */
  margin: 5rem auto;
  padding: 1rem;

  position: relative;

  @media screen and (max-width: 960px) {
    h1 {
      text-align: center;
    }
    h2,
    input {
      font-size: unset !important;
    }
  }

  h2 {
    font-size: 22px;
    font-weight: 400;
  }

  .Product {
    width: 200px;
    height: 200px;

    border-radius: 10px;

    box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.4);

    margin: 1em 0;

    text-align: center;

    transition: 0.2s;

    background: white;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.6);
    }
  }

  p {
    font-size: 20px;
  }

  span {
    font-size: 12px;
    vertical-align: bottom;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;

  .publish {
    background: #00c569 !important;
  }

  .buttons-section {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: 150px;
      height: 60px;
      background: rgb(32, 52, 83);
      font-size: 20px;
      color: white;
      border: none;
      border-radius: 10px;
    }
  }

  .progress-bar-section {
    width: 100%;
    max-width: 1280px;
    display: flex;
    justify-content: flex-end;
    margin: 3em auto 0 auto;
  }

  @media screen and (max-width: 960px) {
    .progress-bar-section {
      width: 90%;
    }

    .buttons-section {
      button {
        width: 100px;
        height: 40px;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  width: 500px;
  height: 50px;

  position: relative;

  display: flex;
  flex-direction: column;

  transition: 0.3s ease;

  @media screen and (max-width: 960px) {
    width: 100%;
    float: none;

    .line-between {
      width: 100%;
      top: 43% !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    li {
      font-size: 15px;
    }

    .text-list-progressbar {
      display: none;
    }
    .icon-list-progressbar {
      display: flex !important;
    }
  }

  ${(props) => {
    if (props.propPage === 'Information') {
      return css`
        .progressTop {
          li:nth-child(1) {
            div {
              border: solid 1px #00c569;
            }

            svg {
              display: flex !important;
            }
          }
        }

        .icon-list-progressbar {
          li:nth-child(1) {
            svg {
              fill: #00c569;
            }
          }
        }

        .line-between {
          div {
            width: 0px;
            height: 100%;
            background: #00c569;
          }
        }
      `;
    }
    if (props.propPage === 'Images') {
      return css`
        .progressTop {
          li:nth-child(n + 1):nth-child(-n + 2) {
            div {
              border: solid 1px #00c569;
            }

            svg {
              display: flex !important;
            }
          }
        }
        .icon-list-progressbar {
          li:nth-child(n + 1):nth-child(-n + 2) {
            svg {
              fill: #00c569;
            }
          }
        }

        .line-between {
          div {
            width: 25%;
            height: 100%;
            background: #00c569;
          }
        }
      `;
    }
    if (props.propPage === 'Contact') {
      return css`
        .progressTop {
          li:nth-child(n + 1):nth-child(-n + 3) {
            div {
              border: solid 1px #00c569;
            }

            svg {
              display: flex !important;
            }
          }
        }

        .icon-list-progressbar {
          li:nth-child(n + 1):nth-child(-n + 3) {
            svg {
              fill: #00c569;
            }
          }
        }

        .line-between {
          div {
            width: 50%;
            height: 100%;
            background: #00c569;
          }
        }
      `;
    }
    if (props.propPage === 'Payment') {
      return css`
        .progressTop {
          li:nth-child(n + 1):nth-child(-n + 4) {
            div {
              border: solid 1px #00c569;
            }

            svg {
              display: flex !important;
            }
          }
        }

        .icon-list-progressbar {
          li:nth-child(n + 1):nth-child(-n + 4) {
            svg {
              fill: #00c569;
            }
          }
        }

        .line-between {
          div {
            width: 75%;
            height: 100%;
            background: #00c569;
          }
        }
      `;
    }
    if (props.propPage === 'Review') {
      return css`
        .progressTop {
          li {
            div {
              border: solid 1px #00c569;
            }

            svg {
              display: flex !important;
            }
          }
        }

        .icon-list-progressbar {
          li {
            svg {
              fill: #00c569;
            }
          }
        }

        .line-between {
          div {
            width: 100%;
            height: 100%;
            background: #00c569;
          }
        }
      `;
    }
  }}

  .progressTop {
    div {
      width: 40px;
      height: 40px;

      margin: 0 auto;

      border-radius: 99px;

      background: white;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      cursor: pointer;
    }

    svg {
      width: 24px;
      height: 24px;
      fill: #00c569;
      display: none;
      transition: 0.3s;
    }
  }

  .line-between {
    width: 80%;
    height: 1px;
    position: absolute;
    background: rgba(125, 125, 125, 0.3);
    top: 40%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -80%);

    div {
      transition: 0.3s;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    width: 100px;
    text-align: center;
    color: rgb(125, 125, 125, 0.5);
    display: inline-block;
  }

  .icon-list-progressbar {
    display: none;

    li {
      padding-top: 20px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
