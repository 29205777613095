import React, { useState, useCallback } from 'react';

import { AiOutlineCloudUpload } from 'react-icons/ai';
import ImageItem from './components/ImageItem';
import { toastError, toastSuccess, toastInfo } from 'utils/defaultToasts';
import S3 from 'react-aws-s3';
import { v4 as uuidv4 } from 'uuid';
import api from '~/services/api';

import * as S from './styles';

export default function ImagesMobile({ setPage, announcement, setAnnouncement }) {
  const [files, setFiles] = useState([]);

  const handleClick = useCallback(async () => {
    if (files.length <= 0 && announcement?.DtoPictures?.length === 0) {
      toastError('Insira ao menos uma foto no anúncio.');
      return;
    }
    if (announcement?.DtoPictures?.length > 0 && files.length <= 0) {
      setTimeout(() => {
        setPage('Contact');
      }, 500);
      return;
    }

    files.forEach((file) => {
      let fileType = file.type
      
      if (file.length > 5) {
        toastError('A foto inserida precisar ter, no máximo, 5MB!');
        return;
      }

      if (fileType === '' && file.name.includes('.heic')) {
        fileType = 'image/heic'
      } else if (fileType === '' && file.name.includes('.heif')) {
        fileType = 'image/heif'
      }

      if (!['image/jpg', 'image/jpeg', 'image/png', 'image/heic', 'image/heif'].includes(fileType)) {
        toastError('A foto inserida deve ser JPG, JPEG, PNG ou HEIC!');
        return;
      }
    });

    const images = [];

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const fileToBase64 = await toBase64(file);
      images.push(fileToBase64);
    }

    const bodyContent = {
      Images: images
    }

    api.patch(`api/v1/Announcements/${announcement.Id}/Images`, bodyContent)
      .then((response) => {
        setAnnouncement(response.data);
        toastSuccess('Enviado com sucesso!');
        setTimeout(() => {
          setPage('Contact');
        }, 500);
      })
      .catch(() => {
        toastError('Erro ao enviar uma ou mais imagens.');
      });
  }, [files]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const handleSendImage = useCallback(async (e) => {
    let file = e.target.files[0];

    if (!file) return;

    setFiles((previous) => [...previous, file]);
  }, []);

  const generateFileListJSX = useCallback(() => {
    return files.map((imageFile, index) => {
      const url = window.URL.createObjectURL(imageFile);

      return (
        <ImageItem
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={`imageItemEement-${index}`}
          name={imageFile.name}
          url={url}
          isFavorite={index === 0}
          imageFiles={files}
          setImageFiles={setFiles}
        />
      );
    });
  }, [files]);
 
  return (
    <>
      <S.Title>Envio de imagens</S.Title>

      <S.Container>
        <S.Main>
          <S.Text>Envie as fotos do produto aqui</S.Text>

          <S.UploadButton>
            <S.Text>
              Enviar <AiOutlineCloudUpload />
            </S.Text>

            <S.Input
              onChange={handleSendImage}
              type="file"
              accept="image/jpeg, image/jpg, image/png, image/heic"
            />
          </S.UploadButton>
        </S.Main>

        <S.List>
          {generateFileListJSX()}
          {announcement?.DtoPictures?.map((imageData) => (
            <S.Thumb key={imageData?.key}>
              <S.ThumbInner>
                <img src={imageData?.ThumbnailUrl} alt={imageData?.Description} />
              </S.ThumbInner>

              <S.FileDetails>
                <p> {imageData?.Bytes} Bytes</p>
                <span id="already-added-image">
                  Essa imagem já foi adicionada anteriormente, você poderá removê-la
                  depois na edição de anúncios;
                </span>
              </S.FileDetails>
            </S.Thumb>
          ))}
        </S.List>
        <section className="buttons-section">
          <button type="button" className="nextStep" onClick={() => setPage('Information')}>
            Voltar
          </button>

          <button type="button" onClick={() => handleClick()}>
            Avançar
          </button>
        </section>
      </S.Container>
    </>
  );
}
