import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import AnunciosBikes from './components/BMX';

import { clearFilters, setFilters } from '~/store/ducks/filters/actions';

import api from '~/services/api';

import Helmet from 'react-helmet';

import {
  Container,
  Categories,
  Products,
  NumberMark,
  ListOfProducts,
} from './styles';
import FilterOptions from '~/components/FilterOptions';
import AccordionFilter from '~/components/AccordionStruct/Accordion';
import { AccordionFilterItemsContext } from '~/context/Accordion';

export default function Announcements() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [handleAnnoucement] = useState('annoucement');
  const [categories, setCategories] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const { setAccordionFilterItems, accordionFilterItems } = useContext(
    AccordionFilterItemsContext
  );

  const [totalAnnouncements, setTotalAnnouncements] = useState(0);

  function countProperties(obj) {
    var count = 0;

    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) ++count;
    }

    return count;
  }

  const reMapCategories = useCallback(
    (categories) => {
      const remappedCategories = [];
      categories.DtoCategories.filter((category) => {
        let hasSubCategory = false;
        category.DtoCategories.forEach((subCategory) => {
          if ('DtoCategories' in subCategory) {
            remappedCategories.push(subCategory);
            hasSubCategory = true;
          }
        });
        if (!hasSubCategory) remappedCategories.push(category);
      });
      return remappedCategories;
    },
    [categories]
  );

  const doCategoriesSearch = useCallback(() => {
    const parameters = {
      IsPaged: true,
      State: accordionFilterItems.initialsUF?.Name,
      CityId: accordionFilterItems.city?.Name,
      UseCondition: accordionFilterItems.useCondition?.Name,
      Size: accordionFilterItems.size?.Name,
      RimSize: accordionFilterItems.rimSize?.Name,
      ForkTravel: accordionFilterItems.forkTravel?.Name,
      BasePrice: accordionFilterItems.basePrice?.Name,
      LimitPrice: accordionFilterItems.limitPrice?.Name,
      BaseYear: accordionFilterItems.baseYear?.Name,
      LimitYear: accordionFilterItems.limitYear?.Name,
    };

    Object.keys(parameters).forEach(
      (key) => parameters[key] === undefined && delete parameters[key]
    );

    api
      .get('api/v1/Announcements/CategoriesSearch', {
        params: parameters,
      })
      .then((response) => {
        setCategories(reMapCategories(response.data));
      })
      .catch((error) => {
        toast.error(error.response, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  }, [categories, accordionFilterItems]);

  const ShowCategories = useCallback(
    () => (
      <>
        {categories.map((category) => (
          <ul key={category.CategoryName}>
            <li
              className="titles"
              onClick={() => {
                setAccordionFilterItems({
                  ...accordionFilterItems,
                  categories: [
                    {
                      Description: category.CategoryDescription,
                      Name: category.CategoryName,
                    },
                  ],
                });
                history.push({
                  pathname: `/Category/${category.CategoryName}`,
                  state: {
                    CategoryDescription: category.CategoryDescription,
                    CategoryName: category.CategoryName,
                  },
                });
              }}
            >
              {category.Title}
            </li>
            {category.DtoCategories.map((subCategory) => {
              return subCategory.Quantity > 0 ? (
                <li
                  onClick={() => {
                    setAccordionFilterItems({
                      ...accordionFilterItems,
                      categories: [
                        {
                          Description: subCategory.CategoryDescription,
                          Name: subCategory.CategoryName,
                        },
                      ],
                    });
                    history.push({
                      pathname: `/Category/${subCategory.CategoryName}`,
                      state: {
                        CategoryDescription:
                          category.CategoryDescription +
                          ' - ' +
                          subCategory.CategoryDescription,
                        CategoryName: subCategory.CategoryName,
                      },
                    });
                  }}
                  key={subCategory.CategoryName}
                >
                  {subCategory.Title}
                </li>
              ) : (
                <li key={subCategory.CategoryName}>{subCategory.Title}</li>
              );
            })}
          </ul>
        ))}
      </>
    ),
    [categories]
  );

  const ShowAnnoucement = useCallback(
    ({ totalAnnouncements }) => {
      switch (handleAnnoucement) {
        case 'Bike':
          return (
            <>
              <Products>
                <AnunciosBikes />
              </Products>
            </>
          );

        case 'annoucement':
          return (
            <>
              <Products>
                <ListOfProducts propBikes={handleAnnoucement}>
                  <div className="products">
                    <span className="main-title ">
                      Todos os anúncios [{totalAnnouncements}]
                      <NumberMark> {categories.TotalCount}</NumberMark>
                    </span>
                    <section className="Fields">
                      <ShowCategories />
                    </section>
                  </div>
                </ListOfProducts>
              </Products>
            </>
          );

        default:
          break;
      }
    },
    [handleAnnoucement, isMobile, categories]
  );

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setAccordionFilterItems({ categories: [] });
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    doCategoriesSearch();
  }, [accordionFilterItems]);

  useEffect(() => {
    const totalCategories = categories.reduce(
      (accumulator, item) => accumulator + item.Quantity,
      0
    );

    setTotalAnnouncements(totalCategories);
  }, [categories]);

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="n bikes | Uma mão na roda! | Brasil"
        ></meta>
        <meta
          property="og:image"
          content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
        ></meta>
        <meta
          property="og:description"
          content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
        ></meta>
        <title>Anúncios | n Bikes | Brasil</title>
      </Helmet>

      <Container>
        <div>
          <h2>Filtros</h2>
          <AccordionFilter />
        </div>

        <Router>
          <div className="right-side">
            <h2 className="anun-title">Anúncios</h2>
            <FilterOptions />
            <ShowAnnoucement totalAnnouncements={totalAnnouncements} />
          </div>
        </Router>
      </Container>
    </>
  );
}
