/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import InputComponent from '~/components/InputComponent';
import * as S from './styles';
import {
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaRegCalendarMinus,
} from 'react-icons/fa';
import { MdLocalOffer } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import {
  FormLabel,
  Button,
  Radio,
  RadioGroup,
  CircularProgress,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { RiCoupon3Fill, RiInformationLine } from 'react-icons/ri';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '~/services/api';
import { toastError, toastSuccess } from '~/utils/defaultToasts';
import Modal from '~/components/Modal';
import { AiOutlineWarning } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import getPartnerVoucherDisablingReason from '~/pages/EditPartnerAndCoupon/utils/getPartnerVoucherDisablingReason';

const schema = yup.object().shape({
  Title: yup
    .string()
    .max(100, 'Nome da promoção deve ter no máximo 100 caracteres.'),
  Value: yup.string(),
  InitialCountLimit: yup.string().test({
    name: 'initial-count-limit-error',
    exclusive: false,
    message: 'Limite de geração inicial deve ser no mínimo de 1 cupom.',
    test: (value) => {
      if (value) {
        return parseInt(value) >= 1;
      }
      return false;
    },
  }),
  DiscountValue: yup.string().test({
    name: 'discount-value-error',
    exclusive: false,
    message: 'Valor do desconto deve ser maior que zero.',
    test: (value) => {
      if (value) {
        return parseFloat(value) > 0;
      }
      return false;
    },
  }),
  IsPercentage: yup.boolean(),
  ExpiresAt: yup.string().notRequired(),
  DaysToUseAfterGeneration: yup.string().test({
    name: 'days-to-use-after-generation-error',
    exclusive: false,
    message: 'Prazo de uso deve ser no mínimo de 1 dia.',
    test: (value) => {
      if (value) {
        return parseInt(value) >= 1;
      }
      return false;
    },
  }),
});

export default function EditCouponInputs() {
  const { partnerId, partnerVoucherId } = useParams();

  const [radioValue, setRadioValue] = useState('percentage');
  const [isPartnerVoucherEnabled, setIsPartnerVoucherEnabled] = useState(true);

  const [openLeavePageModal, setOpenLeavePageModal] = useState(false);
  const handleOpenLeavePageModal = () => setOpenLeavePageModal(true);
  const handleCloseLeavePageModal = () => setOpenLeavePageModal(false);

  const [openWarningModal, setOpenWarningModal] = useState(false);
  const handleOpenWarningModal = () => setOpenWarningModal(true);
  const handleCloseWarningModal = () => setOpenWarningModal(false);

  const [openDisablePartnerVoucherModal, setOpenDisablePartnerVoucherModal] =
    useState(false);
  const handleOpenDisablePartnerVoucherModal = () =>
    setOpenDisablePartnerVoucherModal(true);
  const handleCloseDisablePartnerVoucherModal = () =>
    setOpenDisablePartnerVoucherModal(false);

  const [openEnablePartnerVoucherModal, setOpenEnablePartnerVoucherModal] =
    useState(false);
  const handleOpenEnablePartnerVoucherModal = () => {
    setOpenEnablePartnerVoucherModal(true);
    const data = watch();
  };
  const handleCloseEnablePartnerVoucherModal = () =>
    setOpenEnablePartnerVoucherModal(false);

  const [toolTipContent, setToolTipContent] = useState(null);
  const [countLimitToolTipContent, setCountLimitToolTipContent] =
    useState(null);

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [partnerVoucherData, setPartnerVoucherData] = useState({});
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [dirtyFields, setDirtyFields] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    await api
      .get(`/api/v1/PartnerVouchers/Admin/${partnerVoucherId}`)
      .then((response) => {
        const partnerVoucher = response.data;

        setPartnerVoucherData(partnerVoucher);

        const {
          Title,
          Value,
          InitialCountLimit,
          CountLimit,
          DiscountValue,
          IsPercentage,
          ExpiresAt,
          DaysToUseAfterGeneration,
          IsEnabled,
          DisablingReason,
        } = partnerVoucher;

        setIsPartnerVoucherEnabled(IsEnabled);
        setCountLimitToolTipContent(
          `O limite de geração de cupons inicial é ${InitialCountLimit}, e o limite atual é ${CountLimit}.`
        );

        if (!IsEnabled) {
          const reason = getPartnerVoucherDisablingReason(DisablingReason);
          setToolTipContent(reason);
        }

        setValue('Title', Title);
        setValue('Value', Value);
        setValue('InitialCountLimit', InitialCountLimit);
        setValue('CountLimit', CountLimit);
        setValue('ExpiresAt', ExpiresAt);
        setValue('DiscountValue', DiscountValue);
        setValue('IsPercentage', IsPercentage);
        setValue('DaysToUseAfterGeneration', DaysToUseAfterGeneration);
      });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === 'percentage') {
      setValue('IsPercentage', true);
    } else if (event.target.value === 'monetary') {
      setValue('IsPercentage', false);
    }
  };

  const handleDiscountValueChange = (e) => {
    const value = e.target.value;

    const newValue = value.replace('.', ',');

    if (radioValue === 'monetary') {
      setValue('DiscountValue', newValue.split(' ')[1]);
    } else {
      setValue('DiscountValue', newValue.slice(0, -1));
    }
  };

  const handleClickSaveButton = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleOpenWarningModal();
    }
  };

  const postNewCoupon = async () => {
    handleCloseWarningModal();

    const partnerVoucherFormData = new FormData();

    const data = watch();

    partnerVoucherFormData.append('PartnerId', partnerId);
    partnerVoucherFormData.append('Title', data.Title);
    partnerVoucherFormData.append('Value', data.Value);
    partnerVoucherFormData.append('InitialCountLimit', data.InitialCountLimit);
    partnerVoucherFormData.append('DiscountValue', data.DiscountValue);
    partnerVoucherFormData.append('IsPercentage', data.IsPercentage);
    partnerVoucherFormData.append(
      'DaysToUseAfterGeneration',
      data.DaysToUseAfterGeneration
    );

    try {
      await api.post(`/api/v1/PartnerVouchers`, partnerVoucherFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setIsLoading(false);

      toastSuccess('Dados do Cupom salvos com sucesso!');

      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } catch (err) {
      setIsLoading(false);

      const errors = err.response.data.errors;

      const messages = err.response.data.Messages;

      if (errors) {
        const errorsMessages = Object.values(errors);

        errorsMessages.forEach((messageArr) => {
          const message = messageArr[0];
          const translatedMessage = message
            .replace('Title', 'Nome da promoção')
            .replace('Value', 'Código do cupom')
            .replace('InitialCountLimit', 'Limite inicial de geração')
            .replace('DiscountValue', 'Valor do desconto')
            .replace('IsPercentage', 'Tipo de desconto')
            .replace('ExpiresAt', 'Data de expiração')
            .replace('DaysToUseAfterGeneration', 'Prazo de uso');

          toastError(translatedMessage);
        });
      } else if (messages) {
        messages.forEach((message) => {
          const translatedMessage = message
            .replace('Title', 'Nome da promoção')
            .replace('Value', 'Código do cupom')
            .replace('InitialCountLimit', 'Limite inicial de geração')
            .replace('DiscountValue', 'Valor do desconto')
            .replace('IsPercentage', 'Tipo de desconto')
            .replace('ExpiresAt', 'Data de expiração')
            .replace('DaysToUseAfterGeneration', 'Prazo de uso');

          toastError(translatedMessage);
        });
      }
    }
  };

  const checkIfFormIsDirty = useCallback(() => {
    const watchAllFields = watch();

    if (watchAllFields.Title !== partnerVoucherData.Title) {
      setIsFormDirty(true);
      dirtyFields.push('Title');
    } else if (watchAllFields.Value !== partnerVoucherData.Value) {
      setIsFormDirty(true);
      dirtyFields.push('Value');
    } else if (
      watchAllFields.InitialCountLimit !== partnerVoucherData.InitialCountLimit
    ) {
      setIsFormDirty(true);
      dirtyFields.push('InitialCountLimit');
    } else if (
      watchAllFields.DiscountValue !== partnerVoucherData.DiscountValue
    ) {
      setIsFormDirty(true);
      dirtyFields.push('DiscountValue');
    } else if (
      watchAllFields.IsPercentage !== partnerVoucherData.IsPercentage
    ) {
      setIsFormDirty(true);
      dirtyFields.push('IsPercentage');
    } else if (watchAllFields.ExpiresAt !== partnerVoucherData.ExpiresAt) {
      setIsFormDirty(true);
      dirtyFields.push('ExpiresAt');
    } else if (
      watchAllFields.DaysToUseAfterGeneration !==
      partnerVoucherData.DaysToUseAfterGeneration
    ) {
      setIsFormDirty(true);
      dirtyFields.push('DaysToUseAfterGeneration');
    } else if (watchAllFields.IsEnabled !== partnerVoucherData.IsEnabled) {
      setIsFormDirty(true);
      dirtyFields.push('IsEnabled');
    }
  }, [watch, partnerVoucherData]);

  const handleGoBackButton = useCallback(() => {
    checkIfFormIsDirty();

    if (isFormDirty) {
      handleOpenLeavePageModal();
    } else {
      window.location.href = '/';
    }
  }, [isFormDirty]);

  const onLeaveButtonClick = () => {
    handleCloseLeavePageModal();

    window.location.href = '/';
  };

  const onDisableButtonClick = async () => {
    try {
      await api.patch(
        `/api/v1/PartnerVouchers/${partnerVoucherId}/ToggleEnable`
      );

      handleCloseDisablePartnerVoucherModal();

      toastSuccess('Cupom desabilitado com sucesso!');

      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } catch (err) {
      console.log(err);

      handleCloseDisablePartnerVoucherModal();

      const errorsMessages = err.response?.data?.Messages;

      if (errorsMessages.length > 0) {
        errorsMessages.forEach((message) => {
          toastError(message);
        });
      }
    }
  };

  const onEnableButtonClick = async () => {
    checkIfFormIsDirty();
    trigger();

    const partnerVoucherFormData = new FormData();
    const data = watch();

    if (data.Value !== partnerVoucherData.Value) {
      handleCloseEnablePartnerVoucherModal();
      toastError(
        'O código do cupom não pode ser alterado! Se quiser alterá-lo, crie um novo cupom clicando em "Salvar Cupom".'
      );
      return;
    }

    partnerVoucherFormData.append('Title', data.Title);
    partnerVoucherFormData.append('InitialCountLimit', data.InitialCountLimit);
    partnerVoucherFormData.append('DiscountValue', data.DiscountValue);
    partnerVoucherFormData.append('IsPercentage', data.IsPercentage);
    partnerVoucherFormData.append('ExpiresAt', data.ExpiresAt || '');
    partnerVoucherFormData.append(
      'DaysToUseAfterGeneration',
      data.DaysToUseAfterGeneration
    );

    try {
      await api.patch(
        `/api/v1/PartnerVouchers/${partnerVoucherId}/EnableAndUpdate`,
        partnerVoucherFormData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      handleCloseEnablePartnerVoucherModal();

      if (isFormDirty) {
        toastSuccess('Cupom habilitado e editado com sucesso!');
      } else {
        toastSuccess('Cupom habilitado com sucesso!');
      }

      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } catch (err) {
      console.log(err);

      handleCloseEnablePartnerVoucherModal();

      const errorsMessages = err.response?.data?.Messages;

      if (errorsMessages.length > 0) {
        errorsMessages.forEach((message) => {
          toastError(message);
        });
      }
    }
  };

  const disablePartnerVoucherModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <AiOutlineWarning color="#fce76d" size={80} />

      <S.ModalText>Deseja realmente desabilitar esse cupom?</S.ModalText>

      <S.ModalButtonsDiv>
        <Button
          variant="contained"
          size="large"
          onClick={handleCloseDisablePartnerVoucherModal}
        >
          Cancelar
        </Button>

        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={onDisableButtonClick}
        >
          Desabilitar
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  const enablePartnerVoucherModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <AiOutlineWarning color="#fce76d" size={80} />

      <S.ModalText>Deseja realmente habilitar esse cupom?</S.ModalText>

      <S.ModalSubText>
        Como um parceiro pode possuir apenas 1 cupom habilitado, caso exista
        outro cupom habilitado desse parceiro, ele será desabilitado.{' '}
      </S.ModalSubText>

      <S.ModalButtonsDiv>
        <Button
          variant="contained"
          size="large"
          onClick={handleCloseEnablePartnerVoucherModal}
        >
          Cancelar
        </Button>

        <Button
          color="secondary"
          variant="contained"
          size="large"
          onClick={onEnableButtonClick}
        >
          Habilitar
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  const leavePageModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <AiOutlineWarning color="#304470" size={80} />

      <S.ModalText>Desejar sair sem salvar as alterações?</S.ModalText>

      <S.ModalButtonsDiv>
        <Button
          color="lightBlue"
          variant="contained"
          size="large"
          onClick={handleCloseLeavePageModal}
        >
          Cancelar
        </Button>

        <Button variant="contained" size="large" onClick={onLeaveButtonClick}>
          Sair
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  const warningModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <S.Stack
        style={{
          width: '100%',

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <AiOutlineWarning color="#304470" size={80} />
        <S.ModalTitle>Atenção!</S.ModalTitle>
      </S.Stack>

      <S.ModalText
        style={{
          maxWidth: 'unset',

          textAlign: 'center',
        }}
      >
        Não é possível um parceiro ter mais de um cupom ativo.{' '}
        <span>
          Portanto, ao salvar essas alterações, você estará criando um novo lote
          de cupom de desconto, e desabilitando o já existente.
        </span>{' '}
      </S.ModalText>

      <S.ModalSubText>
        Porém, os cupons do lote anterior já gerados pelos usuários continuarão
        valendo até atingirem as suas respectivas datas de expiração.{' '}
      </S.ModalSubText>

      <S.ModalButtonsDiv>
        <Button
          color="lightBlue"
          variant="contained"
          size="large"
          onClick={handleCloseWarningModal}
        >
          Cancelar
        </Button>

        <Button variant="contained" size="large" onClick={postNewCoupon}>
          {isLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            'Salvar Novo Cupom'
          )}
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  return (
    <S.Stack
      style={{
        width: '100%',

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '4.5rem',

        fontSize: '16px',
      }}
    >
      <S.FormHeader>
        <S.FormTitle>Cupom de Desconto</S.FormTitle>
        <S.Line />

        <S.CustomToolTip title={toolTipContent} arrow enterTouchDelay={100}>
          <S.StatusBadge
            style={{
              backgroundColor: isPartnerVoucherEnabled ? '#00C853' : '#4F4F4F',
            }}
          >
            {isPartnerVoucherEnabled ? 'Habilitado' : 'Desabilitado'}
          </S.StatusBadge>
        </S.CustomToolTip>
      </S.FormHeader>

      <S.CouponForm>
        <S.CouponInputsDiv>
          <InputComponent
            placeholder="Nome da promoção"
            leftAddornment={<RiCoupon3Fill size={20} />}
            isMaxWidth
            {...register('Title')}
            onChange={(e) => {
              checkIfFormIsDirty();
              setValue('Title', e.target.value);
            }}
            isError={errors.Title ? true : false}
            errorMessage={errors.Title?.message}
            infoText="Ex: Promoção de Páscoa"
          />

          <InputComponent
            placeholder="Código do cupom"
            leftAddornment={<MdLocalOffer size={20} />}
            isMaxWidth
            {...register('Value')}
            onChange={(e) => {
              checkIfFormIsDirty();
              setValue('Value', e.target.value);
            }}
            isError={errors.Value ? true : false}
            errorMessage={errors.Value?.message}
            infoText="Ex: NBIKES10"
          />
        </S.CouponInputsDiv>

        <S.CouponInputsDiv>
          <InputComponent
            placeholder="Data de expiração"
            leftAddornment={<FaRegCalendarAlt />}
            isMaxWidth
            type="date"
            min={new Date().toISOString().split('T')[0]}
            {...register('ExpiresAt')}
            onChange={(e) => {
              checkIfFormIsDirty();
              setValue('ExpiresAt', e.target.value);
            }}
            isError={errors.ExpiresAt ? true : false}
            errorMessage={errors.ExpiresAt?.message}
          />

          <InputComponent
            placeholder="Limite inicial de geração"
            leftAddornment={<FaRegCalendarMinus />}
            isMaxWidth
            type="number"
            min={1}
            {...register('InitialCountLimit')}
            onChange={(e) => {
              checkIfFormIsDirty();
              setValue('InitialCountLimit', e.target.value);
            }}
            isError={errors.InitialCountLimit ? true : false}
            errorMessage={errors.InitialCountLimit?.message}
          />

          <S.CustomToolTip
            title={countLimitToolTipContent}
            arrow
            enterTouchDelay={100}
          >
            <S.CountLimitInfo>
              {<RiInformationLine size={21} />}
            </S.CountLimitInfo>
          </S.CustomToolTip>
        </S.CouponInputsDiv>

        <S.CouponInputsDiv
          style={{
            alignItems: 'flex-start',
          }}
        >
          <NumericFormat
            customInput={InputComponent}
            placeholder="Prazo de uso após geração"
            leftAddornment={<FaRegCalendarCheck />}
            isMaxWidth
            suffix=" dias(s)"
            allowNegative={false}
            allowLeadingZeros={false}
            value={partnerVoucherData.DaysToUseAfterGeneration}
            onChange={(e) => {
              checkIfFormIsDirty();
              setValue(
                'DaysToUseAfterGeneration',
                e.target.value.split(' ')[0]
              );
            }}
            isError={errors.DaysToUseAfterGeneration ? true : false}
            errorMessage={errors.DaysToUseAfterGeneration?.message}
          />

          <S.Stack
            style={{
              width: '100%',

              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumericFormat
              customInput={InputComponent}
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              prefix={radioValue === 'monetary' ? 'R$ ' : undefined}
              suffix={radioValue === 'percentage' ? '%' : undefined}
              placeholder="Valor do desconto"
              isMaxWidth
              allowLeadingZeros={false}
              value={partnerVoucherData.DiscountValue}
              onChange={(e) => {
                checkIfFormIsDirty();
                handleDiscountValueChange(e);
              }}
              isError={errors.DiscountValue ? true : false}
              errorMessage={errors.DiscountValue?.message}
            />

            <RadioGroup
              row
              sx={{
                width: '100%',
                height: '100%',

                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '1rem',
              }}
              value={radioValue}
              onChange={(e) => {
                checkIfFormIsDirty();
                handleRadioChange(e);
              }}
            >
              <S.Stack
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FormLabel
                  sx={{
                    fontSize: '16px',
                    color: '#304470',
                    fontFamily: 'Nunito',
                  }}
                >
                  Percentual
                </FormLabel>
                <Radio value="percentage" size="large" />
              </S.Stack>

              <S.Stack
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FormLabel
                  sx={{
                    fontSize: '16px',
                    color: '#304470',
                    fontFamily: 'Nunito',
                  }}
                >
                  Monetário
                </FormLabel>
                <Radio value="monetary" size="large" />
              </S.Stack>
            </RadioGroup>
          </S.Stack>
        </S.CouponInputsDiv>

        <S.ButtonsDiv>
          {isPartnerVoucherEnabled ? (
            <Button
              color="secondary"
              variant="contained"
              type="button"
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                textTransform: 'capitalize',

                borderRadius: '13px',
              }}
              onClick={() => handleOpenDisablePartnerVoucherModal()}
            >
              Desabilitar Cupom
            </Button>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              type="button"
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                textTransform: 'capitalize',

                borderRadius: '13px',
              }}
              onClick={() => handleOpenEnablePartnerVoucherModal()}
            >
              Habilitar Cupom
            </Button>
          )}

          <Button
            variant="contained"
            type="button"
            sx={{
              fontWeight: '700',
              fontSize: '20px',
              textTransform: 'capitalize',

              borderRadius: '13px',
            }}
            onClick={handleGoBackButton}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            color="lightBlue"
            sx={{
              fontWeight: '700',
              fontSize: '20px',
              textTransform: 'capitalize',

              borderRadius: '13px',
            }}
            type="button"
            onClick={handleClickSaveButton}
          >
            Salvar Cupom
          </Button>
        </S.ButtonsDiv>
      </S.CouponForm>

      <Modal
        children={leavePageModal}
        open={openLeavePageModal}
        handleClose={handleCloseLeavePageModal}
      />

      <Modal
        children={warningModal}
        open={openWarningModal}
        handleClose={handleCloseWarningModal}
      />

      <Modal
        children={disablePartnerVoucherModal}
        open={openDisablePartnerVoucherModal}
        handleClose={handleCloseDisablePartnerVoucherModal}
      />

      <Modal
        children={enablePartnerVoucherModal}
        open={openEnablePartnerVoucherModal}
        handleClose={handleCloseEnablePartnerVoucherModal}
      />
    </S.Stack>
  );
}
