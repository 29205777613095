import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { toastError } from 'utils/defaultToasts';

import api from '~/services/api';

import * as S from './styles';

import {
  loadAnnouncementCategoryEnum,
  loadAnnouncementEnum,
} from '~/store/ducks/enums/actions';

export default function Information({
  setPage,
  announcement,
  setAnnouncement,
}) {
  const animationContainerRef = useRef(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const [mustScroll, setMustScroll] = useState(false);

  const [titleLength, setTitleLength] = useState('');
  const [brandLength, setBrandLength] = useState('');
  const [detailLength, setDetailLength] = useState('');

  const [forkTravel, setForkTravel] = useState(
    announcement?.ForkTravel?.Name || 'None'
  );
  const [size, setSize] = useState(announcement?.Size?.Name || 'None');

  const [errors, setErrors] = useState([]);

  const formRef = useRef(null);

  const currentYear = new Date().getFullYear();

  const [subCategoryEnum, setSubCategoryEnum] = useState([]);

  const announcementsEnums = useSelector(
    (state) => state.enumsReducer.announcementsEnums
  );

  const announcementsCategoryEnum = useSelector(
    (state) => state.enumsReducer.announcementsCategoryEnums
  );

  const bicycleInvention = useCallback(
    () => (
      <div>
        A bicicleta foi inventada em{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://pt.wikipedia.org/wiki/Bicicleta#Hist%C3%B3ria"
        >
          1817
        </a>
        ...
      </div>
    ),
    []
  );

  const keyUpHandler = useCallback((refName, set) => {
    set(refName.currentTarget.value.length);
  }, []);

  const removeFromErrors = useCallback((fieldName) => {
    delete errors[fieldName];
    setErrors(errors);
  }, []);

  const getEnumerators = useCallback(async () => {
    if (announcementsEnums.length !== 0) return;

    const getEnumeratorsUrl = 'api/v1/Announcements/Enumerators';

    try {
      const response = await api.get(getEnumeratorsUrl);
      const { data } = response;

      dispatch(loadAnnouncementEnum(data));
    } catch (error) {
      toastError('Erro ao buscar a categoria do seu anúncio.');
    }
  }, []);

  const getCategoryEnumerators = useCallback(async () => {
    if (announcementsCategoryEnum.length !== 0) return;

    const getCategoryEnumeratorsUrl =
      'api/v1/Announcements/Enumerators/Categories';

    try {
      const response = await api.get(getCategoryEnumeratorsUrl);
      const { data } = response;

      dispatch(loadAnnouncementCategoryEnum(data));
    } catch (error) {
      toastError('Erro ao buscar a subcategoria do seu anúncio.');
    }
  }, []);

  const getSubCategoryEnumerators = useCallback(async (category = 'Bike') => {
    setSubCategoryEnum([]);

    if (category === 'Selecione') return;

    const getSubCaregoryEnumeratorsUrl =
      'api/v1/Announcements/Enumerators/Categories';

    try {
      const response = await api.get(getSubCaregoryEnumeratorsUrl, {
        params: { aParentCategory: category },
      });

      const { data } = response;

      setSubCategoryEnum(data);
    } catch (error) {
      toastError('Erro ao buscar a subcategoria do seu anúncio.');
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getEnumerators();
    await getCategoryEnumerators();

    if (announcement) {
      await getSubCategoryEnumerators(announcement?.MajorCategory?.Name);
    }
  };

  const createAnnouncement = useCallback(async (form) => {
    const postAnnouncementUrl = 'api/v1/Announcements';
    const patchAnnouncementUrl = `/api/v1/Announcements/${announcement.Id}/Information`;

    const parameters = {
      Title: form.title.value,
      Description: form.description.value,
      Category:
        form.subCategory.value === 'Selecionar' ? null : form.subCategory.value,
      Brand: form.brand.value,
      UseCondition: form.condition.value,
      UseConditionDescription: form.useConditionDescription.value,
      Year: form.year.value,
      SizeNumber: form.number.value,
      Size: form.size.value,
      RimSize: form.rimSize.value,
      ForkTravel: form.forkTravel.value,
      SerialNumber: form.serialNumber.value,
    };

    try {
      const announcementExists = announcement?.Id?.length > 0;

      if (!announcementExists) {
        const response = await api.post(postAnnouncementUrl, parameters);
        setAnnouncement(response.data);
      } else {
        const response = await api.patch(patchAnnouncementUrl, parameters);
        setAnnouncement(response.data);
      }

      hideContainerAnimation();

      setTimeout(() => {
        setPage('Images');
      }, 500);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push('/Login');

        return;
      }

      setErrors(error?.response?.data?.errors);
      setMustScroll(true);

      setTimeout(() => {
        setMustScroll(false);
      }, 1000);

      toastError(
        'Erro ao enviar as informações. Verifique os campos e tente novamente.'
      );
    }
  }, []);

  const showContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '1';
    animationContainerRef.current.style.transform = 'translateX(0)';
  };

  const hideContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '0';
    animationContainerRef.current.style.transform = 'translateX(100%)';
  };

  useEffect(() => {
    showContainerAnimation();
  }, []);

  return (
    <div>
      <S.AnimationContainer ref={animationContainerRef}>
        <S.MainContainer>
          <form ref={formRef}>
            <S.Container propErrors={errors} propScroll={mustScroll}>
              <h1>Vamos preencher alguns dados do seu produto</h1>

              <S.Field>
                <h2>Identifique seu produto, marca e modelo</h2>
                <p className="title-p-description">
                  {' '}
                  Este será o título do anúncio
                </p>
                <div className="title-input-container">
                  <input
                    defaultValue={announcement?.Title}
                    onChange={() => removeFromErrors('Title')}
                    name="title"
                    onKeyUp={(e) => keyUpHandler(e, setTitleLength)}
                    type="text"
                    placeholder="Digite o titulo do anúncio"
                    maxLength="200"
                  />
                  <br />
                  <span>
                    {' '}
                    Ex: Mountain Bike Caloi Évora - Aro 29 - 21 Marchas{' '}
                  </span>
                  <span className="lenghtCounter">{titleLength}/200</span>
                </div>
              </S.Field>

              <S.Field>
                <h2>Descreva uma ficha técnica do seu produto</h2>
                <p>
                  Todos os detalhes são importantes para responder as dúvidas
                  dos compradores
                </p>
                <div name="descriptionTitle">
                  <textarea
                    defaultValue={announcement?.Description}
                    onChange={() => removeFromErrors('Description')}
                    onKeyUp={(e) => keyUpHandler(e, setDetailLength)}
                    name="description"
                    type="text"
                    maxLength="2000"
                  />
                  <br />

                  <span className="lenghtCounter">{detailLength}/2000</span>
                </div>
              </S.Field>

              <S.Field>
                <h2>Escolha a categoria e marca</h2>
                <p> Assim fica mais fácil encontrar o seu produto</p>
                <div>
                  <div className="selectField">
                    <div className="selectSection">
                      <span>Categoria</span>
                      <select
                        className='non-border-select'
                        defaultValue={announcement?.MajorCategory?.Name}
                        name="category"
                        onChange={() => {
                          const selectedCategory =
                            formRef.current?.category?.value;

                          getSubCategoryEnumerators(selectedCategory);
                        }}
                      >
                        {announcementsCategoryEnum?.map((category) => (
                          <option key={category?.Id} value={category?.Name}>
                            {category?.Description}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="selectSection" name="subCategoryBorder">
                      <span>Subcategoria</span>
                      <select
                        className='non-border-select'
                        name="subCategory"
                        onChange={() => {
                          removeFromErrors('Category');
                        }}
                      >
                        {subCategoryEnum?.map((category) =>
                          category?.DtoEnums?.length > 0 ? (
                            <optgroup label={category?.Description}>
                              {category?.DtoEnums?.map((subCategory) => (
                                <option
                                  selected={
                                    announcement?.Category?.Name ===
                                    subCategory?.Name
                                  }
                                  key={subCategory?.Id}
                                  value={subCategory?.Name}
                                >
                                  {subCategory?.Description}
                                </option>
                              ))}
                            </optgroup>
                          ) : (
                            <option
                              selected={
                                announcement?.Category?.Name === category?.Name
                              }
                              key={category?.Id}
                              value={category?.Name}
                            >
                              {category?.Description}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="brand-div">Marca</div>
                  <input
                    defaultValue={announcement?.Brand}
                    name="brand"
                    onKeyUp={(e) => keyUpHandler(e, setBrandLength)}
                    type="text"
                    placeholder="Digite a marca"
                    maxLength="50"
                  />
                  <span className="lenghtCounter">{brandLength}/50</span>
                  <span> Ex: Caloi, Sense, KWS, Outros </span>

                  <div className="serial-number-div">
                    Número de série (Opcional)
                  </div>
                  <input
                    defaultValue={announcement?.SerialNumber}
                    name="serialNumber"
                    type="text"
                    placeholder="Digite o número de série"
                    maxLength="50"
                  />
                  <br />
                </div>
              </S.Field>

              <S.Field>
                <h2>Mais informações</h2>
                <p> São importantes para quem vai comprar</p>

                <section className="selectField">
                  <div className="selectSection">
                    <span>Condição</span>
                    <select
                      className='non-border-select'
                      name="condition"
                      defaultValue={announcement?.UseCondition?.Name}
                    >
                      {announcementsEnums
                        ?.find(
                          (enumItem) =>
                            enumItem.Name === 'AnnouncementUseCondition'
                        )
                        ?.DtoEnumContents?.map((content) => (
                          <option key={content?.Id} value={content?.Name}>
                            {content?.Description}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="selectSection">
                    <span>Descreva a Condição</span>
                    <input
                      className="non-border"
                      name="useConditionDescription"
                      id="useConditionDescription"
                      defaultValue={announcement?.UseConditionDescription}
                    />
                    <div className="optional">Opcional</div>
                  </div>

                  <div className="selectSection">
                    <span>Ano</span>
                    <input
                      className="non-border"
                      defaultValue={announcement?.Year}
                      type="number"
                      name="year"
                      min="1939"
                      max={currentYear}
                      onChange={(e) => {
                        if (e.target.value >= currentYear) {
                          e.target.value = currentYear;
                        }
                      }}
                      onBlur={(e) => {
                        if (
                          e?.target?.value < 1817 &&
                          e?.target?.value?.length !== 0
                        ) {
                          e.target.value = 1817;
                          toastError(bicycleInvention);
                        }
                      }}
                    />
                    <div className="optional">Opcional</div>
                  </div>

                  <S.FlexWrapper>
                    <div className="selectSection">
                      <span>Tamanho</span>
                      <select
                        className='non-border-select'
                        style={{ width: '240px' }}
                        name="size"
                        value={size}
                        onChange={(e) => setSize(e?.target?.value)}
                      >
                        {announcementsEnums
                          ?.find(
                            (enumItem) => enumItem?.Name === 'AnnouncementSize'
                          )
                          ?.DtoEnumContents?.map((content) => (
                            <option key={content?.Id} value={content?.Name}>
                              {content?.Description}
                            </option>
                          ))}
                      </select>
                      <div className="optional">Opcional</div>
                    </div>

                    <span id="or-span">e/ou</span>

                    <div className="selectSection">
                      <span>Número</span>
                      <input
                        className="non-border"
                        style={{ width: '225px' }}
                        defaultValue={announcement?.SizeNumber}
                        type="number"
                        name="number"
                        min="0"
                        max="9999"
                        onChange={(e) => {
                          if (Number(e.target.value) > 9999) {
                            e.target.value = 9999;
                          }
                        }}
                      />
                      <div className="optional">Opcional</div>
                    </div>
                  </S.FlexWrapper>

                  <div className="selectSection">
                    <span>Curso de suspensão</span>
                    <select
                      className='non-border-select'
                      name="forkTravel"
                      value={forkTravel}
                      onChange={(e) => setForkTravel(e.target.value)}
                    >
                      {announcementsEnums[1]?.DtoEnumContents?.map(
                        (content) => (
                          <option key={content?.Id} value={content?.Name}>
                            {content?.Description}
                          </option>
                        )
                      )}
                    </select>
                    <div className="optional">Opcional</div>
                  </div>
                  <div className="selectSection">
                    <span>Tamanho do Aro</span>
                    <select
                      className='non-border-select'
                      name="rimSize"
                      defaultValue={announcement?.RimSize?.Name}
                    >
                      {announcementsEnums
                        ?.find(
                          (enumItem) => enumItem?.Name === 'AnnouncementRimSize'
                        )
                        ?.DtoEnumContents?.map((content) => (
                          <option
                            key={content?.Id}
                            value={content?.Name}
                            selected={content?.Name === 'None' ? true : false}
                          >
                            {content?.Description}
                          </option>
                        ))}
                    </select>
                    <div className="optional">Opcional</div>
                  </div>
                </section>
              </S.Field>
            </S.Container>
          </form>

          <section className="buttons-section">
            <div />
            <button
              onClick={() => {
                createAnnouncement(formRef.current);
              }}
            >
              Avançar
            </button>
          </section>
        </S.MainContainer>
      </S.AnimationContainer>
    </div>
  );
}
