/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import InputComponent from '~/components/InputComponent';
import * as S from './styles';
import {
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaRegCalendarMinus,
} from 'react-icons/fa';
import { MdLocalOffer } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RiCoupon3Fill } from 'react-icons/ri';

export default function CouponInputs() {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const [radioValue, setRadioValue] = useState('percentage');

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === 'percentage') {
      setValue('CouponInputs.IsPercentage', true);
    } else if (event.target.value === 'monetary') {
      setValue('CouponInputs.IsPercentage', false);
    }
  };

  const handleDiscountValueChange = (e) => {
    const value = e.target.value;

    const newValue = value.replace('.', ',');

    if (radioValue === 'monetary') {
      setValue('CouponInputs.DiscountValue', newValue.split(' ')[1]);
    } else {
      setValue('CouponInputs.DiscountValue', newValue.slice(0, -1));
    }
  };

  return (
    <S.Stack
      style={{
        width: '100%',

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '3rem',

        fontSize: '16px',
      }}
    >
      <S.FormHeader>
        <S.FormTitle>Cadastro do Cupom</S.FormTitle>
        <S.Line />
      </S.FormHeader>

      <S.CouponFormDiv>
        <S.CouponInputsDiv>
          <InputComponent
            placeholder="Nome da promoção"
            leftAddornment={<RiCoupon3Fill size={20} />}
            isMaxWidth
            {...register('CouponInputs.Title')}
            onChange={(e) => setValue('CouponInputs.Title', e.target.value)}
            isError={errors.CouponInputs?.Title ? true : false}
            errorMessage={errors.CouponInputs?.Title?.message}
            infoText="Ex: Promoção de Páscoa"
          />

          <InputComponent
            placeholder="Código do cupom"
            leftAddornment={<MdLocalOffer size={20} />}
            isMaxWidth
            {...register('CouponInputs.Value')}
            onChange={(e) => setValue('CouponInputs.Value', e.target.value)}
            isError={errors.CouponInputs?.Value ? true : false}
            errorMessage={errors.CouponInputs?.Value?.message}
            infoText="Ex: NBIKES10"
          />
        </S.CouponInputsDiv>

        <S.CouponInputsDiv>
          <InputComponent
            placeholder="Data de expiração"
            leftAddornment={<FaRegCalendarAlt />}
            isMaxWidth
            type="date"
            min={new Date().toISOString().split('T')[0]}
            {...register('CouponInputs.ExpiresAt')}
            onChange={(e) => setValue('CouponInputs.ExpiresAt', e.target.value)}
            isError={errors.CouponInputs?.ExpiresAt ? true : false}
            errorMessage={errors.CouponInputs?.ExpiresAt?.message}
          />

          <InputComponent
            placeholder="Limite inicial de geração"
            leftAddornment={<FaRegCalendarMinus />}
            isMaxWidth
            type="number"
            min={1}
            {...register('CouponInputs.InitialCountLimit')}
            onChange={(e) =>
              setValue('CouponInputs.InitialCountLimit', e.target.value)
            }
            isError={errors.CouponInputs?.InitialCountLimit ? true : false}
            errorMessage={errors.CouponInputs?.InitialCountLimit?.message}
          />
        </S.CouponInputsDiv>

        <S.CouponInputsDiv
          style={{
            alignItems: 'flex-start',
          }}
        >
          <NumericFormat
            customInput={InputComponent}
            placeholder="Prazo de uso após geração"
            leftAddornment={<FaRegCalendarCheck />}
            isMaxWidth
            suffix=" dias(s)"
            allowNegative={false}
            allowLeadingZeros={false}
            {...register('CouponInputs.DaysToUseAfterGeneration')}
            onChange={(e) =>
              setValue(
                'CouponInputs.DaysToUseAfterGeneration',
                e.target.value.split(' ')[0]
              )
            }
            isError={
              errors.CouponInputs?.DaysToUseAfterGeneration ? true : false
            }
            errorMessage={
              errors.CouponInputs?.DaysToUseAfterGeneration?.message
            }
          />

          <S.Stack
            style={{
              width: '100%',

              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumericFormat
              customInput={InputComponent}
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              prefix={radioValue === 'monetary' ? 'R$ ' : undefined}
              suffix={radioValue === 'percentage' ? '%' : undefined}
              placeholder="Valor do desconto"
              isMaxWidth
              allowLeadingZeros={false}
              {...register('CouponInputs.DiscountValue')}
              onChange={handleDiscountValueChange}
              isError={errors.CouponInputs?.DiscountValue ? true : false}
              errorMessage={errors.CouponInputs?.DiscountValue?.message}
            />

            <RadioGroup
              row
              sx={{
                width: '100%',
                height: '100%',

                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '1rem',
              }}
              value={radioValue}
              onChange={handleRadioChange}
            >
              <S.Stack
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FormLabel
                  sx={{
                    fontSize: '16px',
                    color: '#304470',
                    fontFamily: 'Nunito',
                  }}
                >
                  Percentual
                </FormLabel>
                <Radio value="percentage" size="large" />
              </S.Stack>

              <S.Stack
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FormLabel
                  sx={{
                    fontSize: '16px',
                    color: '#304470',
                    fontFamily: 'Nunito',
                  }}
                >
                  Monetário
                </FormLabel>
                <Radio value="monetary" size="large" />
              </S.Stack>
            </RadioGroup>
          </S.Stack>
        </S.CouponInputsDiv>
      </S.CouponFormDiv>
    </S.Stack>
  );
}
