/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Coupon } from '~/features/Coupon';
import { styled } from '~/stiches.config';
import api from '~/services/api';
import { useSelector } from 'react-redux';
import Loading from '~/components/Loading';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 230,

  borderRadius: 12,

  '@media screen and (max-width: 960px)': {
    width: '100%',

  },
});

const Flex = styled('div', {
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled('h3', {
  fontSize: 24,
  fontWeight: 700,
  color: '#000',

  width: '100%',

  textAlign: 'start',

  alignSelf: 'flex-start',
});

const AddPartnerButton = styled('button', {
  padding: '4px 16px',
  background: '#45BDC2',

  borderRadius: 12,
  border: 'none',

  fontSize: 24,

  color: 'white',

  transition: 0.15 + 's',

  marginTop: 50,

  '&:hover': {
    background: '#318083',
  },

  '@media screen and (max-width: 960px)': {
    marginTop: 18,
  },
});

const CouponsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: '100%',
  height: '100%',

  '@media screen and (max-width: 960px)': {
    width: '100%',
    height: 290,

    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'auto',

    justifyContent: 'flex-start',
    alignItems: 'unset',
  },

  '@media screen and (max-width: 330px)': {
    height: 230,
  },
});

const NoCoupons = styled('div', {
  position: 'relative',
  zIndex: 0,

  width: '100%',
  maxWidth: 320,
  height: 120,

  background: '#EDE6DE',

  borderRadius: 12,

  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Subtitle = styled('h4', {
  fontSize: 18,
  fontWeight: 700,
  color: 'rgb(34 52 86)',
  textAlign: 'center',
});

export function PartnersSection({ privilegeType }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const userEmail = useSelector((state) => state.authReducer.auth.email);

  const isAdmin = privilegeType === 'Administrator';

  const handleAddPartner = useCallback(() => {
    history.push({ pathname: '/RegisterPartnerAndCoupon' });
  }, []);

  const [partnersVouchers, setPartnersVouchers] = useState([]);

  const getPartnersVouchersOrdered = (data) => {
    const partnersVouchersOrdered = data.sort((a, b) => {
      if (a.IsEnabled === b.IsEnabled) {
        return 0;
      }

      return a.IsEnabled ? -1 : 1;
    });

    return partnersVouchersOrdered;
  };

  const getPartnersVouchers = useCallback(async () => {
    const url = isAdmin ? '/api/v1/PartnerVouchers/Admin' : '/api/v1/PartnerVouchers';

    api
      .get(url)
      .then((response) => {
        const partnersVouchersOrdered = getPartnersVouchersOrdered(
          response.data
        );

        setPartnersVouchers(partnersVouchersOrdered);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getPartnersVouchers();
  }, []);

  return process.env.REACT_APP_FLAG_USER_EMAIL == userEmail ||
    process.env.REACT_APP_FLAG_USER_EMAIL == '' ||
    isAdmin ? (
      <Container
        style={{
          marginBottom: 0,
        }}
      >
        <Title>Parceiros</Title>

        {isLoading ? (
          <Loading loadingText={'Buscando Parceiros...'} />
        ) : partnersVouchers.length === 0 ? (
          <Flex>
            <NoCoupons>
              <Subtitle>Nenhum parceiro cadastrado no momento.</Subtitle>
            </NoCoupons>
            {isAdmin ?
              <>
                <AddPartnerButton onClick={handleAddPartner}>
                  Adicionar parceiro
                </AddPartnerButton>
              </> : '' }
          </Flex>
        ) : (
          <Flex>
            <CouponsContainer>
              {partnersVouchers.map((partnerVoucher, i) => (
                <Coupon
                  discount={partnerVoucher.DiscountValue}
                  remaining={partnerVoucher.CountLimit}
                  hasAdminFeatures={isAdmin}
                  image={partnerVoucher.PartnerBackground.Url}
                  partnerVoucherId={partnerVoucher.Id}
                  partnerUrl={partnerVoucher.PartnerWebsite}
                  partnerId={partnerVoucher.PartnerId}
                  isDisabled={partnerVoucher.IsEnabled === false}
                  viewModeOnly={partnerVoucher.IsEnabled === false}
                  isPercentage={partnerVoucher.IsPercentage}
                  key={i + partnerVoucher.Title}
                  value={partnerVoucher.Value}
                />
              ))}
            </CouponsContainer>
          </Flex>
        )}
      </Container>
    ) : partnersVouchers.length > 0 ? (
      <Container>
        <Title>Parceiros</Title>
        <CouponsContainer>
          {partnersVouchers.map((partnerVoucher, i) => (
            <Coupon
              discount={partnerVoucher.DiscountValue}
              remaining={partnerVoucher.CountLimit}
              hasAdminFeatures={isAdmin}
              image={partnerVoucher.PartnerBackground.Url}
              partnerVoucherId={partnerVoucher.Id}
              partnerUrl={partnerVoucher.PartnerWebsite}
              partnerId={partnerVoucher.PartnerId}
              isDisabled={partnerVoucher.IsEnabled === false}
              viewModeOnly={partnerVoucher.IsEnabled === false}
              isPercentage={partnerVoucher.IsPercentage}
              key={i + partnerVoucher.Title}
              value={partnerVoucher.Value}
            />
          ))}
        </CouponsContainer>
      </Container>
  ) : null;
}
