import styled from 'styled-components';

export const Container = styled.div`
  width: clamp(220px, 100%, 425px);

  @keyframes InputAnim {
    from {
      transform: translateX(-500px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  opacity: 0;

  animation-name: InputAnim;
  animation-delay: ${({ animationDelay }) => animationDelay};
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
`;
