import { AuthTypes } from './types';

export function signInRequest(auth) {
  return {
    type: AuthTypes.SIGN_IN_REQUEST,
    payload: auth,
  };
}

export function signInSuccess(accessToken, profile, username) {
  return {
    type: AuthTypes.SIGN_IN_SUCCESS,
    payload: { accessToken, profile, username },
  };
}
export function updateProfile(profile) {
  return {
    type: AuthTypes.UPDATE_PROFILE,
    payload: { profile },
  };
}

export function signUpRequest(Data) {
  return {
    type: AuthTypes.SIGN_UP_REQUEST,
    payload: Data,
  };
}
export function signUpSuccess(data) {
  return {
    type: AuthTypes.SIGN_UP_SUCCESS,
    payload: data,
  };
}

export function setPrivilegeType(privilegeType) {
  return {
    type: AuthTypes.SET_PRIVILEGE_TYPE,
    payload: privilegeType,
  };
}

export function signUpConfirmation(data) {
  return {
    type: AuthTypes.SIGN_UP_CONFIRMATION,
    payload: { data },
  };
}

export function emailConfirmation(data) {
  return {
    type: AuthTypes.EMAIL_CONFIRMATION,
    payload: data,
  };
}

export function resendCode(data) {
  return {
    type: AuthTypes.RESEND_CODE,
    payload: { data },
  };
}

export function signOut() {
  return {
    type: AuthTypes.SIGN_OUT,
  };
}

export function stopLoading() {
  return {
    type: AuthTypes.STOP_LOADING,
  };
}

export function uploadProfilePic(data) {
  return {
    type: AuthTypes.UPLOAD_PROFILE_PIC,
    payload: data,
  };
}

export function forgotEmailPassword(data) {
  return {
    type: AuthTypes.FORGOT_EMAIL_PASSWORD,
    payload: data,
  };
}

export function confirmNewPassword(data) {
  return {
    type: AuthTypes.CONFIRM_NEW_PASSWORD,
    payload: data,
  };
}
