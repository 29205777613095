import React, { useState } from 'react';

import { FaCircle } from 'react-icons/fa';

import {
  Container,
  MainContainer,
  Header,
  ProgressBar,
  MainInfo,
  Footer,
} from './styles';

import Products from './components/Products';

import Identify from './components/Identify';

import Charge from './components/Charge';

import Payment from './components/Payment';

import Conclusion from './components/Conclusion';

export default function Plugin({ setBuy }) {
  const [mainInfo, setMainInfo] = useState('Products');

  const showMainInfo = () => {
    switch (mainInfo) {
      case 'Products':
        return <Products />;

      case 'Identify':
        return <Identify />;

      case 'Charge':
        return <Charge />;

      case 'Payment':
        return <Payment />;

      case 'Conclusion':
        return <Conclusion />;

      default:
        break;
    }
  };

  const showButton = () => {
    if (mainInfo === 'Products') {
      return (
        <>
          <span />
          <button type="button" onClick={() => setMainInfo('Identify')}>
            Avançar
          </button>
        </>
      );
    }
    if (mainInfo === 'Identify') {
      return (
        <>
          <button
            type="button"
            className="firstButton"
            onClick={() => setMainInfo('Products')}
          >
            Voltar
          </button>
          <button type="button" onClick={() => setMainInfo('Charge')}>
            Avançar
          </button>
        </>
      );
    }
    if (mainInfo === 'Charge') {
      return (
        <>
          <button
            type="button"
            className="firstButton"
            onClick={() => setMainInfo('Identify')}
          >
            Voltar
          </button>
          <button type="button" onClick={() => setMainInfo('Payment')}>
            Avançar
          </button>
        </>
      );
    }
    if (mainInfo === 'Payment') {
      return (
        <>
          <button
            type="button"
            className="firstButton"
            onClick={() => setMainInfo('Charge')}
          >
            Voltar
          </button>
          <button onClick={() => setMainInfo('Conclusion')}>Avançar</button>
        </>
      );
    }
    if (mainInfo === 'Conclusion') {
      return (
        <>
          <span />
          <button
            type="button"
            onClick={() => {
              setMainInfo('Conclusion');
              setBuy(false);
            }}
          >
            Fechar
          </button>
        </>
      );
    }
  };

  return (
    <Container>
      <MainContainer>
        <Header>
          <div className="logoMarca">
            <img
              src={require('~/assets/nbikes_Simbolo-removebg-preview.png')}
              alt=""
            />
          </div>

          <ProgressBar propMainInfo={mainInfo}>
            <div className="progressTop">
              <ul className="Bar">
                <div id="line-between">
                  <span />
                </div>
                <li>
                  <div className="product">
                    <FaCircle />
                  </div>
                </li>
                <li>
                  <div className="identify">
                    <FaCircle />
                  </div>
                </li>
                <li>
                  <div className="charge">
                    <FaCircle />
                  </div>
                </li>
                <li>
                  <div className="payment">
                    <FaCircle />
                  </div>
                </li>
                <li>
                  <div className="conclusion">
                    <FaCircle />
                  </div>
                </li>
              </ul>
              <ul className="Description">
                <li> Produtos </li>
                <li> Identificação </li>
                <li> Dados Cobrança </li>
                <li> Pagamento </li>
                <li> Conclusão </li>
              </ul>
            </div>
          </ProgressBar>

          <div className="closePopUp">
            <h3 onClick={() => setBuy(false)}> x </h3>
          </div>
        </Header>

        <MainInfo>{showMainInfo()}</MainInfo>

        <Footer>
          <h3>Total a pagar: R$ 449,25 </h3>

          <div className="buttons">
            <div>{showButton()}</div>
          </div>
        </Footer>
      </MainContainer>

      <div className="infosFooter">
        <div className="partner">
          <div>
            <img src={require('~/assets/Logo LeadPay.png')} alt="" />
            <img src={require('~/assets/logo stone.png')} alt="" />
          </div>
        </div>

        <div>
          <span>
            <img src={require('~/assets/seguro.png')} alt="" />
          </span>
        </div>
      </div>
    </Container>
  );
}
