import styled, { css } from 'styled-components';

export const Main = styled.div`
  width: 100%;
  height: 200px;

  background: rgb(34, 51, 87);
`;

export const Hamburger = styled.div`
  width: 40px;
  height: 30px;
  position: absolute;
  display: none;
  right: 20px;
  top: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999999;
  transition: 0.3s;
  cursor: pointer;

  span {
    width: 100%;
    height: 5px;
    background: rgb(69, 189, 194);
    border-radius: 99px;
    transition: 0.6s;
  }

  @media screen and (max-width: 960px) {
    display: flex !important;
  }

  ${(props) => {
    if (props.propHamburger) {
      return css`
        right: 80%;
        .topLine {
          position: absolute;
          transform: rotate(45deg);
          top: 50%;
        }

        .middleLine {
          opacity: 0;
        }

        .bottomLine {
          position: absolute;
          transform: rotate(-45deg);
          top: 50%;
        }
      `;
    }
  }}
`;

export const TopField = styled.div`
  width: 100%;
  height: 40%;
  position: relative;
  padding: 20px;
  display: flex;

  @media screen and (max-width: 960px) {
    height: 100% !important;

    .logo {
      display: unset !important;
    }

    .SocialIcons {
      right: 0px !important;
      bottom: 0px;
    }
  }

  .logo {
    position: absolute;
    left: 20px;
    top: 20px;
    display: none;
    z-index: 10;

    img {
      width: 150px;
      height: auto;
    }
  }

  .SearchField {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .SearchBar {
    width: 100%;
    max-width: 500px;
    position: relative;
    display: flex;
    height: 30px;

    input {
      width: 100%;
      height: 100%;
      padding: 0 40px 0 12px;
      text-align: right;
      border: none;
      border-radius: 9px;
    }

    .lupe {
      width: 35px;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      border-radius: 0 9px 9px 0;
      background: var(--blueNbikes);

      svg {
        fill: white;
      }
    }
  }

  .SocialIcons {
    display: flex;

    position: absolute;
    right: 50px;
    z-index: 2;

    svg {
      width: 25px;
      height: 25px;
      margin-left: 2em;
      fill: rgb(69, 189, 194);

      transition: 0.1s;

      &:hover {
        cursor: pointer;
        fill: #fce76d;
      }
    }
  }
`;

export const BottomField = styled.div`
  width: auto;
  height: 60%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1450px) {
    li,
    .linkMenu {
      font-size: 18px !important;
    }

    .menu {
      ul {
        padding: 0 60px;
        color: var(--blueNbikes);
      }
    }
  }

  @media screen and (max-width: 960px) {
    width: 100% !important ;
    height: 100%;
    background: var(--blueNbikes);
    display: block;
    z-index: 99;
    right: -3000px;
    transition: 0.3s;
    position: absolute;
    top: 0;
    padding-top: 50px;

    .logo {
      display: none;
    }
    .menu {
      width: 100% !important ;
      margin: 0 !important;
      max-width: 100% !important ;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      ul {
        width: 100% !important;
        padding: 20px;
        height: calc(100 - 700px);
        flex-direction: column;

        li {
          height: 70px;
          text-align: right;
        }
      }
    }
  }

  ${(props) => {
    if (props.propHamburger) {
      return css`
        right: 0 !important;
      `;
    }
  }}

  ${(props) => {
    if (props.headerColor.includes('/Home')) {
      return css`
        color: #8dbe20;
        width: 50%;
      `;
    }
    return css`
      color: gold;
      width: 100%;
    `;
  }}

  .logo {
    position: absolute;
    left: 50px;

    img {
      width: 250px;
      max-width: 15vw;
      height: auto;
    }
  }

  .menu {
    width: 55%;
    max-width: 1280px;
    margin: 0 auto;

    ul {
      display: flex;
      justify-content: space-between;
    }
    li,
    .linkMenu {
      font-size: 20px;
      font-weight: 600;
      color: rgb(69, 189, 194);

      transition: 0.1s;

      &:hover {
        cursor: pointer;
        color: #fce76d;
      }
      &:active {
        color: #fce76d;
      }
    }
  }

  .Shop {
    width: 90px;
    position: absolute;
    right: 70px;
    display: none;
    justify-content: space-between;

    svg {
      width: 25px;
      height: 25px;

      fill: var(--blueNbikes);
      transition: 0.1s;
      &:hover {
        cursor: pointer;
        fill: #fce76d;
      }
    }
  }
`;

export const MyAccount = styled.div``;

export const SearchBarForm = styled.form`
  position: relative;

  display: flex;
  align-items: center;

  margin: 30px 160px 0 0;

  @media screen and (max-width: 1000px) {
    margin: 30px 0 0 0;
  }
`;

export const SearchBarInput = styled.input`
  font-size: 18px;

  width: 320px;
  height: 45px;

  border-radius: 30px;
  border: none;

  padding-left: 45px;

  &::-webkit-input-placeholder {
    /* Edge */
    color: black;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
  }

  &::placeholder {
    color: black;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
  }
`;

export const SearchButton = styled.button`
  transition: 0.15s ease;

  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  left: 280px;

  cursor: pointer;

  border-radius: 50%;
  border: none;

  background-color: transparent;
  
  #search-icon {
    fill: var(--blueNbikes);
  }

  &:hover {
    transition: 0.15s ease;

    background-color: var(--blueNbikes);

    #search-icon {
      fill: white;
    }
  }

  @media screen and (max-width: 420px) {
    left: unset;
    right: 5%;
  }
`;
