/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import * as S from './styles';
import PartnerInputs from '~/pages/RegisterPartnerAndCoupon/forms/PartnerInputs';
import CouponInputs from '~/pages/RegisterPartnerAndCoupon/forms/CouponInputs';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, CircularProgress } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { cnpj } from 'cpf-cnpj-validator';
import { AiOutlineWarning } from 'react-icons/ai';
import Modal from '~/components/Modal';
import api from '~/services/api';
import { toastError, toastSuccess } from '~/utils/defaultToasts';
import { useAtomValue } from 'jotai';
import { partnerBackgroundAtom } from '~/jotai/atoms/registerPartnerAtoms';

const urlRegex =
  /^((ftp|http|https):\/\/)(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm;

const schema = yup.object().shape({
  StoreInputs: yup.object().shape({
    Title: yup
      .string()
      .required('Nome da loja é obrigatório.')
      .max(60, 'Nome da loja deve ter no máximo 60 caracteres.'),
    Cnpj: yup
      .string()
      .required('CNPJ é obrigatório.')
      .test('is-cnpj-valid', 'CNPJ inválido.', (value) => cnpj.isValid(value)),
    Website: yup
      .string()
      .required('Site é obrigatório.')
      .matches(
        urlRegex,
        'Site inválido. Verifique se a URL começa com http:// ou https:// ou ftp://'
      ),
    Background: yup.string().required('Logotipo da loja é obrigatório.'),
  }),

  CouponInputs: yup.object().shape({
    Title: yup
      .string()
      .max(100, 'Nome da promoção deve ter no máximo 100 caracteres.'),
    Value: yup.string().required('Código do cupom é obrigatório.'),
    InitialCountLimit: yup
      .string()
      .required('Limite inicial de geração é obrigatório.')
      .test({
        name: 'initial-count-limit-error',
        exclusive: false,
        message: 'Limite inicial de geração deve ser no mínimo de 1 cupom.',
        test: (value) => {
          if (value) {
            return parseInt(value) >= 1;
          }
          return false;
        },
      }),
    DiscountValue: yup
      .string()
      .required('Valor do desconto é obrigatório.')
      .test({
        name: 'discount-value-error',
        exclusive: false,
        message: 'Valor do desconto deve ser maior que zero.',
        test: (value) => {
          if (value) {
            return parseFloat(value) > 0;
          }
          return false;
        },
      }),
    IsPercentage: yup.boolean(),
    ExpiresAt: yup.string(),
    DaysToUseAfterGeneration: yup
      .string()
      .required('Prazo de uso é obrigatório.')
      .test({
        name: 'days-to-use-after-generation-error',
        exclusive: false,
        message: 'Prazo de uso deve ser no mínimo de 1 dia.',
        test: (value) => {
          if (value) {
            return parseInt(value) >= 1;
          }
          return false;
        },
      }),
  }),
});

export default function RegisterPartnerAndCoupon() {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      StoreInputs: {
        Title: '',
        Cnpj: '',
        Website: '',
        Background: '',
        EnablePartner: true,
      },
      CouponInputs: {
        Title: '',
        Value: '',
        InitialCountLimit: undefined,
        DiscountValue: undefined,
        IsPercentage: true,
        ExpiresAt: '',
        DaysToUseAfterGeneration: undefined,
      },
    },
  });

  const [openLeavePageModal, setOpenLeavePageModal] = useState(false);
  const handleOpenLeavePageModal = () => setOpenLeavePageModal(true);
  const handleCloseLeavePageModal = () => setOpenLeavePageModal(false);

  const [isLoading, setIsLoading] = useState(false);

  const onLeaveButtonClick = () => {
    handleCloseLeavePageModal();

    window.location.href = '/';
  };

  const leavePageModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <AiOutlineWarning color="#304470" size={80} />

      <S.ModalText>Desejar sair sem salvar as alterações?</S.ModalText>

      <S.ModalButtonsDiv>
        <Button variant="contained" size="large" onClick={onLeaveButtonClick}>
          Sair
        </Button>

        <Button
          color="lightBlue"
          variant="contained"
          size="large"
          onClick={handleCloseLeavePageModal}
        >
          Cancelar
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  const partnerBackground = useAtomValue(partnerBackgroundAtom);

  const onSubmit = async (data) => {
    setIsLoading(true);

    const partnerFormData = new FormData();
    partnerFormData.append('Title', data.StoreInputs.Title);
    partnerFormData.append('Cnpj', data.StoreInputs.Cnpj);
    partnerFormData.append('Website', data.StoreInputs.Website);
    partnerFormData.append(
      'Background',
      partnerBackground,
      partnerBackground.name
    );

    const partnerVoucherFormData = new FormData();

    try {
      const partnerResult = await api.post(
        '/api/v1/Partners',
        partnerFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      const partnerId = partnerResult.data.Id;

      try {
        partnerVoucherFormData.append('PartnerId', partnerId);
        partnerVoucherFormData.append('Title', data.CouponInputs.Title);
        partnerVoucherFormData.append('Value', data.CouponInputs.Value);
        partnerVoucherFormData.append(
          'InitialCountLimit',
          data.CouponInputs.InitialCountLimit
        );
        partnerVoucherFormData.append(
          'DiscountValue',
          data.CouponInputs.DiscountValue
        );
        partnerVoucherFormData.append(
          'IsPercentage',
          data.CouponInputs.IsPercentage
        );
        partnerVoucherFormData.append('ExpiresAt', data.CouponInputs.ExpiresAt);
        partnerVoucherFormData.append(
          'DaysToUseAfterGeneration',
          data.CouponInputs.DaysToUseAfterGeneration
        );

        await api.post(`/api/v1/PartnerVouchers`, partnerVoucherFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setIsLoading(false);
        toastSuccess('Parceiro e cupons cadastrados com sucesso!');

        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } catch (err2) {
        setIsLoading(false);
        const errors = err2.response.data.errors;

        const messages = err2.response.data.Messages;

        if (errors) {
          const errorsMessages = Object.values(errors);

          errorsMessages.forEach((messageArr) => {
            const message = messageArr[0];
            const translatedMessage = message
              .replace('Title', 'Nome da promoção')
              .replace('Value', 'Código do cupom')
              .replace('InitialCountLimit', 'Limite inicial de geração')
              .replace('DiscountValue', 'Valor do desconto')
              .replace('IsPercentage', 'Tipo de desconto')
              .replace('ExpiresAt', 'Data de expiração')
              .replace('DaysToUseAfterGeneration', 'Prazo de uso');

            toastError(translatedMessage);
          });
        } else if (messages) {
          messages.forEach((message) => {
            const translatedMessage = message
              .replace('Title', 'Nome da promoção')
              .replace('Value', 'Código do cupom')
              .replace('InitialCountLimit', 'Limite inicial de geração')
              .replace('DiscountValue', 'Valor do desconto')
              .replace('IsPercentage', 'Tipo de desconto')
              .replace('ExpiresAt', 'Data de expiração')
              .replace('DaysToUseAfterGeneration', 'Prazo de uso');

            toastError(translatedMessage);
          });
        }

        await api.delete(`/api/v1/Partners/${partnerId}`);
        return;
      }
    } catch (err) {
      setIsLoading(false);

      const errors = err.response.data.errors;

      const messages = err.response.data.Messages;

      if (errors) {
        const errorsMessages = Object.values(errors);

        errorsMessages.forEach((messageArr) => {
          const message = messageArr[0];

          if (
            message ===
            'The Website field is not a valid fully-qualified http, https, or ftp URL.'
          ) {
            toastError(
              'Campo Site com URL inválida. É necessário que a URL inserida possua no início http://, https:// ou ftp://'
            );
          } else {
            toastError(message);
          }
        });
      } else if (messages) {
        messages.forEach((message) => {
          toastError(message);
        });
      }
    }
  };

  const checkIfFormIsDirty = () => {
    const isTouched =
      methods.formState.touchedFields?.StoreInputs ||
      methods.formState.touchedFields?.CouponInputs;

    if (isTouched && !methods.formState.isSubmitted) {
      handleOpenLeavePageModal();
    } else {
      window.location.href = '/';
    }
  };

  return (
    <Fragment>
      <S.Container>
        <FormProvider {...methods}>
          <S.GeneralForm onSubmit={methods.handleSubmit(onSubmit)}>
            <PartnerInputs />

            <CouponInputs />

            <S.ButtonsDiv>
              <Button
                variant="contained"
                size="large"
                type="button"
                sx={{
                  fontWeight: '700',
                  fontSize: '20px',
                  textTransform: 'capitalize',

                  padding: '8px 55px',
                  borderRadius: '13px',
                }}
                onClick={checkIfFormIsDirty}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="lightBlue"
                size="large"
                sx={{
                  fontWeight: '700',
                  fontSize: '20px',
                  textTransform: 'capitalize',

                  padding: '8px 55px',
                  borderRadius: '13px',
                }}
                type="submit"
              >
                {isLoading ? <CircularProgress color="inherit" /> : 'Salvar'}
              </Button>
            </S.ButtonsDiv>
          </S.GeneralForm>
        </FormProvider>
      </S.Container>

      <Modal
        children={leavePageModal}
        open={openLeavePageModal}
        handleClose={handleCloseLeavePageModal}
      />
    </Fragment>
  );
}
