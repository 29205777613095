import React, { useEffect, useRef } from 'react';

import * as S from './styles';

const InputAnimation = ({ children, delay, width }) => {
  return (
    <S.Container animationDelay={delay} style={{ width }}>
      {children}
    </S.Container>
  )
}

export default InputAnimation;
