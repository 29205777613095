import React, { useEffect, useState } from 'react';

import * as S from './styles';

import { IoMdArrowRoundBack } from 'react-icons/io';

import { useLocation, useHistory } from 'react-router-dom';

import { FaWhatsapp, FaRegSadCry } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { BiUserCircle } from 'react-icons/bi';

import api from '~/services/api';
import { toastError } from '~/utils/defaultToasts';

import Loading from '~/components/Loading';

const InterestedClients = () => {
  // Recebendo os argumentos 'id', 'title', 'price' e 'photo'
  // do history.push() feito na página MyAnnouncements.
  const location = useLocation();
  const history = useHistory();
  const getMyNegotiationsUrl = '/api/v1/Negotiations';

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [interestedClients, setInterestedClients] = useState([]);
  const [interestedClientsInThisProduct, setInterestedClientsInThisProduct] = useState([]);
  const [clientsMirror, setNegotiationsMirror] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getAllNegotiationsPages = async () => {
    for (let i = currentPage; currentPage <= totalPages; i += 1) {
      const params = {
        IsPaged: true,
        CurrentPage: currentPage,
        PageSize: 15,
        AsSeller: true,
      }

      try {
        const response = await api.get(getMyNegotiationsUrl, { params });
        const { data } = response;

        setInterestedClients(prevNegotiation => [...prevNegotiation, data]);
        setNegotiationsMirror(prevMirror => [...prevMirror, data]);
        setCurrentPage(prevPage => prevPage + 1);
      } catch (error) {
        history.push({ pathname: '/MyAnnouncements' });
        toastError('Erro ao carregar clientes.');
      }
    }

    setIsLoading(false);
  };

  const getMyNegotiations = async () => {
    const params = {
      IsPaged: true,
      CurrentPage: 1,
      PageSize: 15,
      AsSeller: true,
    }

    try {
      const response = await api.get(getMyNegotiationsUrl, { params });
      const { headers } = response;
      const { data } = response;

      const pagination = JSON.parse(headers.pagination);

      setTotalPages(pagination.TotalPages);
      setInterestedClients(data);

      await getAllNegotiationsPages();
    } catch (error) {
      history.push({ pathname: '/MyAnnouncements' });
      toastError('Erro ao carregar clientes.');
    }
  };

  const filterNegotiations = () => {
    const id = location?.state?.id;

    const filteredNegotiations = interestedClients
      .filter((negotiation) => negotiation.DtoAnnouncement.Id === id && negotiation.Status.Name === 'Ongoing');

    setInterestedClientsInThisProduct(filteredNegotiations);
    setNegotiationsMirror(filteredNegotiations);
  };

  const phoneMask = (v) => {
    v = v?.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v?.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v?.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  };

  const dateMask = (date) => {
    const year = date?.slice(0, 4);
    const month = date?.slice(5, 7);
    const day = date?.slice(8, 10);

    return `${day}/${month}/${year}`;
  };

  const shortString = (string, length) => {
    if (string?.length > length) return string?.slice(0, length) + '...';

    return string;
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    const mirrorArray = [...clientsMirror];

    if (value?.length === 0) return setInterestedClientsInThisProduct(clientsMirror);

    const searchedClients =
      mirrorArray.filter(({ DtoBuyer }) =>
        DtoBuyer?.DtoProfile?.NickName?.toLowerCase() === value?.toLowerCase() ||
        DtoBuyer?.DtoProfile?.NickName?.toLowerCase().includes(value?.toLowerCase()));

    setInterestedClientsInThisProduct(searchedClients);
  };

  useEffect(() => {
    getMyNegotiations();
  }, []);

  useEffect(() => {
    filterNegotiations();
  }, [interestedClients]);

  if (isLoading) return (<Loading loadingText="Carregando..." />);

  return (
    <S.Container>
      {!location?.state?.id && history.push({ pathname: '/MyAnnouncements' })}
      <S.Header>
        <S.PageBack onClick={() => history.push({ pathname: '/MyAnnouncements' })}>
          <IoMdArrowRoundBack />
        </S.PageBack>

        <S.Title>Clientes interessados</S.Title>
      </S.Header>

      <S.Wrapper>
        <S.Id>Anúncio: {location?.state?.id}</S.Id>

        <S.Product>
          <S.ProductPhotoContainer>
            <S.ProductPhoto src={location?.state?.photo} />
          </S.ProductPhotoContainer>

          <S.PriceAndTitle>
            <S.ProductTitle title={location?.state?.title}>
              {location?.state?.title || '-----'}
            </S.ProductTitle>

            <S.Price>R$ {location?.state?.price || '-.--'}</S.Price>
          </S.PriceAndTitle>
        </S.Product>

        <S.Search>
          <S.Input onChange={handleSearch} type="text" placeholder="Pesquise por nomes" />
          <S.Button>Pesquisar</S.Button>
        </S.Search>

        <S.InterestedClients>
          {interestedClientsInThisProduct.length < 1 && (
            <S.NoClientsYet>
              <FaRegSadCry />
              <div>Ops... Sem clientes por enquanto.</div>
            </S.NoClientsYet>
          )}
          {interestedClientsInThisProduct?.map(({ Id, DtoBuyer, StartedAt }) => (
            <S.Client key={Id}>
              <S.ClientPhotoContainer>
                {DtoBuyer?.DtoProfile?.DtoAvatar?.ThumbnailUrl ? (
                  <S.ClientPhoto src={DtoBuyer?.DtoProfile?.DtoAvatar?.ThumbnailUrl} />
                ) : (
                  <BiUserCircle />
                )}
              </S.ClientPhotoContainer>

              <S.NameAndPhone>
                <S.Name>{shortString(DtoBuyer?.DtoProfile?.NickName, 19) || '------ -----'}</S.Name>

                {DtoBuyer?.PhoneNumber && (
                  <S.PhoneContainer>
                    <FaWhatsapp />
                    <S.Phone>{phoneMask(DtoBuyer?.PhoneNumber) || '(--) -----------'}</S.Phone>
                  </S.PhoneContainer>
                )}

                {DtoBuyer?.EmailAddress && (
                  <S.EmailContainer>
                    <MdEmail />
                    <S.Email>{DtoBuyer?.EmailAddress}</S.Email>
                  </S.EmailContainer>
                )}
              </S.NameAndPhone>

              <S.DateContainer>
                <S.Date>{dateMask(StartedAt)}</S.Date>
              </S.DateContainer>
            </S.Client>
          ))}
        </S.InterestedClients>
      </S.Wrapper>
    </S.Container>
  )
}

export default InterestedClients;
