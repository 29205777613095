import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  padding: 3rem;

  box-shadow: 0 0 0.4em rgb(0 0 0 / 30%);
`;

export const Title = styled.h1`
  text-align: left !important;

  margin-bottom: 1rem;
`;

export const Main = styled.div`
  padding: 1rem;

  border: 0.125rem solid rgba(0, 0, 0, 0.25);

  border-radius: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const UploadButton = styled.button`
  position: relative;

  width: 25rem;
  height: 3.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2rem;

  border-radius: 1rem;
  border: none;

  color: white;

  background: #203453;
`;

export const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;

  opacity: 0;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const List = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const Thumb = styled.div`
  display: flex;

  margin-top: 20px;
`;

export const ThumbInner = styled.div`
  flex: 1;

  font-size: 10px;

  img {
    width: 80px;
    border-radius: 12px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileDetails = styled.div`
  font-size: 12px;

  flex: 3;
`;
