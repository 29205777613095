import produce from 'immer';
import { filterTypes } from './types';

const INITIAL_STATE = {
  filterState: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case filterTypes.SET_FILTERS:
        draft.filterState = { ...action.payload.filterState };
        break;
      case filterTypes.CLEAR_FILTERS:
        draft.filterState = null;
        break;
      default:
        return draft;
    }
  });
}
