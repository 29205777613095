import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  /* height: 85vh; */
  display: flex;
  margin: 0 auto 60px auto;
  padding: 20px;

  svg {
    display: flex;
    overflow: auto;
    opacity: 100%;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  @media screen and (max-width: 960px) {
    .title {
      margin: 20px 0 0 0;
    }
    .filters-showup {
      margin-left: 0px !important;
    }

    .right-side {
      margin-left: 0px !important;
    }
  }

  .left-side {
    margin-top: 20px;
  }

  .right-side {
    width: 100%;
    max-width: 1000px;
    margin-left: 30px;
  }

  .title {
    margin-top: 20px;
  }

  .filters-showup {
    ul {
      width: 100%;
      overflow-x: auto;
      display: flex;
    }

    li {
      min-width: 70px;

      align-items: center;
      justify-content: space-between;
      padding: 3px 7px;
      background: #213455;
      color: white;
      font-size: 12px;
      margin-right: 10px;
      border-radius: 99px;
    }

    li.clean-all {
      min-width: 70px;
      background: #fce76d;
      color: #213455;
      font-size: 10px;
      display: flex;
      align-items: center;

      span {
        font-size: 15px;
        font-weight: bold;
        transition: color 0.2s;

        &:hover {
          cursor: pointer;
          color: #36bcc1;
        }
      }
    }

    button {
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      font-size: 10px;
      border-radius: 99px;
      margin-left: 5px;
      font-family: Nunito;
      color: white;
      transition: 0.3s;

      &:hover {
        color: #36bcc1;
      }
    }
  }
`;

export const Categories = styled.div`
  margin-top: 20px;
`;
export const Products = styled.div`
  width: 100%;

  border-radius: 10px;
  background: white;
  padding: 20px;
  margin-top: 20px;

  @media screen and (max-width: 960px) {
    max-width: 100% !important;
  }
`;

export const ListOfProducts = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 0.5px solid rgb(33, 52, 85, 0.3);

  .products {
    width: 100%;
    min-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .main-title {
    width: 30%;
    font-size: 18px;
    font-weight: 600;
    color: #213455;
    margin: 8px auto;
  }

  .titles {
    font-size: 18px;
    font-weight: 600;
    color: #213355;
    margin-bottom: 8px;
  }

  li {
    transition: 0.2s;
    color: gray;
    &:hover {
      cursor: pointer;
      color: #37bcc1;
    }
  }

  .Fields {
    width: 100%;
    height: auto;
    border-top: 0.5px solid rgb(33, 52, 85, 0.3);
    padding: 12px 0;
    transition: 0.5s;

    ul {
      width: 25%;
      vertical-align: top;

      transition: 0.5s;
      display: inline-block;
      margin-top: 20px;

      &:nth-child(2) {
        margin: 20px 12.5% 0 12.5%;
      }
      &:nth-child(5) {
        margin: 20px 12.5% 0 12.5%;
      }
      &:nth-child(8) {
        margin: 20px 12.5% 0 12.5%;
      }
      &:nth-child(11) {
        margin: 20px 12.5% 0 12.5%;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .Fields {
      width: 90%;
    }
  }

  @media screen and (max-width: 960px) {
    .Fields {
      width: 100%;
    }
  }

  .invitation {
    font-weight: 600;
    color: #213355;

    a {
      color: #37bcc1;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
        color: #213355;
      }
    }
  }
`;

export const NumberMark = styled.span`
  float: right;
  color: gray;
  font-weight: 400;
`;
