import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  gap: 30px;

  background-color: white;

  border-radius: 10px;

  box-sizing: border-box;

  height: 250px;
  width: 100%;

  @media screen and (max-width: 610px) {
    flex-direction: column;
    width: 250px;

    height: 520px;

    gap: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 610px) {
    justify-content: center;
    align-items: center;

    padding: 10px;
  }
`;

export const GetInTouchWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const PhotoContainer = styled.div`
  width: 250px;
  height: 250px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

export const Photo = styled.img`
  width: auto;
  height: 250px;

  background-color: green;
`;

export const Title = styled.h2`
  margin-top: 10px;

  font-size: clamp(16px, 3vw, 20px);
`;

export const Details = styled.a`
  margin-top: 10px;

  cursor: pointer;

  font-size: 14px;
  font-weight: 600;

  color: var(--blueNbikes);

  :hover {
    text-decoration: underline;
  }
`;

export const Price = styled.p`
  font-size: clamp(16px, 3vw, 28px);
  font-weight: 600;

  color: #00c569;
`;

export const Status = styled.p`
  margin-bottom: 10px;

  font-size: clamp(16px, 3vw, 18px);
`;

export const GetInTouch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 0 20px;

  width: clamp(220px, 100%, 700px);
  height: 70px;

  background-color: #f8f8f8;

  border: 1px solid var(--blueNbikes);

  svg {
    fill: var(--blueNbikes);

    width: 24px;
    height: 24px;
  }
`;

export const Text = styled.p`
  color: var(--blueNbikes);

  transition: .3s ease;

  font-size: clamp(10px, 2vw, 14px);
`;

export const ShowInfo = styled.button`
  background-color: transparent;

  border: none;

  text-decoration: underline;

  padding-left: 34px;

  @media screen and (max-width: 610px) {
    padding-left: 24px;
  }
`;

export const Buttons = styled.div`
  margin-top: 10px;

  display: flex;
  gap: 20px;

  svg {
    transition: .3s ease;

    width: 20px;
    height: 20px;

    fill: var(--blueNbikes);
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;

  position: relative;

  border: none;
  background-color: transparent;

  font-size: 16px;

    :hover {
      svg {
        transition: .3s ease;
        fill: #37bcc1;
      }

      p {
        color: #37bcc1;
        transition: .3s ease;
      }
    }
`;

export const RefuseReason = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  width: 250px;
  height: 200px;

  top: 100%;
  left: 50%;

  transform: translateX(-50%);

  border-radius: 10px;

  background-color: #dfdfdf;

  z-index: 3;

  transition: .3s;

  opacity: ${({ isToggled }) => isToggled ? '1' : '0' };
  pointer-events: ${({ isToggled }) => isToggled ? 'all' : 'none' };

  box-shadow: 0 5px 15px #0000006c;

  textarea {
    width: 230px;
    height: 140px;
    font-size: clamp(14px, 2vw, 20px);
    text-align: justify;

    border-radius: 10px;
    border: none;

    resize: none;

    padding: 5px;
  }
`;

export const EvaluateMessage = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  width: 300px;
  height: 320px;

  top: 100%;
  left: 50%;

  transform: translateX(-50%);

  border-radius: 10px;

  background-color: #dfdfdf;

  z-index: 3;

  transition: .3s;

  opacity: ${({ isToggled }) => isToggled ? '1' : '0' };
  pointer-events: ${({ isToggled }) => isToggled ? 'all' : 'none' };

  box-shadow: 0 5px 15px #0000006c;

  textarea {
    width: 230px;
    height: 140px;
    font-size: clamp(14px, 2vw, 20px);
    text-align: justify;

    border-radius: 10px;
    border: none;

    resize: none;

    padding: 5px;
  }
`;

export const TextAreaButtons = styled.button`
  width: 80px;
  height: 25px;

  border-radius: 5px;
  border: 0;

  color: white;

  background-color: var(--blueNbikes);
`;

export const CustomLeadTrustContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const LeadTrustBackground = styled.div`
  position: absolute;

  bottom: 0;
  left: 0;

  background-color: ${({ faceColor }) => faceColor};

  width: 55px;

  transition: 0.3s ease;
`;

export const LeadTrustFaceContainer = styled.span`
  position: relative;
  height: 50px;
  width: 55px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  overflow: hidden;

  transition: 0.3s ease;

  ${LeadTrustBackground} {
    height: ${({ isSelected }) => isSelected ? '100%' : '3px'};
  }

  :hover {
    transition: 0.3s ease;

    ${LeadTrustBackground} {
      transition: 0.3s ease;

      height: 100%;
    }
  }
`;
