import styled from 'styled-components';

export const AnimationContainer = styled.div`
  position: absolute;

  transition: 1s ease;

  opacity: 0;

  width: 100%;

  left: -50%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 20px auto;
  padding: 50px;

  max-width: 500px;
  width: 100%;

  background-color: white;

  border-radius: 20px;

  box-shadow: 0 5px 5px rgb(0, 0, 0, 0.3);

  h1 {
    font-size: 24px;
    font-weight: 500;

    text-align: center;

    strong {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  span {
    font-size: 20px;

    text-align: center;
  }

  #announcement-icon {
    width: 180px;
    height: 180px;

    fill: #000000;

    opacity: 0.2;
  }

  button {
    margin-top: 20px;

    width: 200px;
    height: 50px;

    font-size: 20px;

    color: white;

    background-color: #00c569;

    border: none;
    border-radius: 20px;
    transition: 0.3s;

    &:hover {
      transform: scale(1.1);

      transition: 0.3s;
    }
  }

  @media screen and (max-width: 960px) {
    h1 {
      font-size: 22px;
    }
  }
`;
