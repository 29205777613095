import produce from 'immer';
import { AuthTypes } from './types';

const INITIAL_STATE = {
  auth: {
    accessToken: '',
    privilegeType: null,
    profile: {
      NickName: 'name',
      DtoAvatar: {
        Url: '',
      },
    },
    loading: false,
    id: '',
    email: '',
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case AuthTypes.SIGN_IN_REQUEST: {
        draft.auth.loading = true;
        break;
      }
      case AuthTypes.SIGN_UP_REQUEST: {
        draft.auth.loading = true;
        break;
      }
      case AuthTypes.EMAIL_CONFIRMATION: {
        draft.auth.loading = true;
        break;
      }
      case AuthTypes.SIGN_UP_CONFIRMATION: {
        draft.auth.loading = true;
        break;
      }
      case AuthTypes.SET_PRIVILEGE_TYPE: {
        draft.auth.privilegeType = action.payload.privilegeType;
        break;
      }
      case AuthTypes.RESEND_CODE: {
        draft.auth.loading = true;
        break;
      }
      case AuthTypes.UPLOAD_PROFILE_PIC: {
        draft.auth.loading = true;
        break;
      }
      case AuthTypes.UPDATE_PROFILE: {
        draft.auth.loading = false;
        draft.auth.profile = {
          ...action.payload.profile,
          Username: draft.auth.email,
        };
        break;
      }
      case AuthTypes.SIGN_IN_SUCCESS: {
        draft.auth.accessToken = action.payload.accessToken;
        draft.auth.profile = action.payload.profile;
        draft.auth.loading = false;
        draft.auth.id = '';
        draft.auth.email = action.payload.username;
        break;
      }
      case AuthTypes.SIGN_UP_SUCCESS: {
        draft.auth.id = action.payload.id;
        draft.auth.email = action.payload.email;
        draft.auth.loading = false;
        break;
      }
      case AuthTypes.STOP_LOADING: {
        draft.auth.loading = false;
        break;
      }
      case AuthTypes.SIGN_OUT: {
        draft.auth.accessToken = '';
        draft.auth.privilegeType = '';
        draft.auth.profile = {
          NickName: 'name',
          DtoAvatar: {
            Url: '',
          },
        };
        draft.auth.email = '';
        break;
      }
      default:
        draft.auth.loading = false;
    }
  });
}
