import styled from 'styled-components';

export const MainFooter = styled.div`
  width: 100%;
  min-height: 300px;
  bottom: 0;
  position: absolute;
  font-size: 1.6rem;
  background: var(--blueNbikes);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
`;

export const FooterClass = styled.footer`
  width: 100%;
  height: 100%;
  max-width: 1320px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 1rem;
  color: white;

  li {
    margin-bottom: 0.5em;
  }

  a {
    color: white;
    text-decoration: none;
  }

  .logo {
    display: flex;
    align-items: center;
  }

  h3 {
    margin-bottom: 0.7em;
  }

  .column1,
  .column2,
  .column3 {
    height: 200px;
  }

  .column2 {
    ul:nth-child(2) {
      margin-bottom: 1.5em;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 1em;
    fill: white;
    transition: 0.1s;

    &:hover {
      cursor: pointer;
      fill: #fce76d;
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center !important;

    .logo {
      margin-bottom: 20px;
    }
    .column1,
    .column2,
    .column3 {
      height: 200px;
      margin: 0 auto;
    }

    .column3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const CopyrightFooter = styled.div`
  background: var(--blueNbikes);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #8f8f8f;
  padding: 0 20px;


  @media screen and (max-width: 960px) {
    padding: 1rem;
    font-size: 8px;
  }
  img {
    width: 14px;
  }

  a {
    display: flex;
    align-items: center;
    font-style: none;
    color: #8f8f8f;
  }
`;
