import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AccordionFilterItemsContext } from '~/context/Accordion';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { toastError } from 'utils/defaultToasts';
import { FaHeart, FaThumbsUp } from 'react-icons/fa';
import nbikesSimpleLogo from 'assets/nbikes_Simbolo-removebg-preview.png';
import { Pagination } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import AccordionFilter from '~/components/AccordionStruct/Accordion';
import { PartnersSection } from './components/PartnersSection';
import api from '~/services/api';
import * as S from './styles';
import FilterOptions from '~/components/FilterOptions';
import { useQuery } from '~/hooks/useQuery';
import { useMediaQuery } from '@material-ui/core';
import useWindowSize from '~/utils/useWindowSize';

export default function Home() {
  const {
    setAccordionFilterItems,
    accordionFilterItems,
    selectedSubcategories,
  } = useContext(AccordionFilterItemsContext);
  const history = useHistory();
  const query = useQuery();

  const [announcements, setAnnouncements] = useState([]);

  const currentUrl = window.location.href;
  const searchParameter = window.location.href.split('#/')[1].split('/')[1];

  const [isGlobalSearch, setIsGlobalSearch] = useState(true);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(null);

  const [filterState, setFilterState] = useState(null);

  const { width } = useWindowSize();

  const isMobile = width && width < 960;

  const accessToken = useSelector(
    (state) => state.authReducer.auth.accessToken
  );
  const userId = useSelector((state) => state.authReducer.auth.profile.Id);
  const privilegeType = useSelector(
    (state) => state.authReducer.auth.privilegeType
  );

  const searchTypeUrl = (isGlobalSearch) => {
    if (isGlobalSearch) return '/api/v1/Announcements/Search?';

    return '/api/v1/Announcements/BasicInfoSearch?';
  };

  function countProperties(obj) {
    var count = 0;

    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) ++count;
    }

    return count;
  }

  const getCategoryQueryParams = useCallback(() => {
    if (selectedSubcategories.length > 0) {
      const subCategoriesWithoutParentCategorySelected =
        selectedSubcategories.filter(
          (subcategory) =>
            !accordionFilterItems?.categories.find(
              (category) => category?.Name === subcategory?.Name.split('_')[0]
            )
        );

      const filteredCategories = accordionFilterItems?.categories.filter(
        (category) =>
          !selectedSubcategories.find(
            (subcategory) => subcategory?.Name.split('_')[0] === category?.Name
          )
      );

      let categoriesAndSubcategories;

      if (subCategoriesWithoutParentCategorySelected.length > 0) {
        const filteredSubCategories = selectedSubcategories.filter(
          (subcategory) =>
            !subCategoriesWithoutParentCategorySelected.find(
              (subCategoryWithoutParentCategorySelected) =>
                subCategoryWithoutParentCategorySelected?.Name ===
                subcategory?.Name
            )
        );

        categoriesAndSubcategories = [
          ...filteredCategories,
          ...filteredSubCategories,
        ];
      } else {
        categoriesAndSubcategories = [
          ...filteredCategories,
          ...selectedSubcategories,
        ];
      }

      const categoryQueryParams = categoriesAndSubcategories
        .map((category) => `&Categories=${category?.Name}`)
        .toString()
        .replaceAll(',', '');

      return categoryQueryParams;
    } else {
      const categoryQueryParams = accordionFilterItems.categories
        ?.map((category) => `&Categories=${category?.Name}`)
        .toString()
        .replaceAll(',', '');

      return categoryQueryParams;
    }
  }, [accordionFilterItems, selectedSubcategories]);

  const getAnnouncements = useCallback(async () => {
    if (!currentPage) return;

    const parameters = {
      Search: searchParameter,
      CurrentPage: currentPage,
      PageSize: 9,
      IsPaged: true,
      State: accordionFilterItems.initialsUF?.Name,
      CityId: accordionFilterItems.city?.Name,
      UseCondition: accordionFilterItems.useCondition?.Name,
      Size: accordionFilterItems.size?.Name,
      RimSize: accordionFilterItems.rimSize?.Name,
      ForkTravel: accordionFilterItems.forkTravel?.Name,
      BasePrice: accordionFilterItems.basePrice?.Name,
      LimitPrice: accordionFilterItems.limitPrice?.Name,
      BaseYear: accordionFilterItems.baseYear?.Name,
      LimitYear: accordionFilterItems.limitYear?.Name,
    };

    const categoryQueryParams = getCategoryQueryParams();

    let getAnnouncemetsUrl = searchTypeUrl(isGlobalSearch);

    try {
      const response = await api.get(
        `${getAnnouncemetsUrl}${categoryQueryParams}`,
        {
          params: parameters,
        }
      );
      const { data } = response;

      const pagination = JSON.parse(response.headers.pagination);

      setTotalPages(pagination.TotalPages);
      setAnnouncements(data);
    } catch (error) {
      toastError('Erro ao buscar anúncios.');
    }
  }, [
    isGlobalSearch,
    currentPage,
    searchParameter,
    accordionFilterItems,
    selectedSubcategories,
  ]);

  const clearFilter = useCallback(() => {
    setAccordionFilterItems(null);
  }, [accordionFilterItems]);

  const addFavorite = useCallback(
    async (id, event) => {
      const heart = event.target;

      const postFavoriteUrl = `api/v1/Profiles/${userId}/Starreds`;

      try {
        await api.post(postFavoriteUrl, { AnnouncementId: id });

        heart.style.fill = '#fce76d';

        setAnnouncements(
          announcements.map((announcement) => {
            return announcement.Id === id
              ? { ...announcement, IsStarred: true }
              : announcement;
          })
        );
      } catch (error) {
        toastError('Erro ao favoritar anúncio.');
      }
    },
    [announcements]
  );

  const removeFavorite = useCallback(
    async (id, event) => {
      const heart = event.target;

      const deleteFavoriteUrl = `api/v1/Profiles/${userId}/Starreds/${id}`;

      try {
        await api.delete(deleteFavoriteUrl);

        heart.style.fill = '#36bcc1';

        setAnnouncements(
          announcements.map((announcement) => {
            return announcement.Id === id
              ? { ...announcement, IsStarred: false }
              : announcement;
          })
        );
      } catch (error) {
        toastError('Erro ao remover anúncio dos favoritos.');
      }
    },
    [announcements]
  );

  const changePage = (event, value) => {
    history.push({ search: '?page=' + value });
    setCurrentPage(value);
  };

  useEffect(() => {
    const pageQuery = query?.get('page');

    if (!pageQuery && !searchParameter) {
      setCurrentPage(1);

      setFilterState(null);

      setIsGlobalSearch(false);

      return;
    }

    if (pageQuery) {
      setCurrentPage(Number(pageQuery));
      setFilterState(null);

      if (searchParameter) {
        setIsGlobalSearch(true);
        return;
      }

      setIsGlobalSearch(false);
    }
  }, [currentUrl, query]);

  useEffect(() => {
    getAnnouncements();
  }, [isGlobalSearch, currentUrl, currentPage, filterState, getAnnouncements]);

  useEffect(() => {
    if (accessToken) return;
    getAnnouncements();
  }, [accessToken]);

  const matches = useMediaQuery('(max-width:390px)');
  const smallMatches = useMediaQuery('(max-width:345px)');

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="n bikes | Uma mão na roda! | Brasil"
        />
        <meta
          property="og:image"
          content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
        />
        <meta
          property="og:description"
          content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
        />
        <title>n bikes | Uma mão na roda! | Brasil</title>
      </Helmet>

      <S.Container>
        {isMobile && (
          <PartnersSection privilegeType={privilegeType} />
        )}

        <S.AccordionFilterContainer>
          <S.Title>Filtros</S.Title>
          <AccordionFilter />
        </S.AccordionFilterContainer>

        <div className="right-side">
          <S.Title>Anúncios</S.Title>

          <FilterOptions />

          <S.Products>
            <div className="ListOfProducts">
              <div className="products">
                <ul>
                  {announcements.length > 0 ? (
                    announcements.map((announcement) => (
                      <li key={announcement.Id} data-id={announcement.Id}>
                        <S.ProductSquare
                          isStarred={announcement.IsStarred}
                          productId={announcement.Id}
                          onClick={() => {
                            history.push({
                              pathname: '/Anuncio/' + announcement.Id,
                            });
                          }}
                        >
                          { announcement.IsFinished ? <>
                              <S.SelledTagWrapper>
                                <S.SelledTagIconBox>
                                  <FaThumbsUp />
                                  Vendido
                                </S.SelledTagIconBox>
                              </S.SelledTagWrapper>
                            </> : 
                            <>
                              <div className="icons">
                                <FaHeart
                                  className="heart-icon"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (accessToken && !announcement.IsStarred)
                                      addFavorite(announcement.Id, event);
                                    else if (accessToken && announcement.IsStarred)
                                      removeFavorite(announcement.Id, event);
                                    else window.location.replace('/#/Login');
                                  }}
                                />
                              </div>
                            </>}
                          <div className="image">
                            <img
                              src={
                                process.env.REACT_APP_S3URL +
                                announcement.DtoPicture?.Key
                              }
                              alt=""
                            />
                          </div>
                          <h1
                            id="announcement-title"
                            title={announcement?.Title}
                          >
                            {announcement?.Title}
                          </h1>
                          <p>
                            {'R$ ' +
                              announcement?.Price?.toLocaleString('pt-BR', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                          </p>
                        </S.ProductSquare>
                      </li>
                    ))
                  ) : (
                    <>
                      <div id="announcements-not-found">
                        <img width={300} src={nbikesSimpleLogo} alt="" />
                        <div id="neutral-text">
                          Nenhum anúncio <br /> encontrado.
                        </div>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </S.Products>

          <Pagination
            count={totalPages}
            page={currentPage}
            shape="rounded"
            onChange={changePage}
            siblingCount={smallMatches ? 0 : 1}
            size={matches ? 'small' : 'medium'}
          />
        </div>

        {!isMobile && (
          <PartnersSection privilegeType={privilegeType} />
        )}
      </S.Container>
    </>
  );
}
