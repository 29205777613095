import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${(props) => {
    if (props.satisfaction === 'very-bad') {
      return css`
        .satisfactionRate-1 {
          span:nth-child(2) {
            background: #ff2327;
            height: 60px !important;

            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            border-radius: 8px 8px 0 0 !important;
          }

          .expression-1 {
            display: unset !important;
            z-index: 2;
          }
        }
      `;
    }
    if (props.satisfaction === 'bad') {
      return css`
        .satisfactionRate-1 {
          span:nth-child(3) {
            height: 60px !important;

            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            border-radius: 8px 8px 0 0 !important ;
          }

          .expression-2 {
            display: unset !important;
            z-index: 2;
          }
        }
      `;
    }
    if (props.satisfaction === 'regular') {
      return css`
        .satisfactionRate-1 {
          span:nth-child(4) {
            height: 60px !important;

            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            border-radius: 8px 8px 0 0 !important ;
          }

          .expression-3 {
            display: unset !important;
            z-index: 2;
          }
        }
      `;
    }
    if (props.satisfaction === 'happy') {
      return css`
        .satisfactionRate-1 {
          span:nth-child(5) {
            height: 60px !important;

            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            border-radius: 8px 8px 0 0 !important ;
          }

          .expression-4 {
            display: unset !important;
            z-index: 2;
          }
        }
      `;
    }
    if (props.satisfaction === 'very-happy') {
      return css`
        .satisfactionRate-1 {
          span:nth-child(6) {
            height: 60px !important;

            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            border-radius: 8px 8px 0 0 !important ;
          }

          .expression-5 {
            display: unset !important;
            z-index: 2;
          }
        }
      `;
    }
  }}

  .LeadTrust {
    .satisfactionRate-1 {
      width: 250px;
      height: 70px;
      position: relative;
      display: flex;
      align-items: flex-end;
      margin-right: 20px;

      svg {
        width: 250px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      label {
        font-size: 17px;
        position: absolute;
        bottom: -30px;
        left: 0;
      }

      span {
        width: 50px;
        height: 3px;
      }

      span:nth-child(n + 1):nth-child(-n + 6) {
        ${(props) => {
    if (props.clickable)
      return css`
              &:hover {
                height: 60px !important;
                cursor: pointer;
                transition: 0.3s;
                border-radius: 8px 8px 0 0 !important ;
              }
            `;
  }}
      }

      span:nth-child(2) {
        background: #ff2327;
        border-radius: 4px 0px 0px 4px;
      }
      span:nth-child(3) {
        background: #ff8523;
      }
      span:nth-child(4) {
        background: #ffdd33;
      }
      span:nth-child(5) {
        background: #a7ec66;
      }
      span:nth-child(6) {
        background: #36e266;
        border-radius: 0 4px 4px 0;
      }

      .expression-1 {
        display: none;
      }
      .expression-2 {
        display: none;
      }
      .expression-3 {
        display: none;
      }
      .expression-4 {
        display: none;
      }
      .expression-5 {
        display: none;
      }
    }
  }
`;
