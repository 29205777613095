import { toastError } from '~/utils/defaultToasts';

export const copyToClipboard = (value) => {
  try {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(value);
    }
  } catch (error) {
    console.log(error);
    toastError('Erro ao copiar para área de transferência.');
  }
};
