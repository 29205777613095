import { atom } from 'jotai';

export const partnerBackgroundAtom = atom(null);
export const partnerWebsiteAtom = atom('');

export const partnerIdAtom = atom('');

export const partnerFormIsDirtyAtom = atom(false);
export const partnerVoucherFormIsDirtyAtom = atom(false);

export const isPartnerFormSubmittedAtom = atom(false);

export const couponInfosAtom = atom({
  image: null,
  discount: '',
  isPercentage: true,
  value: '',
});
