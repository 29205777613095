import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 200px;
`;

export const Title = styled.span`
  font-size: clamp(14px, 100%, 15px);
  cursor: pointer;

  :hover {
    color: #36bcc1;
  }
`;

export const SubCategoriesList = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: clamp(12px, 100%, 14px);
  padding-left: clamp(10px, 100%, 15px);
  padding-bottom: 8px;

  cursor: pointer;

  p {
    :hover {
      color: #36bcc1;
    }
  }
`;

export const CategoryCheckboxDiv = styled.div`
  padding: 3px;

  label {
    padding-left: 5px;
  }
`;

export const SubCategoryCheckboxList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 18px;

  font-size: clamp(11px, 100%, 13px);

  li {
    padding: 3px;

    label {
      padding-left: 5px;
    }
  }
`;

export const ForkTravelDiv = styled.div`
  padding: 5px;
`;

export const UseConditionDiv = styled.div`
  p {
    padding: 10px;
  }
`;

export const RimSizeDiv = styled.div`
  padding: 5px;
`;

export const InputRangeDiv = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 85%;
    border: none;
    padding-bottom: 15px;
    border-bottom: 3px solid rgba(33, 52, 85, 0.2);
    /* padding-left: 3px; */
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const SelectDiv = styled.div`
  padding-left: unset;

  select {
    width: 130px;
    height: 20px;

    background: rgba(33, 52, 85, 0.2);
    border: none;

    font-weight: bold;
  }
`;
