import styled, { css } from 'styled-components';

export const AnimationContainer = styled.div`
  transition: 1s ease;

  opacity: 0;

  width: 100%;

  transform: translateX(-50%);

`;

export const Field = styled.div`
  width: 100%;

  border-radius: 12px;

  margin: 3em auto;
  padding: 50px;

  transition: 0.3s;

  background: white;

  box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);

  @media screen and (max-width: 960px) {
    padding: 20px !important;
  }

  h2 {
    font-size: 30px;
    margin: 0.5em 0 0.1em 0;
    font-weight: 600;
  }

  p,
  span {
    font-size: 12px;
    color: gray;
  }

  #already-added-image {
    position: absolute;

    top: 24px;
    left: 110px;

    color: gray;

    font-size: 12px;
    font-weight: 500;

    width: 170px;

    text-align: justify;
  }

  img {
    border-radius: 10px;
  }

  @media screen and (max-width: 800px) {
    .inputTitle {
      padding-bottom: 400px;
    }
  }

  .reactEasyCrop_Container {
    width: 100%;
    height: 100%;
    border: 1px solid;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
  }

  .image-cropper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;

    button {
      width: 180px;
      height: 40px;
      background: rgb(32, 52, 83);
      border: none;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      font-weight: 600;
      position: absolute;
      bottom: -50px;
      right: 0px;
      z-index: 2;
    }
  }

  .uploadContainer {
    width: 100%;
    max-width: 400px;
    height: 350px;
    margin: 0 auto;
    position: relative;
    border: 3px dashed rgb(125, 125, 125, 0.4);
    border-radius: 10px;

    .dropzone {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        width: 180px;
        height: 40px;
        background: rgb(32, 52, 83);
        border: none;
        border-radius: 10px;
        color: white;
        font-size: 16px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
      }
      h2 {
        font-size: 20px;
      }
      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  .thumbsContainer {
    width: 400px;
    min-height: 60px;
    height: auto;

    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 16px auto 0 auto;
  }

  .filesDetails {
    width: 100px;
    display: flex;
    list-style: none;
    flex-direction: column;

    li {
      font-size: 14px;
      font-weight: 600;
      color: rgb(32, 52, 83);
    }

    p {
      font-size: 12px;
      color: #000000;
    }
  }

  .thumbInner {
    width: 100px;
    min-width: 0;
    overflow: hidden;
    text-align: center;
    margin-right: 5px;

    img {
      width: 80px;
      border-radius: 12px;
    }
  }

  .thumb {
    width: 400px;
    height: 100px;
    display: inline-flex;
    margin: 0 8px 8px 0;
    padding: 4px;
    box-sizing: border-box;
    position: relative;

    span {
      width: 50px;
      height: 100px;
      font-size: 60px;
      font-weight: 900;
      color: #f2f2f2;
      display: inline-block;
    }

    .change-position {
      width: 30px;
      height: 30px;
      border: 0;
      background: none;
      position: absolute;
      right: 0;
      top: 30px;
      transition: 0.2s;

      svg {
        width: 20px;
        fill: #e3e3e3;
        transition: 0.3s;
      }
    }

    .changed-position {
      width: 25px;
      height: 25px;
      border: 0;
      background: none;
      position: absolute;
      right: 3px;
      top: 30px;
      transition: 0.3s;
      fill: #f7f43b;
      transition: 0.2s;
    }

    .delete-image {
      width: 30px;
      height: 30px;
      border: 0;
      background: none;
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        svg {
          fill: #e63737;
        }
      }
      svg {
        width: 20px;
        fill: #e3e3e3;
        transition: 0.3s;
      }
    }
  }
`;

export const Container = styled.div`
  position: relative;
  padding-bottom: 50px;

  ${(props) => {
    if (props.propShow) {
      return css`
        .image-cropper {
          visibility: visible !important;
        }
      `;
    }
  }}
`;

export const Advise = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  padding-top: 10px;

  text-align: center;
`;

export const CropOptionsContainer = styled.div`
  width: 100px;

  display: flex;
  flex-direction: column;

  gap: 10px;

  gap: 10px;
  position: absolute;
  bottom: 80px;
  right: -200px;

  @media screen and (max-width: 800px) {
    bottom: -270px;
    transform: translateX(50%);
    right: 50%;
  }
`;
