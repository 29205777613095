import { useEffect } from 'react';

export const useRedirectHashRoute = () => {
  useEffect(() => {
    const { href } = window.location;

    if (!href.includes('#')) {
      const indexToInsertHash = href.indexOf('/', 8);

      const urlWithHash = `${href.slice(0, indexToInsertHash)}/#${href.slice(
        indexToInsertHash
      )}`;

      window.location.replace(urlWithHash);
    }
  }, []);
};
