import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Countdown from 'react-countdown';

import { Coupon } from '~/features/Coupon';

import { styled } from '~/stiches.config';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import Loading from '~/components/Loading';
import { toastError, toastSuccess } from '~/utils/defaultToasts';
import { useAtomValue } from 'jotai';
import {
  couponInfosAtom,
  partnerWebsiteAtom,
} from '~/jotai/atoms/registerPartnerAtoms';
import { copyToClipboard } from '~/utils/copyToClipboard';

const Container = styled('div', {
  width: '100%',
  maxWidth: 1280,

  background: 'white',

  margin: '48px auto',

  padding: 48,

  borderRadius: 12,

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 45,
});

const TimerWrapper = styled('div', {
  width: '100%',

  marginTop: 64,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 12,
});

const Timer = styled('div', {
  width: 56,
  height: 56,

  border: '3px solid $PrimaryBlue',
  borderRadius: 32,

  padding: 4,

  fontSize: 28,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const RedirectTo = styled('p', {
  width: '100%',
  maxWidth: 480,
  fontSize: 24,
  color: '#A3A3A3',

  b: {
    color: 'black',
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  textAlign: 'center',
});

const SentToEmailMessage = styled('div', {
  color: '#45BDC2',
  fontSize: 32,
});

const Disclaimer = styled('span', {
  color: '#A3A3A3',
  fontSize: 16,
});

const AnchorGroup = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,

  marginTop: -10,
});

const Anchor = styled('a', {
  padding: '4px 16px',
  background: '#45BDC2',

  borderRadius: 12,
  border: 'none',

  fontSize: 24,

  color: 'white',
  textAlign: 'center',

  transition: 0.15 + 's',

  '&:hover': {
    filter: 'grayscale(0.5)',
  },
});

const ErrorDiv = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
});

const ErrorTitle = styled('h3', {
  fontSize: 32,
  fontWeight: 700,
  color: '#000',

  textAlign: 'center',

  '@media (max-width: 400px)': {
    fontSize: 22,
  },
});

export function PartnerCoupon() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const partnerWebsite = useAtomValue(partnerWebsiteAtom);
  const { discount, image, isPercentage, value } =
    useAtomValue(couponInfosAtom);

  const profileId = useSelector((state) => state.authReducer.auth.profile.Id);

  const { partnerVoucherId } = useParams();

  const [errorMessage, setErrorMessage] = useState('');

  const URL = partnerWebsite;

  const handleGoBack = useCallback(() => {
    history.push('/');
  }, []);

  useEffect(() => {
    const formData = new FormData();

    formData.append('PartnerVoucherId', partnerVoucherId);
    formData.append('ProfileId', profileId);

    api
      .post('/api/v1/Vouchers', formData)
      .then((response) => {
        setIsLoading(false);

        copyToClipboard(value);

        toastSuccess(
          'Cupom gerado com sucesso e copiado para sua área de transferência!'
        );
      })
      .catch((error) => {
        const errorMessages = error.response?.data?.Messages;

        setIsLoading(false);
        setIsError(true);

        if (errorMessages) {
          errorMessages.forEach((message) => {
            toastError(message);
            setErrorMessage(message);
          });
        }
      });
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Loading loadingText={'Gerando seu cupom de desconto...'} />
      ) : isError ? (
        <ErrorDiv>
          <ErrorTitle>Ops! {errorMessage}</ErrorTitle>
          <Anchor href="/">Voltar para Home</Anchor>
        </ErrorDiv>
      ) : (
        <Fragment>
          <Coupon
            image={image}
            discount={discount}
            isPercentage={isPercentage}
            viewModeOnly
          />

          <Wrapper>
            <SentToEmailMessage>
              O cupom de desconto foi enviado no seu e-mail!
            </SentToEmailMessage>

            <Disclaimer>
              Acesse seu e-mail e copie o código do cupom para utilizá-lo no
              site da loja parceira.
            </Disclaimer>
          </Wrapper>

          <AnchorGroup>
            <Anchor href="/">Voltar para o nBikes</Anchor>

            <Anchor href={URL} target="_blank" css={{ background: '#304470' }}>
              Ir para a loja parceira
            </Anchor>
          </AnchorGroup>
        </Fragment>
      )}
    </Container>
  );
}
