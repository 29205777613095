import styled, { keyframes } from 'styled-components';

export const LoadingContainer = styled.div`
  margin-top: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 20px;

    font-size: 24px;
    font-weight: 500;

    color: var(--blueNbikes);

    text-align: center;
  }
`;

const spinnerRotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: inline-block;

  width: 80px;
  height: 80px;

  &::after {
    content: ' ';
    display: block;

    width: 64px;
    height: 64px;

    margin: 8px;

    border-radius: 50%;
    border: 6px solid var(--blueNbikes);
    border-color: var(--blueNbikes) transparent var(--blueNbikes) transparent;

    animation: ${spinnerRotateAnimation} 1.2s linear infinite;
  }
`;
