import React, { useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { FaCheckCircle } from 'react-icons/fa';

import { AnimationContainer, Field, Container, MainContainer } from './styles';

export default function Aproved({ announcement }) {
  const history = useHistory();

  const animationContainerRef = useRef(null);

  const goToAnnouncement = () => history.push(`/Anuncio/${announcement.Id}`);

  const showContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '1';
    animationContainerRef.current.style.transform = ' translateX(0)';
  };

  const hideContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '0';
    animationContainerRef.current.style.transform = 'translateX(100%)';
  };

  useEffect(() => {
    showContainerAnimation();
  }, []);

  return (
    <AnimationContainer ref={animationContainerRef}>
      <MainContainer>
        <Container>
          <Field>
            <FaCheckCircle />
            <h1>
              Publicado com sucesso. <br />
            </h1>
            <button
              id="go-to-announcement-button"
              type="button"
              onClick={() => {
                hideContainerAnimation();

                setTimeout(() => {
                  goToAnnouncement();
                }, 500);
              }}
            >
              Visualizar Anúncio
            </button>
          </Field>
        </Container>
        <button
          type="button"
          className="nextStep"
          onClick={() => history.push('/MyAnnouncements')}
        >
          Meus Anúncios
        </button>
      </MainContainer>
    </AnimationContainer>
  );
}
