import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { signInRequest } from '~/store/ducks/auth/actions';

import { Field, MainContainer, BaseContainer } from './styles';

export default function Login() {
  const dispatch = useDispatch();

  const [reCaptcha, setReCaptcha] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [username, setUsernameState] = useState('');
  const [passwordState, setPasswordState] = useState('');

  const changed = {
    bottom: '28px',
    fontSize: '14px',
  };
  const formRef = useRef(null);

  const onVerify = useCallback((token) => {
    setReCaptcha(token);
  }, []);

  const handleSubmit = useCallback(() => {
    const form = formRef.current;
    dispatch(
      signInRequest({
        username: form.email.value,
        password: form.password.value,
        reCaptcha,
      })
    );
    setRefreshReCaptcha((r) => !r);
  }, [dispatch, reCaptcha]);

  const handleEnterKey = useCallback(
    (e) => {
      const isEnterKeyPressed = e.key === 'Enter';

      if (!isEnterKeyPressed) return;

      const form = formRef.current;

      dispatch(
        signInRequest({
          username: form.email.value,
          password: form.password.value,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (refreshReCaptcha) setRefreshReCaptcha(false);
  }, [refreshReCaptcha]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <MainContainer>
        <Helmet>
          <meta
            property="og:title"
            content="n bikes | Uma mão na roda! | Brasil"
          />
          <meta
            property="og:image"
            content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
          />
          <meta
            property="og:description"
            content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
          />
          <title>Login | n Bikes | Brasil</title>
        </Helmet>
        <BaseContainer>
          <Field propChange={[username, passwordState]} ref={formRef}>
            <div className="title">
              <h1>Olá, </h1>
              <p>Digite o seu e-mail e senha para continuar.</p>
            </div>
            <ul>
              <li>
                <div className="Mail">
                  <span className="spanMail" style={username ? changed : null}>
                    E-mail
                  </span>
                  <input
                    name="email"
                    type="email"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setUsernameState('click');
                      } else {
                        setUsernameState(e.target.value);
                      }
                    }}
                    onFocus={() =>
                      username === '' ? setUsernameState('click') : null
                    }
                    onBlur={() =>
                      username === 'click' ? setUsernameState('') : null
                    }
                  />
                </div>

                <div>
                  <span
                    className="spanPassword"
                    style={passwordState ? changed : null}
                  >
                    Senha
                  </span>
                  <input
                    onKeyPress={handleEnterKey}
                    name="password"
                    type="password"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setPasswordState('click');
                      } else {
                        setPasswordState(e.target.value);
                      }
                    }}
                    onFocus={() =>
                      passwordState === '' ? setPasswordState('click') : null
                    }
                    onBlur={() =>
                      passwordState === 'click' ? setPasswordState('') : null
                    }
                  />

                  <GoogleReCaptcha
                    onVerify={onVerify}
                    refreshReCaptcha={refreshReCaptcha}
                  />
                </div>
                <p id="forgot-password">
                  <a href="#/forgotpassword">Esqueceu sua senha?</a>
                </p>
              </li>
            </ul>

            <div className="log-in">
              <button type="button" onClick={handleSubmit}>
                Logar
              </button>
            </div>

            <div className="logged">
              <span>
                Ainda não possui conta? <a href="/#/Register">Cadastre-se </a>
              </span>
            </div>
          </Field>
        </BaseContainer>
      </MainContainer>
    </GoogleReCaptchaProvider>
  );
}
