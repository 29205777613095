/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useCallback } from 'react';

import { toastError } from 'utils/defaultToasts';

import {
  useLocation,
  BrowserRouter as Router,
  useHistory,
} from 'react-router-dom';

import {
  FaFacebook,
  FaInstagram,
  FaSearch,
  FaShoppingCart,
  FaHeart,
} from 'react-icons/fa';

import { store } from '~/store';

import { Main, TopField, BottomField, Hamburger, MyAccount } from './styles';
import * as S from './styles';

import LoggedLogin from './components/LoggedLogin';

const Header = () => {
  const history = useHistory();

  const searchBarRef = useRef(null);

  const { accessToken } = store.getState().authReducer.auth;
  const [hamburger, setHamburger] = useState(false);

  const [headerColor, setHeaderColor] = useState('/home');
  const location = useLocation();

  const [searchParameter, setSearchParameter] = useState(null);

  const handleSearchParameter = ({ target }) => {
    setSearchParameter(target.value.trim());
  };

  const onSubmitSearch = (event) => {
    event.preventDefault();
    searchBarRef.current.blur();

    const isValidSearch =
      searchParameter !== null && searchParameter.trim().length > 0;

    if (!isValidSearch)
      return toastError('O campo de busca não pode estar vazio.');

    history.push(`/home/${searchParameter}`);
  };

  const selectMenu = () => {
    setHeaderColor(location.pathname);
  };

  const MinhaContaMenu = () => {
    if (!accessToken) {
      return (
        <li>
          <a
            className="linkMenu"
            href="/#/Login"
            onClick={() => setHamburger(false)}
          >
            Cadastrar/Login
          </a>
        </li>
      );
    }

    return <></>;
  };

  const handleCreateAnnouncement = useCallback(() => {
    setHamburger(false);

    if (!accessToken) {
      history.push({ pathname: '/Login' });

      return;
    }

    history.push({
      pathname: '/NewAnnouncement',
      state: { continue: false, id: null },
    });
  }, [accessToken, history]);

  return (
    <>
      <Router>
        <Main>
          <Hamburger
            propHamburger={hamburger}
            onClick={() => setHamburger(!hamburger)}
          >
            <span className="topLine" />
            <span className="middleLine" />
            <span className="bottomLine" />
          </Hamburger>
          <TopField>
            <div className="logo">
              <a className="linkMenu" href="/#/home">
                <img src={require('../../assets/nbikes_Logo.png')} alt="" />
              </a>
            </div>

            <div className="SearchField">
              <S.SearchBarForm onSubmit={onSubmitSearch}>
                <S.SearchBarInput
                  ref={searchBarRef}
                  onChange={handleSearchParameter}
                  type="text"
                  placeholder="Pesquisar"
                />

                <S.SearchButton type="submit">
                  <FaSearch id="search-icon" size={15} />
                </S.SearchButton>
              </S.SearchBarForm>

              <div className="SocialIcons">
                <a
                  href="https://www.facebook.com/Nbikes-100710171762615/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.instagram.com/nbikes.com.br/?igshid=1w6o8kfu5giw2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
          </TopField>

          <BottomField headerColor={headerColor} propHamburger={hamburger}>
            <div className="logo">
              <a className="linkMenu" href="/#/home">
                <img src={require('../../assets/nbikes_Logo.png')} alt="" />
              </a>
            </div>

            <div className="menu">
              <ul>
                <li>
                  <a
                    className="linkMenu"
                    href="/#/home"
                    onClick={() => {
                      selectMenu();
                      setHamburger(false);
                    }}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    className="linkMenu"
                    href="/#/Anuncios"
                    onClick={() => {
                      selectMenu();
                      setHamburger(false);
                    }}
                  >
                    Anúncios
                  </a>
                </li>

                <li>
                  <a className="linkMenu" onClick={handleCreateAnnouncement}>
                    Anuncie aqui
                  </a>
                </li>

                <MinhaContaMenu />

                <li>
                  <a
                    className="linkMenu"
                    href="/#/Help"
                    onClick={() => setHamburger(false)}
                  >
                    Ajuda
                  </a>
                </li>
              </ul>

              {accessToken && (
                <MyAccount>
                  <LoggedLogin setHamburger={setHamburger} />
                </MyAccount>
              )}
            </div>

            <div className="Shop">
              <FaShoppingCart />
              <FaHeart />
            </div>
          </BottomField>
        </Main>
      </Router>
    </>
  );
};

export default Header;
