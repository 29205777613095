import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position:relative;

  @media screen and (max-width: 960px) {
    ul {
      width: 100% !important;
    }


    h3 {
    font-size: 2vmax !important;

  }

  span {
    font-size: 1.5vmax !important;
  }

  }

  h5 {
    font-size: 23px;
    font-weight: 400;
    margin-bottom: 1em;
  }

  ul {
    width: 100%;
    height:91%;
  overflow:auto;
    border-bottom: 1px solid rgb(125, 125, 125, 0.3);
  }

  li {
    width: 100%;
    height: 70px;
    padding-top: 20px;

    & + li {
      border-top: 1px solid rgb(125, 125, 125, 0.3);
    }
  }

  span {
    font-size: 16px;
  }

  span:nth-child(2) {
    font-size: 18px;
    float: right;
    font-weight: 600;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: gray;
  }

`;
