import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 960px) {
    h5 {
      font-size: 20px !important;
    }
  }

  h5 {
    font-size: 23px;
    font-weight: 400;
    font-family: Nunito;
    margin: 0 auto;
  }

  svg {
    width: 200px;
    height: 200px;
    margin: 2em auto;
    fill: #00c569;
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 1em auto;
    font-family: Nunito;
  }
`;
