import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow:auto;
  display:flex;

  @media screen and (max-width: 960px) {

    flex-direction:column;
    overflow-y:auto;
    ul {
      width: 100% !important;
    }

    .paymentMethod, .paymentInfo, .photos
 {
      width: 100% !important;
    }


    h3 {
    font-size: 25px !important;

  }
  }

  ${(props) => {
    if (props.propPayment === 'card') {
      return css`
        .paymentMethod {
          .card {
            background: #00c569;
            border: none;

            svg,
            p {
              fill: white !important;
              color: white !important;
            }
          }
        }
      `;
    } else if (props.propPayment === 'boleto') {
      return css`
        .paymentMethod {
          .boleto {
            background: #00c569;
            border: none;

            svg,
            p {
              fill: white !important;
              color: white !important;
            }
          }
        }
      `;
    }
  }}


.paymentInfo{

width:50%;

}

  h5 {
    font-size: 23px;
    font-weight: 400;
    margin-bottom: 1em;
  }

  .paymentMethod {
    width: 100%;

    display: flex;
    justify-content: space-between;

    p {
      font-size: 10px;
      margin-top: 8px;
      pointer-events: none;
    }

    div {
      width: 110px;
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: solid 1px rgb(125, 125, 125, 0.3);
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;

        fill: rgb(125, 125, 125, 0.3);
      }
    }
  }

  ul {
    width: 100%;
    &:nth-child(2) {
      margin-top: 30px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  li {
    width: 100%;
    height: 70px;
    padding-top: 20px;
  }
  .fullField {
    display: flex;
    justify-content: space-between;

    div {
      width: 45%;

      input {
        width: 100% !important;
      }
    }
  }

  span {
    font-size: 16px;
  }

  input {
    width: 50%;
    border-width: 0 0 2px 0;
    border-color: rgb(125, 125, 125, 0.3);
    border-style: solid;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: gray;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
    color: #00c569;
    font-family: Nunito;
    margin: 1em 0;
  }



.photos{ width:40%;
         padding-left:50px;



         .slide { background:none !important;}

         .carousel.carousel-slider{

height:200px;

         }

         .slider-wrapper{


overflow:unset !important;

         }

         }




         .control-dots{


li{

width:20px !important;
height:0px !important;

}

         }

         .Carousel {
    width: 100%;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

`;
