import React, { useContext } from 'react';
import { AccordionFilterItemsContext } from '~/context/Accordion';
import { v4 } from 'uuid';

import * as S from './styles';

const FilterOptions = () => {

  const { accordionFilterItems, setAccordionFilterItems } = useContext(AccordionFilterItemsContext)

  return (
    <div className="filters-showup">
      <ul>
        {(Object.keys(accordionFilterItems).find(key => !!accordionFilterItems[key]?.Description || (Array.isArray(accordionFilterItems[key]) && accordionFilterItems[key].length > 0))) && (
          <li
            onClick={() => {
              setAccordionFilterItems({ categories: [] })
            }}
            className="clean-all"
          >
            <span>Limpar Tudo</span>
          </li>
        )}
        {accordionFilterItems &&
          Object.keys(accordionFilterItems)?.map((option, index) => {

            const optionElement = accordionFilterItems[option];
            let filtersSelected;

            if (Array.isArray(optionElement)) {
              filtersSelected = optionElement.map(category => category?.Description).join(', ');

            }
            else if (optionElement?.Description) {
              filtersSelected = optionElement.Description;
            }
            else
              filtersSelected = '';

            return filtersSelected ? (
              <>
                <li key={v4()} style={{ display: 'flex' }}>

                  <span>{filtersSelected}</span>
                  <button
                    type="button"
                    onClick={(event) => {
                      setAccordionFilterItems(prev => {
                        const newObject = { ...prev };
                        if (Array.isArray(newObject[option]))
                          newObject[option] = [];
                        else
                          delete newObject[option];

                        return newObject;
                      })
                    }}
                  >
                    X
                  </button>
                </li>
              </>
            ) : null
          })}
      </ul>
    </div>
  )
};

export default FilterOptions;
