import { createStitches } from '@stitches/react';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      PrimaryBlue: '#304470',
      PrimaryLightBlue: '#C1D5FF',
      SecondaryBlue: '#45BDC2',

      PrimaryYellow: '#FCE76D',

      SuccessGreen: '#55CD59',
      ErrorRed: '#EE5D5D',

      LightGray: '#D2D2D2',
      DarkGray: '#4F4F4F',
    },
    fonts: {
      Nunito: 'Nunito, sans-serif',
    },
    transitions: {
      Default: '0.1s ease',
      SlowEase: '0.3s ease',
    },
  },
});
