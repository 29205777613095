import React, { useCallback, useEffect, useState } from 'react';

import * as S from './styles';

const Birthdate = ({ setValue }) => {
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const months31 = ['1', '3', '5', '7', '8', '10', '12'];

  const leapYear = (year) => ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);

  const generateNumberArray = (init, end) => {
    const numberArray = [];

    for (let number = init; number <= end; number += 1) numberArray.push(number);

    return numberArray;
  };

  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const [day, setDay] = useState('1');
  const [month, setMonth] = useState('1');
  const [year, setYear] = useState(currentYear);

  const getTotalDayInMoth = () => {
    if (months31.includes(month)) return 31;

    if (leapYear(year)) return 29;

    if (month === '2') return 28;

    return 30;
  };

  const getBirthdateLimit = useCallback(() => {
    if (Number(year) !== currentYear) return;

    if (Number(day) > currentDay) setDay(currentDay);

    if (Number(month) > currentMonth) setMonth(currentMonth + 1);
  }, [day, month]);

  useEffect(() => {
    getBirthdateLimit();
  }, [day, month]);

  useEffect(() => {
    const formattedDay = Number(day) >= 10 ? day : '0' + day;
    const formattedMonth = Number(month) >= 10 ? month : '0' + month;

    setValue(`${year}-${formattedMonth}-${formattedDay}`);
  }, [day, month, year]);

  return (
    <S.Container>
      <S.FlexWrapper>
        <S.Select value={day} onChange={(event) => setDay(event.target.value)}>
          {generateNumberArray(1, getTotalDayInMoth()).map((number) => <S.Option value={number}>{number}</S.Option>)}
        </S.Select>

        <S.Select value={month} onChange={(event) => setMonth(event.target.value)}>
          {months.map((month, index) => <S.Option value={index + 1}>{month}</S.Option>)}
        </S.Select>

        <S.Select value={year} onChange={(event) => setYear(event.target.value)}>
          {generateNumberArray(1900, currentYear).reverse().map((number) => <S.Option value={number}>{number}</S.Option>)}
        </S.Select>
      </S.FlexWrapper>

      <S.Span>
        Data de nascimento
      </S.Span>

      <S.Error>
        Por favor, preencha sua data de nascimento.
      </S.Error>
    </S.Container>
  )
}

export default Birthdate;
