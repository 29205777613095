import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { styled } from 'stiches.config';

import { IconEdit } from '~/icons/IconEdit';

import bikeCoupon from '~/assets/bike-coupon.png';

import { useAtom } from 'jotai';
import {
  couponInfosAtom,
  partnerBackgroundAtom,
  partnerWebsiteAtom,
} from '~/jotai/atoms/registerPartnerAtoms';
import { useSelector } from 'react-redux';
import { toastInfo } from '~/utils/defaultToasts';
import api from '~/services/api';

const Container = styled('div', {
  position: 'relative',
  zIndex: 0,

  marginTop: "40px",

  minWidth: 230,
  width: 230,
  height: 230,

  backgroundImage: (url) => `url(${url})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  borderRadius: 12,

  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    cursor: 'pointer',
  },

  variants: {
    isDisabled: {
      true: {
        '&:hover': {
          cursor: 'not-allowed',
        },
      },
    },
    viewModeOnly: {
      true: {
        '&:hover': {
          cursor: 'unset',
        },
      },
    },
  },

  '@media (max-width: 960px)': {
    marginLeft: "36px",
    marginTop: "18px",
  },

  '@media (max-width: 330px)': {
    minWidth: 180,
    width: 180,
    height: 180,
  },
});

const EditButton = styled('button', {
  position: 'absolute',
  top: 12,
  right: 12,

  padding: 4,
  borderRadius: 8,
  border: 'none',

  zIndex: 2,

  width: 32,
  height: 32,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
});

const Discount = styled('div', {
  width: 75,
  height: 75,

  position: 'absolute',
  bottom: -32,
  right: -32,
  zIndex: 2,

  background: '#223357',

  borderRadius: 64,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 3,

  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',

  '@media (max-width: 330px)': {
    width: 65,
    height: 65,

    bottom: -22,
    right: -22,
  },
});

const BikeCoupon = styled('img', {
  position: 'absolute',
  bottom: 12,
  right: 32,

  zIndex: 0,

  width: 40,
  height: 'auto',

  '@media (max-width: 330px)': {
    width: 32,
  },
});

const Wrapper = styled('div', {
  position: 'relative',
  zIndex: 3,

  display: 'flex',
  flexDirection: 'column',
});

const Value = styled('div', {
  position: 'relative',
  zIndex: 3,

  color: '#FCE76D',
  fontSize: 26,
  fontWeight: 'bold',
});

const DiscountType = styled('div', {
  color: '#FCE76D',
  fontSize: 14,
  fontWeight: 'bolder',
  textAlign: 'center',
});

const Off = styled('div', {
  color: '#FCE76D',
  fontWeight: 'bolder',
  fontSize: 8,
});

const Remaining = styled('div', {
  position: 'absolute',
  zIndex: 1,
  inset: 0,

  borderRadius: 12,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  opacity: 0,

  background: 'rgba(0, 0, 0, 0.35)',
  backdropFilter: 'blur(16px)',
  color: 'white',

  transition: 0.15 + 's',

  fontSize: 20,
  fontWeight: 'bold',
  textAlign: 'center',

  padding: 24,

  '&:hover': {
    opacity: 1,
  },
});

export function Coupon(props) {
  const history = useHistory();
  const [partnerWebsite, setPartnerWebsite] = useAtom(partnerWebsiteAtom);
  const [partnerBackground, setPartnerBackground] = useAtom(
    partnerBackgroundAtom
  );
  const [couponInfos, setCouponInfos] = useAtom(couponInfosAtom);

  const accessToken = useSelector(
    (state) => state.authReducer.auth.accessToken
  );
  const [partnerData, setPartnerData] = useState({});

  const {
    discount = 10,
    image = '',
    remaining = 3,
    hasAdminFeatures = false,
    viewModeOnly = false,
    isDisabled,
    partnerVoucherId,
    partnerId,
    partnerUrl,
    isPercentage,
    value,
  } = props;

  const getPartner = useCallback(async () => {
    api
      .get(`/api/v1/Partners/${partnerId}`)
      .then((response) => {
        setPartnerData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [partnerId]);

  useEffect(() => {
    getPartner();
  }, [getPartner]);

  const disabledMessage = useMemo(() => {
    if (isDisabled) {
      return 'Cupom desabilitado';
    } else if (partnerData.isEnabled === false) {
      return 'Parceiro desabilitado';
    }

    return '';
  }, [isDisabled, partnerData]);

  const remainingMessage = !remaining
    ? 'Esta loja não possui nenhum cupom restante'
    : `Esta loja possui ${remaining} ${
        remaining > 1 ? 'cupons restantes' : 'cupom restante.'
      }`;

  const handleClick = useCallback(async () => {
    if (!remaining || viewModeOnly) return;

    if (!accessToken) {
      toastInfo('Você precisa estar logado para acessar o cupom!');

      return;
    }

    setPartnerWebsite(partnerUrl);

    setCouponInfos({
      image,
      discount,
      isPercentage,
      value,
    });

    history.push({
      pathname: `/PartnerCoupon/${partnerVoucherId}`,
    });
  }, [remaining]);

  const handleEditPartner = useCallback(
    (e) => {
      // if (!remaining || viewModeOnly) return;

      e.stopPropagation();

      setPartnerBackground(image);

      history.push({
        pathname: `/EditPartnerAndCoupon/${partnerId}/${partnerVoucherId}`,
      });
    },
    [remaining]
  );

  return (
    <Container
      onClick={handleClick}
      isDisabled={isDisabled}
      viewModeOnly={viewModeOnly}
      css={{
        filter:
          hasAdminFeatures && (!remaining || isDisabled)
            ? 'grayscale(1)'
            : 'unset',

        backgroundImage: `url(${image})`,
      }}
    >
      {hasAdminFeatures && remaining && !isDisabled && (
        <Remaining>{remainingMessage}</Remaining>
      )}

      {hasAdminFeatures && isDisabled && (
        <Remaining>{disabledMessage}</Remaining>
      )}

      {hasAdminFeatures && (
        <EditButton onClick={handleEditPartner}>
          <IconEdit width={24} height={24} />
        </EditButton>
      )}

      {/* <Image src={image} /> */}

      <Discount>
        <BikeCoupon src={bikeCoupon} />

        <Value>{discount}</Value>

        <Wrapper>
          <DiscountType>{isPercentage ? '%' : 'R$'}</DiscountType>
          <Off>OFF</Off>
        </Wrapper>
      </Discount>
    </Container>
  );
}
