import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const AnimationContainer = styled.div`
  width: 100%;

  transition: 1s ease;

  opacity: 0;

  transform: translateX(-50%);
`;

export const Field = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  margin: 3em auto 20px 0;
  padding: 50px;

  border-radius: 20px;

  transition: 0.3s;

  background: white;

  box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);

  @media screen and (max-width: 960px) {
    padding: 20px !important;
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
  }

  p, span {
    font-size: 16px;
    color: gray;
  }

  .title-p-description {
    margin-bottom: 20px;
  }

  input {
    width: 100%;

    border-style: 1px solid rgb(125, 125, 125, 0.3);
    border-width: 0 0 1px 0;

    overflow: hidden;

    box-sizing: border-box;

    margin: 0px 0 10px 0;

    font-size: 22px;
  }

  .non-border {
    @media screen and (max-width: 960px) {
      border: none; 
    }
  }

  .brand-div,
  .serial-number-div {
    font-weight: bold;
    font-size: 18px;

    color: #000;
  }

  .serial-number-div {
    margin-top: 20px;
  }

  .lenghtCounter {
    float: right;
  }

  textarea {
    width: 100%;
    height: 300px;
    resize: none;
    padding: 5px;
    font-size: 22px;
    border: 1px solid rgb(125, 125, 125, 0.3);
    margin: 12px 0;
  }

  .selectField {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 1em 0 2em 0;

    li {
      margin: 2em 0;
    }

    input {
      margin: 30px 0 0 0 !important;
    }
  }

  .selectSection {
    max-width: 550px;
    height: 60px;

    margin: 20px 0;
    border: 1px solid rgb(125, 125, 125, 0.4);
    position: relative;

    &:hover {
      cursor: pointer;
    }

    span {
      font-size: 18px;
      font-weight: 600;

      color: #000;

      position: absolute;

      top: 3px;
      left: 5px;

      pointer-events: none;
    }

    .non-border-select {
      border: 0;
      margin:0;
      padding-left: 4px;
    }

    select {
      width: 100%;

      height: 100%;
      font-size: 23px;
      font-weight: 400;
      transition: 0.3s;

      padding-top: 24px;

      background: white;

      &:hover {
        cursor: pointer;
      }
    }

    input {
      padding-left: 5px;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 20px 0 50px 0;

  ${(props) => {
    if (props.propScroll) {
      if (props.propErrors?.Category) {
        document
          .getElementsByName('subCategoryBorder')[0]
          .scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      if (props.propErrors?.Description) {
        document
          .getElementsByName('descriptionTitle')[0]
          .scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      if (props.propErrors?.Title) {
        document
          .getElementsByName('title')[0]
          .scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  }}

    ${(props) => {
      if (props.propErrors?.Category) {
        return css`
          div[name='subCategoryBorder'] {
            color: #b41212 !important;
            border-color: #b41212 !important;
          }
        `;
      }
    }}

${(props) => {
  if (props.propErrors?.Description) {
    return css`
      textArea[name='description'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
${(props) => {
  if (props.propErrors?.Title) {
    return css`
      input[name='title'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
  return css``;
}}

${(props) => {
  if (props.propErrors?.Brand) {
    return css`
      select[name='brand'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
${(props) => {
  if (props.propErrors?.Condition) {
    return css`
      select[name='condition'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
${(props) => {
  if (props.propErrors?.Year) {
    return css`
      input[name='year'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
${(props) => {
  if (props.propErrors?.Size) {
    return css`
      select[name='size'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
${(props) => {
  if (props.propErrors?.Number) {
    return css`
      select[name='number'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
${(props) => {
  if (props.propErrors?.ForkTravel) {
    return css`
      select[name='forkTravel'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
${(props) => {
  if (props.propErrors?.RimSize) {
    return css`
      select[name='rimSize'] {
        color: #b41212;
        border-color: #b41212;
        &::placeholder {
          color: #b41212;
        }
      }
    `;
  }
}}
`;

export const FlexWrapper = styled.div`
  display: flex;

  #or-span {
    font-size: 20px;

    margin: 0 20px;

    transform: translateY(40px);
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;

    #or-span {
      transform: translateY(0);

      margin: 10px 0;
    }
  }
`;
