import styled from 'styled-components';

export const AnimationContainer = styled.div`
  transition: 1s ease;

  transform: translateX(-50%);

  opacity: 0;

  width: 100%;
`;

export const MainContainer = styled.div`
  position: relative;

  max-width: 800px;

  margin: 0 auto;

  .nextStep {
    margin-top: 40px;

    width: 150px;
    height: 60px;

    background: rgb(32, 52, 83);

    font-size: 20;
    color: white;

    border: none;
    border-radius: 10px;

    float: right;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;

  margin: 0 auto;
  padding-bottom: 50px;

  background-color: white;

  border: 1px solid black;
  border-radius: 20px;

  h1 {
    margin-top: 20px;

    font-size: 28px;

    font-weight: 500;
  }

  #go-to-announcement-button {
    margin-top: 20px;

    font-size: 24px;

    text-decoration: 1px underline;

    background-color: transparent;

    border: none;

    transition: 0.3s ease;

    &:hover {
      color: #37bcc1;

      transition: 0.3s ease;
    }
  }
`;

export const Field = styled.div`
  margin: 3em auto;

  border-radius: 12px;
  text-align: center;
  transition: 0.3s;

  h4 {
    font-size: 24px;
    margin: 0.5em 0.1em 0;
    font-weight: 400;
  }

  svg {
    width: 150px;
    height: 150px;
    fill: #00c569;
    margin: 0 auto;
  }

  span {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  span:hover {
    text-decoration: none;
    text-shadow: 1px 1px 1px #555;
  }
`;
