import styled from 'styled-components';

export const Field = styled.div`
  margin: 3em auto;
  border-radius: 12px;
  padding: 0 3rem 3rem;
  transition: 0.3s;
  background: white !important;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  @media screen and (max-width: 520px) {
    padding: 2rem;
  }
  h4 {
    font-size: 24px;
    margin: 1em 1em 0;
    font-weight: 400;
    text-align: center;
  }

  .email-field {
    height: 25px;
    display: flex;
    margin-top: 1rem;
    button {
      width: 25px;
      height: 25px;
      border-radius: 3px;
      margin-left: 10px;
      background: #213455;
      border: 0;

      svg {
        fill: white;
      }
    }
  }

  input {
    max-width: 350px;
    width: 100%;
    font-size: 20px;
    border-width: 0 0 1px 0;

    border-bottom: 1px solid gray;
  }

  span {
    margin: 0 auto;
    text-align: left;
    width: 100%;
    margin-top: .5rem;
  }

  .reset-password-field {
    width: 350px;
    margin: 60px auto 0 auto;

    li {
      text-align: left;
      margin: 1em 0;
    }
  }
  .confirm-button {
    width: 350px;
    height: 35px;
    margin: 0 auto;
    background: #213455;
    color: white;
    border: 0;
    border-radius: 3px;
  }
`;

export const BaseContainer = styled.form`
  width: 100%;
  max-width: 1280px;
  min-height: 600px;
  height: 100%;
  margin: 0 auto;
  padding: 1em 20px 3em 20px;
  position: relative;

  h2 {
    font-size: 22px;
    font-weight: 400;
  }
  h1 {
    margin: 50px 0 0 50px;
  }

  p {
    font-size: 20px;
  }

  span {
    font-size: 12px;
    vertical-align: bottom;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 20px 0;
  overflow: hidden;
  background: rgb(125, 125, 125, 0.1);
`;

export const GoBackButton = styled.button`
  position: absolute;

  top: 10px;
  left: 20px;

  display: flex;

  justify-content: center;
  align-items: center;

  width: fit-content;

  background-color: transparent;
  border: 0;

  svg {
    color: var(--blueNbikes);
  }
`;
