import React from 'react';

export function IconEdit({ width = 16, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
    >
      <path
        d="M4.359 12.939H3.168a.998.998 0 0 1-.992-1.003V2.307a.998.998 0 0 1 .992-1.003h2.976v3.812c0 .329.27.601.596.601h3.81c.079.233.293.402.555.402a.603.603 0 0 0 .595-.602v-.401a.626.626 0 0 0-.174-.426L7.16.277a.613.613 0 0 0-.42-.176H3.168A2.193 2.193 0 0 0 .985 2.307v9.629c0 1.22.977 2.206 2.183 2.206h1.19a.603.603 0 0 0 0-1.203ZM7.335 2.155l2.333 2.359H7.335v-2.36Zm6.238 4.862a1.757 1.757 0 0 0-1.206-.497 1.605 1.605 0 0 0-1.23.473l-5.072 5.135a.617.617 0 0 0-.175.377l-.142 1.79a.582.582 0 0 0 .174.473.591.591 0 0 0 .42.177h.056l1.786-.169a.585.585 0 0 0 .365-.176l5.072-5.136c.635-.633.611-1.78-.048-2.447Zm-.793 1.597-4.921 4.983-.865.08.063-.85 4.921-4.983c.08-.08.222-.113.365-.12.159 0 .302.055.39.144.19.184.213.577.047.746Z"
        fill="#45BDC2"
      />
    </svg>
  );
}
