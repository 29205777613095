import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
`;

export const BaseContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  height: auto;
  margin: auto;
  padding: 20px;
  position: relative;

  .alterar {
    float: right;
  }

  span {
    font-size: 12px;
    vertical-align: bottom;
  }
  h2 {
    margin-bottom: 20px;
  }

  #filter {
    padding-left: 5px;

    border-radius: 10px;

    margin-bottom: 30px;

    width: 200px;
    height: 30px;

    font-size: 18px;
  }

  #search-bar {
    padding-left: 10px;

    border: 1px solid grey;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    margin-bottom: 30px;

    width: 300px;
    height: 30px;

    font-size: 18px;
  }

  #search-container {
    display: flex;
  }

  #search-button {
    transition: 0.3s ease;

    border: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    height: 30px;
    width: 100px;

    font-size: 18px;

    background-color: var(--blueNbikes);

    color: white;

    &:hover {
      transition: 0.3s ease;

      filter: brightness(85%);
    }
  }

  #filter-by {
    margin: 0 0 10px 2px;
  }
`;

export const Products = styled.div`
  width: 100%;
`;

export const ProductSquare = styled.div`
  width: 100%;
  height: 240px;

  display: flex;

  position: relative;

  align-items: center;

  transition: 0.5s;

  background: #ffffff;

  border-radius: 10px;

  overflow: hidden;

  margin-bottom: 2em;

  box-shadow: #bdbdbd 0 0 5px;

  #interested-clients {
    margin: 10px 0;

    text-decoration: underline;

    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    height: auto;
    flex-direction: column;

    .image {
      width: 100% !important;

      img {
        width: 100% !important;
      }
    }

    .mainInfo {
      width: 100% !important;
      margin-left: 0px !important;
      padding: 10px !important;
    }

    p {
      font-size: 12px !important;
    }

    h3 {
      font-size: 15px !important;
    }

    h4 {
      font-size: 16px !important;
    }

    .icons {
      width: 100% !important  ;
    }
  }

  .mainInfo {
    width: clamp(250px, 100%, 900px);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 20px;

    padding: 10px 0;

    #announcement-title {
      width: clamp(250px, 100%, 750px);

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .publication {
    width: 220px;
    height: 30px;

    background: white;

    position: absolute;

    top: 10px;
    right: -10px;
    z-index: 2;

    border-radius: 20px;

    padding: 10px 20px;

    box-shadow: #6b6b6b 0 2px 2px;

    .publicado,
    .criado,
    .finalizado {
      width: 100%;
      height: 100%;

      display: flex;

      align-items: center;

      svg {
        width: 15px;
        height: 12px;

        margin-right: 5px;
      }
    }

    .publicado {
      color: #00c569;

      svg {
        fill: #00c569;
      }
    }

    .criado {
      color: #e47351;

      svg {
        fill: #e47351;
      }
    }

    .finalizado {
      width: 100%;
      height: 100%;
      color: var(--blueNbikes);

      svg {
        fill: var(--blueNbikes);
      }
    }
  }

  .icons {
    width: 60%;
    display: flex;
    align-items: center;
    color: var(--blueNbikes);
    padding: 10px 0 0 0;
    border-top: var(--blueNbikes) solid 1px;

    button {
      width: 90px;

      display: flex;
      align-items: center;

      background: none;
      color: var(--blueNbikes);

      border: none;

      &:hover {
        color: #37bcc1;

        svg {
          fill: #37bcc1;
        }
      }
      svg {
        margin-right: 4px;

        fill: var(--blueNbikes);

        transition: 0.3s;
      }
      span {
        padding-top: 2px;

        transition: 0.3s;
      }
    }
  }

  .image {
    min-width: 250px;
    height: 245px;
    overflow: hidden;
    position: relative;

    img {
      width: 370px;
      height: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h3 {
    font-size: 25px;
    color: var(--blueNbikes);
    transition: 0.5s;
  }

  .data {
    font-size: 14px;
  }

  p {
    font-weight: 200;
    display: flex;
    align-items: center;
    transition: 0.5s;

    svg {
      margin-right: 10px;
    }
  }

  h4 {
    color: #00c569;
    font-size: 32px;
  }
`;

export const PagePreviousNext = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  .pageCounter {
    display: flex;
  }

  button {
    width: 100px;
    height: 40px;
    margin: 0 5px;
    transition: 0.2s;
    border-radius: 4px;
    background: var(--blueNbikes);
    color: white;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background: #37bcc1;
    }
  }
`;
