export default [
  {
    questions: 'Quem somos',
    answers: [
      'a. Somos um grupo de pessoas apaixonadas por bikes. Como ciclistas, sentíamos a necessidade de vender bicicletas e peças usadas. Com dificuldade de achar um local para anunciar esses produtos, tivemos a ideia de criar o N Bikes, uma ferramenta que conecta o vendedor ao comprador com eficiência, contando com uma interface simples para qualquer usuário.',
      'b. Os anúncios são gratuitos e você entra em contato diretamente com o vendedor para realizar sua compra após seu cadastro. Aproveite!',
    ],
  },

  {
    questions: 'Produtos permitidos',
    answers: [
      'a. Bicicletas, peças e acessórios, seminovos ou usados - exemplo: pedais, freios, capacetes, hacks, óculos, luvas, joelheiras, truck pads, etc.',
    ],
  },

  {
    questions: 'Produtos não permitidos',
    answers: [
      'a. Não é permitido nenhum outro tipo de produto que não esteja relacionado com o contexto da bicicleta - exemplo: roupas casuais, peças de motos, tênis casuais, bonés, cadeiras, etc.',
      'b. Não é permitida a venda de produtos novos, mesmo que esses estejam relacionados a bike.',
    ],
  },

  {
    questions: 'Dicas de segurança',
    answers: [
      'a. Comprador: quando for procurar algum produto, fique atento à classificação do vendedor, entre em contato com ele, converse sobre o produto que está interessado.  Certifique-se de que o produto ainda está à venda.',
      'b. Vendedor: fique atento à classificação do comprador. Certifique-se do interesse da pessoa em seu produto e, se for necessário, entre em contato com o comprador. Seja claro e verdadeiro nas imagens dos produtos anunciados. Mostre defeitos que eventualmente possam existir em algum produto usado, e informe se eles interferem, de alguma forma, no funcionamento do equipamento.',
    ],
  },

  {
    questions: 'Quanto tenho que pagar para anunciar um produto?',
    answers: [
      'a. Os anúncios são gratuitos, feitos após um cadastro em que são solicitados documentos e dados pessoais para manter a segurança de usuários reais. O site não se responsabiliza pelas negociações ocorridas entre comprador e vendedor. Ele é apenas um veículo de comunicação entre as partes interessadas.',
    ],
  },

  {
    questions:
      'O N Bikes cobra alguma taxa do usuário – vendedor ou comprador?',
    answers: [
      'a. Não cobramos taxas pelas transações efetivadas no site. Você entra em contato com o vendedor e realiza sua compra diretamente com ele, após seu cadastro, quando são solicitados documentos e dados pessoais para manter a segurança de usuários reais. O site não se responsabiliza pelas negociações ocorridas entre comprador e vendedor. Ele é apenas um veículo de comunicação entre as partes interessadas.',
    ],
  },

  {
    questions: 'Meus dados estarão em segurança?',
    answers: [
      'a. Sim. Ao realizar seu cadastro, serão solicitados documentos e dados pessoais para garantir a veracidade dos usuários e evitar possíveis robôs que comprometem o funcionamento do site. Estes dados serão mantidos em sigilo e não serão divulgados. ',
    ],
  },

  {
    questions:
      'Quando eu fizer um anúncio, meus dados irão aparecer para qualquer pessoa que entrar no site?',
    answers: [
      'a. Não. Apenas o nome do vendedor estará exposto no anúncio, e só aparecerá para outros usuários cadastrados, quando logados no site. O vendedor escolhe a forma de contato que será divulgada no anúncio (telefone ou e-mail). ',
    ],
  },

  {
    questions: 'Como funciona a avaliação de comprador e vendedor?',
    answers: [
      'a. O N Bikes tem uma avaliação dupla, na qual o comprador avalia o vendedor e o vendedor avalia o comprador. A avaliação é feita através do Lead Trust, que verifica a veracidade dos dados cadastrais através do cruzamento legal de bases governamentais e pontua a precisão e qualidade das informações, junto ao documento anexo ao cadastro. ',
    ],
  },

  {
    questions: 'Posso negociar diretamente com o vendedor?',
    answers: [
      'a. Sim. Uma boa negociação se dá quando existe clareza e boa comunicação entre os interessados. Por esse motivo, o site deixa livre essa interação. O site não se responsabiliza pelas negociações ocorridas entre comprador e vendedor. Ele é apenas um veículo de comunicação entre as partes.',
    ],
  },
];
