import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 300%;
  position: fixed;
  left:0;
  display: flex;
  justify-content:center;
  backdrop-filter: blur(5px);
   background: rgb(0, 0, 0, 0.3);
  z-index: 99999;
  top:-200px;




 @media screen and (max-height:880px){


.infosFooter{

top:825px !important;

}

}


.infosFooter{

width:880px;
height:auto;
position:absolute;
top:1000px;
transform:translateY(-10%);
display:flex;
justify-content:space-between;
padding:0 20px;




span {
      font-size: 13px;

      fill: rgb(125, 125, 125, 0.8);
      color: rgb(125, 125, 125, 0.5);
    }

}


  .partner {
    width: 300px;
    height: 80%;
    margin: 10px 0;
    padding: 0 10px;

    div {
      width: 80px;
      vertical-align: middle;
      display: flex;
      justify-content: space-between;
      align-items: center;

    img {
      height: auto;
    }}
  }




`;

export const MainContainer = styled.div`
   width: 900px;
  height: 600px;
  background: white;
  box-shadow: 0 0 1em rgb(0, 0, 0, 0.3);
  border-radius:15px;
  overflow:hidden;
  padding:20px;
  position:absolute;
  top:12%;
  transition:0.2s;



  @media screen and (max-height: 880px) {
    height:550px !important;
  }



  @media screen and (max-width: 960px) {
    width: 95% !important;
  }



`;

export const Header = styled.div`
  width: 100%;
  height: 130px;
  background: white;
  display: flex;
  align-items:center;
  border-bottom: 3px solid #0075c2;
  position: relative;
  padding-bottom:20px;

  @media screen and (max-width: 960px) {
    height: 120px;
    flex-direction: column;
  border-bottom: 1px solid transparent;

    .logoMarca {
      width: 50px !important;
      height:50px !important;
      margin: 10px 0;
      position:absolute ;
      left:0;
      top:0;

      img {
        width: 70px;

        margin-left: 0;
      }
    }


    .closePopUp{


      width: 50px !important;
      height:50px !important;
top:0;
right:0;
position:absolute !important;}

  }

  .logoMarca {
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;

    img {
      width: 60%;
      height: auto;
      margin-left: 20px;
    }
  }

.closePopUp{


width:150px;
height:100%;
position:relative;

h3{

  font-family:Nunito;
font-size:2em;
color:#1a1a1a;
right:0px;
top:0px;
position:absolute;
cursor:pointer;
}

}

`;

export const ProgressBar = styled.div`
  width: calc(100% - 300px);
  height: auto;
  display: flex;
  position:relative;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 100%;
    height:100%;
    bottom:0;

 li {
      width: 80px;
    }

.Bar{
position:absolute !important;
bottom:0;

 li {
   svg, div{ display:none !important;}}

  #line-between {
      width: 100% !important;
      height:2px !important;
      top: unset !important;
      bottom:0;
    }
}

.Description{ width:100% !important;
             position:absolute;
               bottom:10px;



               li{
width:100%;
font-size:23px;
color:#1a1a1a;
               }

               }





               ${(props) => {

if (props.propMainInfo === 'Products') {
      return css`
        .Description {


li:nth-child(1){

display:unset !important;

}

li:nth-child(n+2):nth-child(-n+5){ display:none !important;}


        }
      `;}

   else if (props.propMainInfo === 'Identify') {
      return css`
        .Description {


li:nth-child(2){

display:unset !important;

}
li:nth-child(1){

display:none !important;

}

li:nth-child(n+3):nth-child(-n+5){ display:none !important;}


        }


      `;
    } else if (props.propMainInfo === 'Charge') {
      return css`
       .Description {


        li:nth-child(3){

display:unset !important;

}


li:nth-child(n+1):nth-child(-n+2){ display:none !important;}
li:nth-child(n+4):nth-child(-n+5){ display:none !important;}


        }

      `;
    } else if (props.propMainInfo === 'Payment') {
      return css`
      .Description {

        li:nth-child(4){

display:unset !important;

}


li:nth-child(n+1):nth-child(-n+3){ display:none !important;}
li:nth-child(5){ display:none !important;}


        }

      `;
    } else if (props.propMainInfo === 'Conclusion') {
      return css`
     .Description {


      li:nth-child(5){

display:unset !important;

}


li:nth-child(n+1):nth-child(-n+4){ display:none !important;}


     }

      `;
    }
  }}



  }

  ${(props) => {
    if (props.propMainInfo === 'Identify') {
      return css`
        .product {
          border-color: #00c569 !important;
        }


        .identify {
          svg {
            fill: #00c569 !important;
          }
        }

.Bar{
        #line-between {
          span {
            width: 23% !important;
          }
        }}
      `;
    } else if (props.propMainInfo === 'Charge') {
      return css`
        .identify,
        .product {
          border-color: #00c569 !important;
        }

        .charge,
        .identify {
          svg {
            fill: #00c569 !important;
          }
        }

      .Bar{  #line-between {
          span {
            width: 50% !important;
          }
        }}
      `;
    } else if (props.propMainInfo === 'Payment') {
      return css`
        .identify,
        .charge,
        .product {
          border-color: #00c569 !important;
        }

        .charge,
        .identify,
        .payment {
          svg {
            fill: #00c569 !important;
          }
        }

       .Bar{ #line-between {
          span {
            width: 75% !important;
          }
        }}
      `;
    } else if (props.propMainInfo === 'Conclusion') {
      return css`
        .identify,
        .charge,
        .product,
        .payment,
        .conclusion {
          border-color: #00c569 !important;
        }

        .charge,
        .identify,
        .payment,
        .conclusion {
          svg {
            fill: #00c569 !important;
          }
        }

     .Bar{   #line-between {
          span {
            width: 100% !important;
          }
        }}
      `;
    }
  }}



.Bar{
height:30px;
position:relative;
display: flex;
      align-items: center;


#line-between {
    width: 80%;
    height: 1px;
    position: absolute;
    top: 50%;
    border:none !important;
    left: 50%;
    display: unset !important;
    z-index: 1;
    background: rgb(125, 125, 125, 0.3);
    transform: translate(-50%, -50%);

    span {
      width: 0%;
      height: 100%;
      z-index: 2;
      position: absolute;
      background-color: #00c569;
      transition: 1s !important;
    }
  }

}



  .progressTop {
    width: 100%;
   .product,  .charge,
        .identify,
        .payment,
        .conclusion {
      width: 20px;
      height: 20px;
      margin: 0 auto;
      border-radius: 99px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      border: solid rgb(125, 125, 125, 0.3) 0.5px;
    }

    .product {
      svg {
        fill: #00c569;
        transition-delay: 0.4s;
      }
    }

    svg {
      width: 12px;
      height: 12px;
      fill: transparent;
    }
  }







  ul {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }

  li {
    width: 100px;
    text-align: center;
    font-size: 12px;
    font-family:Nunito;
    color: rgb(125, 125, 125, 0.5);
  }
`;

export const MainInfo = styled.div`
  width: 100%;
  height: calc(85% - 130px);
  background: white;
  overflow:hidden;
  padding:20px 0 ;

`;

export const Footer = styled.div`
  width: 100%;
  height: 15%;
  background: white;
  display: flex;
  align-items:center;
  border-top: solid 1px rgb(125, 125, 125, 0.3);

@media screen and (max-width:960px){


  .buttonFixer{ width:0 !important;}


  h3 {
    font-size: 15px !important;

  }

}






h3 {width:50%;
    height:30px;
    background:white;
    font-size: 28px;
    font-weight: 400;
    color: #00c569;
    font-family: Nunito;
    text-align:left;
    margin: 1em 0;
  }

  .buttons {
    width: 50%;
    height: 100%;
    padding: 20px 0px 0 0;
  display: flex;
  align-items:center;

    div {
      width: 100%;
  display:flex;
  justify-content:flex-end;

    }



    .firstButton {
      border: solid 1px #0075c2 !important;
      background: none !important;
      margin-right:10px;
      color: black !important;
    }


span{
      width: 130px;
}
    button {
      width: 130px;
      height: 40px;
      border: none;
      color: white;
      border-radius: 4px;
      background: #009afd;
    }
  }
`;
