import React, { useState } from 'react';

import { Container } from './styles';

import { Carousel } from 'react-responsive-carousel';

import { FaCreditCard, FaBarcode } from 'react-icons/fa';

export default function Payment() {
  const [cardNumber, setCardNumber] = useState();

  const cardNumberMask = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2');
  };

  const [date, setDate] = useState();

  const dateMask = (value) => {
    return value
      .replace(/\D/g, '')

      .replace(/(\d{2})(\d)/, '$1/$2');
  };

  const [Payment, setPayment] = useState('');

  return (
    <Container propPayment={Payment}>
      <div className="paymentInfo">
        <h5>Forma de Pagamento</h5>

        <div className="paymentMethod">
          <div onClick={() => setPayment('card')} className="card">
            <FaCreditCard />

            <p> Cartão de Credito </p>
          </div>

          <div onClick={() => setPayment('boleto')} className="boleto">
            <FaBarcode />

            <p> Boleto Bancário </p>
          </div>
        </div>

        <ul>
          <li>
            <p>Nome do Cartão* </p>

            <input type="text" style={{ width: '100%' }} />
          </li>

          <li>
            <div>
              <p>Número do Cartão* </p>

              <input
                type="text"
                value={cardNumber}
                maxLength="19"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setCardNumber(cardNumberMask(e.target.value));
                }}
              />
            </div>
          </li>

          <li className="fullField">
            <div>
              <p>Data de Vencimento* </p>

              <input
                type="email"
                maxLength="5"
                value={date}
                onChange={(e) => {
                  setDate(dateMask(e.target.value));
                }}
              />
            </div>

            <div>
              <p>CVV * </p>

              <input type="email" maxLength="3" />
            </div>
          </li>
          <h3>Total a pagar: R$ 3.799,00 </h3>
        </ul>
      </div>
      <div className="photos">
        <h5>Seu Produto</h5>

        <Carousel
          showArrows={true}
          showStatus={false}
          infiniteLoop={true}
          emulateTouch={true}
          className="Carousel"
        >
          <div>
            <img src={require('~/assets/nBikes_show_1.jpg')} alt="bg1" />
          </div>
          <div>
            <img src={require('~/assets/nBikes_show_2.jpg')} alt="bg2" />
          </div>
          <div>
            <img src={require('~/assets/nBikes_show_3.jpg')} alt="bg3" />
          </div>
          <div>
            <img src={require('~/assets/nBikes_show_1.jpg')} alt="bg4" />
          </div>
        </Carousel>
      </div>
    </Container>
  );
}
