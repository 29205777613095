import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  /* height: 85vh; */
  display: flex;
  flex-direction: column;
  margin: 0 auto 5em auto;
  padding: 0 20px;

  @media screen and (max-width: 960px) {
    .title {
      margin: 20px 0 0 0;
    }
  }

  .title {
    margin-top: 20px;
  }
`;

export const Field = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: white;
  padding: 20px;
  margin: 20px 0;
`;
