import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import { FaFacebook, FaHeart, FaAngleRight } from 'react-icons/fa';

import { Products, Used, ProductSquare } from './styles';

export default function AnunciosBikes() {
  const isUsed = false;

  const h4 = 500;

  // const calculo = h4 / 12;

  const { url } = useRouteMatch();

  return (
    <>
      <Products>
        <div className="ListOfProducts">
          <h3>Anúncios</h3>

          <div className="products">
            <ul>
              <a href={`/#${url}Product-01`}>
                <li>
                  <ProductSquare>
                    <div className="icons">
                      <FaFacebook className="face-icon" />
                      <FaHeart className="heart-icon" />
                    </div>

                    <div className="image">
                      <img src={require('~/assets/DemoBanner4.jpg')} alt="" />
                    </div>
                    <Used isUsed={isUsed} className="bikeState">
                      {isUsed ? 'Novo' : 'Usado'}
                    </Used>
                    <span>Bike Gear</span>
                    <p>
                      <h4>R$ {h4}</h4>
                    </p>
                    {/* <p>
                      <h6>em 12x R$ {final} sem juros</h6>
                    </p> */}
                  </ProductSquare>
                </li>
              </a>
              <li>
                <ProductSquare>
                  <div className="icons">
                    <FaFacebook className="face-icon" />
                    <FaHeart className="heart-icon" />
                  </div>

                  <div className="image">
                    <img src={require('~/assets/DemoBanner2.jpg')} alt="" />
                  </div>
                  <Used isUsed={isUsed} className="bikeState">
                    {isUsed ? 'Novo' : 'Usado'}
                  </Used>
                  <span>Bike Gear</span>
                  <p>
                    <h4>R$ {h4}</h4>
                  </p>
                  {/* <p>
                    <h6>em 12x R$ {final} sem juros</h6>
                  </p> */}
                </ProductSquare>
              </li>
              <li>
                <ProductSquare>
                  <div className="icons">
                    <FaFacebook className="face-icon" />
                    <FaHeart className="heart-icon" />
                  </div>

                  <div className="image">
                    <img src={require('~/assets/DemoBanner.jpg')} alt="" />
                  </div>
                  <Used isUsed={isUsed} className="bikeState">
                    {isUsed ? 'Novo' : 'Usado'}
                  </Used>
                  <span>Bike Gear</span>
                  <p>
                    <h4>R$ {h4}</h4>
                  </p>
                  {/* <p>
                    <h6>em 12x R$ {final} sem juros</h6>
                  </p> */}
                </ProductSquare>
              </li>
              <li>
                <ProductSquare>
                  <div className="icons">
                    <FaFacebook className="face-icon" />
                    <FaHeart className="heart-icon" />
                  </div>

                  <div className="image">
                    <img src={require('~/assets/DemoBanner4.jpg')} alt="" />
                  </div>
                  <Used isUsed={isUsed} className="bikeState">
                    {isUsed ? 'Novo' : 'Usado'}
                  </Used>
                  <span>Bike Gear</span>
                  <p>
                    <h4>R$ {h4}</h4>
                  </p>
                  {/* <p>
                    <h6>em 12x R$ {final} sem juros</h6>
                  </p> */}
                </ProductSquare>
              </li>
              <li>
                <ProductSquare>
                  <div className="icons">
                    <FaFacebook className="face-icon" />
                    <FaHeart className="heart-icon" />
                  </div>

                  <div className="image">
                    <img src={require('~/assets/DemoBanner4.jpg')} alt="" />
                  </div>
                  <Used isUsed={isUsed} className="bikeState">
                    {isUsed ? 'Novo' : 'Usado'}
                  </Used>
                  <span>Bike Gear</span>
                  <p>
                    <h4>R$ {h4}</h4>
                  </p>
                  {/* <p>
                    <h6>em 12x R$ {final} sem juros</h6>
                  </p> */}
                </ProductSquare>
              </li>
            </ul>
          </div>
        </div>
        <div className="pageCounter">
          <div className="squareCounter">1</div>
          <div className="squareCounter">2</div>
          <div className="squareCounter">3</div>
          <div className="squareCounter">4</div>
          <div className="squareCounter">5</div>
          <div className="squareCounter">6</div>
          <div className="squareCounter">
            Próxima <FaAngleRight />
          </div>
        </div>
      </Products>
    </>
  );
}
