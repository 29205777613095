/* eslint-disable default-case */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { toastError, toastSuccess, toastInfo } from 'utils/defaultToasts';

import Helmet from 'react-helmet';
import api from '~/services/api';

import { VscDebugContinue } from 'react-icons/vsc';
import { AiFillLike } from 'react-icons/ai';

import {
  FaAngleLeft,
  FaAngleRight,
  FaEdit,
  FaThumbsUp,
  FaCheck,
  FaEye,
  FaUserCheck,
  FaTrashAlt,
} from 'react-icons/fa';

import {
  MainContainer,
  BaseContainer,
  ProductSquare,
  Products,
  PagePreviousNext,
} from './styles';

export default function MyAnnouncements() {
  const history = useHistory();

  const apiMethodUrl = '/api/v1/Announcements/Mine/';

  const filterNodeElement = document.querySelector('#filter');

  const [announcementData, setAnnouncementData] = useState([]);

  const [negotiationViewers, setNegotiationViewers] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isUsingFilter, setIsUsingFilter] = useState(false);

  const [searchFilterInput, setSearchFilterInput] = useState('');

  const getAnnouncementsWithPagination = async () => {
    try {
      if (isUsingFilter) setCurrentPage(1);

      const response = await api.get(
        `${apiMethodUrl}?CurrentPage=${currentPage}&PageSize=5&IsPaged=true`
      );
      const pagination = JSON.parse(response.headers.pagination);

      setTotalPages(pagination.TotalPages);
      setAnnouncementData(response.data);
    } catch (error) {
      toastError('Ocorreu um erro ao buscar os seus anúncios...');
    }
  };

  const getAnnouncementsWithFilter = async (filter) => {
    try {
      let queryParam = '';

      if (filter === 'publicado') queryParam = '?IsPublished=true';
      else if (filter === 'criado') queryParam = '?IsPublished=false';
      else queryParam = '?IsFinished=true';

      const response = await api.get(apiMethodUrl + queryParam);

      setIsUsingFilter(true);
      setAnnouncementData(response.data);
    } catch (error) {
      toastError('Ocorreu um erro ao buscar os seus anúncios...');
    }
  };

  const getAnnouncementsWithSearchFilter = async (searchFilterInput) => {
    try {
      const isSearchFilterInputEmpty = searchFilterInput.length === 0;

      if (isSearchFilterInputEmpty) {
        getAnnouncementsWithPagination();
        setIsUsingFilter(false);

        return toastError('Preencha a barra de busca.');
      }

      const response = await api.get(
        `${apiMethodUrl}?SearchText=${searchFilterInput}`
      );

      const isResponseEmpty = response.data.length === 0;

      if (isResponseEmpty) return toastError('Nenhum anúncio encontrado.');

      setIsUsingFilter(true);
      setAnnouncementData(response.data);
    } catch (error) {
      toastError('Ocorreu um erro ao buscar os seus anúncios...');
    }
  };

  const deleteMyAnnouncement = async (announcementId) => {
    toastInfo('Excluindo anúncio...');

    const apiDeleteMethodUrl = `/api/v1/Announcements/${announcementId}`;

    try {
      await api.delete(apiDeleteMethodUrl);

      getAnnouncementsWithPagination();

      toastSuccess('Anúncio excluído com sucesso!');
    } catch (error) {
      toastError('Erro ao excluir o anúncio, tente novamente.');
    }
  };

  const setAnnouncementAsSold = async (announcementId) => {
    toastInfo('Atualizando anúncio...');

    const apiUrl = `/api/v1/Announcements/${announcementId}/Sold`;

    try {
      await api.patch(apiUrl);

      getAnnouncementsWithPagination();

      toastSuccess('Anúncio atualizado como vendido!');
    } catch (error) {
      toastError('Erro ao atualizar o anúncio, tente novamente.');
    }
  };

  useEffect(() => {
    getAnnouncementsWithPagination();
  }, [currentPage]);

  useEffect(() => {
    if (announcementData) getNegotiationsViewersOfAnnouncement();
  }, [announcementData]);

  const createAnnouncementHTMLStatus = (announcementStatus) => {
    if (announcementStatus === 'Publicado')
      return (
        <>
          <FaCheck /> <span>Publicado</span>
        </>
      );
    if (announcementStatus === 'Criado')
      return (
        <>
          <FaCheck /> <span>Aguardando Publicação</span>
        </>
      );
    if (announcementStatus === 'Finalizado')
      return (
        <>
          <FaThumbsUp /> <span>Vendido</span>
        </>
      );
  };

  const createButtonHTML = (buttonType, announcementId) => {
    switch (buttonType) {
      case 'edit':
        return (
          <button
            type="button"
            data-id={announcementId}
            onClick={({ target }) => {
              const id = target.getAttribute('data-id');

              history.push({ pathname: `/EditMyAnnouncement/${id}` });
            }}
          >
            <FaEdit data-id={announcementId} />
            <span data-id={announcementId}>Editar</span>
          </button>
        );

      case 'delete':
        return (
          <button
            data-id={announcementId}
            onClick={async ({ target }) => {
              const id = target.getAttribute('data-id');

              await deleteMyAnnouncement(id);
              getAnnouncementsWithPagination();
            }}
            type="button"
          >
            <FaTrashAlt data-id={announcementId} />
            <span data-id={announcementId}>Excluir</span>
          </button>
        );

      case 'continue':
        return (
          <button
            data-id={announcementId}
            onClick={async ({ target }) => {
              const id = target.getAttribute('data-id');

              history.push({
                pathname: `/NewAnnouncement/continue`,
                state: { continue: true, id },
              });
              setCurrentPage(1);
            }}
            type="button"
          >
            <VscDebugContinue data-id={announcementId} />
            <span data-id={announcementId}>Continuar</span>
          </button>
        );

      case 'sold':
        return (
          <button
            data-id={announcementId}
            onClick={async ({ target }) => {
              const id = target.getAttribute('data-id');

              await setAnnouncementAsSold(id);
              getAnnouncementsWithPagination();
            }}
            type="button"
          >
            <AiFillLike data-id={announcementId} />
            <span data-id={announcementId}>Já vendi</span>
          </button>
        );
    }
  };

  const createAnnouncementButtons = (announcementStatus, announcementId) => {
    switch (announcementStatus) {
      case 'Publicado':
        return (
          <>
            {createButtonHTML('edit', announcementId)}
            {createButtonHTML('delete', announcementId)}
            {createButtonHTML('sold', announcementId)}
          </>
        );

      case 'Criado':
        return (
          <>
            {createButtonHTML('continue', announcementId)}
            {createButtonHTML('delete', announcementId)}
          </>
        );

      default:
    }
  };

  const getNegotiationsViewersOfAnnouncement = useCallback(async () => {
    const response = await api.get('api/v1/Negotiations', {
      params: {
        IsAccepted: true,
        AsSeller: true,
        IsPaged: true,
      },
    });
    setNegotiationViewers(response.data);
  }, [setNegotiationViewers]);

  return (
    <MainContainer>
      <Helmet>
        <meta
          property="og:title"
          content="n bikes | Uma mão na roda! | Brasil"
        />
        <meta
          property="og:image"
          content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
        />
        <meta
          property="og:description"
          content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
        />
        <title>Meus Anúncios | n Bikes | Brasil</title>
      </Helmet>
      <BaseContainer>
        <h2>Meus anúncios</h2>
        <h3 id="filter-by">Filtrar por: </h3>
        <select
          name="filter"
          id="filter"
          onChange={({ target }) => {
            if (target.value === 'sem-filtros') {
              setIsUsingFilter(false);
              document.getElementById('search-bar').value = '';

              return getAnnouncementsWithPagination();
            }

            getAnnouncementsWithFilter(target.value);
          }}
        >
          <option value="sem-filtros">Sem Filtros</option>
          <option value="publicado">Publicado</option>
          <option value="criado">Criado</option>
          <option value="finalizado">Finalizado</option>
        </select>

        <br />

        <div id="search-container">
          <input
            id="search-bar"
            type="text"
            name="search"
            placeholder="Pesquisar por nome"
            onChange={({ target }) => {
              setSearchFilterInput(target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                getAnnouncementsWithSearchFilter(searchFilterInput);
                filterNodeElement.value = '-';
              }
            }}
          />
          <button
            id="search-button"
            onClick={() => {
              getAnnouncementsWithSearchFilter(searchFilterInput);
              filterNodeElement.value = '-';
            }}
          >
            Pesquisar
          </button>
        </div>

        <Products>
          <ul>
            {announcementData.map((announcement, index) => {
              return (
                <li key={index}>
                  <ProductSquare>
                    <div className="image">
                      <div className="publication">
                        <div className={announcement.Status.toLowerCase()}>
                          {createAnnouncementHTMLStatus(announcement.Status)}
                        </div>
                      </div>
                      <img
                        src={
                          process.env.REACT_APP_S3URL +
                          announcement.DtoPicture?.Key
                        }
                      />
                    </div>
                    <div className="mainInfo">
                      <h3 title={announcement.Title} id="announcement-title">
                        {announcement.Title}
                      </h3>
                      <h4>
                        {announcement.Price.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </h4>
                      <p className="data">
                        Criado em{' '}
                        {`${announcement.CreatedAt.slice(
                          8,
                          10
                        )}/${announcement.CreatedAt.slice(
                          5,
                          7
                        )}/${announcement.CreatedAt.slice(0, 4)}`}
                      </p>
                      <p className="data">
                        {announcement.LastUpdateAt &&
                          `Última atualização em: ${announcement.LastUpdateAt?.slice(
                            8,
                            10
                          )}/${announcement.LastUpdateAt?.slice(
                            5,
                            7
                          )}/${announcement.LastUpdateAt?.slice(0, 4)}`}
                      </p>
                      {announcement.Status === 'Publicado' && (
                        <p
                          id="interested-clients"
                          onClick={() => {
                            history.push({
                              pathname: '/InterestedClients',
                              state: {
                                id: announcement.Id,
                                title: announcement.Title,
                                price: announcement.Price,
                                photo:
                                  process.env.REACT_APP_S3URL +
                                  announcement.DtoPicture?.Key,
                              },
                            });
                          }}
                        >
                          <FaUserCheck fill={'var(--blueNbikes'} />
                          {negotiationViewers.length > 0
                            ? negotiationViewers.filter(
                                (negotiation) =>
                                  negotiation.DtoAnnouncement.Id ===
                                    announcement.Id && !negotiation.FinishedAt
                              ).length
                            : 0}{' '}
                          interessados
                        </p>
                      )}
                      {announcement.Status !== 'Finalizado' && (
                        <div className="icons">
                          {createAnnouncementButtons(
                            announcement.Status,
                            announcement.Id,
                            announcement?.LastCreationStep?.Id
                          )}
                        </div>
                      )}
                    </div>
                  </ProductSquare>
                </li>
              );
            })}
          </ul>

          <PagePreviousNext>
            <div className="pageCounter">
              <button
                type="button"
                onClick={() => {
                  currentPage === 1
                    ? setCurrentPage(1)
                    : setCurrentPage(currentPage - 1);
                }}
                style={
                  currentPage === 1 || isUsingFilter
                    ? { display: 'none' }
                    : { display: 'inherit' }
                }
              >
                <FaAngleLeft />
                Anterior
              </button>
              <button
                type="button"
                onClick={() => {
                  if (currentPage + 1 > totalPages) return;

                  setCurrentPage(currentPage + 1);
                }}
                style={
                  currentPage === totalPages || isUsingFilter
                    ? { display: 'none' }
                    : { display: 'inherit' }
                }
              >
                Próxima
                <FaAngleRight />
              </button>
            </div>
          </PagePreviousNext>
        </Products>
      </BaseContainer>
    </MainContainer>
  );
}
