import styled, { css } from 'styled-components';

export const Products = styled.div`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin: 10px 0 5em 0;

  @media screen and (max-width: 960px) {
    margin: 5em 0 0 0;

    ul {
      width: 78% !important;
      margin: 4px auto;
    }
  }

  @media screen and (max-width: 1160px) {
    .productSquare {
      width: 240px !important;
      margin: 5px 0 !important;
    }

    .pageCounter {
      margin-right: 200px;
    }
  }

  .ListOfProducts {
    width: 100%;
    height: 100%;
    padding: 12px 0;

    border-bottom: 0.5px solid rgb(33, 52, 85, 0.3);
  }

  .products {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    margin-top: 2em;
  }

  ul {
    width: 1000px;
    max-width: 100%;
    height: auto;

    margin-bottom: 1em;
  }

  li {
    margin: 4px;
    display: inline-block;
  }

  .face-icon {
    &:hover {
      fill: #334f8d !important;
      cursor: pointer;
    }
  }

  .heart-icon {
    &:hover {
      fill: #fce76d !important;
      cursor: pointer;
    }
  }

  .pageCounter {
    height: 35px;
    float: right;
    display: flex;
    margin-top: 15px;
    transition: 0.3s;
  }

  .squareCounter {
    width: 35px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #c3c3c3;
    margin: 0 5px;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: #213355;
    }
    &:nth-last-child(1) {
      width: 100px;
    }
  }
`;

export const Used = styled.div`
  width: 60px;
  border-radius: 5px;
  margin: 3px;
  padding: 2px 6px;

  ${(props) => {
    if (props.isUsed) {
      return css`
        background: #213355;
      `;
    }

    return css`
      background: #37bcc1;
    `;
  }}

  font-weight: 400;
  color: #ffff;
  color: white;
  text-align: center;
`;

export const ProductSquare = styled.div`
  width: 230px;
  height: 300px;
  display: flex;
  position: relative;
  flex-direction: column;
  transition: 0.5s;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 1160px) {
    width: 280px;
    height: 350px;
    box-shadow: 0 0 0.2em rgb(125, 125, 125, 0.8);
  }

  .image {
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;

    img {
      width: 340px;
      height: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .icons {
    width: 90%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    top: 3%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.5s;

    svg {
      width: 25px;
      height: 25px;
      transition: 0.5s;
      fill: white;
    }
  }
  &:hover {
    cursor: pointer;

    box-shadow: 0 0 1em rgb(0, 0, 0, 0.4);

    p,
    span {
      color: #36bcc1;
    }

    .icons {
      opacity: 1;
    }

    svg:nth-child(1) {
      fill: white;
    }

    svg:nth-child(2) {
      fill: white;
    }
  }

  span {
    padding: 6px;
    color: #213355;
    transition: 0.5s;
  }

  p {
    border: none !important;
    padding: 6px;
    font-weight: 200;
    transition: 0.5s;
  }

  h6 {
    color: gray;
  }
  h4 {
    color: #00c569;
  }
`;
