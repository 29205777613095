import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  /* height: clamp(40vh, 100%, 70vh); */

  margin: 0;
`;

export const Container = styled.div``;
