import styled from 'styled-components';

export const Stack = styled.div`
  display: flex;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  width: 100%;
`;

export const FormTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
  color: #223357;

  min-width: 33%;

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

export const Line = styled.hr`
  width: 100%;
  height: 5.5px;
  background: #223357;

  border-radius: 4px;
`;

export const StoreInputs = styled.div`
  width: 100%;
  height: 230px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column;

    height: 100%;
  }
`;

export const StoreFormDiv = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  font-size: 16px;
`;

export const InputsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  height: 100%;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  color: #223357;
`;

export const ErrorText = styled.p`
  font-size: 14;
  font-weight: 400;
  color: #ee5d5d;

  text-align: start;

  margin-left: 8;
`;

export const ButtonsDiv = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    gap: 1.5rem;

    button {
      width: 100%;
    }
  }
`;

export const ModalText = styled.p`
  font-size: 25px;
  font-weight: 500;
  color: #000000;

  text-align: center;

  max-width: 240px;

  line-height: 95%;
`;

export const ModalButtonsDiv = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  button {
    text-transform: initial;

    font-size: 20px;
    font-weight: 700;

    border-radius: 12px;

    width: 80%;
  }
`;
