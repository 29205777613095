import React, { useState, useEffect } from 'react';

import { useLocation, useHistory, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { toastError } from 'utils/defaultToasts';

import {
  FaCircle,
  FaAlignJustify,
  FaCamera,
  FaIdCard,
  FaMoneyBill,
  FaCheckCircle,
} from 'react-icons/fa';

import Helmet from 'react-helmet';

import api from '~/services/api';

import { Container, MainContainer, ProgressBar } from './styles';

import Start from './components/Start';
import Information from './components/Information';
import Images from './components/Images';
import Contact from './components/Contact';
import Review from './components/Review';
import Aproved from './components/Aproved';
import Payment from './components/Payment';

import Loading from '../../components/Loading';
import ImagesMobile from './components/ImagesMobile';
import useWindowSize from '~/utils/useWindowSize';

export default function NewAnnouncement() {
  const accessToken = useSelector(
    (state) => state.authReducer.auth.accessToken
  );

  const location = useLocation();
  const history = useHistory();

  const shouldContinue = location?.state?.continue;

  const { width } = useWindowSize();

  const isMobile = !!width && width <= 1024;

  const [page, setPage] = useState('Start');
  const [announcement, setAnnouncement] = useState({});

  const [loading, setLoading] = useState(true);

  const stepMap = {
    0: 'Information',
    1: 'Images',
    2: 'Contact',
    3: 'Payment',
    4: 'Review',
  };

  const shouldContinueAnnouncement = async () => {
    if (!shouldContinue) {
      setLoading(false);
      setAnnouncement({});
      setPage('Start');

      return;
    }

    const id = location.state.id;

    const getAnnouncementDataUrl = `/api/v1/Announcements/${id}`;

    try {
      const response = await api.get(getAnnouncementDataUrl);
      const { data } = response;

      const notFinished = data.Status !== 'Finalizado';
      const notPublished = data.Status !== 'Publicado';

      if (shouldContinue && notPublished && notFinished) {
        let nextStep = data.LastCreationStep.Id + 1;

        if (nextStep > 4) nextStep = 4;

        const announcementPage = stepMap[nextStep];

        setAnnouncement(data);
        setPage(announcementPage);
        setLoading(false);

        return;
      }

      if (!notFinished) toastError('Este anúncio já foi finalizado.');

      if (!notPublished)
        toastError(
          'Este anúncio já foi publicado. Vá até seus anúncios e clique em editar.'
        );

      history.replace('/NewAnnouncement');
    } catch (error) {
      history.push('/MyAnnouncements');

      if (error.response.status === 403) {
        toastError('Este anúncio não pertence a sua conta.');

        return;
      }

      toastError('Erro ao continuar a criação do seu anúncio.');
    }
  };

  const showPage = () => {
    switch (page) {
      case 'Start':
        return <Start setPage={setPage} />;

      case 'Information':
        return (
          <Information
            setPage={setPage}
            announcement={announcement}
            setAnnouncement={setAnnouncement}
          />
        );

      case 'Images':
        return isMobile ? (
          <ImagesMobile
            setPage={setPage}
            announcement={announcement}
            setAnnouncement={setAnnouncement}
          />
        ) : (
          <Images
            setPage={setPage}
            announcement={announcement}
            setAnnouncement={setAnnouncement}
          />
        );

      case 'Contact':
        return (
          <Contact
            setPage={setPage}
            announcement={announcement}
            setAnnouncement={setAnnouncement}
          />
        );

      case 'Payment':
        return (
          <Payment
            setPage={setPage}
            announcement={announcement}
            setAnnouncement={setAnnouncement}
          />
        );

      case 'Review':
        return (
          <Review
            setPage={setPage}
            announcement={announcement}
            setAnnouncement={setAnnouncement}
          />
        );

      case 'Aproved':
        return <Aproved announcement={announcement} />;

      default:
        break;
    }
  };

  const progressBar = () => {
    if (page === 'Aproved') return null;
    if (page === 'Start') return null;

    return (
      <ProgressBar propPage={page}>
        <div>
          <ul className="progressTop">
            <li>
              <div onClick={() => setPage('Information')}>
                <FaCircle />
              </div>
            </li>

            <li>
              <div onClick={() => setPage('Images')}>
                <FaCircle />
              </div>
            </li>

            <li>
              <div onClick={() => setPage('Contact')}>
                <FaCircle />
              </div>
            </li>

            <li>
              <div onClick={() => setPage('Payment')}>
                <FaCircle />
              </div>
            </li>

            <li>
              <div onClick={() => setPage('Review')}>
                <FaCircle />
              </div>
            </li>
          </ul>
        </div>

        <div className="line-between">
          <div />
        </div>

        <ul className="text-list-progressbar">
          <li> Informação </li>
          <li> Fotos </li>
          <li> Contato </li>
          <li> Pagamento </li>
          <li> Conclusão </li>
        </ul>

        <ul className="icon-list-progressbar">
          <li>
            <FaAlignJustify />
          </li>

          <li>
            <FaCamera />
          </li>

          <li>
            <FaIdCard />
          </li>

          <li>
            <FaMoneyBill />
          </li>

          <li>
            <FaCheckCircle />
          </li>
        </ul>
      </ProgressBar>
    );
  };

  useEffect(() => {
    shouldContinueAnnouncement();
  }, []);

  return (
    <>
      {!accessToken.length && <Redirect to="/Login" />}
      <Helmet>
        <meta
          property="og:title"
          content="n bikes | Uma mão na roda! | Brasil"
        />

        <meta
          property="og:image"
          content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
        />

        <meta
          property="og:description"
          content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
        />

        <title>Anuncie Aqui | n Bikes | Brasil</title>
      </Helmet>

      <MainContainer id="main-container">
        <section className="progress-bar-section">{progressBar()}</section>

        <Container>
          {loading ? (
            <Loading loadingText="Carregando anúncio..." />
          ) : (
            showPage()
          )}
        </Container>
      </MainContainer>
    </>
  );
}
