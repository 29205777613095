import React, { useState,useEffect }  from 'react';

import { toastError } from 'utils/defaultToasts';

import { FaFacebook, FaInstagram } from 'react-icons/fa';

import * as S from './styles';

import api from '../../services/api';


export default function Footer() {

  const apiVersion = '/api/Version/';
  const [version, setVersion] = useState('');

  const getVersion = async () => {
    try {
      const response = await api.get(apiVersion);

      setVersion(response.data.Value);
    } catch (error) {
      toastError('Houve um erro ao buscar a versão.');
    }
  }

  useEffect(()=> {
    getVersion();
  }, []);

  return (
    <S.MainFooter>
        <S.FooterClass>
        <div className="logo">
          <img
            src={require('../../assets/LogoNbikesSmall.png')}
            alt="Logo nbikes"
          />
        </div>

        <div className="column1">
          <ul>
            <li><a href='https://nbikes.com.br/#/Help?section=0' onClick={() => window.scroll(0,200)}>Quem somos</a></li>
            <li><a href='https://nbikes.com.br/#/Help?section=0' onClick={() => window.scroll(0,200)}>Termos e condições de serviços</a></li>
            <li><a href='https://nbikes.com.br/#/Help?section=1' onClick={() => window.scroll(0,200)}>Produtos e serviços permitidos</a></li>
            <li><a href='https://nbikes.com.br/#/Help?section=2' onClick={() => window.scroll(0,200)}>Produtos e serviços não permitidos</a></li>
            <li><a href='https://nbikes.com.br/#/Help?section=3' onClick={() => window.scroll(0,200)}>Dicas de segurança: comprar e vender</a></li>
          </ul>
        </div>

        <div className="column2">
          <h3>Dúvidas</h3>

          <ul>
            <li>
              <a href="mailto:contato@nbikes.com.br">contato@nbikes.com.br</a>
            </li>
            <li>www.nbikes.com.br</li>
          </ul>
        </div>

        <div className="column3">
          <h3>Redes Sociais</h3>

          <div>
            <a
              href="https://www.facebook.com/Nbikes-100710171762615/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/nbikes.com.br/?igshid=1w6o8kfu5giw2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </S.FooterClass>

      <S.CopyrightFooter>
        <span>n Bikes API Version  {version} </span>
        <span>Site Version {process.env.REACT_APP_SITE_VERSION} </span>
        <a href="www.leadsoft.inf.br" target="_blank">
          <img
            src={require('../../assets/LambIntegral_small.png')}
            alt="logo"
          />
          LeadSoft Soluções Web | © 2021/2022 Copyright
        </a>
      </S.CopyrightFooter>
    </S.MainFooter>
  );
}
