import React, { useState } from 'react';

import { Container } from './styles';

export default function Identify() {
  const [CPF, setCPF] = useState();
  const [CNPJ, setCNPJ] = useState();

  const cpfMask = (value) => {
    if (CNPJ <= 14) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2');
    } else if (CNPJ > 12) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    }
  };

  return (
    <Container>
      <h5>Dados do Cliente</h5>

      <ul>
        <li>
          <p>Nome Completo/Razão Social * </p>

          <input type="text" style={{ width: '100%' }} />
        </li>

        <li>
          <div>
            <p>CPF/CNPJ * </p>

            <input
              type="text"
              value={CPF}
              maxLength="18"
              onChange={(e) => {
                setCPF(cpfMask(e.target.value));
                setCNPJ(e.target.value.length);
              }}
            />
          </div>

          <div>
            <p>Celular * </p>

            <input type="number" />
          </div>
        </li>

        <li>
          <p>E-mail * </p>

          <input type="email" />
        </li>
      </ul>
    </Container>
  );
}
