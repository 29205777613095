/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { FaUserCircle, FaCamera } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { uploadProfilePic } from '~/store/ducks/auth/actions';
import { toastSuccess } from '~/utils/defaultToasts';
import getCroppedImg from '../../cropImage';
import { Field, Container, UploadPicture } from './styles';

export default function ProfilePicture() {
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  const serverProfilePic = useSelector((state) => state.authReducer.auth.profile.DtoAvatar.Key);
  
  const [urlProfilePic, setUrlProfilePic] = useState(process.env.REACT_APP_S3URL + serverProfilePic);
  const [profilePic, setProfilePic] = useState('');

  const [image, setImage] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    setProfilePic(croppedImage);
  };

  const id = useSelector((state) => state.authReducer.auth.profile.Id);

  const handleClick = async () => {
    const imageExtension = profilePic.split(":")[1].split(";")[0];

    if (!['image/jpg', 'image/jpeg', 'image/png', 'image/heic'].includes(imageExtension)) {
      toast.error('A foto inserida deve ser JPG, JPEG, PNG ou HEIC!');
      return;
    }

    const buffer = Buffer.from(profilePic.substring(profilePic.indexOf(',') + 1));

    if(buffer.length > 5 * 1024 * 1024) {
      toast.error('A foto inserida deve ter no máximo 5MB!');
      return;
    }

    if (profilePic) {
      try {
        dispatch(uploadProfilePic([id, profilePic]));
        setUrlProfilePic(profilePic);
        toastSuccess('Foto de perfil atualizada com sucesso.');
      } catch (error) {
        toast.error('Ocorreu uma falha ao atualizar a foto de perfil.');
      }
    } else {
      toast.error('Selecione um arquivo para upload.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <Container>
      <div
        className="picture"
        onClick={() => {
          setShow(!show);
          if (!show) {
            setProfilePic(serverProfilePic);
            setImage('');
          }
        }}
      >
        <div className="perfilPic">
          {urlProfilePic ? (
            <img
              src={urlProfilePic}
              alt="profilePic"
            />
          ) : (
            <FaUserCircle />
          )}
        </div>
        <div className="camButton">{show ? <FaCamera /> : <h3>X</h3>}</div>
      </div>
      <UploadPicture propShow={!show}>
        <div className="info">
          <div className="buttons">
            <button
              onClick={() => {
                setShow(!show);
              }}
            >
              Cancelar
            </button>
            <button
              id="save-button"
              onClick={() => {
                if (!show && profilePic !== serverProfilePic) {
                  handleClick();
                  setShow(!show);

                  if (!show) setImage('');
                } else {
                  toast.error('Nenhuma mudança foi feita!', {
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                }
              }}
            >
              Salvar
            </button>
          </div>
        </div>
        <Field propShow={!show}>
          <div className="inputTitle">
            <Dropzone
              onDrop={(acceptedFile) => {
                Object.assign(acceptedFile[0], {
                  preview: URL.createObjectURL(acceptedFile[0]),
                });
                
                setImage(acceptedFile[0].preview);
                
              }}
              multiple={false}
              accept="image/png, image/jpg, image/jpeg, image/heic"
            >
              {({ getRootProps, getInputProps }) => (
                <section className="uploadContainer">
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <img
                      src={require('~/assets/uploadIcon.svg')}
                      alt="uploadIcon"
                    />
                    <h3>Arraste e solte sua imagem aqui</h3>
                    <p>ou</p>
                    <button type="button">Procure no computador</button>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div
            className="cropContainer"
            style={!image ? { visibility: 'hidden' } : null}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              showGrid={false}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />

            <input
              type="range"
              orient="vertical"
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
              className="slider"
              id="myRange"
            />
          </div>
        </Field>
      </UploadPicture>
    </Container>
  );
}
