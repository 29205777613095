/* eslint-disable no-unused-vars */
import React from 'react';
import * as S from './styles';
import EditPartnerInputs from '~/pages/EditPartnerAndCoupon/forms/EditPartnerInputs';
import EditCouponInputs from '~/pages/EditPartnerAndCoupon/forms/EditCouponInputs';

export default function EditPartnerAndCoupon() {
  return (
    <S.Container>
      <EditPartnerInputs />

      <EditCouponInputs />
    </S.Container>
  );
}
