import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BiUserCircle } from 'react-icons/bi';
import { MdDone, MdEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { IoMdArrowRoundBack } from 'react-icons/io';

import { toastError, toastSuccess } from '~/utils/defaultToasts';

import Satisfaction from '~/components/LeadTrust/components/Satisfaction';

import api from '~/services/api';

import * as S from './styles';

import Loading from '~/components/Loading';

export default function RateNegotiation() {
  const history = useHistory();
  const getMyNegotiationsUrl = '/api/v1/Negotiations';

  const { profileType } = useParams();
  const { announcementId } = useParams();

  const [tab, setTab] = useState(profileType === 'AsSeller' ? 'sold' : 'review');

  const [isCheckBoxMarked, setIsCheckBoxMarked] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [notes, setNotes] = useState('');
  const [review, setReview] = useState('Regular');

  const [announcementData, setAnnouncementData] = useState({});
  const [interestedClientsInThisProduct, setInterestedClientsInThisProduct] = useState([]);
  const [interestedClients, setInterestedClients] = useState([]);

  const [clientsMirror, setNegotiationsMirror] = useState([]);

  const [selectedClient, setSelectedClient] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const getAllNegotiationsPages = async () => {
    for (let i = currentPage; currentPage <= totalPages; i += 1) {
      const params = {
        IsPaged: true,
        CurrentPage: currentPage,
        PageSize: 15,
        AsSeller: true,
      }

      try {
        const response = await api.get(getMyNegotiationsUrl, { params });
        const { data } = response;

        setInterestedClients(prevNegotiation => [...prevNegotiation, data]);
        setNegotiationsMirror(prevMirror => [...prevMirror, data]);
        setCurrentPage(prevPage => prevPage + 1);
      } catch (error) {
        history.push({ pathname: '/MyAnnouncements' });
        toastError('Erro ao carregar clientes.');
      }
    }

    setIsLoading(false);
  };

  const getMyNegotiations = async () => {
    const params = {
      IsPaged: true,
      CurrentPage: 1,
      PageSize: 15,
      AsSeller: true,
    }

    try {
      const response = await api.get(getMyNegotiationsUrl, { params });
      const { headers } = response;
      const { data } = response;

      const pagination = JSON.parse(headers.pagination);

      setTotalPages(pagination.TotalPages);
      setInterestedClients(data);

      await getAllNegotiationsPages();
    } catch (error) {
      history.push({ pathname: '/MyAnnouncements' });
      toastError('Erro ao carregar clientes.');
    }
  };

  const filterNegotiations = () => {
    const id = announcementId;

    const filteredNegotiations = interestedClients
      .filter((negotiation) => negotiation.DtoAnnouncement.Id === id && !negotiation.SellerReview);

    setInterestedClientsInThisProduct(filteredNegotiations);
    setNegotiationsMirror(filteredNegotiations);
  };

  const phoneMask = (v) => {
    v = v?.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v?.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v?.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  };

  const dateMask = (date) => {
    const year = date?.slice(0, 4);
    const month = date?.slice(5, 7);
    const day = date?.slice(8, 10);

    return `${day}/${month}/${year}`;
  };

  const shortString = (string, length) => {
    if (string?.length > length) return string?.slice(0, length) + '...';

    return string;
  };

  const closeNegotiation = async () => {
    if (!selectedClient.length) return;

    const closeNegotiationUrl = `/api/v1/Negotiations/${selectedClient}/Close`;

    try {
      await api.patch(closeNegotiationUrl);
      toastSuccess('Negociação encerrada, agora você pode realizar sua avaliação.');
    } catch (error) {
      toastSuccess('Erro ao encerrar sua avaliação.');
    }
  }

  const handleSearch = (event) => {
    const { value } = event.target;
    const mirrorArray = [...clientsMirror];

    if (value?.length === 0) return setInterestedClientsInThisProduct(clientsMirror);

    const searchedClients =
      mirrorArray.filter(({ DtoBuyer }) =>
        DtoBuyer?.DtoProfile?.NickName?.toLowerCase() === value?.toLowerCase() ||
        DtoBuyer?.DtoProfile?.NickName?.toLowerCase().includes(value?.toLowerCase()));

    setInterestedClientsInThisProduct(searchedClients);
  };

  const handleOnSubmit = useCallback(() => {
    api
      .patch(`/api/v1/Negotiations/${profileType === 'AsSeller' ? selectedClient : announcementId}/Review`, {
        health: review,
        note: notes,
      })
      .then((_) => {
        toastSuccess('Avaliação enviada com sucesso!');
        if (profileType === 'AsSeller') history.push('/MyAnnouncements');
        else history.push('/MyNegotiations');
      })
      .catch((_) => {
        toastError('Não foi possível enviar a avaliação.');
      });
  }, [announcementId, review, notes, profileType, history]);

  const loadAnnouncementData = useCallback(async () => {
    if (profileType === 'AsSeller')
      try {
        const { data } = await api.get(`/api/v1/Announcements/${announcementId}`);
        setAnnouncementData(data);
      } catch (err) {
        toastError('Não foi possível carregar os dados do anúncio.');
      }
  }, [setAnnouncementData, toastError, announcementId, profileType]);

  const setAnnouncementAsSold = async (announcementId) => {

    const announcementSoldUrl = `/api/v1/Announcements/${announcementId}/Sold`;

    try {
      await api.patch(announcementSoldUrl);

      toastSuccess('O anúncio foi colocado como vendido. Todas as negociações restantes foram canceladas.');

      history.push(
        profileType === 'AsBuyer'
          ? '/MyNegotiations'
          : '/MyAnnouncements'
      );
    } catch (error) {
      toastError('Ocorreu um erro ao colocar o anúncio como vendido.');
    }
  }

  const loadQuestionAfterReview = () => {
    return (
      <>
        <S.TitleButtonContainer>
          <S.PageBack onClick={() => {
            profileType === 'AsBuyer'
              ? history.push({ pathname: '/MyNegotiations' })
              : history.push({ pathname: '/MyAnnouncements' });
          }}
          >
            <IoMdArrowRoundBack />
          </S.PageBack>

          <h1>Vendido</h1>
        </S.TitleButtonContainer>
        <S.Content>

          <S.Product>
            <S.ProductPhotoContainer>
              <S.ProductPhoto src={process.env.REACT_APP_S3URL + (announcementData?.DtoPictures && announcementData?.DtoPictures[0]?.Key)} />
            </S.ProductPhotoContainer>

            <S.PriceAndTitle>
              <S.ProductTitle>{announcementData?.Title || '-----'}</S.ProductTitle>

              <S.Price>R$ {announcementData?.Price?.toLocaleString('pt-BR', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }) ?? '-.--'}</S.Price>
            </S.PriceAndTitle>
          </S.Product>

          <p>
            Encontrou o {profileType === 'AsBuyer' ? 'vendedor' : 'comprador'}{' '}
            através do Nbikes?
          </p>

          <S.CheckBox>

            <div>
              <S.Button id="yes" isMarked={isCheckBoxMarked} onClick={() => setIsCheckBoxMarked(true)}><MdDone /></S.Button>
              <label htmlFor="yes">Sim</label>
            </div>

            <div>
              <S.Button id="no" isMarked={!isCheckBoxMarked} onClick={() => setIsCheckBoxMarked(false)}><MdDone /></S.Button>
              <label htmlFor="no">Não</label>
            </div>


          </S.CheckBox>

          {isCheckBoxMarked && (<><S.Search>
            <S.Input onChange={handleSearch} type="text" placeholder="Pesquise por nomes" />
            <S.SearchButton>Pesquisar</S.SearchButton>
          </S.Search>

            <S.InterestedClients>
              {interestedClientsInThisProduct?.map(({ Id, DtoBuyer, StartedAt }) => (
                <S.Client onClick={() => { setSelectedClient(Id); }} key={Id} isSelected={selectedClient === Id}>
                  <S.ClientPhotoContainer>
                    {DtoBuyer?.DtoProfile?.DtoAvatar?.ThumbnailUrl ? (
                      <S.ClientPhoto src={DtoBuyer?.DtoProfile?.DtoAvatar?.ThumbnailUrl} />
                    ) : (
                      <BiUserCircle />
                    )}
                  </S.ClientPhotoContainer>

                  <S.NameAndPhone>
                    <S.Name>{shortString(DtoBuyer?.DtoProfile?.NickName, 19) || '------ -----'}</S.Name>

                    {DtoBuyer?.PhoneNumber && (
                      <S.PhoneContainer>
                        <FaWhatsapp />
                        <S.Phone>{phoneMask(DtoBuyer?.PhoneNumber) || '(--) -----------'}</S.Phone>
                      </S.PhoneContainer>
                    )}

                    {DtoBuyer?.EmailAddress && (
                      <S.EmailContainer>
                        <MdEmail />
                        <S.Email>{DtoBuyer?.EmailAddress}</S.Email>
                      </S.EmailContainer>
                    )}
                  </S.NameAndPhone>

                  <S.DateContainer>
                    <S.Date>{dateMask(StartedAt)}</S.Date>
                  </S.DateContainer>
                </S.Client>
              ))}
            </S.InterestedClients></>)}


          <S.SoldButtonsContainer>
            <S.SubmitButton onClick={() => history.push(
              profileType === 'AsBuyer'
                ? '/MyNegotiations'
                : '/MyAnnouncements'
            )}>Cancelar</S.SubmitButton>
            <S.SubmitButton onClick={() => {
              if (isCheckBoxMarked && !selectedClient.length) return toastError('Selecione um cliente primeiro.');

              isCheckBoxMarked && selectedClient ? setTab('review') : setAnnouncementAsSold(announcementData.Id);

              closeNegotiation();
            }}
            >
              Confirmar
            </S.SubmitButton>
          </S.SoldButtonsContainer>
        </S.Content>
      </>
    );
  };

  const loadReviewPage = () => {
    return (
      <>
        <S.TitleButtonContainer>
          <S.PageBack onClick={() => {
            profileType === 'AsBuyer'
              ? history.push({ pathname: '/MyNegotiations' })
              : history.push({ pathname: '/MyAnnouncements' });
          }}
          >
            <IoMdArrowRoundBack />
          </S.PageBack>

          <h1>
            Avalie o {profileType === 'AsBuyer' ? 'Vendedor' : 'Comprador'}
          </h1>
        </S.TitleButtonContainer>

        <S.Content>
          <p>
            Como foi sua experiência com o{' '}
            {profileType === 'AsBuyer' ? 'vendedor' : 'comprador'}?
          </p>
          <Satisfaction clickable callback={setReview} />
          <S.NotesArea
            placeholder="Descreva sua experiência"
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
          <S.SubmitButton onClick={handleOnSubmit}>Enviar</S.SubmitButton>
        </S.Content>
      </>
    );
  };

  const loadTabs = () => {
    switch (tab) {
      case 'review':
        return loadReviewPage();

      case 'sold':
        return loadQuestionAfterReview();

      default:
        return <h1>Not Found</h1>;
    }
  };

  useEffect(() => {
    if (announcementId)
      loadAnnouncementData();
  }, [announcementId]);

  useEffect(() => {
    getMyNegotiations();
  }, []);

  useEffect(() => {
    filterNegotiations();
  }, [interestedClients]);

  if (isLoading) return (<Loading loadingText="Carregando..." />);

  return <S.Container>{loadTabs()}</S.Container>;
}
