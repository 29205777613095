import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';

import { toastError, toastSuccess, toastInfo } from 'utils/defaultToasts';

import {
  Field,
  FieldTwo,
  Container,
  AnimationContainer,
  NextButton,
} from './styles';
import { loadUFEnum, clearCities } from '~/store/ducks/enums/actions';

import api from '~/services/api';

export default function Contact({ setPage, announcement, setAnnouncement }) {
  const animationContainerRef = useRef(null);

  const dispatch = useDispatch();

  const UFEnums = useSelector((state) => state.enumsReducer.UFEnums);
  const profile = useSelector((state) => state.authReducer.auth.profile);

  const [citiesEnums, setCitiesEnums] = useState([]);

  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState(
    citiesEnums && announcement?.DtoCity?.id
  );
  const [cep, setCep] = useState(announcement?.CEP);

  const [mustScroll, setmustScroll] = useState(false);

  const [errors, setErrors] = useState([]);

  const PhoneMask = (v) => {
    if (!v?.length) return;

    v = v?.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v?.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v?.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  };

  const [commercialPhone, setCommercialPhone] = useState(
    announcement?.DtoContact?.DtoCommercialPhone
  );
  const [mobilePhone, setMobilePhone] = useState('');
  const [email, setEmail] = useState(
    announcement?.DtoContact?.DtoEmail || profile.Username
  );

  const getUFs = async () => {
    if (UFEnums.length !== 0) return;

    const stateDiv = document.querySelector('#select-state');
    stateDiv.disabled = false;

    const getUfsUrl = 'api/UFs/';

    try {
      const response = await api.get(getUfsUrl);
      const { data } = response;

      stateDiv.disabled = true;

      dispatch(loadUFEnum(data));
    } catch (error) {
      toastError('Erro ao buscar os estados.');
    }
  };

  const getCities = async (UF) => {
    const getCitiesUrl = `/api/UFs/${UF}/Cities`;
    const cityDiv = document.querySelector('#select-city');

    if (cityDiv) cityDiv.disabled = true;

    try {
      const response = await api.get(getCitiesUrl);
      const { data } = response;

      cityDiv.disabled = false;
      setCitiesEnums(data);
    } catch (error) {
      toastError('Erro ao buscar as cidades.');
    }
  };

  const toggleSelectStateAndCityInput = (toggle) => {
    const stateDiv = document.querySelector('#select-state');
    const cityDiv = document.querySelector('#select-city');

    if (!stateDiv || !cityDiv) return;

    if (toggle === 'enable') {
      stateDiv.disabled = false;
      cityDiv.disabled = false;
    } else {
      stateDiv.disabled = true;
      cityDiv.disabled = true;
    }
  };

  const searchCep = async (cep) => {
    const loadingMessageDiv = document.querySelector(
      '#loading-state-city-message'
    );
    const isValidCep = cep?.length === 8;

    if (!isValidCep) {
      toggleSelectStateAndCityInput('disable');

      loadingMessageDiv.style.display = 'none';
      return;
    }

    try {
      const response = await api.get(`api/cep/${cep}`);
      const data = response.data;

      const state = data.uf;
      const city = data.ibge;

      if (!state || !city) {
        loadingMessageDiv.style.display = 'inherit';
        loadingMessageDiv.innerHTML = 'CEP não encontrado.';
        loadingMessageDiv.style.color = 'red';

        toggleSelectStateAndCityInput('disable');

        return;
      }

      setEstado(state);
      getCities(state);
      setCidade(city);

      toggleSelectStateAndCityInput('enable');

      loadingMessageDiv.style.display = 'none';
      loadingMessageDiv.style.color = '#0e1eff';
    } catch (error) {
      toastError('Erro ao buscar o CEP, tente novamente.');
    }
  };

  const isButtonDisabled =
    !mobilePhone || PhoneMask(mobilePhone)?.length < 14 || !email || !cidade;

  const addInfo = async () => {
    if (isButtonDisabled) return;

    let unformattedMobilePhone = mobilePhone
      .replace(/\s/g, '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .trim();

    let [mobileDDD, mobilePhoneNoDDD] = [
      unformattedMobilePhone.slice(0, 2),
      unformattedMobilePhone.slice(2),
    ];

    let commercialDDD, commercialPhoneNoDDD;

    if (commercialPhone) {
      const newCommercialPhone = commercialPhone
        .replace(/\s/g, '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
        .trim();
      [commercialDDD, commercialPhoneNoDDD] = [
        newCommercialPhone.slice(0, 2),
        newCommercialPhone.slice(2),
      ];
    }

    const mobileDto = {
      type: 'Mobile',
      canNotify: true,
      isPrimary: true,
      DDI: 55,
      DDD: mobileDDD,
      Number: mobilePhoneNoDDD,
    };

    const homeDto = {
      type: 'Commercial',
      canNotify: true,
      DDI: 55,
      DDD: commercialDDD,
      Number: commercialPhoneNoDDD,
    };

    let parameters = {
      DtoEmail: {
        address: email,
        isPrimary: true,
      },
      cityId: cidade,
      state: estado,
      cep: cep,
    };

    if (commercialPhoneNoDDD)
      parameters = { ...parameters, DtoPhones: [mobileDto, homeDto] };
    else parameters = { ...parameters, DtoPhones: [mobileDto] };

    const patchUrl = `api/v1/Announcements/${announcement.Id}/Contact`;
    const ONE_SECOND = 1000;

    try {
      toastInfo('Enviando informações de contato...');

      const response = await api.patch(patchUrl, parameters);
      const { data } = response;

      setAnnouncement(data);
      hideContainerAnimation();

      setTimeout(() => {
        setPage('Payment');
      }, 500);

      toastSuccess('Informações de contato enviadas!');
    } catch (error) {
      if (error?.response?.status === 400) {
        toastError(
          'Erro ao enviar informações de contato, verifique os campos.'
        );
        return;
      }

      setErrors(error?.response?.data?.errors);
      setmustScroll(true);

      setTimeout(() => {
        setmustScroll(false);
      }, ONE_SECOND);

      toastError('Erro ao adicionar informações de contato.');
    }
  };

  // const getPhones = async () => {
  //   const getPhonesUrl = '/api/v1/Profiles/Customer/Phones?IsPrimary=false';

  //   try {
  //     const phoneResponse = await api.get(getPhonesUrl);

  //     const phoneData = phoneResponse.data;

  //     const mobile = phoneData?.filter(phoneObj => phoneObj?.Type === 3)[0];
  //     const commercial = phoneData?.filter(phoneObj => phoneObj?.Type === 0)[0];

  //     if (mobile?.DDD)
  //       setMobilePhone(mobile?.DDD + mobile?.Number);

  //     if (!commercial) return;

  //     setCommercialPhone(commercial?.DDD + commercial?.Number);
  //   } catch (error) {
  //     toastError('Erro ao carregar os seus dados.');
  //   }
  // }

  const getUserData = async () => {
    const url = `/api/v1/Profiles/${profile.Id}`;

    try {
      const response = await api.get(url);
      const { data } = response;

      setMobilePhone(data.Cellphone);

      if (data.Telephone.length > 0) {
        setCommercialPhone(data.Telephone);
      }
    } catch (error) {
      toastError('Erro ao carregar os seus dados.');
    }
  };

  useEffect(() => {
    showContainerAnimation();
    getUFs();
    searchCep(cep);
    getUserData();

    if (announcement.State) setEstado(announcement.State);
  }, []);

  useEffect(() => {
    if (estado && typeof estado !== 'object') {
      getCities(estado);
    } else {
      dispatch(clearCities());
    }
  }, [estado]);

  const showContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '1';
    animationContainerRef.current.style.transform = 'translateX(0)';
  };

  const hideContainerAnimation = () => {
    animationContainerRef.current.style.opacity = '0';
    animationContainerRef.current.style.transform = 'translateX(100%)';
  };

  useEffect(() => {
    if (announcement?.DtoContact?.DtoMobilePhone)
      setMobilePhone(announcement?.DtoContact?.DtoMobilePhone);
  }, [announcement?.DtoContact?.DtoMobilePhone]);

  return (
    <div>
      <AnimationContainer ref={animationContainerRef}>
        <Container>
          <h1>Vamos agora definir como o comprador irá entrar em contato</h1>

          <Field propErrors={errors} propScroll={mustScroll}>
            <h2>Quais formas de contato estarão disponíveis:</h2>
            <div className="inputTitle">
              <p>
                <FaCheckCircle
                  style={
                    PhoneMask(mobilePhone)?.length > 0 && { fill: '#36bcc1' }
                  }
                />
                <span>Celular/Whatsapp</span>
              </p>
              <input
                type="text"
                maxLength="15"
                name="celular"
                defaultValue={mobilePhone}
                value={PhoneMask(mobilePhone)}
                onChange={(e) => setMobilePhone(e.target.value)}
                placeholder="(00) - 99999-9999"
                required
              />

              <p>
                <FaCheckCircle
                  style={commercialPhone?.length > 0 && { fill: '#36bcc1' }}
                />
                <span>Telefone Fixo</span>
              </p>
              <input
                type="text"
                maxLength="14"
                name="telefone"
                value={PhoneMask(commercialPhone)}
                onChange={(e) => setCommercialPhone(PhoneMask(e.target.value))}
                placeholder="(00) - 9999-9999 (Opcional)"
              />

              <p>
                <FaCheckCircle
                  style={email?.length > 0 && { fill: '#36bcc1' }}
                />
                <span>E-mail</span>
              </p>
              <input
                type="email"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>

            <h2>Onde está o produto?</h2>
            <FieldTwo>
              <div className="selectField">
                <input
                  type="number"
                  placeholder="CEP"
                  name="CEP"
                  value={cep}
                  onChange={({ target }) => {
                    setCep(target.value);
                    searchCep(target.value);
                  }}
                />

                <div id="loading-state-city-message">Carregando...</div>

                <div name="estado" className="selectSection selectSection-one">
                  <span>Estado</span>
                  <select
                    disabled
                    id="select-state"
                    name="estado"
                    defaultValue={announcement?.State}
                    onChange={({ target }) => {
                      if (target.value === 'Selecione') return;

                      setEstado(target.value);
                    }}
                    value={estado}
                  >
                    <option value="Selecione">Selecione</option>
                    {UFEnums?.map((UF) => (
                      <option key={UF.id} value={UF.sigla}>
                        {UF.sigla}
                      </option>
                    ))}
                  </select>
                </div>
                <div name="cidade" className="selectSection selectSection-two">
                  <span>Cidade</span>
                  <select
                    id="select-city"
                    name="cidade"
                    disabled
                    onChange={({ target }) => {
                      if (target.value === 'Selecione') return;

                      setCidade(target.value);
                    }}
                    value={cidade}
                  >
                    <option value="Selecione">Selecione</option>
                    {citiesEnums?.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </FieldTwo>
          </Field>
          <section className="buttons-section">
            <button
              type="button"
              className="nextStep"
              onClick={() => setPage('Images')}
            >
              Voltar
            </button>
            <NextButton
              type="button"
              disabled={isButtonDisabled}
              onClick={async () => {
                await addInfo();
              }}
            >
              Avançar
            </NextButton>
          </section>
        </Container>
      </AnimationContainer>
    </div>
  );
}
