import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;

    box-sizing: border-box;
    font-family: "Nunito";

    transition: 0.15s ease;
  }

  *:focus {
      outline: 0;
  }

  html {
    /* a cada 1rem será considera 10px */
    font-size: 62.5%;

  }

  :root {
    --container-shadow: 0 0 1em rgba(0, 0, 0, 0.3);
  }

  button {
    color: black
  }

  select {
    -moz-appearance: none;
	  -webkit-appearance: none;
	  appearance: none;

    color: black;
    margin-left: 4px;
  }

  #root {
    overflow-x:hidden !important;
    background:rgba(125,125,125,0.1);
    --blueNbikes: rgb(33, 52, 85);
  }

  html, body, #root {
    width:100%;
    height: 100%;
    min-height: 100vh;
    position:relative;


    background-color: rgb(224, 224, 224);

  }

  body {
    height:auto;
    font-size: 1.6rem;
    background-color: #fff;
    overflow-x:hidden !important;
    padding: 0 !important;

    -webkit-font-smoothing: antialiased!important;

  }

  #root {
    padding-bottom: 380px;

    @media screen and (max-width: 960px) {
      padding-bottom: 900px;
    }
  }

  header {
      background-color: var(--blueNbikes);
  }

  footer {
      background-color: var(--blueNbikes);
  }

  a {
      text-decoration: none;
  }

  ul {
      list-style: none;
  }

  button {
      cursor: pointer;
  }

  .Toastify__toast-container {
    width:550px;
  }
`;
