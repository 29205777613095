import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import '~/configs/ReactotronConfig';

import Routes from '~/routes';
import history from '~/services/history';

import StartUp from '~/components/StartUp';
import AccordionContextProvider from '~/context/Accordion';

import { ThemeProvider } from '@mui/material/styles';
import { store, persistor } from './store';

import GlobalStyle from './styles/global';
import { GlobalMuiTheme } from './styles/GlobalMuiTheme';
import { useRedirectHashRoute } from '~/hooks/useRedirectHashRoute';
import ScrollToTop from './utils/scrollToTop.jsx';

const App = () => {
  useRedirectHashRoute();

  return (
    <ThemeProvider theme={GlobalMuiTheme}>
      <Provider store={store}>
        <StartUp>
          <PersistGate persistor={persistor}>
            <AccordionContextProvider>
              <Router history={history}>
                <ScrollToTop>
                  <GlobalStyle />
                  <ToastContainer autoClose={6000} limit={3} />
                  <Routes />
                </ScrollToTop>
              </Router>
            </AccordionContextProvider>
          </PersistGate>
        </StartUp>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
