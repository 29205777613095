import React from 'react';
import { useSelector } from 'react-redux';
import { FaCircleNotch } from 'react-icons/fa';
import { Container } from './styles';

export default function Spinner() {
  const loading = useSelector((state) => state.authReducer.auth.loading);
  const visible = {
    visibility: 'visible',
  };
  const invisible = {
    visibility: 'hidden',
  };

  return (
    <Container style={loading ? visible : invisible}>
      <FaCircleNotch />
    </Container>
  );
}
