import { createTheme } from '@mui/material/styles';

export const GlobalMuiTheme = createTheme({
  palette: {
    primary: {
      main: '#304470',
      light: '#59698c',
      dark: '#212f4e',
      contrastText: '#fff',
    },

    secondary: {
      main: '#fce76d',
      light: '#fceb8a',
      dark: '#b0a14c',
      contrastText: '#000',
    },

    lightBlue: {
      main: '#45BDC2',
      light: '#b4e8e7',
      dark: '#35a3a9',
      contrastText: '#fff',
    },

    info: {
      main: '#4F4F4F',
      light: '#727272',
      dark: '#373737',
      contrastText: '#fff',
    },

    error: {
      main: '#EE5D5D',
      light: '#f17d7d',
      dark: '#a64141',
      contrastText: '#fff',
    },

    success: {
      main: '#55CD59',
      light: '#77d77a',
      dark: '#3b8f3e',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Nunito, sans-serif',
    fontSize: 16,
  },
  // props: {
  //   MuiModal: {
  //     disableScrollLock: true,
  //   },
  // },
});
