import React from 'react';

import { Container } from './styles';

import { FaCheckCircle } from 'react-icons/fa';

export default function Products() {
  return (
    <Container>
      <FaCheckCircle />

      <h5>Pagamento efetuado com sucesso</h5>

    </Container>
  );
}
