import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;

  min-height: 600px;
  height: auto;

  margin: 0 auto;
  padding: 1em 20px 3em 20px;

  position: relative;

  #loading {
    margin-top: 50px !important;
  }

  input {
    width: 100%;

    font-size: 22px;

    border-color: rgb(125 125 125 / 30%);
    border-width: 0 0 1px 0;
  }

  textarea {
    width: 100%;
    height: 300px;

    resize: none;

    font-size: 22px;
  }

  p {
    font-size: 20px;
  }

  h2 {
    font-size: 22px;
    font-weight: 400;
  }

  .edit-announcement-page-title {
    margin: 30px 0;
  }

  .title,
  .description,
  .category-section,
  .more-info,
  .price,
  .bike-photo,
  .contact,
  .save-all-changes {
    border-radius: 12px;

    transition: 0.3s;

    position: relative;

    padding: 12px 50px 60px 50px;

    background: white;

    margin-bottom: 30px;

    box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);

    h2 {
      font-size: 30px;
      font-weight: 600;

      padding: 20px 0 20px 0;
    }
  }

  .more-info input,
  .more-info select,
  .category-section select {
    position: relative;

    font-size: 23px;

    min-width: 200px;
    max-width: 550px;

    width: 100%;

    height: 58px;

    padding-top: 20px;

    margin-bottom: 15px;
  }

  .more-info {
    span {
      margin: 0 0 5px 0;

      font-size: 18px;
      font-weight: 600;

      pointer-events: none;

      z-index: 1;

      color: black;

      position: absolute;
    }
  }

  .category-section span {
    left: 55px;

    font-size: 18px;
    font-weight: 600;

    pointer-events: none;

    z-index: 1;

    color: black;

    position: absolute;
  }

  .brand-container,
  .serial-number-container {
    position: relative;
    margin-top: 20px;

    input {
      margin-top: 10px;
    }

    span {
      top: -15px;

      left: 0;

      position: absolute;
    }
  }

  .contact-title {
    font-size: 18px;
    font-weight: 600;

    color: black;

    margin: 20px 0 0 0;

    display: flex;
    align-items: center;
  }

  .select-section {
    span {
      left: 55px;
    }

    input {
      padding-left: 5px;
    }
  }

  .current-pics ul {
    display: flex;

    flex-wrap: wrap;
  }

  .current-pic {
    position: relative;

    transition: 0.3s ease;

    width: 250px;
    height: 200px;

    margin: 0 30px 30px 0;

    img {
      width: 250px;
      height: 200px;

      border-radius: 10px;

      margin: 0 30px 30px 0;
    }

    button {
      opacity: 0;

      left: 10px;
      top: 10px;

      position: absolute;

      background-color: #d33e3e;

      color: white;

      font-size: 14px;

      width: 80px;
      height: 30px;

      z-index: 1;

      border: 0;
      border-radius: 10px;

      transition: 0.3s ease;
    }

    &:hover {
      transform: scale(1.1);

      button {
        opacity: 1;

        transition: 0.3s ease;
      }
    }
  }

  .drop-file {
    margin-top: 30px;

    div .drop-box {
      width: 250px;
      height: 250px;
    }

    p {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;
      text-align: center;

      padding: 30px;
      margin: 0 30px 30px 0;

      width: 250px;
      height: 250px;

      border: dashed 2px #cecece;
      border-radius: 10px;
    }

    button {
      margin-top: 30px;

      width: 190px;
      height: 40px;

      font-size: 14px;

      background-color: #203453;
      color: white;

      border-radius: 10px;
      border: none;
    }

    section {
      display: flex;

      flex-wrap: wrap;
    }

    .cropper-and-slider {
      position: relative;

      width: 530px;
    }

    .reactEasyCrop_Container {
      position: relative;
      background-color: #fff;
      min-width: 400px;
      max-width: 530px;
      width: 100%;
      height: 500px;

      border-radius: 20px;

      /* .reactEasyCrop_Image {
        width: auto;
        height: auto;
      } */
    }

    .slider {
      position: absolute;

      top: 0;
      left: 540px;
    }

    input[type='range'][orient='vertical'] {
      -webkit-appearance: slider-vertical; /* WebKit */
      width: 8px;
      height: 500px;
      padding: 0 5px;
    }

    .confirm-crop {
      position: absolute;
      left: 50%;
      top: 500px;

      transform: translate(-50%, -50%);
    }
  }

  .cep-input::-webkit-outer-spin-button,
  .cep-input::-webkit-inner-spin-button,
  .phone-number::-webkit-outer-spin-button,
  .phone-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .cep-input[type='number'],
  .phone-number[type='number'] {
    -moz-appearance: textfield;
  }

  .state-city-container {
    display: flex;

    @media screen and (max-width: 500px) {
      flex-direction: column;

      .select-city {
        max-width: 200px;
      }
    }
  }

  .state,
  .city {
    position: relative;

    span {
      font-size: 18px;
      font-weight: 600;

      top: 30px;
      left: 5px;

      pointer-events: none;

      z-index: 1;

      color: black;

      position: absolute;
    }
  }

  .select-state,
  .select-city {
    font-size: 23px;

    padding-top: 20px;
    margin-top: 30px;

    height: 58px;
  }

  .select-state {
    width: 150px;

    margin-right: 20px;
  }

  .select-city {
    width: 250px;
  }

  .save-changes-button,
  .discard-changes-button {
    margin-top: 30px;

    width: 150px;
    height: 60px;

    font-size: 20px;
    font-weight: 600;

    border: 0;
    border-radius: 10px;

    color: white;
  }

  .save-changes-button {
    background-color: #00c569;

    margin-right: 30px;
  }

  .discard-changes-button {
    background-color: #c22f2f;
  }
`;

export const PhoneInput = styled.input``;

export const Advise = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const CropOptionsContainer = styled.div`
  width: 100px;

  display: flex;
  flex-direction: column;

  gap: 10px;

  gap: 10px;
  position: absolute;
  bottom: 80px;
  right: -200px;
`;
