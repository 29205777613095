import { combineReducers } from 'redux';

import authReducer from './auth';
import enumsReducer from './enums';
import filtersReducer from './filters';

export default combineReducers({
  authReducer,
  enumsReducer,
  filtersReducer,
});
