export default function getPartnerVoucherDisablingReason(reasonNumber) {
  switch (reasonNumber) {
    case 1:
      return 'Cupom de desconto desabilitado pelo administrador';

    case 2:
      return 'Cupom de desconto desabilitado pois o parceiro foi desabilitado';

    case 3:
      return 'Cupom de desconto desabilitado pois atingiu o prazo de expiração';

    case 4:
      return 'Cupom de desconto desabilitado pois atingiu o limite de geração de cupons';

    case 5:
      return 'Desabilitado porque o Parceiro gerou outro Cupom de Desconto';

    case 6:
      return 'Desabilitado porque o Parceiro habilitou outro Cupom de Desconto';

    default:
      return null;
  }
}
