import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Carousel } from 'react-responsive-carousel';

import { FaEdit, FaHeart, FaTrash } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight, FiShare2 } from 'react-icons/fi';

import api from '~/services/api';
import PluginPopUp from '~/components/PluginPopUp';
import SellerInfo from '~/components/SellerInfo';
import Helmet from 'react-helmet';

import { Redirect, useHistory, useParams } from 'react-router-dom';

import { toastError, toastInfo, toastSuccess } from 'utils/defaultToasts';

import * as S from './styles';

import Loading from '~/components/Loading';
import { Modal } from '@material-ui/core';

export default function Conclusion() {
  const history = useHistory();
  const { announcementId } = useParams();
  const accessToken = useSelector(
    (state) => state.authReducer.auth.accessToken
  );
  const privilegeType = useSelector(
    (state) => state.authReducer.auth.privilegeType
  );
  const [announcementData, setAnnouncementData] = useState({});
  const [picturesData, setPicturesData] = useState([]);
  const [hoverButton, setHoverButton] = useState(false);

  const [sellerResponseData, setSellerResponseData] = useState(0);

  const userId = useSelector((state) => state.authReducer.auth.profile.Id);

  const [isLoading, setIsLoading] = useState(true);

  const [isImageOpen, setIsImageOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const [toggleSellerInfo, setToggleSellerInfo] = useState(false);

  function getAnnouncement(id) {
    api
      .get(`api/v1/Announcements/${id}/Watch`)
      .then((response) => {
        setAnnouncementData(response.data);
        setPicturesData(response.data.DtoPictures);

        setIsLoading(false);

        window.scrollTo(0, 0);
      })
      .catch(() => {
        history.push({ pathname: '/home' });

        toastError('Erro ao carregar os dados do anúncio.');
      });
  }

  const [buy, setBuy] = useState(false);

  const showbBuyerPopUp = () => {
    if (buy === true) {
      return <PluginPopUp setBuy={setBuy} />;
    }
    return null;
  };

  const handleDeleteAnnouncement = async (id) => {
    toastInfo('Excluindo anúncio...');
    try {
      await api.delete(`/api/v1/Announcements/${id}`);
      toastSuccess('Anúncio excluído com sucesso pelo administrador.');

      window.location.href = '/#/home';
    } catch (error) {
      toastError('Não foi possível excluir o anúncio.');
    }
  };

  useEffect(() => {
    getAnnouncement(announcementId);
  }, []);

  const FavoriteStyle = {
    fill: '#fce76d',
  };

  function addFavorite(event) {
    const heart = event.target;

    api
      .post(`api/v1/Profiles/${userId}/Starreds`, {
        announcementId,
      })
      .then((response) => {
        heart.style.fill = '#fce76d';
        getAnnouncement(announcementId);
      })
      .catch((error) => {
        toastError('Erro ao favoritar anúncio.');
      });
  }

  function deleteFavorite(event) {
    const heart = event.target;

    api
      .delete(`api/v1/Profiles/${userId}/Starreds/${announcementId}`)
      .then((response) => {
        heart.style.fill = '#37bcc1';
        getAnnouncement(announcementId);
      })
      .catch((error) => {
        toastError('Erro ao remover anúncio dos favoritos.');
      });
  }

  // Usar na nova versão da API para evitar duplicatas de negociações.
  const getSellerInfo = async (negotiationId) => {
    const getSellerInfoUrl = `/api/v1/Negotiations/${negotiationId}/SellerInfo/Watch`;

    try {
      const response = await api.get(getSellerInfoUrl);
      const { data } = response;

      setSellerResponseData(data);
      setToggleSellerInfo(true);
    } catch (error) {
      toastError('Erro ao carregar os dados do vendedor.');
    }
  };

  const postNegotiations = async () => {
    const postNegotiationsUrl = '/api/v1/Negotiations';

    try {
      const response = await api.post(postNegotiationsUrl, { announcementId });
      const { data } = response;

      setSellerResponseData(data);
      setToggleSellerInfo(true);
    } catch (error) {
      const isConflict = error.response.status === 409;

      if (isConflict) return getSellerInfo(error.response.data.NegotiationId);

      if (error?.response?.status === 400) {
        if (error?.response?.data?.Messages)
          return toastError(error?.response?.data?.Messages[0]);
      }

      toastError('Erro ao carregar dados do vendedor.');
    }
  };

  const handleCloseImage = useCallback(() => {
    setCurrentImageIndex(null);
    setIsImageOpen(false);
  }, []);

  const handleClickOnImage = useCallback((imageIndex) => {
    setCurrentImageIndex(imageIndex);
    setIsImageOpen(true);
  }, []);

  const handleSlideZoomImage = useCallback(
    (previuos = false) => {
      if (!announcementData.DtoPictures) return;

      const { length } = announcementData.DtoPictures;

      if (previuos) {
        setCurrentImageIndex((prev) => (prev - 1 < 0 ? length - 1 : prev - 1));
      } else {
        setCurrentImageIndex((prev) => (prev + 1) % length);
      }
    },
    [announcementData.DtoPictures]
  );

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowLeft') handleSlideZoomImage(true);
      else if (e.key === 'ArrowRight') handleSlideZoomImage();
    });

    return document.removeEventListener('keydown', (e) => {
      if (e.key === 'ArrowLeft') handleSlideZoomImage(true);
      else if (e.key === 'ArrowRight') handleSlideZoomImage();
    });
  }, [handleSlideZoomImage]);

  if (isLoading)
    return (
      <S.LoadingContainer>
        <Loading loadingText="Carregando anúncio..." />
      </S.LoadingContainer>
    );

  const date = new Date(announcementData.CreatedAt);
  const createdAt = new Intl.DateTimeFormat('pt-BR').format(date);

  const linkToShare = window.location.href.replace('#/', '');

  return (
    <>
      {!announcementId && <Redirect to="/home" />}

      <Helmet>
        <meta property="og:title" content={announcementData?.Title} />
        <meta
          property="og:image"
          content={process.env.REACT_APP_S3URL + picturesData[0]?.Key}
        />
        <meta
          property="og:description"
          content={announcementData?.Description?.slice(0, 100)}
        />
        <title>{announcementData.Title} | n Bikes | Brasil</title>
      </Helmet>

      {showbBuyerPopUp()}

      <S.Container>
        <SellerInfo
          sellerInformation={sellerResponseData}
          toggle={toggleSellerInfo}
          setToggle={setToggleSellerInfo}
        />
        <S.MainField>
          <div className="title">
            <h2>
              {announcementData.Title}
              <FaHeart
                style={announcementData.IsStarred ? FavoriteStyle : null}
                className="heart-icon"
                onClick={(event) => {
                  event.stopPropagation();

                  if (!accessToken) {
                    history.push({ pathname: '/Login' });

                    return;
                  }
                  if (accessToken && !announcementData.IsStarred)
                    addFavorite(event);
                  else if (accessToken && announcementData.IsStarred)
                    deleteFavorite(event);
                  else window.location.replace('/#/Login');
                }}
              />
            </h2>
            <p>
              <S.ShareButton
                onClick={() => {
                  const link = document.querySelector('#link');

                  link.focus();
                  link.select();

                  try {
                    document.execCommand('copy');
                    toastInfo('Link do anúncio copiado com sucesso!');
                  } catch (error) {
                    toastError('Erro ao copiar o link do anúncio.');
                  }
                }}
              >
                <FiShare2 size={20} color="white" /> Compartilhar
              </S.ShareButton>

              <input
                id="link"
                type="text"
                readOnly
                value={linkToShare}
                style={{
                  position: 'absolute',
                  opacity: '0',
                  pointerEvents: 'none',
                }}
              />

              <br />
              <br />

              {announcementData.LastUpdateAt &&
                `Última atualização em: ${announcementData.LastUpdateAt?.slice(
                  8,
                  10
                )}/${announcementData.LastUpdateAt?.slice(
                  5,
                  7
                )}/${announcementData.LastUpdateAt?.slice(0, 4)}`}
            </p>
            <span>{announcementData.StateName}</span>
          </div>

          <S.ProductState
            privilegeType={privilegeType}
            useCondition={announcementData?.UseCondition?.Description}
          >
            <span>{announcementData?.UseCondition?.Description}</span>
          </S.ProductState>
          {privilegeType === 'Administrator' && (
            <S.ActionsContainer>
              <div className="edit-button">
                <div
                  onClick={() =>
                    history.push({
                      pathname: `/EditMyAnnouncement/${announcementId}`,
                    })
                  }
                >
                  <FaEdit />
                </div>
              </div>
              <div className="delete-button">
                <div
                  onClick={() => {
                    handleDeleteAnnouncement(announcementData?.Id);
                  }}
                >
                  <FaTrash />
                </div>
              </div>
            </S.ActionsContainer>
          )}
          <S.TopField>
            <div className="photos">
              <Carousel
                showArrows
                showStatus={false}
                infiniteLoop
                emulateTouch
                className="Carousel"
              >
                {announcementData.DtoPictures?.map((picture, index) => (
                  <div onClick={() => handleClickOnImage(index)}>
                    <img
                      className="CarouselItem"
                      src={process.env.REACT_APP_S3URL + picture.Key}
                      alt={picture.Description}
                    />
                  </div>
                ))}
              </Carousel>
              <div className="mainPic" />

              <div />
            </div>

            <div className="right">
              <S.MiddleField>
                <h3>Ficha Técnica:</h3>

                <ul>
                  <li>
                    <span>Categoria:</span>
                    <span>
                      {announcementData?.MajorCategory?.Description || '-'}
                    </span>
                  </li>
                  <li>
                    <span>Sub:</span>
                    <span>
                      {announcementData?.Category?.Description || '-'}
                    </span>
                  </li>
                  <li>
                    <span>Tamanho:</span>
                    <span>{announcementData?.Size?.Description || '-'}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span>Marca:</span>{' '}
                    <span>{announcementData?.Brand || '-'}</span>
                  </li>
                  <li>
                    <span>Ano:</span>{' '}
                    <span>{announcementData?.Year || '-'}</span>
                  </li>
                  <li>
                    <span>Condição:</span>{' '}
                    <span>
                      {announcementData?.UseConditionDescription || '-'}
                    </span>
                  </li>
                </ul>
                <p>
                  <h2>
                    {announcementData?.Price
                      ? 'R$ ' +
                        announcementData?.Price?.toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })
                      : 'R$ 0,00'}
                  </h2>
                </p>
              </S.MiddleField>

              <div className="selfInfos">
                <h3>Dados do Vendedor</h3>

                <button
                  type="button"
                  className="showSellerInfo"
                  onClick={() => {
                    if (!accessToken) {
                      history.push('/Login');

                      return;
                    }

                    postNegotiations();
                  }}
                >
                  Exibir dados do Vendedor
                </button>
              </div>
            </div>
          </S.TopField>

          <S.BottomField>
            <h3>Detalhes:</h3>
            <pre>{announcementData.Description}</pre>
          </S.BottomField>
          <S.CreatedData>
            <h3>
              Data do anúncio: <span>{createdAt}</span>
            </h3>
          </S.CreatedData>
        </S.MainField>
      </S.Container>

      <Modal
        style={S.ModalStyles}
        onClose={() => setIsImageOpen(false)}
        open={isImageOpen}
      >
        <S.ZoomContainer>
          <div onClick={handleCloseImage}>
            <img
              src={`${process.env.REACT_APP_S3URL}${announcementData.DtoPictures[currentImageIndex]?.Key}`}
            />
          </div>

          <div>
            <FiChevronLeft
              size={60}
              onClick={() => handleSlideZoomImage(true)}
            />
            <FiChevronRight size={60} onClick={() => handleSlideZoomImage()} />
          </div>
        </S.ZoomContainer>
      </Modal>
    </>
  );
}
