import styled from 'styled-components';

export const Container = styled.div`
  width: clamp(320px, 100%, 1200px);

  margin: 50px auto;
  padding: 0 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const PageBack = styled.button`
  background-color: var(--blueNbikes);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: none;

  min-width: 30px;
  height: 30px;

  svg {
    fill: white;

    width: 20px;
    height: 20px;

    pointer-events: none;
  }
`;

export const Title = styled.h1`
  color: black;

  font-size: clamp(24px, 10vw, 32px);
`;

export const Wrapper = styled.div`
  position: relative;

  width: clamp(250px, 100%, 1200px);

  margin-top: 20px;
  padding: 20px;

  background-color: white;

  border-radius: 20px;
`;

export const Id = styled.div`
  margin-bottom: 20px;
`;

export const Product = styled.div`
  display: flex;
  gap: 20px;

  padding-bottom: 20px;
  margin-bottom: 20px;

  border-bottom: 1px solid gray;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ProductPhotoContainer = styled.div`
  min-width: 150px;
  height: 100px;

  overflow: hidden;

  border-radius: 10px;
`;

export const ProductPhoto = styled.img`
  width: 150px;
  height: 100px;

  background-color: gray;
`;

export const PriceAndTitle = styled.div`
  width: 70%;

  display: flex;
  flex-direction: column;
`;

export const ProductTitle = styled.h2`
  font-size: 24px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Price = styled.h2`
  color: #00c569;

  font-size: 24px;
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  width: clamp(150px, 50vw, 300px);
  height: 30px;

  font-size: clamp(14px, 100%, 20px);

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  border: 1px solid black;

  padding-left: 15px;
`;

export const Button = styled.button`
  width: clamp(90px, 10vw, 150px);
  height: 30px;

  font-size: clamp(14px, 100%, 20px);

  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  border: 1px solid black;

  background-color: var(--blueNbikes);
  color: white;
`;

export const InterestedClients = styled.div`
  margin-top: 30px;
  width: clamp(250px, 100%, 650px);

  height: 400px;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const NoClientsYet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  opacity: 0.4;

  div {
    font-size: 20px;

    color: black;
  }

  svg {
    width: 30px;
    height: 30px;

    fill: black;
  }
`;

export const Client = styled.div`
  height: 100px;

  position: relative;

  display: flex;
  align-items: center;
  gap: 10px;

  width: clamp(250px, 100%, 600px);

  border-bottom: 1px solid gray;
`;

export const ClientPhotoContainer = styled.div`
  min-width: 60px;
  height: 60px;

  overflow: hidden;

  border-radius: 50%;

  svg {
    min-width: 60px;
    height: 60px;

    fill: var(--blueNbikes);
  }
`;

export const ClientPhoto = styled.img`
  width: 60px;
  height: 60px;

  background-color: gray;
`;

export const NameAndPhone = styled.div``;

export const Name = styled.h2`
  font-size: clamp(16px, 2vw, 24px);
`;

export const PhoneContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: var(--blueNbikes);

    width: clamp(14px, 2vw, 22px);
    height: clamp(14px, 2vw, 22px);

    margin-right: 5px;
  }
`;

export const Phone = styled.div`
  font-size: clamp(14px, 2vw, 18px);
  color: var(--blueNbikes);
`;

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: var(--blueNbikes);

    width: clamp(14px, 2vw, 22px);
    height: clamp(14px, 2vw, 22px);

    margin-right: 5px;
  }
`;

export const Email = styled.div`
  font-size: clamp(14px, 2vw, 18px);
  color: var(--blueNbikes);
`;

export const DateContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Date = styled.div`
  color: black;

  opacity: 0.8;
`;
