import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  padding: 40px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
`;

export const Title = styled.h1`
  margin: 3rem 0;

  font-size: clamp(24px, 6vw, 32px);

  width: clamp(260px, 100%, 1000px);
`;

export const FormContainer = styled.form`
  width: clamp(260px, 100%, 1000px);

  background-color: white;

  border-radius: 10px;
  border: none;

  box-shadow: var(--container-shadow);

  padding: 40px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FlexWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1020px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const Terms = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-top: 20px;

  @media screen and (max-width: 1020px) {
    justify-content: center;

    margin: 20px auto 0 auto;

    width: clamp(220px, 100%, 425px);
  }
`;

export const Checkbox = styled.input`
  min-width: 20px;
  min-height: 20px;
`;

export const Text = styled.p`
  font-size: clamp(14px, 3vw, 18px);


  text-align: justify;

  a {
    text-decoration: underline;
    color: var(--blueNbikes);
  }
`;

export const Wrapper = styled.div`
  width: clamp(260px, 100%, 1000px);

  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  margin: 30px 0 0 0;

  width: 250px;
  height: 70px;

  border: none;
  border-radius: 10px;

  color: white;
  background-color: var(--blueNbikes);

  font-size: 20px;

  :not(:disabled):hover {
    box-shadow: var(--container-shadow);

    transform: scale(1.025);
  }

  :disabled {
    opacity: 0.8;

    filter: grayscale(1);
  }
`;
