import styled, { css } from 'styled-components';

export const Container = styled.div`
  height:auto;
  margin-top:20px;
  display:inline-block;
  vertical-align:top;
  transition: 0.3s;




  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin: auto;
  }

  .picture {
    width: 200px;
    height: 200px;
    z-index:4;
cursor:pointer;

    position:relative;

    &:hover{


.camButton{background:#666666 !important;}

}



.camButton{
width:50px;
height:50px;
display:flex;
align-items:center;
justify-content:center;
right:0;
bottom:0;
pointer-events:none;
    border-radius: 999px;
background: rgb(125,125,125);
position:absolute;
opacity:0.7;
transition:0.5s;


}

svg{  fill:white;}

h3{ font-family:Nunito;
   color:white;
    font-size:18px;
     font-weight:400;}

}

  }



  .perfilPic {
    width: 200px;
    height: 200px;
    border-radius: 999px;
    overflow: hidden;
    position:relative;
    text-align: center;

    img {
      width: 200px;
      height: auto;
    }

    svg {
      width: 200px;
      height: auto;
      fill: #fce76d;
    }

  }


`;

export const UploadPicture = styled.div`
  width: 0px;
  height: 0px;
  visibility: hidden;
  display: flex;
  position: absolute;
  top: 20px;
  background: white;
  transition: 0.4s;
  z-index: 3;

  ${(props) => {
    if (props.propShow) {
      return css`
        width: 100%;
        height: calc(100% - 20px);
        visibility: visible;

        .info {
          opacity: 1 !important;
        }
      `;
    }
  }}

  .info {
    width: 30%;
    height: 500px;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: 0.3s;

    .buttons {
      width: 100%;
      height: 50px;
      display: flex;

      #save-button {
        z-index: 2;
      }

      button {
        width: 150px;
        height: 50px;
        border-radius: 4px;
        margin: 0;
        border: 1px solid #fa0d00;
        background: none;
      }

      button:nth-child(2) {
        border: none;
        background: rgb(32, 52, 83) !important;
        color: white;
        margin: 0 20px;
      }
    }
  }

  .uploadPicture {
    opacity: 0;
  }

  .cropContainer {
    .reactEasyCrop_Container {
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .slider {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    cursor: pointer;
    width: 8px;
    height: 200px;
    position: absolute;
    left: -20px;
  }

  .dropzone {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      width: 180px;
      height: 50px;
      background: rgb(32, 52, 83);
      border: none;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
    }
    h3 {
      font-size: 20px;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
`;
export const Field = styled.div`
  width: 500px;
  height: 500px;
  border-radius: 12px;
  padding: 20px;
  background: white;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px dashed rgba(125, 125, 125, 0.3);
  transition: 0.3s;

  ${(props) => {
    if (props.propShow) {
      return css`
        opacity: 1 !important;
      `;
    }
  }}

  h3 {
    font-size: 30px;
    margin: 0.5em 0 0.1em 0;
    font-weight: 600;
  }

  span {
    font-size: 12px;
    color: gray;
  }
`;
