/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useCallback } from 'react';
import { AiFillDelete, AiFillStar } from 'react-icons/ai';

import { FiImage } from 'react-icons/fi';
import * as S from './styles';

export default function ImageItem({
  name,
  url,
  isFavorite,
  imageFiles,
  setImageFiles,
  index,
}) {
  const [isErrored, setIsErrored] = useState(false);

  const handleRemoveImage = useCallback(() => {
    setImageFiles((prev) => prev.filter((_, imgIndex) => imgIndex !== index));
  }, [index, setImageFiles]);

  const handleSetMainImage = useCallback(() => {
    const newImageFiles = [...imageFiles];
    const element = newImageFiles[index];

    newImageFiles.splice(index, 1);
    newImageFiles.splice(0, 0, element);
    setImageFiles(newImageFiles);
  }, [index, imageFiles, setImageFiles]);

  return (
    <S.Container>
      {!isErrored ? (
        <S.Image src={url} onError={() => setIsErrored(true)} />
      ) : (
        <FiImage size="8rem" />
      )}

      <S.Flex>
        <S.Name title={name}>{name}</S.Name>

        <S.Buttons>
          <S.Star onClick={() => handleSetMainImage()} isFavorite={isFavorite}>
            <AiFillStar size="2rem" />
          </S.Star>

          <S.Delete onClick={() => handleRemoveImage()}>
            <AiFillDelete size="2rem" fill="#e65e5e" />
          </S.Delete>
        </S.Buttons>
      </S.Flex>
    </S.Container>
  );
}
