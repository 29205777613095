import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px);
  align-items: center;

  svg {
    width: 80px;
    height: 80px;
    fill: #36bcc1;
    animation: ${spin} 1s linear infinite;
  }
`;
