import React from 'react';

import * as S from './styles';

const Loading = ({ loadingText }) => {
  return (
    <>
      <S.LoadingContainer>
        <S.Spinner className="spinner" />
        <h1>{loadingText}</h1>
      </S.LoadingContainer>
    </>
  );
};

export default Loading;
