import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { toastError, toastSuccess } from '~/utils/defaultToasts';

import * as S from './styles';
import api from '~/services/api';

const GetInTouch = () => {
  const history = useHistory();

  const reasons = [
    'Dúvida',
    'Crítica',
    'Elogio',
    'Sugestão de Melhoria',
    'Outro',
  ];

  const phoneMask = (v) => {
    if (!v) return '';

    v = v?.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v?.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v?.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    return v;
  };

  const [reason, setReason] = useState('Dúvida');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [message, setMessage] = useState('');

  const handleChangeReason = useCallback(
    (event) => setReason(event.target.value),
    []
  );

  const handleChangeInput = useCallback(
    (event, setter) => setter(event.target.value),
    []
  );

  const handleSendButton = useCallback(async () => {
    if (!mail || !reason || !message)
      return toastError('Por favor, preencha todos os campos obrigatórios!');

    history.push({ pathname: '/home' });

    window.scrollTo(0, 0);
    try {
      await api.post('api/v1/Email/Contact', {
        reason, name, phone: phone.replace(/\D/g, ''), email: mail, message
      });

      toastSuccess('Mensagem enviada com sucesso!');
    } catch (err) {
      if (err) {
        toastError('Erro ao enviar, por favor envie um email para contato@nbikes.com.br');
      };

    }
  }, [reason, mail, message]);

  // TODO Integrar com API de envio de E-Mail

  return (
    <S.Container>
      <S.SelectWrapper>
        <S.ReasonTitle>Motivo do Contato</S.ReasonTitle>

        <S.Select name="reason" defaultValue="Dúvida" onChange={handleChangeReason}>
          {reasons.map((reasonParam) => (
            <S.Option  value={reasonParam}>{reasonParam}</S.Option>
          ))}
        </S.Select>
        <S.Mandatory>(Obrigatório)</S.Mandatory>
      </S.SelectWrapper>

      <S.Wrapper>
        <S.Input
          onChange={(event) => handleChangeInput(event, setName)}
          type="name"
          name="name"
          placeholder="Nome"
        />
        <S.Optional>(Opcional)</S.Optional>
      </S.Wrapper>

      <S.Wrapper>
        <S.Input
          onChange={(event) => {
            if (event.target.value.length > 15) return;

            handleChangeInput(event, setPhone)
          }}
          type="phone"
          name="phone"
          placeholder="Telefone"
          value={phoneMask(phone)}
        />
        <S.Optional>(Opcional)</S.Optional>
      </S.Wrapper>

      <S.Wrapper>
        <S.Input
          onChange={(event) => handleChangeInput(event, setMail)}
          type="email"
          name="email"
          placeholder="E-mail"
        />
        <S.Mandatory>(Obrigatório)</S.Mandatory>
      </S.Wrapper>

      <S.Wrapper>
        <S.Topic>Mensagem</S.Topic>
        <S.TextArea
          onChange={(event) => handleChangeInput(event, setMessage)}
          name="message"
        />
        <S.Mandatory>(Obrigatório)</S.Mandatory>
      </S.Wrapper>

      <S.ButtonWrapper>
        <S.Button onClick={handleSendButton}>Enviar</S.Button>
      </S.ButtonWrapper>
    </S.Container>
  );
};

export default GetInTouch;
