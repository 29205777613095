import styled from 'styled-components';


export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 20px;
`;

export const PhonesWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 10px;
`;

export const Overlay = styled.div`
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: ${({ isToggled }) => isToggled ? 'block' : 'none'};

  position: absolute;

  background-color: black;

  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;

  top: 50%;
  left: 50%;

  transform: translate( -50%, -50%);

  display: grid;
  grid-template: 'FH SH';
  grid-template-columns: 50% 50%;

  width: clamp(300px, 100%, 700px);
  height: 400px;

  border-radius: 10px;

  box-shadow: 0 5px 10px #41414175;
  
  background-color: white;

  z-index: 4;

  overflow: hidden;

  transition: .5s ease;

  opacity: ${({ isToggled }) => isToggled ? '1' : '0'};

  pointer-events: ${({ isToggled }) => isToggled ? 'all' : 'none'};

  transform: ${({ isToggled }) =>
    isToggled ? 'translateY(400)' : 'translateY(-600px)'};

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;

    height: auto;

    left: 0;

    transform: translateY(-50%);
  }
`;

export const Title = styled.h1`
  margin: 0 0 20px 0 !important;
  
  font-size: 20px;

  color: var(--blueNbikes);
`;

export const FirstHalf = styled.div`
  padding: 20px;
  grid-area: FH;

  border-right: 1px solid lightgrey;

  #first-title {
    font-size: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }
`;

export const ProfilePhoto = styled.img`
  width: 90px;
  height: 90px;

  border: 2px solid var(--blueNbikes);
  border-radius: 50%;

  box-shadow: 0 5px 10px #41414175;
`;

export const ProfileName = styled.h1`
  margin: 0 !important;
 
  font-size: 24px;
  font-weight: 500;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Phone = styled.div`
  display: flex;
  align-items: center;

  gap: 12px;

  svg {
    width: 24px;
    height: 24px;
    fill: #000000;
  }
`;

export const MobilePhone = styled(Phone)`
  a {
    text-decoration: none;

    color: black;
  }
`;

export const CommercialPhone = styled(Phone)``;

export const PhoneNumber = styled.a`
  font-size: 20px;
`;

export const GetInTouch = styled.p`
  width: 70%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 30px auto;

  font-size: 14px;

  text-align: center;
`;

export const SecondHalf = styled.div`
  padding: 20px;
  grid-area: SH;

  display: flex;
  flex-direction: column;

  align-items: center;

  position: relative;

  overflow-y: scroll;

  
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const CloseButton = styled.button`
  position: absolute;

  cursor: pointer;

  background-color: transparent;
  border: none;

  top: 10px;
  right: 10px;

  svg {
    fill: var(--blueNbikes);

    width: 30px;
    height: 30px;

    transition: .3s ease;

    :hover {
      filter: brightness(1.5);

      transition: .3s ease;
    }
  }

  @media screen and (max-width: 650px) {
    /* top: -320px; */
  }

  z-index: 5;
`;

export const BuyersReviewContainer = styled.div`
  margin-top: 10px;

  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const BuyersReviewTitle = styled.h3`
  display: flex;
  justify-content: flex-start;
  
  width: 100%;

  color: #223357;

  font-size: 18px;
  font-weight: 500;
`;

export const ReviewContainer = styled.div`
  width: 100%;
  height: 90px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;

  ::after {
    position: absolute;
    top: 70px;
    left: 20px;

    width: 265px;

    content: '';

    border-bottom: 2px solid ${(props) => props.color};

    @media screen and (max-width: 650px) {
      width: 215px;
    }
  }
`;

export const Face = styled.div`
  img {
    width: 70px;
    height: 60px;
  }
`;

export const Comment = styled.p`
  font-size: 18px;
  font-weight: 500;

  color: #0f0c14;
  
  width: 250px;
  height: 50px;

  padding-bottom: 20px;
  margin-right: 20px;

  overflow-wrap: break-word;

  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;