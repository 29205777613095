import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 30px auto;
  width: 100%;
  max-width: 800px;

  position: relative;

  padding: 10px;

  h1 {
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

export const Content = styled.div`
  padding: 30px;
  margin: 10px auto;
  width: 100%;

  background-color: white;

  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageBack = styled.button`
  background-color: var(--blueNbikes);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: none;

  width: 30px;
  height: 30px;

  svg {
    fill: white;

    width: 20px;
    height: 20px;

    pointer-events: none;
  }
`;

export const NotesArea = styled.textarea`
  margin-top: 30px;
  height: 200px;
  resize: none;
  width: 88%;
  font-size: 22px;
  border-radius: 10px;
  border: solid var(--blueNbikes) 2px;

  padding: 10px;
`;

export const SubmitButton = styled.button`
  width: 150px;
  height: 40px;
  background: var(--blueNbikes);
  border-radius: 7px;
  border: 0;
  text-transform: uppercase;
  color: white;

  margin-top: 20px;

  transition: background 0.4s;

  &:hover {
    background: #0f1828;
  }
`;

export const TitleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const SoldButtonsContainer = styled.div`
  display: flex;

  justify-content: space-between;
  width: 100%;
`;

export const Product = styled.div`
  display: flex;
  gap: 20px;

  width: 100%;

  padding-bottom: 20px;
  margin-bottom: 20px;

  border-bottom: 1px solid gray;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ProductPhotoContainer = styled.div`
  max-width: 150px;
  min-width: 150px;
  height: 100px;

  overflow: hidden;

  border-radius: 10px;
`;

export const ProductPhoto = styled.img`
  width: 150px;
  height: 100px;

  background-color: gray;
`;

export const PriceAndTitle = styled.div``;

export const ProductTitle = styled.h2`
  font-size: 24px;
`;

export const Price = styled.h2`
  color: #00c569;

  font-size: 24px;
`;

export const CheckBox = styled.div`
  margin-top: 10px;

  display: flex;
  flex-direction: column;

  div {
    height: 30px;
    display: flex;
    flex-direction: row;

    label {
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 50%;

  width: 20px;
  height: 20px;

  margin-right: 10px;
  svg {
    fill: white;
  }

  ${({ isMarked }) =>
    isMarked
      ? css`
          background-color: #36bcc1;
        `
      : css`
          background-color: gray;
        `}
`;

export const InterestedClients = styled.div`
  margin-top: 30px;
  width: clamp(250px, 100%, 650px);

  height: 400px;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Client = styled.div`
  height: 100px;

  cursor: pointer;

  padding: 4px;

  position: relative;

  display: flex;
  align-items: center;
  gap: 10px;

  width: clamp(250px, 100%, 600px);

  border-bottom: 1px solid gray;

  &:hover {
    background-color: #abd7d8;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #abd7d8;
    `}
`;

export const ClientPhotoContainer = styled.div`
  min-width: 60px;
  height: 60px;

  overflow: hidden;

  border-radius: 50%;

  svg {
    min-width: 60px;
    height: 60px;

    fill: var(--blueNbikes);
  }
`;

export const ClientPhoto = styled.img`
  width: 60px;
  height: 60px;

  background-color: gray;
`;

export const NameAndPhone = styled.div``;

export const Name = styled.h2`
  font-size: clamp(16px, 2vw, 24px);
`;

export const PhoneContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: var(--blueNbikes);

    width: clamp(14px, 2vw, 22px);
    height: clamp(14px, 2vw, 22px);

    margin-right: 5px;
  }
`;

export const Phone = styled.div`
  font-size: clamp(14px, 2vw, 18px);
  color: var(--blueNbikes);
`;

export const EmailContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: var(--blueNbikes);

    width: clamp(14px, 2vw, 22px);
    height: clamp(14px, 2vw, 22px);

    margin-right: 5px;
  }
`;

export const Email = styled.div`
  font-size: clamp(14px, 2vw, 18px);
  color: var(--blueNbikes);
`;

export const DateContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Date = styled.div`
  color: black;

  opacity: 0.8;
`;

export const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding-top: 10px;
  border-top: 1px solid gray;
`;

export const Input = styled.input`
  width: clamp(150px, 50vw, 300px);
  height: 30px;

  font-size: clamp(14px, 100%, 20px);

  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  border: 1px solid black;

  padding-left: 15px;
`;

export const SearchButton = styled.button`
  width: clamp(90px, 10vw, 150px);
  height: 30px;

  font-size: clamp(14px, 100%, 20px);

  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  border: 1px solid black;

  background-color: var(--blueNbikes);
  color: white;
`;
