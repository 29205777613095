import React from 'react';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const Stack = styled.div`
  display: flex;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  width: 100%;

  position: relative;
`;

export const FormTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
  color: #223357;

  min-width: 33%;

  @media (min-width: 900px) and (max-width: 1000px) {
    font-size: 26px;
    white-space: nowrap;
  }

  @media (max-width: 900px) {
    font-size: 24px;
  }

  @media (max-width: 400px) {
    font-size: 23px;
  }

  @media (max-width: 300px) {
    font-size: 21px;
  }
`;

export const Line = styled.hr`
  width: 100%;
  height: 5.5px;
  background: #223357;

  border-radius: 4px;
`;

export const PartnerInputs = styled.div`
  width: 100%;
  height: 230px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 3rem;

    height: 100%;
  }
`;

export const PartnerForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  font-size: 16px;
`;

export const PartnerInputsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  height: 100%;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const ErrorText = styled.p`
  font-size: 14;
  font-weight: 400;
  color: #ee5d5d;

  text-align: start;

  margin-left: 8;
`;

export const CouponForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

export const CouponInputsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  height: 100%;
  width: 100%;

  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ButtonsDiv = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    gap: 1.5rem;

    button {
      width: 100%;
    }
  }
`;

export const ModalText = styled.p`
  font-size: 25px;
  font-weight: 500;
  color: #000000;

  text-align: center;

  span {
    font-weight: 700;
  }
`;

export const PartnerModalText = styled(ModalText)`
  max-width: 500px;

  line-height: 95%;
`;

export const ModalSubText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #000000;

  text-align: center;
`;

export const ModalTitle = styled.h2`
  font-size: 31px;
  font-weight: 700;
  color: #223357;
  text-align: center;
`;

export const PartnerModalTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
`;

export const ModalButtonsDiv = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  button {
    text-transform: initial;

    font-size: 20px;
    font-weight: 700;

    border-radius: 12px;

    width: 80%;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  color: #223357;
`;

export const CustomToolTip = MuiStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    textAlign: 'center',
    maxWidth: 250,
  },
}));

export const StatusBadge = styled.label`
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  padding: 1rem 1.5rem;
  border-radius: 12px;

  position: absolute;
  top: 33px;
  right: 2px;

  @media (min-width: 900px) and (max-width: 1000px) {
    top: 32px;
  }

  @media (max-width: 900px) {
    top: 28px;
  }

  @media (max-width: 760px) {
    top: 45px;
  }

  @media (max-width: 600px) {
    top: 50px;
  }

  @media (max-width: 360px) {
    top: 60px;

    font-size: 12px;

    padding: 0.8rem 1rem;
  }
`;

export const PartnerStatusBadge = styled.label`
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  padding: 1rem 1.5rem;
  border-radius: 12px;

  position: absolute;
  top: 35px;
  right: 2px;

  @media (max-width: 580px) {
    top: 28px;
  }

  @media (max-width: 450px) {
    top: 45px;
  }

  @media (max-width: 360px) {
    font-size: 12px;

    padding: 0.8rem 1rem;
  }
`;

export const CountLimitInfo = styled.label`
  font-size: 14px;
  font-weight: 700;
  color: #223357;

  height: 21px;

  position: absolute;
  top: 53px;
  right: 4px;
`;
