import React, { useCallback, useState } from 'react';

import * as S from './styles';

// attributes {
//   name: string;
//   placeholder: string;
//   type: string;
// }

const Input = ({
  setValue,
  hasError,
  span,
  errorMessage,
  value,
  attributes,
  maxLength,
}) => {
  const [isEmptyMessage, setIsEmptyMessage] = useState('');
  const [innerError, setInnerError] = useState(false);

  const handleEmptyValue = useCallback((event) => {
    const { value } = event.target;
    const isEmpty = value.length === 0;

    if (isEmpty) {
      setInnerError(true);
      setIsEmptyMessage('O campo não pode estar vazio.');

      return;
    }

    setInnerError(false);
    setIsEmptyMessage('');
  }, []);

  const handleChange = (event) => {
    if (event.target.value.length > maxLength && maxLength !== -1) return;

    setValue(event.target.value);
  };

  return (
    <S.Container hasError={hasError || innerError}>
      <S.Input
        hasError={hasError || innerError}
        type={attributes.type}
        name={attributes.name}
        placeholder={attributes.placeholder}
        onChange={handleChange}
        value={value}
        onBlur={handleEmptyValue}
      />

      <S.Error hasError={hasError || innerError}>
        {errorMessage || isEmptyMessage}
      </S.Error>

      <S.Span hasError={hasError || innerError}>{span}</S.Span>
    </S.Container>
  );
};

export default Input;
