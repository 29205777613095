import React, { useCallback, useEffect, useState } from 'react';
import { FaStoreAlt } from 'react-icons/fa';
import { RxIdCard } from 'react-icons/rx';
import { SlGlobe } from 'react-icons/sl';
import * as S from './styles';
import InputComponent from '~/components/InputComponent';
import { PatternFormat } from 'react-number-format';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAtomValue } from 'jotai';
import { partnerBackgroundAtom } from '~/jotai/atoms/registerPartnerAtoms';
import api from '~/services/api';
import { toastError, toastSuccess } from '~/utils/defaultToasts';
import Modal from '~/components/Modal';
import { AiOutlineWarning } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import UploadLogo from '~/pages/RegisterPartnerAndCoupon/components/UploadLogo';
import { cnpj } from 'cpf-cnpj-validator';
import { Button, CircularProgress } from '@mui/material';
import getPartnerDisablingReason from '~/pages/EditPartnerAndCoupon/utils/getPartnerDisablingReason';

const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const schema = yup.object().shape({
  Title: yup
    .string()
    .required('Nome da loja é obrigatório.')
    .max(60, 'Nome da loja deve ter no máximo 60 caracteres.'),
  Cnpj: yup
    .string()
    .required('CNPJ é obrigatório.')
    .test('is-cnpj-valid', 'CNPJ inválido.', (value) => cnpj.isValid(value)),
  Website: yup.string().required('Site é obrigatório.'),
  // .matches(urlRegex, 'Site inválido.'),
  Background: yup.string().required('Logotipo da loja é obrigatória.'),
  EnablePartner: yup.boolean(),
});

export default function EditPartnerInputs() {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isPartnerEnabled, setIsPartnerEnabled] = useState(true);

  const [openLeavePageModal, setOpenLeavePageModal] = useState(false);
  const handleOpenLeavePageModal = () => setOpenLeavePageModal(true);
  const handleCloseLeavePageModal = () => setOpenLeavePageModal(false);

  const [openDisablePartnerModal, setOpenDisablePartnerModal] = useState(false);
  const handleOpenDisablePartnerModal = () => setOpenDisablePartnerModal(true);
  const handleCloseDisablePartnerModal = () =>
    setOpenDisablePartnerModal(false);

  const [openEnablePartnerModal, setOpenEnablePartnerModal] = useState(false);
  const handleOpenEnablePartnerModal = () => setOpenEnablePartnerModal(true);
  const handleCloseEnablePartnerModal = () => setOpenEnablePartnerModal(false);

  const [openDeletePartnerModal, setOpenDeletePartnerModal] = useState(false);
  const handleOpenDeletePartnerModal = () => setOpenDeletePartnerModal(true);
  const handleCloseDeletePartnerModal = () => setOpenDeletePartnerModal(false);

  const [isLoading, setIsLoading] = useState(false);

  const [partnerData, setPartnerData] = useState({});
  const { partnerId } = useParams();

  const [toolTipContent, setToolTipContent] = useState(null);

  const partnerBackground = useAtomValue(partnerBackgroundAtom);

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const backgroundFile = watch('Background');

  const getData = useCallback(async () => {
    await api.get(`/api/v1/Partners/Admin/${partnerId}`).then((response) => {
      setPartnerData(response.data);

      const { Title, Cnpj, Website, IsEnabled, Background, DisablingReason } =
        response.data;

      setIsPartnerEnabled(IsEnabled);

      if (!IsEnabled) {
        const reason = getPartnerDisablingReason(DisablingReason);
        setToolTipContent(reason);
      }

      reset({
        Title,
        Cnpj: Cnpj.Number,
        Website,
        Background: Background.Url,
        EnablePartner: IsEnabled,
      });
    });
  }, [partnerId, reset]);

  useEffect(() => {
    getData();
  }, []);

  const onLeaveButtonClick = () => {
    handleCloseLeavePageModal();

    window.location.href = '/';
  };

  const formatCnpj = (cnpj) => {
    return cnpj
      .replace('.', '')
      .replace('.', '')
      .replace('/', '')
      .replace('-', '');
  };

  const onSubmitForm = async (data) => {
    const partnerFormData = new FormData();

    const fieldsToSend = ['Title', 'Cnpj'];

    if (data.Website !== partnerData.Website) {
      fieldsToSend.push('Website');
    }
    if (data.EnablePartner !== partnerData.IsEnabled) {
      fieldsToSend.push('EnablePartner');
    }
    if (data.Background !== partnerData.Background.Url) {
      fieldsToSend.push('Background');
    }
    if (
      fieldsToSend === ['Title', 'Cnpj'] &&
      data.Title === partnerData.Title &&
      data.Cnpj === partnerData.Cnpj.Number
    ) {
      toastError('Nenhuma alteração foi feita.');
      return;
    }

    fieldsToSend.forEach((field) => {
      if (field !== 'Background') {
        partnerFormData.append(field, data[field]);
      }
    });

    if (fieldsToSend.find((field) => field === 'Background')) {
      partnerFormData.append(
        'Background',
        partnerBackground,
        partnerBackground.name
      );
    }

    setIsLoading(true);

    try {
      await api.put(`/api/v1/Partners/${partnerId}`, partnerFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setIsLoading(false);
      toastSuccess('Dados do Parceiro atualizados com sucesso!');
    } catch (err) {
      setIsLoading(false);

      const errors = err.response.data.errors;

      const messages = err.response.data.Messages;

      if (errors) {
        const errorsMessages = Object.values(errors);

        errorsMessages.forEach((messageArr) => {
          const message = messageArr[0];

          if (
            message ===
            'The Website field is not a valid fully-qualified http, https, or ftp URL.'
          ) {
            toastError(
              'Campo Site com URL inválida. É necessário que a URL inserida possua no início http://, https:// ou ftp://'
            );
          } else {
            toastError(message);
          }
        });
      } else if (messages) {
        messages.forEach((message) => {
          toastError(message);
        });
      }
    }
  };

  const checkIfFormIsDirty = useCallback(() => {
    const watchAllFields = watch();

    if (watchAllFields.Title !== partnerData.Title) {
      setIsFormDirty(true);
    } else if (watchAllFields.Cnpj !== partnerData.Cnpj.Number) {
      setIsFormDirty(true);
    } else if (watchAllFields.Website !== partnerData.Website) {
      setIsFormDirty(true);
    } else if (watchAllFields.EnablePartner !== partnerData.IsEnabled) {
      setIsFormDirty(true);
    } else if (watchAllFields.Background !== partnerData.Background.Url) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [watch, partnerData]);

  const handleGoBackButton = useCallback(() => {
    checkIfFormIsDirty();

    if (isFormDirty) {
      handleOpenLeavePageModal();
    } else {
      window.location.href = '/';
    }
  }, [isFormDirty]);

  const onDisableButtonClick = async () => {
    try {
      await api.patch(`/api/v1/Partners/${partnerId}/Disable`);

      handleCloseDisablePartnerModal();

      toastSuccess('Parceiro desabilitado com sucesso!');
      setTimeout(() => {
        window.location.href = '/';
      }, 1700);
    } catch (err) {
      console.log(err);

      handleCloseDisablePartnerModal();

      toastError('Erro ao desabilitar parceiro. Tente novamente.');
    }
  };

  const onEnableButtonClick = async () => {
    try {
      await api.patch(`/api/v1/Partners/${partnerId}/ToggleEnable`);

      handleCloseEnablePartnerModal();

      toastSuccess('Parceiro habilitado com sucesso!');
      setTimeout(() => {
        window.location.href = '/';
      }, 1700);
    } catch (err) {
      console.log(err);

      handleCloseDisablePartnerModal();

      toastError('Erro ao habilitar parceiro. Tente novamente.');
    }
  };

  const onDeleteButtonClick = async () => {
    try {
      await api.delete(`/api/v1/Partners/${partnerId}`);

      handleCloseDeletePartnerModal();

      toastSuccess('Parceiro deletado com sucesso!');
      setTimeout(() => {
        window.location.href = '/';
      }, 1700);
    } catch (err) {
      console.log(err);

      handleCloseDeletePartnerModal();

      toastError('Erro ao deletar parceiro. Tente novamente.');
    }
  };

  const leavePageModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <AiOutlineWarning color="#304470" size={80} />

      <S.ModalText>Desejar sair sem salvar as alterações?</S.ModalText>

      <S.ModalButtonsDiv>
        <Button
          color="lightBlue"
          variant="contained"
          size="large"
          onClick={handleCloseLeavePageModal}
        >
          Cancelar
        </Button>

        <Button variant="contained" size="large" onClick={onLeaveButtonClick}>
          Sair
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  const disablePartnerModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <AiOutlineWarning color="#fce76d" size={80} />

      <S.ModalText>Deseja realmente desabilitar esse parceiro?</S.ModalText>

      <S.ModalSubText>
        Os cupons desse parceiro também serão desabilitados.
      </S.ModalSubText>

      <S.ModalButtonsDiv>
        <Button
          variant="contained"
          size="large"
          onClick={handleCloseDisablePartnerModal}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          size="large"
          onClick={onDisableButtonClick}
          color="secondary"
        >
          Desabilitar
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  const enablePartnerModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <AiOutlineWarning color="#fce76d" size={80} />

      <S.ModalText>Deseja realmente habilitar esse parceiro?</S.ModalText>

      <S.ModalButtonsDiv>
        <Button
          variant="contained"
          size="large"
          onClick={handleCloseEnablePartnerModal}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          size="large"
          onClick={onEnableButtonClick}
          color="secondary"
        >
          Habilitar
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  const deletePartnerModal = (
    <S.Stack
      style={{
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',

        padding: '3rem',
      }}
    >
      <S.Stack
        style={{
          width: '100%',

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <AiOutlineWarning color="#EE5D5D" size={80} />
        <S.ModalTitle
          style={{
            color: '#EE5D5D',
          }}
        >
          Atenção!
        </S.ModalTitle>
      </S.Stack>

      <S.ModalText>
        Essa ação não pode ser revertida. Deseja mesmo excluir esse parceiro?
      </S.ModalText>

      <S.ModalSubText>
        Os cupons desse parceiro também serão deletados.
      </S.ModalSubText>

      <S.ModalButtonsDiv>
        <Button
          variant="contained"
          size="large"
          onClick={handleCloseDeletePartnerModal}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          size="large"
          onClick={onDeleteButtonClick}
          color="error"
        >
          Excluir
        </Button>
      </S.ModalButtonsDiv>
    </S.Stack>
  );

  return (
    <>
      <S.Stack
        style={{
          width: '100%',

          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '4rem',

          fontSize: '16px',

          marginBottom: '3rem',

          '@media (max-width: 670px)': {
            gap: '6rem',
          },
        }}
      >
        <S.FormHeader>
          <S.FormTitle>Editar Loja</S.FormTitle>
          <S.Line />

          <S.CustomToolTip title={toolTipContent} arrow>
            <S.PartnerStatusBadge
              style={{
                backgroundColor: isPartnerEnabled ? '#00C853' : '#4F4F4F',
              }}
            >
              {isPartnerEnabled ? 'Habilitada' : 'Desabilitada'}
            </S.PartnerStatusBadge>
          </S.CustomToolTip>
        </S.FormHeader>

        <S.PartnerForm onSubmit={handleSubmit(onSubmitForm)}>
          <S.PartnerInputs>
            <UploadLogo
              type={'image/*'}
              filename="Logotipo da Loja"
              file={backgroundFile}
              onChange={(file) => {
                checkIfFormIsDirty();
                return setValue('Background', file);
              }}
              isError={errors.Background ? true : false}
              errorMessage={errors.Background?.message}
            />
            <S.PartnerInputsDiv>
              <InputComponent
                placeholder="Nome da Loja"
                leftAddornment={<FaStoreAlt size={20} />}
                isMaxWidth
                {...register('Title')}
                onChange={(e) => {
                  checkIfFormIsDirty();
                  setValue('Title', e.target.value);
                }}
                isError={errors.Title ? true : false}
                errorMessage={errors.Title?.message}
              />

              <PatternFormat
                customInput={InputComponent}
                placeholder="CNPJ"
                leftAddornment={<RxIdCard size={20} />}
                mask="_"
                format="##.###.###/####-##"
                isMaxWidth
                {...register('Cnpj')}
                value={partnerData.Cnpj?.Number}
                onChange={(e) => setValue('Cnpj', formatCnpj(e.target.value))}
                isError={errors.Cnpj ? true : false}
                errorMessage={errors.Cnpj?.message}
              />

              <InputComponent
                placeholder="Site"
                leftAddornment={<SlGlobe size={20} />}
                isMaxWidth
                {...register('Website')}
                onChange={(e) => {
                  checkIfFormIsDirty();
                  setValue('Website', e.target.value);
                }}
                isError={errors.Website ? true : false}
                errorMessage={errors.Website?.message}
              />
            </S.PartnerInputsDiv>
          </S.PartnerInputs>

          <S.ButtonsDiv>
            <Button
              color="error"
              variant="contained"
              type="button"
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                textTransform: 'capitalize',

                borderRadius: '13px',
              }}
              onClick={() => handleOpenDeletePartnerModal()}
            >
              Deletar Parceiro
            </Button>
            {isPartnerEnabled ? (
              <Button
                color="secondary"
                variant="contained"
                type="button"
                sx={{
                  fontWeight: '700',
                  fontSize: '20px',
                  textTransform: 'capitalize',

                  borderRadius: '13px',
                }}
                onClick={() => handleOpenDisablePartnerModal()}
              >
                Desabilitar Parceiro
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                type="button"
                sx={{
                  fontWeight: '700',
                  fontSize: '20px',
                  textTransform: 'capitalize',

                  borderRadius: '13px',
                }}
                onClick={() => handleOpenEnablePartnerModal()}
              >
                Habilitar Parceiro
              </Button>
            )}

            <Button
              variant="contained"
              type="button"
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                textTransform: 'capitalize',

                borderRadius: '13px',
              }}
              onClick={handleGoBackButton}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="lightBlue"
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                textTransform: 'capitalize',

                borderRadius: '13px',
              }}
              type="submit"
            >
              {isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                'Editar Parceiro'
              )}
            </Button>
          </S.ButtonsDiv>
        </S.PartnerForm>
      </S.Stack>

      <Modal
        children={leavePageModal}
        open={openLeavePageModal}
        handleClose={handleCloseLeavePageModal}
      />

      <Modal
        children={disablePartnerModal}
        open={openDisablePartnerModal}
        handleClose={handleCloseDisablePartnerModal}
      />

      <Modal
        children={enablePartnerModal}
        open={openEnablePartnerModal}
        handleClose={handleCloseEnablePartnerModal}
      />

      <Modal
        children={deletePartnerModal}
        open={openDeletePartnerModal}
        handleClose={handleCloseDeletePartnerModal}
      />
    </>
  );
}
