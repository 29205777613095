import React, { useCallback, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';

import { RiCloseCircleFill } from 'react-icons/ri';

import { AiOutlinePhone } from 'react-icons/ai';
import { ImWhatsapp } from 'react-icons/im';

import LeadTrust from './components/LeadTrustRanking';

import * as S from './styles';

import { Portal } from '@material-ui/core';

export default function SellerInfos({ sellerInformation, toggle, setToggle }) {
  const [showImage, setShowImage] = useState(true);

  const getRegexPhoneNumber = useCallback((phone) => {
    return phone?.replace(/^(\d{2})(\d)/g, '($1) - $2')
      ?.replace(/(\d)(\d{4})$/, '$1-$2');
  }, []);

  const getWhatsappLink = useCallback((phone) => {
    return 'https://web.whatsapp.com/send?phone=' + phone;
  }, []);

  const getSellerName = () => {
    const rawSellerName = sellerInformation?.SellerName;
    const rawSellerNameSplitted = rawSellerName?.split(' ');

    if (rawSellerNameSplitted)
      return rawSellerNameSplitted[0] + ' ' + rawSellerNameSplitted[1];
  }

  const getExpression = (health) => {
    if (health === 100) return require('~assets/very-happy-emoji.png');
    if (health >= 80) return require('~assets/happy-emoji.png');
    if (health >= 60) return require('~assets/regular-emoji.png');
    if (health >= 40) return require('~assets/bad-emoji.png');
    if (health >= 20) return require('~assets/very-bad-emoji.png');
  }

  const getColor = (health) => {
    if (health === 100) return '#36e266';
    if (health >= 80) return '#a7ec66';
    if (health >= 60) return '#ffdd33';
    if (health >= 40) return '#ff8523';
    if (health >= 20) return '#ff2327';
  }

  return (
    <Portal>
      <S.Overlay isToggled={toggle} />
      <S.Container isToggled={toggle}>
        <S.FirstHalf>
          <S.Title id="first-title">Dados do Vendedor</S.Title>

          <S.FlexWrapper>
          {sellerInformation?.DtoAvatar?.ThumbnailUrl && showImage ? (
              <S.ProfilePhoto src={sellerInformation?.DtoAvatar?.ThumbnailUrl} alt="profilePic" onError={e => {setShowImage(false)}} />
            ) : (
              <FaUserCircle color={"#213455"} size={92} />
            )}
            <S.ProfileName>
              {getSellerName()}
            </S.ProfileName>
          </S.FlexWrapper>

          <S.PhonesWrapper>
            <S.MobilePhone>
              <ImWhatsapp />
              <S.PhoneNumber
                href={getWhatsappLink(sellerInformation?.MobileNumber)}
                target="_blank"
              >
                {getRegexPhoneNumber(sellerInformation?.MobileNumber)}
              </S.PhoneNumber>
            </S.MobilePhone>
          </S.PhonesWrapper>

          <S.GetInTouch>
            Entre em contato com o vendedor preferencialmente por Whatsapp
          </S.GetInTouch>
        </S.FirstHalf>

        <S.CloseButton onClick={() => setToggle(false)}>
          <RiCloseCircleFill />
        </S.CloseButton>

        <S.SecondHalf>


          <S.Title>Avaliação nbikes</S.Title>

          <LeadTrust score={sellerInformation.ScoreAsSeller} />

          <S.BuyersReviewContainer>
            {sellerInformation?.BuyersReview?.length > 0 && (
              <S.BuyersReviewTitle>Avaliações de clientes</S.BuyersReviewTitle>
            )}
            {sellerInformation?.BuyersReview?.map(buyerReview => (
              <S.ReviewContainer color={getColor(buyerReview?.Health)}>
                <S.Face>
                  <img src={getExpression(buyerReview?.Health)}/>
                </S.Face>

                <S.Comment>"{buyerReview?.Notes}"</S.Comment>
              </S.ReviewContainer>
            ))}
          </S.BuyersReviewContainer>
        </S.SecondHalf>
      </S.Container>
    </Portal>
  );
}
