/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import { cpf } from 'cpf-cnpj-validator';
import EmailValidator from 'email-validator';

// import terms from 'assets/termos.pdf';
import terms from '../../assets/termos.pdf';
import { phoneMask } from '../../utils/phoneMask';
import { useDispatch } from 'react-redux';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import useIsMount from '~/utils/isMount';
import formatCPF from '~/utils/formatCPF';
import { signUpRequest } from '~/store/ducks/auth/actions';
import Input from './components/Input';
import Birthdate from './components/Birthdate';
import InputAnimation from './components/InputAnimation';

import * as S from './styles';

const Register = () => {
  const dispatch = useDispatch();

  const [reCaptcha, setReCaptcha] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token) => {
    setReCaptcha(token);
  }, []);

  const [errorFields, setErrorFields] = useState({ none: ['none'] }); // Apenas pro código não quebrar.

  const attributes = {
    name: {
      type: 'text',
      name: 'name',
      placeholder: 'Nome',
    },
    lastName: {
      type: 'text',
      name: 'lastName',
      placeholder: 'Sobrenome',
    },
    email: {
      type: 'text',
      name: 'email',
      placeholder: 'E-mail',
    },
    documentNumber: {
      type: 'text',
      name: 'documentNumber',
      placeholder: 'CPF',
    },
    birthDate: {
      type: 'text',
      name: 'birthDate',
      placeholder: 'Data de nascimento',
    },
    mobilePhone: {
      type: 'text',
      name: 'mobilePhone',
      placeholder: 'Número de celular',
    },
    password: {
      type: 'password',
      name: 'password',
      placeholder: 'Senha',
    },
    confirmPassword: {
      type: 'password',
      name: 'confirmPassword',
      placeholder: 'Confirmar a senha',
    },
  };

  const isFirstRender = useIsMount();

  const ONLY_LETTERS_REGEX = /[^a-zA-Z\u00C0-\u00FF ]+/;

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isTerms, setIsTerms] = useState(false);

  const [hasError, setHasError] = useState({
    name: false,
    lastName: false,
    documentNumber: false,
    birthDate: false,
    email: false,
    mobilePhone: false,
    password: false,
    confirmPassword: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    name: '',
    lastName: '',
    documentNumber: '',
    birthDate: '',
    email: '',
    mobilePhone: '',
    password: '',
    confirmPassword: '',
  });

  const handleCheckTerms = useCallback((event) => {
    const isChecked = event.target.checked;

    if (isChecked) return setIsTerms(true);

    setIsTerms(false);
  }, []);

  const validatePassword = useCallback(() => {
    if (password.length === 0) return;

    setHasError((prevError) => ({
      ...prevError,
      password: false,
      confirmPassword: false,
    }));
    setErrorMessage((prevMessage) => ({
      ...prevMessage,
      password: '',
      confirmPassword: '',
    }));

    if (password.length < 6) {
      setHasError((prevError) => ({
        ...prevError,
        password: true,
        confirmPassword: false,
      }));
      setErrorMessage((prevMessage) => ({
        ...prevMessage,
        password: 'A senha deve estar entre 6 e 20 caracteres.',
      }));

      return;
    }

    if (confirmPassword.length === 0) return;

    if (password === confirmPassword) return;

    setHasError((prevError) => ({
      ...prevError,
      password: true,
      confirmPassword: true,
    }));
    setErrorMessage((prevMessage) => ({
      ...prevMessage,
      password: 'As senhas devem ser iguais.',
    }));
  }, [password, confirmPassword]);

  const validateCPF = useCallback(() => {
    setHasError((prevError) => ({ ...prevError, documentNumber: false }));
    setErrorMessage((prevMessage) => ({ ...prevMessage, documentNumber: '' }));

    if (cpf.isValid(documentNumber)) return;

    setHasError((prevError) => ({ ...prevError, documentNumber: true }));
    setErrorMessage((prevMessage) => ({
      ...prevMessage,
      documentNumber: 'CPF Inválido.',
    }));
  }, [documentNumber]);

  const validateEmail = useCallback(() => {
    setHasError((prevError) => ({ ...prevError, email: false }));
    setErrorMessage((prevMessage) => ({ ...prevMessage, email: '' }));

    if (EmailValidator.validate(email)) return;

    setHasError((prevError) => ({ ...prevError, email: true }));
    setErrorMessage((prevMessage) => ({
      ...prevMessage,
      email: 'Verifique se o e-mail foi digitado corretamente.',
    }));
  }, [email]);

  const isButtonDisabled = useCallback(() => {
    if (
      !name ||
      !lastName ||
      !documentNumber ||
      !birthDate ||
      !email ||
      !mobilePhone ||
      !password ||
      !confirmPassword
    )
      return true;

    const isInvalidNameRegex = /[^a-zA-Z\u00C0-\u00FF ]+/;

    if (isInvalidNameRegex.test(name) || isInvalidNameRegex.test(lastName))
      return true;

    if (
      password.length < 6 ||
      password.length > 20 ||
      confirmPassword.length < 6 ||
      confirmPassword.length > 20
    )
      return true;

    if (password !== confirmPassword) return true;

    const unformattedCPF = documentNumber
      .replaceAll('.', '')
      .replace('-', '')
      .trim();

    if (!cpf.isValid(unformattedCPF)) return true;

    if (unformattedCPF.length !== 11) return true;

    const unformattedPhone = mobilePhone
      .replace(/\s/g, '')
      .replaceAll(' ', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .trim();

    if (unformattedPhone.length !== 11) return true;

    if (isNaN(unformattedPhone)) return true;

    if (!EmailValidator.validate(email)) return true;

    if (!isTerms) return true;

    return false;
  }, [
    name,
    lastName,
    documentNumber,
    birthDate,
    email,
    mobilePhone,
    password,
    confirmPassword,
    isTerms,
  ]);

  const handleSubmit = useCallback(() => {
    const documentType = 'Natural';
    const cellphone = phoneMask(mobilePhone);
    const telephone = '(99) 99999-9999';

    dispatch(
      signUpRequest([
        name,
        lastName,
        documentType,
        documentNumber,
        birthDate,
        email,
        telephone,
        cellphone,
        password,
        confirmPassword,
        reCaptcha,
        setErrorFields,
      ])
    );

    setRefreshReCaptcha((r) => !r);
  }, [
    dispatch,
    name,
    lastName,
    documentNumber,
    birthDate,
    email,
    mobilePhone,
    password,
    confirmPassword,
    reCaptcha,
  ]);

  useEffect(() => {
    if (isFirstRender) return;

    validatePassword();
  }, [validatePassword]);

  useEffect(() => {
    if (isFirstRender) return;

    validateCPF();
  }, [validateCPF]);

  useEffect(() => {
    if (isFirstRender) return;

    validateEmail();
  }, [validateEmail]);

  useEffect(() => {
    if (refreshReCaptcha) setRefreshReCaptcha(false);
  }, [refreshReCaptcha]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    >
      <S.Container>
        <S.Title>Complete seus dados</S.Title>

        <S.FormContainer>
          <S.FlexWrapper>
            <InputAnimation delay="0.15s">
              <Input
                attributes={attributes.name}
                span="Nome"
                hasError={hasError.name}
                errorMessage={errorMessage.name}
                setValue={setName}
                value={name.replace(ONLY_LETTERS_REGEX, '')}
                maxLength={50}
              />
            </InputAnimation>

            <InputAnimation delay="0.25s">
              <Input
                attributes={attributes.lastName}
                span="Sobrenome"
                hasError={hasError.lastName}
                errorMessage={errorMessage.lastName}
                setValue={setLastName}
                value={lastName.replace(ONLY_LETTERS_REGEX, '')}
                maxLength={50}
              />
            </InputAnimation>
          </S.FlexWrapper>

          <S.FlexWrapper>
            <InputAnimation delay="0.35s">
              <Input
                attributes={attributes.documentNumber}
                span="CPF"
                hasError={hasError.documentNumber}
                errorMessage={errorMessage.documentNumber}
                setValue={setDocumentNumber}
                value={formatCPF(documentNumber)}
                maxLength={14}
              />
            </InputAnimation>

            <InputAnimation delay="0.45s">
              <Input
                attributes={attributes.email}
                span="Insira seu endereço de e-mail."
                hasError={hasError.email}
                errorMessage={errorMessage.email}
                setValue={setEmail}
                maxLength={80}
              />
            </InputAnimation>
          </S.FlexWrapper>

          <S.FlexWrapper>
            <InputAnimation delay="0.55s">
              <Birthdate setValue={setBirthDate} />
            </InputAnimation>
            <InputAnimation delay="0.65s">
              <Input
                attributes={attributes.mobilePhone}
                span="Insira seu número de celular."
                hasError={hasError.mobilePhone}
                errorMessage={errorMessage.mobilePhone}
                setValue={setMobilePhone}
                value={phoneMask(mobilePhone)}
                maxLength={15}
              />
            </InputAnimation>{' '}
            <GoogleReCaptcha
              onVerify={onVerify}
              refreshReCaptcha={refreshReCaptcha}
            />
          </S.FlexWrapper>

          <S.FlexWrapper>
            <InputAnimation delay="0.75s">
              <Input
                attributes={attributes.password}
                span="A senha deve conter entre 6 a 20 caracteres."
                hasError={hasError.password}
                errorMessage={errorMessage.password}
                setValue={setPassword}
                value={password}
                maxLength={20}
              />
            </InputAnimation>

            <InputAnimation delay="0.85s">
              <Input
                attributes={attributes.confirmPassword}
                span="Deve ser idêntica a senha."
                hasError={hasError.confirmPassword}
                errorMessage={errorMessage.confirmPassword}
                setValue={setConfirmPassword}
                value={confirmPassword}
                maxLength={20}
              />
            </InputAnimation>
          </S.FlexWrapper>

          <InputAnimation delay="0.95s" width="unset">
            <S.Terms>
              <S.Checkbox
                type="checkbox"
                name="terms"
                onClick={handleCheckTerms}
              />

              <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />

              <S.Text>
                Estou de acordo com os{' '}
                <a href={terms} rel="noreferrer" target="_blank">
                  Termos e Condições Gerais de Uso
                </a>{' '}
                do <strong>n bikes</strong>
              </S.Text>
            </S.Terms>
          </InputAnimation>
        </S.FormContainer>

        <S.Wrapper>
          <S.Button onClick={handleSubmit} disabled={isButtonDisabled()}>
            Continuar
          </S.Button>
        </S.Wrapper>
      </S.Container>
    </GoogleReCaptchaProvider>
  );
};

export default Register;
