import React, { useState } from 'react';

import { FaChevronRight } from 'react-icons/fa';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';

import { Field, MainContainer, BaseContainer, GoBackButton } from './styles';

import {
  forgotEmailPassword,
  confirmNewPassword,
} from '../../store/ducks/auth/actions';

export default function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();

  const email = window.location.href.split('email=')[1];

  const [resetPassword, setResetPassword] = useState('forgotPass');
  const [dataUserForChangePass, setDataUserForChangePass] = useState({
    userEmail: email !== undefined ? email : '',
    userPassword: '',
    userConfirmPassword: '',
    userCode: '',
  });

  const showResetPassword = () => {
    switch (resetPassword) {
      case 'forgotPass':
        return (
          <>
            <h4> Redefinir senha</h4>

            <div className="email-field">
              <input
                onChange={(event) => {
                  setDataUserForChangePass({
                    ...dataUserForChangePass,
                    userEmail: event.target.value,
                  });
                }}
                name="e-mail"
                type="email"
                defaultValue={email}
                placeholder="email@email.com"
              />

              <button
                type="button"
                className="send-email-button"
                onClick={() => {
                  dispatch(
                    forgotEmailPassword({
                      email: dataUserForChangePass.userEmail,
                      setResetPassword,
                    })
                  );
                }}
              >
                <FaChevronRight />
              </button>
            </div>
            <span>E-mail para redefinição de senha </span>
          </>
        );

      case 'secretCodeAndResetPass':
        return (
          <>
            <h4> Redefinir senha</h4>

            <br />

            <ul className="secret-code-field">
              <li>
                <h5>Insira o código enviado no seu e-mail</h5>

                {/*  SECRET CODE FIELD */}
                <input
                  name="secret-code"
                  type="text"
                  onChange={(event) =>
                    setDataUserForChangePass({
                      ...dataUserForChangePass,
                      userCode: event.target.value,
                    })
                  }
                />

                <ul className="reset-password-field">
                  {/*  PASSWORD FIELD */}
                  <li>
                    <input
                      name="password"
                      type="password"
                      onChange={(event) =>
                        setDataUserForChangePass({
                          ...dataUserForChangePass,
                          userPassword: event.target.value,
                        })
                      }
                    />
                    <br />
                    <span>Nova Senha </span>
                  </li>

                  {/*  CONFIRM PASSWORD FIELD */}
                  <li>
                    <input
                      name="confirm-password"
                      type="password"
                      onChange={(event) =>
                        setDataUserForChangePass({
                          ...dataUserForChangePass,
                          userConfirmPassword: event.target.value,
                        })
                      }
                    />
                    <br />
                    <span>Confirmar Nova Senha </span>
                  </li>
                </ul>

                <br />
              </li>
            </ul>

            <br />

            <button
              type="button"
              className="confirm-button"
              onClick={() => {
                dispatch(
                  confirmNewPassword({
                    email: dataUserForChangePass.userEmail,
                    code: dataUserForChangePass.userCode,
                    password: dataUserForChangePass.userPassword,
                    confirmPassword: dataUserForChangePass.userConfirmPassword,
                    setResetPassword,
                  })
                );
              }}
            >
              Confirmar
            </button>
          </>
        );

      case 'passwordChanged':
        return <h4>Senha alterada com sucesso!</h4>;

      default:
        return <></>;
    }
  };

  return (
    <MainContainer>
      <BaseContainer>
        <Field>
          <GoBackButton onClick={() => history.push('/Login')}>
            <FiChevronLeft size={20} />
            <p>voltar</p>
          </GoBackButton>
          {showResetPassword()}
        </Field>
      </BaseContainer>
    </MainContainer>
  );
}
