import styled, { css } from 'styled-components';

export const BackContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;

  margin: 30px auto 0 auto;
  padding: 0 20px;

  button {
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 15px;

    background: var(--blueNbikes);

    border: 0;
    border-radius: 50%;

    svg {
      fill: white;

      width: 20px;
      height: 20px;

      pointer-events: none;
    }
  }
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 1250px;
  display: flex;
  margin: 20px auto 5px auto;
  font-size: 25px;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  height: auto;
  display: flex;
  margin: 0 auto 60px auto;
  padding: 0 20px;

  svg {
    display: flex;
    overflow: auto;
    opacity: 100%;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  @media screen and (max-width: 960px) {
    .title {
      margin: 20px 0 0 0;
    }
    .filters-showup {
      margin-left: 0px !important;
    }

    .right-side {
      margin-left: 0px !important;
    }
  }

  .left-side {
    margin-top: 20px;
  }

  .right-side {
    width: 100%;
    max-width: 1000px;
    margin-left: 30px;
  }

  .title {
    margin-top: 20px;
  }

  .filters-showup {
    ul {
      width: 100%;
      overflow-x: auto;
      display: flex;
    }

    li {
      min-width: 70px;

      align-items: center;
      justify-content: space-between;
      padding: 3px 7px;
      background: #213455;
      color: white;
      font-size: 12px;
      margin-right: 10px;
      border-radius: 99px;
    }

    li.clean-all {
      min-width: 70px;
      background: #fce76d;
      color: #213455;
      font-size: 10px;
      display: flex;
      align-items: center;

      span {
        font-size: 15px;
        font-weight: bold;
        transition: color 0.2s;

        &:hover {
          cursor: pointer;
          color: #36bcc1;
        }
      }
    }

    button {
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      font-size: 10px;
      border-radius: 99px;
      margin-left: 5px;
      font-family: Nunito;
      color: white;
      transition: 0.3s;

      &:hover {
        color: #36bcc1;
      }
    }
  }

  .MuiPagination-root {
    button {
      width: 35px;
      background: #c3c3c3;
      color: white;
      border-radius: 0;

      &:hover {
        background: var(--blueNbikes);
      }

      svg {
        fill: white;
      }
    }
  }

  .MuiPaginationItem-page.Mui-selected {
    background: var(--blueNbikes);
  }
`;

export const Categories = styled.div`
  max-width: 200px;
  width: 100%;
  height: 70%;
  margin-top: 20px;

  @media screen and (max-width: 960px) {
    min-width: 100% !important;
    min-height: 100% !important;
  }
`;

export const Products = styled.div`
  width: 100%;

  margin-bottom: 10px;

  .ListOfProducts {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-top: 20px;
    border-radius: 10px;
    background: white;
    padding: 20px;
  }

  .products {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  li {
    position: relative;

    display: flex;
    justify-content: center;

    margin-bottom: 10px;

    flex: 0 0 33%;

    width: 260px;
    height: 350px;
  }

  @media (min-width: 960px) and (max-width: 1200px) {
    li {
      flex: 0 0 50%;
    }
  }

  @media (min-width: 500px) and (max-width: 960px) {
    ul {
      justify-content: space-between;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 500px) {
    ul {
      justify-content: center;
      width: 200px;
    }

    .products {
      display: flex;
      justify-content: center;
    }
  }

  .pageCounter {
    height: 35px;
    float: right;
    display: flex;
    margin-top: 15px;
    transition: 0.3s;
  }

  .squareCounter {
    width: 35px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #c3c3c3;
    margin: 0 5px;
    transition: 0.2s;
    border: none;

    &:hover {
      cursor: pointer;
      background: #213355;
    }
    &:nth-last-child(1) {
      width: 100px;
    }
  }
`;

export const ProductSquare = styled.div`
  position: relative;

  width: 280px;
  height: 350px;

  transition: 0.5s;
  overflow: hidden;
  border-radius: 10px;
  background: white;

  @media screen and (max-width: 960px) {
    width: 200px !important;
    height: 320px !important;

    .image {
      width: 100%;
      height: 160px !important;
    }
  }

  .image {
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
    margin-bottom: 14px;

    img {
      width: 300px;
      height: auto;

      position: absolute;
      left: 50%;

      transform: translateX(-50%);
    }
  }

  .icons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 10px;
    top: 10px;
    transition: 0.5s;
    z-index: ${({ isStarred }) => (isStarred ? '2' : '0')};

    ${({ isStarred }) => {
      if (!isStarred) {
        return css`
          .heart-icon {
            fill: #36bcc1;
          }
        `;
      }
      return css`
        .heart-icon {
          fill: #fce76d;
        }
      `;
    }}

    svg {
      width: 25px;
      height: 25px;
      transition: 0.3s;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 1em rgb(0, 0, 0, 0.4);

    p,
    span {
      color: #36bcc1;
    }

    .icons {
      opacity: 1;
      z-index: 2;
    }

    ${({ isStarred }) => {
      if (!isStarred) {
        return css`
          .heart-icon {
            fill: #36bcc1;
          }
        `;
      }
      return css`
        .heart-icon {
          fill: #fce76d;
        }
      `;
    }}
  }

  img {
    width: 240px;
    height: 180px;
  }

  p {
    padding: 6px;
    font-weight: 600;
    color: #213355;
    transition: 0.5s;
  }

  .use-condition {
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;

    font-size: 18px;

    width: 80px;
    height: 30px;

    text-align: center;

    border-radius: 10px;

    margin: 6px 0 6px 14px;
  }

  .announcement-title-container {
    width: 100%;
    height: 30px;

    overflow: hidden;
  }

  .announcement-title {
    margin-left: 14px;

    transition: 0.5s;

    color: black;

    font-size: 22px;
    font-weight: bold;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    padding-left: 14px;

    font-weight: 600;

    color: #213355;

    transition: 0.5s;
  }

  .announcement-price {
    color: #0cc870;

    font-size: 22px;
    font-weight: 500;
  }

  &:hover {
    fill: #334f8d !important;
    cursor: pointer;
    box-shadow: 0 0 1em rgb(0, 0, 0, 0.4);
  }

  .heart-icon:hover {
    fill: #fce76d !important;
    cursor: pointer;
    filter: drop-shadow(0 0 0.2em rgb(0, 0, 0, 0.4));
  }
`;
