import React from 'react';
import { Button, Modal as MuiModal } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import { styled } from '~/stiches.config';

const Stack = styled('div', {
  backgroundColor: 'white',

  minWidth: '500px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  borderRadius: '20px',

  boxShadow: '1px 0px 16px 4px rgba(0, 0, 0, 0.5)',
  padding: 4,

  '@media (max-width: 900px)': {
    width: '80%',
  },
});

export default function Modal({ open, handleClose, children }) {
  return (
    <MuiModal open={open} onClose={handleClose} disableScrollLock>
      <Stack>
        <Button
          onClick={handleClose}
          color="info"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',

            borderRadius: '50%',

            minWidth: 'unset',
          }}
        >
          <IoMdClose size={25} />
        </Button>

        {children}
      </Stack>
    </MuiModal>
  );
}
