import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  overflow: hidden;
`;

export const BaseContainer = styled.div`
  width: 100%;
  max-width: 1195px;
  margin: auto;
  padding: 20px;
  position: relative;

  button {
    width: 150px;
    height: 60px;
    background: rgb(32, 52, 83);
    font-size: 20;
    color: white;
    border: none;
    border-radius: 10px;
    margin-right: 65px;
  }

  .alterar {
    float: right;
  }

  h2 {
    margin-bottom: 20px;
  }

  span {
    font-size: 12px;
    vertical-align: bottom;
  }

  #no-favorites-div {
    font-size: 20px;

    color: #000000;

    p {
      transition: 0.2s ease;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      cursor: pointer;

      margin-top: 20px;
      padding: 10px;

      width: 200px;

      border-radius: 10px;

      color: white;
      background-color: rgb(33 52 85);

      &:hover {
        transition: 0.2s ease;

        background-color: #37bcc1;
      }
    }
  }
`;

export const Products = styled.div`
  width: 100%;
  margin-bottom: 2em;

  @media screen and (max-width: 960px) {
    margin: 5em 0 0 0;
  }

  .ListOfProducts {
    width: 100%;
    height: 100%;
    padding: 12px 0;

    border-bottom: 0.5px solid rgb(33, 52, 85, 0.3);
  }

  .products {
    display: flex;

    width: 100%;
    height: auto;

    position: relative;

    margin-top: 2em;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    width: 100%;
    height: auto;

    margin-bottom: 1em;
  }

  li {
    margin: 4px;
    display: inline-block;
  }

  .pageCounter {
    height: 35px;

    float: right;

    display: flex;

    margin-top: 15px;
    transition: 0.3s;
  }

  .squareCounter {
    width: 35px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #c3c3c3;
    margin: 0 5px;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: #213355;
    }
    &:nth-last-child(1) {
      width: 100px;
    }
  }
`;

export const PagePreviousNext = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 100px;
    height: 40px;
    margin: 0 5px;
    transition: 0.2s;
    border-radius: 4px;
    background: var(--blueNbikes);
    color: white;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background: #37bcc1;
    }
  }
`;
export const ProductSquare = styled.div`
  width: 280px;
  height: 350px;
  display: flex;
  position: relative;
  flex-direction: column;
  transition: 0.5s;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;

  .image {
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;

    img {
      width: 340px;
      height: auto;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .icons {
    width: 90%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    top: 3%;
    transform: translateX(-50%);
    transition: 0.5s;
    z-index: 1;

    svg {
      width: 25px;
      height: 25px;
      transition: 0.5s;
      fill: #fce76d;
    }
  }
  &:hover {
    cursor: pointer;

    box-shadow: 0 0 1em rgb(0, 0, 0, 0.4);

    p,
    span {
      color: #36bcc1;
    }
  }

  span {
    padding: 6px;
    color: #213355;
    transition: 0.5s;
  }

  #announcement-title {
    font-size: 18px;

    margin-left: 5px;
    margin-top: 5px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    border: none !important;
    padding: 6px;
    font-weight: 200;
    transition: 0.5s;
  }

  h6 {
    color: gray;
  }
  h4 {
    color: #00c569;
  }
`;

export const Used = styled.div`
  width: 60px;
  height: 30px;
  
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px 5px 5px 5px;
  padding: 2px 6px;

  ${(props) => {
    if (props.isUsed) {
      return css`
        background: #213355;
      `;
    }

    return css`
      background: #37bcc1;
    `;
  }}

  font-weight: 400;
  color: #ffff;
  color: white;
  text-align: center;
`;
