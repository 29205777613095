import styled, { css } from 'styled-components';

export const AnimationContainer = styled.div`
  transition: 1.5s ease;

  opacity: 0;

  transform: translateX(-50%);

  width: 100%;
`;

export const MainField = styled.div`
  width: 100%;
  margin: 1em auto;
  border-radius: 12px;
  /* box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2); */
  padding: 12px 50px;
  transition: 0.3s;
  background: white;
  position: relative;

  box-shadow: 0 0 0.4em rgb(0, 0, 0, 0.3);

  @media screen and (max-width: 960px) {
    padding: 20px !important;
  }

  .notify-button {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }

    .notify-counter {
      width: 20px;
      height: 20px;
      position: absolute;
      right: -3px;
      top: -1px;
      background: #fa8787;
      border-radius: 99px;
      display: flex;
      z-index: 2;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }

  h2 {
    font-size: 30px;
    margin: 0.5em 0 0.7em 0;
    font-weight: 600;
  }

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100px;
      height: 30px;

      font-size: 18px;
      font-weight: 600;
      text-align: center;

      background: #37bcc1;
      color: white;

      border-radius: 10px;
    }
  }

  h3 {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: solid 1px rgb(125, 125, 125, 0.4);
    color: #37bcc1;
  }

  .Carousel {
    width: 100%;
    background: white;
    cursor: pointer;

    .slide.selected {
      background: white;
      div {
        background: white;
      }
    }
    .CarouselItem {
      display: flex;

      justify-content: center;
      align-items: center;

      margin: 0 auto;

      max-width: 400px;
      max-height: 400px;
      width: auto;
      height: auto;

      @media screen and (max-width: 520px) {
        max-width: 300px;
        max-height: 300px;
      }

      @media screen and (max-width: 420px) {
        max-width: 200px;
        max-height: 200px;
      }
    }

    .thumb {
      .CarouselItem {
        max-width: 60px;
      }
    }

    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const TopField = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 4em;
    justify-content: unset !important;
    align-content: center;

    .photos,
    .right {
      width: 100% !important;
    }
  }

  .photos {
    width: 45%;
    height: auto;
    margin: 1em 0;
    border-radius: 12px;
    box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);
    padding: 12px 50px;
    transition: 0.3s;
    background: white;
  }

  .right {
    width: 45%;
    height: auto;
    margin: 1em 0;
    transition: 0.3s;
    background: white;

    .price-tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: solid 1px rgb(125, 125, 125, 0.4);
      h2 {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: #00c569;
      }
    }

    .contactButton {
      width: 120px;
      color: rgb(32, 52, 83);
      border: none;
      font-size: 23px;
      background: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    span {
      font-size: 16px;
      color: black;
      font-weight: 600;
      margin-top: 20px;
    }
  }

  .selfInfos {
    width: 100%;
    height: auto;
    padding: 12px 20px !important;
    transition: 0.3s;
    background: white;
    border-radius: 12px;
    box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);

    h3 {
      font-size: 24px;
    }

    button {
      background: none;
      border: none;
      margin-left: 20px;
      transition: 0.3s;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .myAccount {
    width: 100%;
    display: flex;
    align-items: center;
    color: rgb(33, 52, 85);
    padding-left: 20px;
    margin-top: 20px;

    h6 {
      width: clamp(250px, 90%, 350px);

      font-weight: 600;
      font-size: 23px;

      text-transform: uppercase;

      margin-left: 20px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .profilePic {
    min-width: 70px;
    height: 70px !important;
    border: solid rgb(33, 52, 85) 1px;
    border-radius: 99px;
    overflow: hidden;
    text-align: center;

    img {
      width: 70px;
      height: auto;
    }
  }

  .LeadTrust {
    width: 100%;
    max-width: 300px;
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    margin: 0 auto;

    .satisfactionRate {
      width: 100%;
      max-width: 300px;
      height: 70px;
      position: relative;
      display: flex;
      align-items: flex-end;
      overflow: hidden;

      svg {
        width: 100%;
        max-width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      span {
        width: 60px;
        height: 3px;
      }
      span:nth-child(2) {
        background: #ff2327;
        border-radius: 4px 0px 0px 4px;
      }
      span:nth-child(3) {
        background: #ff8523;
      }
      span:nth-child(4) {
        background: #ffdd33;
      }
      span:nth-child(5) {
        background: #a7ec66;
        height: 60px !important;

        box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
        border-radius: 8px 8px 0 0;
      }
      span:nth-child(6) {
        background: #36e266;
        border-radius: 0 4px 4px 0;
      }

      .expression-1 {
        display: none;
      }
      .expression-2 {
        display: none;
      }
      .expression-3 {
        display: none;
      }
      .expression-4 {
        display: unset;
        z-index: 2;
      }
      .expression-5 {
        display: none;
      }
    }

    .logo-resume {
      display: flex;
      margin-top: 30px;
    }

    .logoLeadTrust {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      span {
        display: none;
        position: absolute;
        width: 2px;
        background: darkgray;
        height: 85px;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      svg {
        width: 70px;
        height: auto;
      }
    }

    .LeadTrustResume {
      width: 200px;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        width: 100%;
        padding: 0 20px;
        font-size: 10px;
        text-align: center;
      }
    }
  }
`;

export const MobilePhone = styled.div`
  margin-top: 20px;

  h2 {
    font-size: 20px;
    font-weight: 500;

    margin: 10px 0 5px 0;
  }

  .number-and-icon {
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 10px;

    width: 200px;

    span {
      transition: 0.25s ease;

      font-weight: 500;

      margin: 0;
    }

    svg {
      transition: 0.25s ease;

      width: 16px;
      height: 16px;
    }

    &:hover {
      span {
        transition: 0.25s ease;

        color: #37bcc1;
      }

      svg {
        transition: 0.25s ease;

        fill: #37bcc1;
      }
    }
  }
`;

export const CommercialPhone = styled.div`
  margin-bottom: 20px;

  h2 {
    font-size: 20px;
    font-weight: 500;

    margin: 10px 0 5px 0;
  }

  .number-and-icon {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 500;

      margin: 0;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const GetInTouchDescription = styled.div`
  text-align: justify;
`;

export const MiddleField = styled.div`
  width: 100%;
  height: auto;

  margin: 1em auto;

  border-radius: 12px;

  box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);

  padding: 20px 24px;

  transition: 0.3s;

  background: white;

  h3 {
    font-size: 24px;
  }

  ul {
    display: inline-block;
    vertical-align: top;

    &:nth-child(2) {
      margin-right: 200px;
    }
  }
  li {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 20px 0;

    span {
      font-size: 16px;
      width: inherit;
      padding-left: 10px;
    }

    span:nth-child(1) {
      font-size: 20px;
      font-weight: 600;
      text-align: right;
    }
  }
`;

export const BottomField = styled.div`
  width: 100%;
  height: auto;

  margin: 1em auto;
  padding: 20px 24px;

  border-radius: 12px;

  box-shadow: 0 0 0.3em rgb(0, 0, 0, 0.2);

  transition: 0.3s;

  background: white;

  h3 {
    font-size: 24px;

    margin-bottom: 10px;
  }

  pre {
    font-size: 15px;

    word-break: break-all;
    white-space: pre-wrap;

    text-align: justify;
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 20px 0 50px 0;

  .teste {
    top: -200px;
    opacity: 0;
  }

  section {
    #publish-button {
      background-color: #00c569;

      &:disabled {
        filter: grayscale(1);
      }
    }
  }

  ${(props) => {
    if (props.propPersonalData) {
      return css`
        .teste {
          top: 200px !important;
          opacity: 1 !important;
        }
      `;
    }
  }}
`;

export const NotifySection = styled.section`
  width: 100%;
  height: auto;

  ul {
    width: 100%;
    height: auto;
    margin: 0 0 1em 0;
    background: #ff9558;
    border-radius: 12px;
    padding: 20px;

    li {
      width: 100%;
      height: auto;
      color: white;
    }
  }
`;
