/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';

import SVG from 'react-inlinesvg';

import { Container } from './styles';

export default function SellerSatisfaction({ score }) {
  const [satisfaction, setSatisfaction] = useState('happy');
  const [noRate, setNoRate] = useState(false);

  const handleChangeSatisfaction = useCallback(() => {
    if (score < 0) {
      setSatisfaction('regular');
      setNoRate(true);

      return;
    }

    if (score === 100) return setSatisfaction('very-happy');
    if (score >= 80) return setSatisfaction('happy');
    if (score >= 60) return setSatisfaction('regular');
    if (score >= 40) return setSatisfaction('bad');
    if (score >= 20) return setSatisfaction('very-bad');
  }, [setSatisfaction, satisfaction, score]);

  useEffect(() => {
    handleChangeSatisfaction();
  }, [score]);

  return (
    <Container satisfaction={satisfaction}>
      <div className="LeadTrust" style={{filter: noRate ? 'grayscale(1)' : 'grayscale(0)'}}>
        <div className="satisfactionRate-1">
          <SVG src={require('~/assets/Expressions Lead Trust.svg')} />

          <span />
          <span />
          <span />
          <span />
          <span />
        </div>

        <p style={{ display: noRate ? 'unset' : 'none', opacity: '0.5' }}>
          Sem avaliações.
        </p>
      </div>
    </Container>
  );
}
