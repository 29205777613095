import styled from 'styled-components';

export const MainContainer = styled.div`
  position: relative;

  max-width: 1280px;
  width: 100%;
  margin: 0px auto 20px auto;

  padding: 20px;

  .arrow {
    width: 40px;
    height: 40px;

    pointer-events: none;

    fill: #203453;
  }
`;

export const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 32px;

  margin-bottom: 10px;
`;

const ToggleComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;

  border: 2px solid gray;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  padding: 20px;

  font-size: 24px;
  font-weight: 600;

  width: auto;
  height: 60px;

  cursor: pointer;

  transition: 0.15s ease;
`;

export const ToggleProfile = styled(ToggleComponent)``;

export const ProfileContainer = styled.div`
  position: relative;

  height: 900px;
  max-width: 1280px;
  width: 100%;

  background-color: #fff;

  overflow: hidden;

  transition: 0.15s ease;

  padding-top: 30px;
  padding-left: 50px;

  border: 2px solid gray;
  border-top: 0px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  margin-bottom: 20px;

  @media screen and (max-width: 650px) {
    height: 950px;

    padding-left: 20px;
  }
`;

export const FieldName = styled.span`
  color: #8b8b8b;

  transition: 0.3s;

  margin-top: 4px;

  font-size: 16px;

  @media screen and (max-width: 650px) {
    margin-top: 1px;

    font-size: 12px;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  transition: 0.3s;

  #name-error {
    display: none;

    margin-top: 2px;

    color: red;

    width: 400px;
  }

  .register {
    @media screen and (max-width: 850px) {
      margin: 0 auto;
    }
  }

  .register-name {
    @media screen and (max-width: 850px) {
      margin-top: 10px;
    }
  }

  .field-name {
    @media screen and (max-width: 850px) {
      margin: 0 auto 30px auto;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;

  transition: 0.3s ease;

  #name {
    transition: 0.3s ease;

    display: flex;
    flex-direction: column;
  }

  #span-tag {
    position: relative;

    transition: 0.3s ease;

    color: gray;

    margin-left: 10px;

    font-size: 20px;
    font-weight: 500;

    cursor: default;

    &:hover::before {
      position: absolute;

      content: '';

      top: 28px;
      left: 36px;

      transform: rotate(45deg);

      font-size: 14px;

      background-color: darkgray;

      padding: 8px;
    }

    &:hover::after {
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;

      border-radius: 10px;

      content: 'Essa é a Tag do seu perfil.';

      top: 32px;
      left: 0;

      font-size: 14px;

      background-color: darkgray;
      color: #fff;

      padding: 4px;
    }

    @media screen and (max-width: 650px) {
      font-size: 16px;
    }
  }
`;

export const InputStyle = styled.input`
  font-size: 20px;
  font-weight: bold;

  transition: 0.3s;

  color: #000;

  border: 0;
  border-bottom: 1px solid #7d7d7d4d;

  width: 350px;
  height: 30px;

  @media screen and (max-width: 800px) {
    width: 180px;
    font-size: 14px;

    .register {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &[data-autocompleted] {
    background-color: transparent !important;
  }
`;

export const MobileError = styled.span`
  color: #ee4c4c;
  font-size: 14px;
`;

export const CommercialError = styled.span`
  color: #ee4c4c;
  font-size: 14px;
`;

export const CpfError = styled.span`
  color: #ee4c4c;
  font-size: 13px;

  margin-top: 5px;
  text-align: start;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DeletePhoneButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #e93131;

  padding: 10px;

  color: white;

  font-size: 16px;

  border: none;
  border-radius: 10px;

  height: 30px;

  :hover {
    background-color: #bf2828;
  }
`;

export const NickName = styled(InputStyle)`
  margin-top: 30px;
`;

export const Email = styled.div`
  font-size: 20px;
  font-weight: bold;

  transition: 0.3s ease;

  color: #000;

  border: 0;
  border-bottom: 1px solid #7d7d7d4d;

  width: 350px;
  height: 30px;

  margin-top: 30px;

  cursor: not-allowed;

  @media screen and (max-width: 850px) {
    width: 180px;
    font-size: 14px;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  align-items: center;

  #change-password-span {
    margin-top: 20px;
    color: #000;

    cursor: pointer;

    &:hover {
      color: var(--blueNbikes);
    }
  }
`;

export const ChangePasswordContainer = styled.div`
  margin-top: 15px;

  height: 210px;

  overflow: hidden;

  transition: 0.15s ease;
`;

export const NewPassword = styled(InputStyle)`
  width: 350px;
`;

export const ConfirmNewPassword = styled(InputStyle)`
  width: 350px;
`;

export const ChangePasswordButton = styled.button`
  width: 130px;
  height: 30px;

  margin-top: 10px;

  overflow: hidden;

  transition: 0.2s ease;

  background-color: #203453;
  color: white;

  font-size: 16px;

  border: none;
  border-radius: 10px;
`;

export const SecretCodeContainer = styled.div`
  display: flex;

  transition: 0.3s ease;

  height: 0px;

  overflow: hidden;

  transition: 0.2s ease;

  margin-top: 10px;
`;

export const SecretCodeField = styled(InputStyle)`
  width: 210px;

  margin-right: 10px;

  font-size: 16px;
  font-weight: 500;

  transition: 0.3s ease;
`;

export const SecretCodeConfirmButton = styled.button`
  width: 90px;
  height: 30px;

  background-color: #203453;
  border: none;
  border-radius: 10px;

  color: #fff;

  transition: 0.3s ease;
`;

export const LeadTrustContainer = styled.div`
  margin: 30px 0;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
`;

const SaveButton = styled.button`
  position: absolute;

  transition: 0.3s ease;

  right: 60px;
  top: 85%;

  width: 150px;
  height: 60px;

  background-color: #203453;
  color: white;

  font-size: 20px;

  border: none;
  border-radius: 10px;

  margin-right: 20px;
`;

export const SaveProfileButton = styled(SaveButton)`
  &:disabled {
    filter: grayscale(100%);

    opacity: 0.6;

    cursor: not-allowed;
  }

  @media screen and (max-width: 650px) {
    margin-top: 20px;

    left: 50%;
    top: 90%;

    transform: translateX(-50%);

    width: 100px;
    height: 40px;
  }
`;

export const ToggleRegister = styled(ToggleComponent)``;

export const RegisterContainer = styled.div`
  position: relative;

  height: 400px;
  max-width: 1280px;
  width: 100%;

  background-color: #fff;

  overflow: hidden;

  transition: 0.15s ease;

  padding-top: 30px;
  padding-left: 50px;

  border: 2px solid gray;
  border-top: 0px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  margin-bottom: 20px;

  #register-section-1,
  #register-section-2 {
    display: flex;

    @media (min-width: 650px) and (max-width: 850px) {
      justify-content: space-between;
      width: 500px;
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 650px) {
    padding-top: 10px;
    padding-left: 20px;
  }
`;

export const Name = styled(InputStyle)`
  margin: 30px 50px 0 0;

  width: 310px;
`;

export const SurName = styled(InputStyle)`
  margin: 30px 0 0 0;

  width: 310px;
`;

export const CPF = styled(InputStyle)`
  margin: 30px 50px 0 0;

  width: 310px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const BirthDate = styled(InputStyle)`
  margin: 30px 0 0 0;

  width: 310px;
`;

export const SaveRegisterButton = styled(SaveButton)`
  top: 70%;

  &:disabled {
    filter: grayscale(100%);

    opacity: 0.6;

    cursor: not-allowed;
  }

  @media screen and (max-width: 650px) {
    top: 90%;

    left: 50%;
    transform: translateX(-50%);

    width: 100px;
    height: 30px;
  }
`;

export const ToggleCoupon = styled(ToggleComponent)``;

export const CouponContainer = styled.div`
  position: relative;

  max-width: 1280px;
  width: 100%;

  background-color: #fff;

  overflow: hidden;

  transition: 0.15s ease;

  padding: 50px;

  border: 2px solid gray;
  border-top: 0px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  margin-bottom: 20px;

  #coupons-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5.5rem;
  }

  @media screen and (max-width: 650px) {
    padding: 30px;
  }

  @media screen and (max-width: 330px) {
    padding: 15px;
  }
`;

export const CouponDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;

  width: 100%;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 4.5rem;

    &:first-child {
      width: 95% !important;
    }
  }
`;

export const CouponHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: #213455;
    padding: 10px;
  }

  @media screen and (max-width: 650px) {
    h3 {
      font-size: 16px;
    }
  }
`;

export const CouponBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  width: 100%;
  height: 100%;

  padding: 16px;
`;

export const CouponInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  width: 100%;

  h4 {
    font-size: 18px;
    font-weight: 600;
    color: #213455;
  }

  p {
    font-size: 16px;
    font-weight: bolder;
    color: #000;
  }

  @media screen and (max-width: 650px) {
    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const NoCoupons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: 100%;

  h4 {
    font-size: 24px;
    font-weight: bold;
    color: #213455;
    text-align: center;
  }

  @media screen and (max-width: 530px) {
    h4 {
      font-size: 20px;
    }

    svg {
      width: 70px;
      height: 70px;
    }
  }
`;
