/* eslint-disable react/prop-types */
import React from 'react';

import logoLeadTrust from '~/assets/LogoLeadTrust.svg';

import Satisfacion from './components/Satisfaction';

import { Container } from './styles';

export default function LeadTrust({
  clickable = false,
  sellerScore,
  buyerScore,
}) {
  return (
    <Container>
      <div className="LeadTrust">
        <section>
          <Satisfacion
            clickable={clickable}
            score={sellerScore}
            title="Vendedor"
          />
          <Satisfacion
            score={buyerScore}
            clickable={clickable}
            title="Comprador"
          />
        </section>

        {/* <div className="logo-resume">
          <div className="logoLeadTrust">
            <img alt="LeadTrust" src={logoLeadTrust} />
          </div>

          <div className="LeadTrustResume">
            <p>
              Avaliamos o vendedor e o comprador com base na fidelidade dos
              dados pessoais preenchidos no cadastro e no histórico de compras e
              vendas.
            </p>

            <div>
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </div> */}
      </div>
    </Container>
  );
}
