import React, { useState } from 'react';

const DEFAULT_STATE = {
  state: {
    categories: [],
    initialsUF: { Description: '', Name: '' },
    city: { Description: '', Name: '' },
    useCondition: { Description: '', Name: '' },
    rimSize: { Description: '', Name: '' },
    size: { Description: '', Name: '' },
    forkTravel: { Description: '', Name: '' },
    basePrice: { Description: '', Name: '' },
    limitPrice: { Description: '', Name: '' },
    baseYear: { Description: '', Name: '' },
    limitYear: {
      Description: '',
      Name: '',
    },
  },
  setState: () => { },
};

export const AccordionFilterItemsContext = React.createContext(DEFAULT_STATE);

export default function AccordionContextProvider({ children }) {
  const [accordionFilterItems, setAccordionFilterItems] = useState(
    DEFAULT_STATE.state
  );

  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  return (
    <AccordionFilterItemsContext.Provider
      value={{
        accordionFilterItems,
        setAccordionFilterItems,
        selectedSubcategories,
        setSelectedSubcategories,
      }}
    >
      {children}
    </AccordionFilterItemsContext.Provider>
  );
}
