import React from 'react';

import Helmet from 'react-helmet';
import { Container, Field } from './styles';
import Questions from './components/questions';

import GetInTouch from './components/GetInTouch';

export default function Help() {
  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="n bikes | Uma mão na roda! | Brasil"
        />
        <meta
          property="og:image"
          content="https://s3.us-east-2.amazonaws.com/nbikes.com.br/assets/nbikes_Simbolo.png"
        />
        <meta
          property="og:description"
          content="Site de classificados para bicicletas, peças e acessórios usados e/ou seminovos."
        />
        <title>Ajuda | n Bikes | Brasil</title>
      </Helmet>
      <Container>
        <h2 className="title">Perguntas Frequentes</h2>
        <Field>
          <Questions />
        </Field>

        <h2 className="title">Fale conosco</h2>

        <GetInTouch />
      </Container>
    </>
  );
}
