import styled from 'styled-components';

export const MainContainer = styled.div`
  width: clamp(300px, 100%, 1200px);
  height: 100%;
  position: relative;

  margin: 0 auto;

  padding: 10px;
  margin-bottom: 100px;
`;

export const PageTitle = styled.h2`
  margin: 20px 0;

  @media screen and (max-width: 610px) {
    display: flex;
    justify-content: center;
  }
`;

export const ProductsContainer = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const SelectorWrapper = styled.div`
  margin-top: 10px;

  display: flex;
  gap: 10px;

  @media screen and (max-width: 610px) {
    justify-content: center;
  }
`;

export const Label = styled.label`
  font-size: 20px;
  color: black;
`;

export const Selector = styled.select`
  width: 150px;
  height: 30px;

  font-size: clamp(16px, 2vw, 20px);

  border-radius: 10px;

  padding-left: 10px;
`;

export const SearchBarContainer = styled.div`
  margin-top: 10px;

  display: flex;
  align-items: center;

  @media screen and (max-width: 610px) {
    justify-content: center;
  }
`;

export const SearchBar = styled.input`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  border: 1px solid gray;

  height: 30px;

  padding-left: 10px;

  font-size: clamp(16px, 2vw, 20px);

  width: clamp(180px, 100%, 255px);
`;

export const SearchButton = styled.button`
  height: 30px;
  width: 130px;

  border: 1px 1px 1px 1px;
  border-width: 1px;
  border-color: solid gray;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  font-size: clamp(16px, 2vw, 20px);

  background-color: var(--blueNbikes);

  color: white;
`;

export const NegotiationsNotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: 0.5;

  svg {
    width: 150px;
    height: 150px;

    fill: black;
  }
`;

export const NegotiationsNotFoundText = styled.div`
  font-size: clamp(18px, 2vw, 24px);
  color: black;
`;

export const PagePreviousNext = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-top: 20px;

  div {
    display: flex;
  }

  button {
    width: 100px;
    height: 40px;

    margin: 0 5px;

    transition: 0.2s;

    border: 0;
    border-radius: 4px;

    background: var(--blueNbikes);
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: #37bcc1;
    }
  }
`;
