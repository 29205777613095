import React from 'react';
import Accordion from '../Accordion';
import AccordionItem from '../AccordionItem';
import mocks from '../mocks';
import { Categories } from './styles';

import { useLocation } from 'react-router-dom';

export default function SideMenu() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const currentSection = searchParams.get('section');

  return (
    <Categories>
      <Accordion>
        {mocks.map((item, index) => {
          return (
            <AccordionItem
              expanded={Number(currentSection) === index}
              key={item[0]}
              title={item.questions}
            >
              <div>
                <ul>
                  {item.answers.map((answer) => (
                    <li>{answer}</li>
                  ))}
                </ul>
              </div>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Categories>
  );
}
